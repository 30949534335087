import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    RestService
} from 'bkng';

import { IOrganization } from '@amt/i-organization';
import { IConfig } from '@amt/i-config';
import { IOrganizationConfig } from '@amt/i-organization-config';
import { lastValueFrom } from 'rxjs';
import { EnvService } from './env.service';

import { OrganizationGetOptions } from '@api-routes/organization/types/organization-get-options';
import { OrganizationGetQuery } from '@api-routes/organization/types/organization-get-query';
import { OrganizationGetResponse } from '@api-routes/organization/types/organization-get-response';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `organization`;
    }

    addConfig (organizationId: string, configId: string): Promise<{
        config: IConfig,
        organizationConfig: IOrganizationConfig;
    }> {
        return lastValueFrom(
            this.$post(`id/${organizationId}/config/id/${configId}/add`, {})
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                organizationConfig: IOrganizationConfig;
            }>result;
        });
    }

    create (options: unknown): Promise<{organization: IOrganization}> {
        return lastValueFrom(
            this.$post(`create`, options)
        ).then((res: unknown) => {
            return <{organization: IOrganization}>res;
        });
    }

    getAvailableConfigs (organizationId: string): Promise<IConfig[]> {
        return lastValueFrom(
            this.$get(`id/${organizationId}/available_configs`, {})
        ).then((result: unknown) => {
            return <IConfig[]>result;
        });
    }

    async getById (
        options: OrganizationGetOptions
    ): Promise<OrganizationGetResponse> {
        const queryParams: OrganizationGetQuery = {
            organization_id: options.organizationId,
        };

        const response = await lastValueFrom(
            this.$get(
                "",
                {
                    params: queryParams
                }
            )
        );

        return <OrganizationGetResponse><unknown>response;
    }

    getEmailTemplate (organizationId: string, emailTemplateId: string): Promise<unknown> {
        return lastValueFrom(
            this.$get(
                `email_template`,
                {
                    params: {
                        organization_id: organizationId,
                        email_template_id: emailTemplateId,
                    }
                }
            )
        );
    }

    save (id: string, organization: IOrganization): Promise<unknown> {
        return lastValueFrom(
            this.$post(`id/${id}/save`, organization)
        );
    }
}
