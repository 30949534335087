import {
    Component,
    Input
} from '@angular/core';

import { ICurrency } from '@amt/i-currency';

import {
    IFormControlValidator,
    ISelectListItem,
} from 'bkng';

import { CheckoutComponent } from '../checkout/checkout.component';

import {
    CheckoutSectionComponent
} from '../checkout-section/checkout-section.component';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { Router } from '@angular/router';
import { EnvService } from '@s/env.service';
import { IOrganization } from '@amt/i-organization';

@Component({
    selector: 'app-checkout-section-contribution',
    templateUrl: './checkout-section-contribution.component.html',
    styleUrls: ['./checkout-section-contribution.component.scss'],
    exportAs: 'section'
})
export class CheckoutSectionContributionComponent extends CheckoutSectionComponent {
    @Input() currency: ICurrency;
    @Input() donation: CreateDonationBody;
    @Input() hasFrequencyOption: boolean = true;
    @Input() hasMessageOption: boolean = false;

    amountValidators: IFormControlValidator = {
        nullable: false,
        requirements:{
            amount: {
                error: "Please provide a contribution amount of $1 or more.",
                fn: (): boolean => {
                    if (this.donation.amount >= 1) return true;
                    return false;
                }
            }
        },
        type: "number",
    };

    sectionName: string = "contribution";
    selectedOrganizationId: string;

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public router: Router,
    ) {
        super(checkout, envService, router);
    }

    clearOtherAmount (): void {
        delete this.donation.otherAmount;
        this.setAmount(this.donation.presetAmount);
    }

    clearPresetAmount (): void {
        delete this.donation.presetAmount;
        this.setAmount(this.donation.otherAmount);
    }

    onInit (): void {
        if (this.donation?.organization?.id) {
            this.selectedOrganizationId = this.donation.organization.id;
        }
    }

    orgDropDownDisplay (
        sli: ISelectListItem<IOrganization>
    ): string {
        if (
            sli?.data.data?.donationDropDownLabel
        ) {
            return sli.data.data.donationDropDownLabel;
        }
        return sli?.data?.label || '';
    }

    setAmount (
        amount: number | string
    ): void {
        /*
        if (amount) {
            amount = amount.toString();
            const numRegexp = /[0-9]+([,.][0-9]+)?/g;
            const result = numRegexp.exec(amount);
            if (result && result[0]) {
                amount = result[0];
            }
        }
        */

        const parsedAmount = parseFloat(
            String(amount)
        );

        this.donation.amount = parsedAmount;

        if (!amount || (parsedAmount < 1)) {
            delete this.donation.amount;
        }
    }

    setOrganization (): void {
        const orgToSet = this.env.organizations.filter((org) => {
            return this.selectedOrganizationId === org.id;
        })[0];

        if (orgToSet) {
            this.organizationChange.emit(orgToSet);
        }
    }
}
