import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IConfig } from '@amt/i-config';
import { IDomain } from '@amt/i-domain';
import { IEmailTemplate } from '@amt/i-email-template';

import {
    Blocker,
    FileUploadButtonComponent,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { MerchantService } from '@s/merchant.service';
import { IMerchant } from '@amt/i-merchant';
import { ICampaign } from '@amt/i-campaign';
import { IEnv } from '@alt/i-env';
import { MerchantPostBody } from '@api-routes/merchant/types/merchant-post-body';

@Component({
    selector: 'app-merchant-view',
    templateUrl: './merchant-view.component.html',
    styleUrls: ['./merchant-view.component.scss']
})
export class MerchantViewComponent implements OnInit {
    blocker: Blocker;
    busy: boolean = false;
    campaigns: Array<ICampaign>;
    configs: Array<IConfig>;
    domains: Array<IDomain>;
    emailTemplates: Array<IEmailTemplate>;
    env: IEnv;
    logoFileToUpload?: globalThis.File;
    merchant: IMerchant;
    saveSubject = new Subject();

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public merchantService: MerchantService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch(console.error);
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    downloadDonationCsv (): void {
        this.busy = true;
        this.merchantService.getDonationCsv(this.merchant.id).subscribe({
            next: (data) => {
                this.downloadFile(data);
            },
            error: (error) => {
                console.log(`Error downloading donations report CSV`, error);
                this.busy = false;
            },
            complete: () => {
                this.busy = false;
            }
        });
    }

    downloadCadDonationCsv (): void {
        this.busy = true;
        this.merchantService.getCadDonationCsv(this.merchant.id).subscribe({
            next: (data) => {
                this.downloadFile(data);
            },
            error: (error) => {
                console.log(`Error downloading CAD donations report CSV`, error);
                this.busy = false;
            },
            complete: () => {
                this.busy = false;
            }
        });
    }

    downloadFile (data: BlobPart): void {
        const blob = new Blob([data], { type: `text/csv` });
        const url = window.URL.createObjectURL(blob); // would be nice to name this file.
        window.open(url);
    }

    async fetch (): Promise<void> {
        const response = await this.merchantService.getById(
            <string>(this.route.snapshot.params.merchantId)
        );

        console.log("MerchantViewComponent got:", response);
        Object.assign(this, response);
    }

    logoFileSelected (
        fileUploadButtonComponent: FileUploadButtonComponent
    ): void {
        this.logoFileToUpload = fileUploadButtonComponent.fileToUpload;
    }

    ngOnInit (): void {
    }

    async save (
    ): Promise<void> {
        if (
            this.busy
        ) {
            return Promise.resolve();
        }

        this.busy = true;

        try {
            const body: MerchantPostBody = {
                merchant: {
                    label: this.merchant.label,
                    data: this.merchant.data,
                }
            };

            const response = await this.merchantService.save({
                merchantId: this.merchant.id,
                body: body,
                logoFile: this.logoFileToUpload,
            });

            delete this.logoFileToUpload;

            console.log("Saved merchant response:", response);

            Object.assign(this.merchant, response.merchant);

            console.log("ASSIGNED");
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setTimeout(() => {
                this.busy = false;
            }, 125);
        }
    }
}
