import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AddDomainDialogComponent, IAddDomainDialogData } from '../add-domain-dialog/add-domain-dialog.component';
import { DomainPostResult } from '@api-routes/domain/types/domain-post-result';

@Component({
    selector: 'app-add-domain',
    templateUrl: './add-domain.component.html',
    styleUrls: ['./add-domain.component.scss']
})
export class AddDomainComponent implements OnInit {
    @Output() created = new EventEmitter<DomainPostResult>();
    @Input() data: IAddDomainDialogData = {};

    constructor (
        public dialog: MatDialog,
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit (): void {
    }

    openDialog (): MatDialogRef<AddDomainDialogComponent> {
        this.data.route = this.route;

        const dialogRef = this.dialog.open(AddDomainDialogComponent, {
            width: '556px',
            data: this.data
        });

        dialogRef.afterClosed().subscribe((result: DomainPostResult) => {
            if (result?.domain?.id) {
                this.created.emit(result);
            }
        });

        return dialogRef;
    }
}
