<div *ngIf="page?.id">
    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Page Label</mat-label>
            <input matInput type="text" [name]="'PageLabel'" [(ngModel)]="page.label" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Page Title</mat-label>
            <input matInput type="text" [name]="'PageTitle'" [(ngModel)]="page.title" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Pre-Title</mat-label>
            <input matInput type="text" [name]="'PageTitle'" [(ngModel)]="page.data.preTitle" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey; display: flex; justify-content: space-between; flex-wrap: wrap;">
        <mat-form-field style="flex: 1 0 100%;">
            <mat-label>Landing Video Embed URL</mat-label>
            <input matInput type="text" [name]="'LandingVideoEmbedUrl'" [(ngModel)]="page.data.landingVideoEmbedUrl" (ngModelChange)="doSave()" />
        </mat-form-field>
        <mat-form-field style="flex: 1 1 49%;">
            <mat-label>Landing Video Width</mat-label>
            <input matInput type="number" [name]="'LandingVideoWidth'" [(ngModel)]="page.data.landingVideoWidth" (ngModelChange)="doSave()" />
        </mat-form-field>
        <mat-form-field style="flex: 1 1 49%;">
            <mat-label>Landing Video Height</mat-label>
            <input matInput type="number" [name]="'LandingVideoHeight'" [(ngModel)]="page.data.landingVideoHeight" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Left Callout Title</mat-label>
            <input matInput type="text" [name]="'LeftCalloutTitle'" [(ngModel)]="page.data.leftCalloutTitle" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Left Callout Text</mat-label>
            <textarea matInput [name]="'LeftCalloutText'" [(ngModel)]="page.data.leftCalloutText" (ngModelChange)="doSave()" rows="5"></textarea>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Right Callout Title</mat-label>
            <input matInput type="text" [name]="'RightCalloutTitle'" [(ngModel)]="page.data.rightCalloutTitle" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Right Callout Text</mat-label>
            <textarea matInput [name]="'RightCalloutText'" [(ngModel)]="page.data.rightCalloutText" (ngModelChange)="doSave()" rows="5"></textarea>
        </mat-form-field>
    </div>
</div>
