import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { RestService } from 'bkng';
import { EnvService } from './env.service';
import { ISubscription } from '@amt/i-subscription';

@Injectable({
    providedIn: 'root'
})
export class AccountService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `account`;
    }

    cancelSubscription (
        subscription: ISubscription
    ): Observable<ArrayBuffer> {
        return this.$delete(`subscriptions/id/${subscription.id}`);
    }

    get (
        params: unknown = {}
    ): Promise<unknown> {
        return lastValueFrom(this.$get(``, {
            params
        }));
    }

    resendEmailVerification (userId: string, emailId: string): Promise<unknown> {
        return lastValueFrom(this.$post(`user/id/${userId}/resend/email/verification/${emailId}`, {}));
    }
}
