<div class="add-something-dialog">
    <form #createNewForm="ngForm" (ngSubmit)="createCampaign()">
        <div>
            <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'CampaignLabel'" placeholder="i.e. Trailblazer" [(ngModel)]="newCampaign.label" required />
            </mat-form-field>
        </div>
        <div>
            <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="busy || !createNewForm.valid"
                (click)="createNewForm.ngSubmit.emit()"
            >Create</button>
        </div>
    </form>
</div>
