import { HttpClient } from '@angular/common/http';
import {
    Injectable,
    // InjectionToken,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    lastValueFrom,
    Subject,
} from 'rxjs';

import * as utils from './utils';

interface FrontEndData {
    csrfToken?: string;
    head?: {
        faviconPath?: string;
        title?: string;
    };
    mode?: string;
    revision?: {
        git?: string;
        docker?: string;
    };
    static?: boolean; // served-up by an nginx placeholder?
    user?: {
        id?: string;
    };
}

// export const ENV_SERVICE: InjectionToken<EnvService> =
//     new InjectionToken<EnvService>('Env service token');

let windowEventListenerAdded: boolean = false;

@Injectable({
    providedIn: 'root'
})
export class CommonEnvService <
    DATA = unknown
> {
    data: DATA;
    gotData: Subject<DATA> = new Subject();
    url: string = '/environment.json';

    constructor (
        public http: HttpClient,
        public titleService: Title,
    ) {
    }

    addWindowEventListener (): void {
        if (
            !windowEventListenerAdded
        ) {
            window.addEventListener('focus', (e: FocusEvent) => {
                this.get({
                    action: 'windowfocus'
                }).catch((err) => {
                    throw err;
                });
            });
            windowEventListenerAdded = true;
        }
    }

    bootstrap (queryParams: {
        [key: string]: unknown;
    } = {}): Promise<DATA> {
        queryParams.bootstrap = 1;

        return this.get(queryParams).then((result) => {
            this.addWindowEventListener();
            return result;
        }).catch((err) => {
            this.addWindowEventListener();
            throw err;
        });
    }

    async get (
        params: {
            action?: string;
            bootstrap?: 1;
            t?: string;
            useraction?: 1;
            user_action?: string;
            signin?: 1;
        } = {}
    ): Promise<DATA> {
        params.t = `${(new Date()).getTime()}`;

        const resp = await this.httpGet(
            params
        );

        this.data = this.data || resp;
        const env = <FrontEndData>(this.data);
        const incomingEnvData = <FrontEndData>resp;

        if (incomingEnvData.revision && env.revision) {
            const incomingRevision = incomingEnvData.revision;
            const existingRevision = env.revision;
            let mismatch = false;
            if (incomingRevision.docker && existingRevision.docker && (incomingRevision.docker !== existingRevision.docker)) {
                console.warn('Docker revision mismatch', env.revision, incomingEnvData.revision);
                mismatch = true;
            }
            if (incomingRevision.git && existingRevision.git && (incomingRevision.git !== existingRevision.git)) {
                console.warn('Git revision mismatch', env.revision, incomingEnvData.revision);
                mismatch = true;
            }
            if (mismatch) {
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                return resp;
            }
        }
        else if (!incomingEnvData.static && env.static) {
            console.warn('No longer static');
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return resp;
        }

        /*if (env.user && !incomingEnvData.user) {
            console.warn(`User logged-out.`);
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return resp;
        }*/

        if (env.user?.id && !incomingEnvData.user?.id) {
            console.warn(`EnvService: User logged-out.`);
            delete env.user; // trying to avoid "stale" logged-in state
        }

        // Object.assign(env, incomingEnvData);
        utils.updateObject(env, incomingEnvData);

        if (params.bootstrap && env.mode !== 'production') {
            console.log('Env', env);
        }

        if (params.bootstrap && env?.head?.title) {
            // might not be the best way to do this.
            this.titleService.setTitle(env.head.title);
        }

        this.gotData.next(
            <DATA>env
        );

        return resp;
    }

    async httpGet (
        params: Record<string, string | number>,
    ): Promise<DATA> {
        const data = <DATA>(await lastValueFrom(
            this.http.get(
                this.url,
                {params}
            )
        ));

        return data;
    }
}
