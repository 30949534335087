import { BkRoutes } from 'bkng';

import { email } from './email/email';
import { password } from './password/password';

export const account: BkRoutes = [
    {
        path: "email",
        label: "Email",
        children: email,
    },
    {
        path: "password",
        label: "Password",
        children: password,
    },
];
