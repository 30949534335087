
<div class="checkout-section" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title"><span class="num">{{number}}.</span><span>Your e-mail</span></div>
        <div class="checkout-section-edit-button" *ngIf="isEditable() && !env.user?.id"><span (bkClick)="edit()">Edit</span></div>
    </div>
    <form name="emailForm" bkForm #emailForm="bkForm" [ngClass]="{'bk-hidden': !active || !enabled}" [appCheckoutSectionValidator]="sectionValidateForm(emailForm)" [checkoutSection]="this">
        <div class="input-holder" bkFormControlHolder #fch="bkFormControlHolder">
        <div class="input-label" *ngIf="!donor.email">Email</div>
        <input type="email" name="email" [(ngModel)]="donor.email" required (keyup.enter)="next()" bkFormControl>
        <bk-validation-display></bk-validation-display>
        <div class="input-customerinfo" *ngIf="!(emailForm.submissionAttempted && fch.control.errors)"><span>You'll receive receipts and notifications at this email address.</span></div>
        </div>
        <div class="button-holder">
        <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>
    <div class="checkout-section-detail-view" *ngIf="!active && (activated || completed || donor.email || env.user?.email)">
        <div [innerHtml]="donor.email"></div>
    </div>
</div>
