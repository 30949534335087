import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EnvService } from '@s/env.service';

import { IPage } from '@amt/i-page';
import { PageService } from '@s/page.service';

@Component({
    selector: 'app-page-edit',
    templateUrl: './page-edit.component.html',
    styleUrls: ['./page-edit.component.scss']
})
export class PageEditComponent implements OnInit {
    @Input() page: IPage;
    saveSubject = new Subject();

    constructor (
        public envService: EnvService,
        public pageService: PageService,
    ) {
        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch(console.error);
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    ngOnInit (): void {
    }

    save (): Promise<void> {
        const data: {
            page: IPage;
        } = {
            page: this.page,
        };
        return this.pageService.save(this.page.id, data).then((res) => {
            return Promise.resolve();
        });
    }
}
