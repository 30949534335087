
<div class="checkout-section review-section" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title"><span class="num">{{number}}.</span><span>Review &amp; Donate</span></div>
        <div class="checkout-section-edit-button" *ngIf="isEditable()"><span (click)="edit()">Edit</span></div>
    </div>
    <div class="review-section-content" *ngIf="active">
        <div class="review-section-message"><span>Review your information and donate when you’re ready.</span></div>
        <form bkForm name="donateForm" #donateForm="bkForm" [appCheckoutSectionValidator]="sectionValidateForm(donateForm)" [checkoutSection]="this">
        <div class="input-holder checkbox-input-holder" bkFormControlHolder *ngIf="giveCreateAccountOption()">
            <input bkFormControl type="checkbox" name="createAccountCheckbox" [(ngModel)]="donation.createAccount">
            <div class="checkbox-input-label muted"><span>Save payment details for future donations</span></div>
        </div>
        <div class="input-holder" bkFormControlHolder *ngIf="showCreateAccountPasswordField()">
            <div class="input-label" *ngIf="!donation.donor.password">Password</div>
            <input bkFormControl #passControl="bkFormControl" type="password" name="password" [(ngModel)]="donation.donor.password" required (keyup.enter)="donate()" autocomplete="new-password" [validatorName]="'new-password'">
            <bk-validation-display-hints [ngClass]="{'bk-hidden': !passControl.control?.dirty && !passControl.control?.touched && !donateForm.submissionAttempted}"></bk-validation-display-hints>
        </div>
        </form>
        <div class="review-section-message" *ngIf="!env.user?.id && (donation.interval !== 'once')"><span>As you have chosen a recurring donation cycle, you will need to create an account with a password in order to manage your billing options.</span></div>
        <div class="review-section-message" *ngIf="!env.user?.id && (donation.interval === 'once') && donation.savePaymentDetails"><span>As you have chosen to save your payment details, you will need to create an account with a password in order to manage your billing options.</span></div>
        <div form-server-errors-display></div>
        <div class="button-holder">
        <app-button (action)="donate()" [busy]="donating">Donate</app-button>
        </div>
    </div>
</div>
