import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';

import { IConfig } from '@amt/i-config';

import { AddConfigDialogComponent, IAddConfigDialogData } from '../add-config-dialog/add-config-dialog.component';

@Component({
    selector: 'app-add-config',
    templateUrl: './add-config.component.html',
    styleUrls: ['./add-config.component.scss']
})
export class AddConfigComponent implements OnInit {
    @Input() data: IAddConfigDialogData;
    @Output() selected = new EventEmitter<IConfig>();

    constructor (
        public dialog: MatDialog,
    ) { }

    ngOnInit (): void {
    }

    openAddConfigDialog (): MatDialogRef<AddConfigDialogComponent> {
        const dialogRef = this.dialog.open(AddConfigDialogComponent, {
            width: '364px',
            data: this.data
        });

        dialogRef.afterClosed().subscribe((result: IConfig) => {
            if (result) {
                this.selected.emit(result);
            }
        });

        return dialogRef;
    }
}
