import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    ControlContainer,
    NgForm,
    // NgModelGroup,
} from '@angular/forms';

import { Address } from '@alt/address';
import { ContactInfo } from '@alt/contact-info';
import { Phone } from '@alt/phone';

import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-form-inputs-person-contact-info',
    templateUrl: './form-inputs-person-contact-info.component.html',
    styleUrls: ['./form-inputs-person-contact-info.component.scss'],
    viewProviders: [
        // {
        //     provide: ControlContainer,
        //     useExisting: NgModelGroup
        // },
        {
            provide: ControlContainer,
            useExisting: NgForm
        },
    ],
})
export class FormInputsPersonContactInfoComponent implements OnInit {
    @Input() addressData: Partial<Address>;
    @Input() disableAllInputs: boolean = false;
    @Input() personData: Partial<ContactInfo>;
    @Input() phoneData: Phone;
    @Input() showInputLabels: boolean = true;

    @Output() onPressedEnter = new EventEmitter<Event>();

    env: IEnv;
    test?: string;

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    enter ($event: unknown): void {
        this.onPressedEnter.emit(<Event>$event);
    }

    ngOnInit (): void {
    }

    setSelectedCountry (
        selectListItem: {
            data?: unknown;
        },
        targetObject: {
            selectedCountry?: unknown;
        }
    ): void {
        targetObject.selectedCountry = selectListItem?.data;
    }
}
