import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AddCampaignDialogComponent, IAddCampaignDialogData, IAddCampaignDialogReturnData } from '../add-campaign-dialog/add-campaign-dialog.component';

@Component({
    selector: 'app-add-campaign',
    templateUrl: './add-campaign.component.html',
    styleUrls: ['./add-campaign.component.scss']
})
export class AddCampaignComponent implements OnInit {
    @Output() created = new EventEmitter<IAddCampaignDialogReturnData>();
    @Input() data: IAddCampaignDialogData = {};

    constructor (
        public dialog: MatDialog,
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit (): void {
    }

    openDialog (): MatDialogRef<AddCampaignDialogComponent> {
        this.data.route = this.route;

        const dialogRef = this.dialog.open(AddCampaignDialogComponent, {
            width: '556px',
            data: this.data
        });

        dialogRef.afterClosed().subscribe((result: IAddCampaignDialogReturnData) => {
            if (result?.campaign?.id) {
                this.created.emit(result);
            }
        });

        return dialogRef;
    }
}
