import { LoginComponent } from '@c/login/login.component';
import { BkRoutes } from 'bkng';
import { outletNotLoggedIn } from './outlet-not-logged-in';
import { outletTeamDonationCampaign } from './outlet-team-donation-campaign';

// import { UserLoginComponent } from '@c/user-login/user-login.component';

import { routeStartingPoint } from './route-starting-point';

/*
import { CreateAccountComponent } from '@c/create-account/create-account.component';
import { ResetPasswordComponent } from '@c/reset-password/reset-password.component';
import { CreateAccountSuccessComponent } from '@c/create-account-success/create-account-success.component';
import { NotLoggedInGuard } from '@c/not-logged-in.guard';
import { SentPasswordResetEmailComponent } from '@c/sent-password-reset-email/sent-password-reset-email.component';
*/

export const auth: BkRoutes = [
    {
        label: "Authorization",
        path: "",
        propagate: true,
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: LoginComponent}),
            ])
        ]),
        /*children: [
            {
                label: "Create Account",
                path: "create-account",
                ngRoute: ngRouteStartingPoint([
                    outletNotLoggedIn({canActivate: [NotLoggedInGuard], children: [
                        outletUserAuth({component: CreateAccountComponent})
                    ]})
                ]),
                children: [
                    {
                        label: "Create Account: Success",
                        path: "success",
                        ngRoute: ngRouteStartingPoint([
                            outletLoggedIn({children: [
                                outletUserAuth({component: CreateAccountSuccessComponent})
                            ]}),
                            outletNotLoggedIn({children: [
                                outletUserAuth({component: CreateAccountSuccessComponent})
                            ]}),
                        ]),
                    }
                ],
            },
            {
                label: "Reset Password",
                path: "reset-password",
                ngRoute: ngRouteStartingPoint([
                    outletNotLoggedIn({canActivate: [NotLoggedInGuard], children: [
                        outletUserAuth({component: ResetPasswordComponent})
                    ]})
                ]),
                children: [
                    {
                        label: "Reset Password: Success",
                        path: "success",
                        ngRoute: ngRouteStartingPoint([
                            outletLoggedIn({children: [
                                outletUserAuth({component: SentPasswordResetEmailComponent})
                            ]}),
                            outletNotLoggedIn({children: [
                                outletUserAuth({component: SentPasswordResetEmailComponent})
                            ]}),
                        ]),
                    }
                ]
            }
        ]*/
    },
];
