import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    RestService
} from 'bkng';

import { IPage } from '@amt/i-page';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class PageService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService,
    ) {
        super(http, envService);
        this.namespace = `page`;
    }

    create (options: unknown): Promise<unknown> {
        return lastValueFrom(this.$post(`create`, options));
    }

    delete (pageId: string): Promise<unknown> {
        return lastValueFrom(this.$delete(`id/${pageId}`));
    }

    getById (id: string): Promise<unknown> {
        return lastValueFrom(this.$get(``, {
            params: {
                id
            }
        }));
    }

    save (id: string, data: {
        page: IPage;
    }): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${id}/save`, data));
    }
}
