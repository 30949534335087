<div
    class="donate"
    [ngClass]="{'showing': !env?.user?.id}"
    *ngIf="clearedToLoad"
>
    <app-header-holder></app-header-holder>

    <div class="checkout-holder">
        <div class="checkout-content">
            <div
                class="checkout-section checkout-section-donor-info active"
                [ngClass]="{
                    'checkout-section-large': (env.user?.email?.[0]?.confirmed ? true : false)
                }"
            >
                <div class="checkout-section-title-holder">
                    <div class="checkout-section-title">
                        <!-- <span class="num">{{number}}.</span> -->
                        <span>Sign in</span>
                    </div>
                </div>

                <div>
                    <app-login-form
                        [credentials]="credentials"
                        (forgotPassword)="forgotPass()"
                        (success)="loggedIn($event)"
                    ></app-login-form>
                </div>
            </div>
        </div>
    </div>
</div>
