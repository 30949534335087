<div *ngIf="!result">
    <div class="title-holder">
        <div class="title">Password reset</div>
        <div class="subtext">We'll send you a reset-link</div>
    </div>

    <div mat-dialog-content>
        <form bkForm #resetPassForm="bkForm" (bkSubmit)="resetPass(resetPassForm)">
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label" *ngIf="!credentials.email">Email</div>
                <input
                    type="email"
                    name="email"
                    [(ngModel)]="credentials.email"
                    required
                    (keyup.enter)="resetPassForm.submit()"
                    bkFormControl
                />
                <bk-validation-display></bk-validation-display>
            </div>
            <bk-form-server-errors></bk-form-server-errors>
        </form>
    </div>

    <div>
        <div class="button-holder">
            <app-button (action)="resetPassForm.submit()" [busy]="busy" [disabled]="!resetPassForm.ngForm.valid">Send reset link</app-button>
        </div>
        <div class="something-else">
            <div class="link" (bkButton)="signIn()">Sign-in</div>
            <div class="separator"></div>
            <div class="link" (bkButton)="close()">Cancel</div>
        </div>
    </div>
</div>

<div *ngIf="result">
    <div class="title-holder">
        <div class="title">Done!</div>
        <div class="subtext">A password-reset link has been sent to your e-mail address.</div>
    </div>

    <p>Please check your e-mail for the password-reset link.</p>
    
    <div class="button-holder">
        <div class="button" (bkButton)="close()">Close</div>
    </div>
</div>
