<div class="platform-view-component" *ngIf="organization?.id">
    <div>
        <span>Organization: {{organization.label}}</span>
    </div>
    <div style="padding-top: 15px;" *ngIf="emailTemplates?.length">
        <div *ngFor="let et of emailTemplates">
            <a [routerLink]="['template', 'id', et.id]">{{et.label || et.name || et.id || 'something is wrong'}}</a>
        </div>
    </div>
</div>
