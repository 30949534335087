import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { ICampaignBenefactor, ICampaignBenefactorContribution } from '@amt/i-campaign-benefactor';
import { ICampaignParticipantBenefactor } from '@amt/i-campaign-participant-benefactor';
import { EnvService } from '@s/env.service';
import { ICampaignParticipantMissionBenefactor } from '@amt/i-campaign-participant-mission-benefactor';

@Component({
    selector: 'app-crowdfund-supporter-tile',
    templateUrl: './crowdfund-supporter-tile.component.html',
    styleUrls: ['./crowdfund-supporter-tile.component.scss']
})
export class CrowdfundSupporterTileComponent implements OnInit {
    contributions: Array<ICampaignBenefactorContribution> = [];
    @Input() supporter: ICampaignBenefactor | ICampaignParticipantBenefactor | ICampaignParticipantMissionBenefactor;

    constructor (
        public env: EnvService,
    ) {
    }

    getForDisplayAmountDonated (): number {
        // obviously will need improvement.

        // const env = this.env;
        const supporter = this.supporter;

        // (supporter?.info?.amountByCurrency?.[env.campaign.data.fundraisingGoalCurrencyCode] || 0);

        let amount = 0;

        if (supporter?.info?.amountByCurrency && Object.keys(supporter.info.amountByCurrency).length) {
            const amountsArray = Object.keys(supporter.info.amountByCurrency).map((currKey) => {
                return supporter.info.amountByCurrency[currKey];
            });
            amount = Math.max(...amountsArray);
        }

        return amount;
    }

    ngOnInit (): void {
        this.contributions = (this.supporter.info?.contributions || []).filter((c) => {
            return (c.inMemoryOrHonor?.value || c.message);
        });
    }

}
