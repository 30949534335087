<div>
    <div *ngIf="personData">
        <div class="inputs-sidebyeaches two-sidebyeaches">
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label-above" *ngIf="showInputLabels">First name</div>
                <div class="input-label" *ngIf="!showInputLabels && !personData.firstName">First name</div>
                <input bkFormControl name="personContactInfoFirstName" type="text" [(ngModel)]="personData.firstName" required (keyup.enter)="enter($event)" autocomplete="given-name">
                <bk-validation-display></bk-validation-display>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label-above" *ngIf="showInputLabels">Last name</div>
                <div class="input-label" *ngIf="!showInputLabels && !personData.lastName">Last name</div>
                <input bkFormControl name="personContactInfoLastName" type="text" [(ngModel)]="personData.lastName" required (keyup.enter)="enter($event)" autocomplete="family-name">
                <bk-validation-display></bk-validation-display>
            </div>
        </div>
    </div>

    <div *ngIf="addressData">
        <div class="input-holder" bkFormControlHolder>
            <div class="input-label-above" *ngIf="showInputLabels">Address 1</div>
            <div class="input-label" *ngIf="!showInputLabels && !addressData.address1">Address 1</div>
            <input bkFormControl name="personContactInfoAddress1" type="text" [(ngModel)]="addressData.address1" required (keyup.enter)="enter($event)" autocomplete="address-line1">
            <bk-validation-display></bk-validation-display>
        </div>
        <div class="input-holder" bkFormControlHolder>
            <div class="input-label-above" *ngIf="showInputLabels">Address 2</div>
            <div class="input-label" *ngIf="!showInputLabels && !addressData.address2">Address 2</div>
            <input bkFormControl name="personContactInfoAddress2" type="text" [(ngModel)]="addressData.address2" (keyup.enter)="enter($event)" autocomplete="address-line2">
            <bk-validation-display></bk-validation-display>
        </div>
        <div class="input-holder" bkFormControlHolder>
            <div class="input-label-above" *ngIf="showInputLabels">Country</div>
            <div class="input-label" *ngIf="!showInputLabels && !addressData.country_code">Country</div>
            <bk-select-countries
                bkFormControl
                name="personContactInfoCountry"
                [(ngModel)]="addressData.country_code"
                [selectList]="env.countries"
                autocomplete="country"
                (itemChange)="setSelectedCountry($event, addressData)"
                required
            ></bk-select-countries>
            <bk-validation-display></bk-validation-display>
        </div>
        <div class="inputs-sidebyeaches three-sidebyeaches">
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label-above" *ngIf="showInputLabels">{{addressData.selectedCountry?.data.postalCodeTitle || 'Postal code'}}</div>
                <div class="input-label" *ngIf="!showInputLabels && !addressData.postal_code">{{addressData.selectedCountry?.data.postalCodeTitle || 'Postal code'}}</div>
                <input bkFormControl name="personContactInfoPostalCode" type="text" [(ngModel)]="addressData.postal_code" required (keyup.enter)="enter($event)" autocomplete="postal-code">
                <bk-validation-display></bk-validation-display>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label-above" *ngIf="showInputLabels">City</div>
                <div class="input-label" *ngIf="!showInputLabels && !addressData.city">City</div>
                <input bkFormControl name="personContactInfoCity" type="text" [(ngModel)]="addressData.city" required (keyup.enter)="enter($event)" autocomplete="address-level2">
                <bk-validation-display></bk-validation-display>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label-above" *ngIf="showInputLabels">{{addressData.selectedCountry?.data.regionTitle || 'Region'}}</div>
                <div class="input-label" *ngIf="!showInputLabels && !addressData.state">{{addressData.selectedCountry?.data.regionTitle || 'Region'}}</div>
                <input bkFormControl name="personContactInfoState" type="text" [(ngModel)]="addressData.state" required (keyup.enter)="enter($event)" autocomplete="address-level1">
                <bk-validation-display></bk-validation-display>
            </div>
        </div>
    </div>

    <div class="input-holder" *ngIf="phoneData">
        <div class="input-label-above" *ngIf="showInputLabels">Phone number</div>
        <bk-phone-number-input
            [countries]="env.countries"
            [phone]="phoneData"
            (pressedEnter)="enter($event)"
            required
        ></bk-phone-number-input>
    </div>
</div>
