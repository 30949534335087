<mat-tab-group *ngIf="campaignParticipant?.id && person?.id">
    <mat-tab label="Profile">
        <app-campaign-participant-profile-tab-profile
            [campaignParticipant]="campaignParticipant"
            [participant]="participant"
            [person]="person"
        >
        </app-campaign-participant-profile-tab-profile>
    </mat-tab>
    <mat-tab label="Mission">
        <app-campaign-participant-profile-tab-mission
            [campaign]="campaign"
            [campaignParticipantMission]="campaignParticipantMission"
            [participant]="participant"
            (createdMission)="createdMission.emit($event)"
        >
        </app-campaign-participant-profile-tab-mission>
    </mat-tab>
</mat-tab-group>
