<div class="platform-view-component" *ngIf="config?.id">
    <div class="janky-back-link">
        <a [routerLink]="['../']">
            <mat-icon [color]="'primary'">keyboard_backspace</mat-icon>
        </a>
    </div>
    
    <div>Config: {{config.label}}</div>
    <div style="font-size: 12px; padding-top: 5px;">Type: {{configType.label}}</div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey; display: flex;">
        <div style="flex: 1 0 auto;">
            <mat-form-field style="width: 100%;">
                <mat-label>Config Label</mat-label>
                <input matInput type="text" [name]="'ConfigLabel'" [(ngModel)]="config.label" (ngModelChange)="doSave()" />
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey; display: flex;" *ngIf="['domain'].includes(configType.name)">
        <mat-form-field style="flex: 1 0 auto;">
            <mat-label>Domain Config Priority</mat-label>
            <input matInput type="number" [name]="'DomainConfigPriority'" [(ngModel)]="domainConfig.priority" (ngModelChange)="doSave()" />
        </mat-form-field>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="['domain'].includes(configType.name)">
        <div style="padding-bottom: 15px;">Layout:</div>
        <div style="display: flex; flex-wrap: wrap;">
            <div style="flex: 0 0 50%; min-width: 350px;">
                <mat-form-field appearance="fill" style="width: 100%;">  
                    <mat-label>Domain Layout:</mat-label>
                    <mat-select [name]="'SelectedDomainLayoutName'" [(ngModel)]="config.public.layoutName" (ngModelChange)="doSave()">
                        <mat-option *ngFor="let lo of layouts" [value]="lo.name">
                            <span>{{lo.label || lo.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="['domain'].includes(configType.name)">
        <div style="padding-bottom: 15px;">Banner image:</div>
        <div class="photo">
            <div class="image">
                <div
                    class="img"
                    style="width: 250px; height: 100px; background-repeat: no-repeat; background-position: center center; background-size: cover;"
                    [ngStyle]="{
                        'background-image': 'url(' + (config | domainConfigBannerPhoto) + ')'
                    }"
                ></div>
            </div>

            <div class="upload-photo">
                <bk-upload-file
                    [accept]="'.jpg, .jpeg, .png'"
                    [uploadMethod]="uploadBannerPhoto"
                >
                    <span>Upload banner photo</span>
                </bk-upload-file>
            </div>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="['domain'].includes(configType.name)">
        <div style="padding-bottom: 15px;">Page:</div>
        <div *ngIf="!page">
            <button mat-raised-button color="primary" (click)="createNewPage()">
                <span>New Page</span>
            </button>
        </div>
        <div *ngIf="page?.id">
            <app-page-edit [page]="page"></app-page-edit>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="['domain', 'organization'].includes(configType.name)">
        <div style="padding-bottom: 15px;">Campaign:</div>
        <div>
            <mat-form-field appearance="fill" style="min-width: 350px;">  
                <mat-label>Campaign</mat-label>
                <mat-select [name]="'SelectedCampaign'" [(ngModel)]="configCampaign.campaign_id" (ngModelChange)="setCampaign()">
                    <mat-option *ngFor="let ac of availableCampaigns" [value]="ac.id">
                        <span>{{ac.label || ac.name || ac.id}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- <div style="padding-top: 15px;" *ngIf="campaign?.id">
            <app-campaign-edit [campaign]="campaign"></app-campaign-edit>
        </div> -->
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'domain'">
        <div style="padding-bottom: 15px;">Default organization:</div>
        <div *ngIf="defaultOrganization?.id">
            <div>{{defaultOrganization.label}}</div>
        </div>
        <div *ngIf="!defaultOrganization?.id">
            <div>No default organization.</div>
            <div style="padding-top: 25px;">
                <app-add-organization [data]="addDefaultOrganizationData" (selected)="addDefaultOrganization($event)">
                    <span>Add default</span>
                </app-add-organization>
            </div>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'domain'">
        <div style="padding-bottom: 15px;">Country default organization:</div>
        <div *ngIf="countryDefaultOrganizations?.length">
            <div *ngFor="let cdo of countryDefaultOrganizations">
                <span>{{cdo.country.name}} ({{cdo.country.code}}): {{cdo.label}}</span>
            </div>
        </div>
        <div *ngIf="!countryDefaultOrganizations?.length">
            <div>No country default organizations.</div>
        </div>
        <div style="padding-top: 25px;">
            <app-add-organization [data]="addCountryDefaultOrganizationData" (selected)="addCountryDefaultOrganization($event)">
                <span>Add country default</span>
            </app-add-organization>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'organization'">
        <div style="padding-bottom: 15px;">Email templates:</div>
        <div *ngIf="emailTemplates?.length">
            <div *ngFor="let et of emailTemplates">
                <span>{{et.label}}</span>
            </div>
        </div>
        <div *ngIf="!emailTemplates?.length">
            <div>No email templates.</div>
            <div style="padding-top: 25px;">
                <app-add-email-template
                    [data]="addEmailTemplateData"
                    (selected)="addEmailTemplate($event)"
                >
                    <span>Add email template</span>
                </app-add-email-template>
            </div>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'organization'">
        <div style="padding-bottom: 15px;">Currency:</div>
        <div>
            <mat-form-field appearance="fill" style="min-width: 350px;">  
                <mat-label>Currency</mat-label>
                <mat-select [name]="'SelectedCurrency'" [(ngModel)]="configCurrency.currency_code" (ngModelChange)="setCurrency()">
                    <mat-option *ngFor="let c of availableCurrencies" [value]="c.code">
                        <span>{{c.code}} - {{c.name}} ({{c.symbol}})</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'organization'">
        <div style="padding-bottom: 15px;">Country:</div>
        <div>
            <mat-form-field appearance="fill" style="min-width: 350px;">  
                <mat-label>Country</mat-label>
                <mat-select [name]="'SelectedCountry'" [(ngModel)]="configCountry.country_code" (ngModelChange)="setCountry()">
                    <mat-option *ngFor="let c of availableCountries" [value]="c.code">
                        <span>{{c.code}} - {{c.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="configType.name === 'organization'">
        <div style="padding-bottom: 15px;">Payment Processor Account:</div>
        <div>
            <mat-form-field appearance="fill" style="min-width: 350px;">  
                <mat-label>Payment Processor Account</mat-label>
                <mat-select [name]="'SelectedPaymentProcessorAccount'" [(ngModel)]="configPaymentProcessorAccount.payment_processor_account_id" (ngModelChange)="setPaymentProcessorAccount()">
                    <mat-option *ngFor="let appa of availablePaymentProcessorAccounts" [value]="appa.id">
                        <span>{{appa.payment_processor?.label}} - {{appa.data?.settings?.dashboard?.display_name || appa.external_id}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;" *ngIf="['domain', 'organization'].includes(configType.name)">
        <div style="padding-bottom: 15px;">Special:</div>
        <div>
            <app-checkbox
                [(ngModel)]="config.data.teamRaise"
                (click)="config.data.teamRaise = !config.data.teamRaise"
                name="TeamRaiseToggle"
                (ngModelChange)="save()"
            >
                <span>TeamRaise</span>
            </app-checkbox>
        </div>
    </div> -->
</div>
