<div class="loading" [ngClass]="{showing: !blb.blockers.all.hasCleared}">
    <div>
        <div style="color: #a1a1a1; padding-bottom: 0px;">Loading...</div>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<div class="supercontainer" [ngClass]="{showing: blb.blockers.all.hasCleared}">
    <div class="view router-outlet" [ngClass]="{showing: blb.blockers.all.hasCleared}" bkLoadBlock #blb="bkLoadBlock">
        <router-outlet
            name="platform"
            *ngIf="(!env.domain?.config?.public?.layoutName && !env.merchant?.id) || (env.domain?.config?.public?.layoutName === 'platform')"
        ></router-outlet>
        <router-outlet
            name="merchant"
            *ngIf="(!env.domain?.config?.public?.layoutName && env.merchant?.id) || (env.domain?.config?.public?.layoutName === 'donation_campaign')"
        ></router-outlet>
        <router-outlet
            name="team_donation_campaign"
            *ngIf="env.domain?.config?.public?.layoutName === 'team_donation_campaign'"
        ></router-outlet>
        <router-outlet
            name="admin"
            *ngIf="(!env.domain?.config?.public?.layoutName && !env.merchant?.id) || (env.domain?.config?.public?.layoutName === 'admin')"
        ></router-outlet>
    </div>
</div>
