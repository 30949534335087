import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    FormDirective,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
    busy: boolean = false;
    env: IEnv;
    options: {
        password?: string;
        recaptchaToken?: string;
        token?: string;
        userId?: string;
    } = {};
    serverErrorMessages: string[];

    constructor (
        public envService: EnvService,
        public userService: UserService,
        public recaptchaService: RecaptchaService,
        public route: ActivatedRoute,
        public router: Router,
    ) {
        this.env = envService.data;
    }

    confirm (): void {
        if (this.busy) {
            return;
        }

        if (this.route.snapshot.queryParams.token) {
            this.options.token = <string>(this.route.snapshot.queryParams.token);
        }

        if (this.route.snapshot.queryParams.user_id) {
            this.options.userId = <string>(this.route.snapshot.queryParams.user_id);
        }

        this.busy = true;

        Promise.resolve().then(() => {
            return this.userService.confirmEmail(this.options);
        }).then((result) => {
            console.log("Email confirmed!", result);
        }).catch((err: {
            error?: {
                messages?: string[];
            };
            message?: string;
        }) => {
            console.error(err);
            this.serverErrorMessages = err?.error?.messages ? err.error.messages : [err.message];
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }

    submit (form: FormDirective): void {
        if (this.busy || !form.ngForm.valid) {
            return;
        }
        this.busy = true;
        this.recaptchaService.execute(
            this.env.recaptcha.sitekey,
            {action: 'reset_password'},
        ).then((recaptchaToken) => {
            this.options.recaptchaToken = recaptchaToken;
        }).then(() => {
            return this.userService.resetPassword({
                password: this.options.password,
                recaptchaToken: this.options.recaptchaToken,
                token: <string>(this.route.snapshot.queryParams.token),
                userId: <string>(this.route.snapshot.queryParams.user_id),
            });
        }).then(async (result) => {
            console.log("Password reset", result);
            await this.router.navigate(['/account'], {
                relativeTo: this.route,
                queryParams: {},
            });
            form.onSuccess();
        }).catch((err: unknown) => {
            console.error(err);
            form.onError(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
