<div class="platform-view-component" *ngIf="merchant?.id">
    <div>
        <span>Merchant: {{merchant.label}} (Campaigns)</span>
    </div>
    <div style="padding-top: 15px;" *ngIf="campaigns?.length">
        <div *ngFor="let c of campaigns" style="padding: 10px 0px;">
            <a [routerLink]="['id', c.id] | linkFix">{{c.label || c.name || c.id}}</a>
        </div>
    </div>
    <div style="padding-top: 25px;">
        <app-add-campaign [data]="data" (created)="createdCampaign($event)">
            <span>Add Campaign</span>
        </app-add-campaign>
    </div>
</div>
