import { IEnv } from '@alt/i-env';
import { Component, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-team-donation-campaign-outlet',
    templateUrl: './team-donation-campaign-outlet.component.html',
    styleUrls: ['./team-donation-campaign-outlet.component.scss']
})
export class TeamDonationCampaignOutletComponent implements OnInit {
    env: IEnv;

    constructor (
        public envService: EnvService
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }

}
