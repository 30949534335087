import {
    Component,
    Input,
} from '@angular/core';

import { EnvService } from '@s/env.service';

import { CheckoutComponent } from '../checkout/checkout.component';
import { CheckoutSectionComponent } from '../checkout-section/checkout-section.component';

import { Router } from '@angular/router';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { Donor } from '@alt/donor';

@Component({
    selector: 'app-checkout-section-campaign-participant',
    templateUrl: './checkout-section-campaign-participant.component.html',
    styleUrls: ['./checkout-section-campaign-participant.component.scss'],
    exportAs: 'section',
})
export class CheckoutSectionCampaignParticipantComponent extends CheckoutSectionComponent {
    @Input() donation: CreateDonationBody;
    @Input() donor: Donor;
    sectionName: string = "participant_selection";

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public router: Router,
    ) {
        super(checkout, envService, router);
    }
}

