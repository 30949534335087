import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IEmailTemplate } from '@amt/i-email-template';

import { EmailTemplateService } from '@s/email-template.service';

import { PaymentListDialogComponent } from '../payment-list-dialog/payment-list-dialog.component';
import { IPayment } from '@amt/i-payment';

@Component({
    selector: 'app-edit-email-template',
    templateUrl: './edit-email-template.component.html',
    styleUrls: ['./edit-email-template.component.scss']
})
export class EditEmailTemplateComponent implements OnInit {
    @Output() changed = new EventEmitter<IEmailTemplate>();
    editorOptions = {theme: 'vs-dark', language: ''};
    emailTemplate: IEmailTemplate;
    @Input() emailTemplateId: string;
    paymentId: string;
    saveSubject = new Subject();

    constructor (
        public sanitizer: DomSanitizer,
        public etService: EmailTemplateService,
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        this.saveSubject.pipe(
            debounceTime(300)
        ).subscribe(() => {
            this.save().catch(console.error);
        });
    }

    adjustQueryParamsAndNavigate (): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: this.getQueryParams(),
            queryParamsHandling: 'merge',
        }).catch(console.error);
    }

    didChange (): void {
        this.changed.emit(this.emailTemplate);
        this.saveSubject.next(null);
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    fetch (): Promise<unknown> {
        const queryParams: unknown = this.getQueryParams();
        return this.etService.getById(this.emailTemplateId, queryParams).then((res: unknown) => {
            this.editorOptions.language = (<{emailTemplate: IEmailTemplate;}>res).emailTemplate.data.format;
            Object.assign(this, res);
            console.log(`Edit e-mail template got`, res);
            return Promise.resolve();
        });
    }

    getQueryParams (): {
        payment_id?: string;
    } {
        const queryParams: {
            payment_id?: string;
        } = {};

        if (this.paymentId) {
            queryParams.payment_id = this.paymentId;
        }

        return queryParams;
    }

    ngOnInit (): void {
        this.route.queryParams.subscribe((params) => {
            if (params.payment_id) {
                this.paymentId = (<{payment_id?: string}>params).payment_id;
            }
            this.fetch().catch(console.error);
        });
    }

    sanitize (str: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(str);
    }

    save (): Promise<unknown> {
        const queryParams = this.getQueryParams();
        return this.etService.save(this.emailTemplate.id, this.emailTemplate, queryParams).then((res: unknown) => {
            console.log("Saved", res);
            this.emailTemplate.renderedHtml = (<{emailTemplate: IEmailTemplate}>res).emailTemplate.renderedHtml;
            return Promise.resolve();
        });
    }

    selectExamplePayment (): MatDialogRef<PaymentListDialogComponent> {
        const dialogRef = this.dialog.open(PaymentListDialogComponent, {
            width: '554px',
            data: {}
        });

        dialogRef.afterClosed().subscribe((result: IPayment) => {
            console.log("Close dialog result", result);
            if (result) {
                this.paymentId = result.id;
                this.adjustQueryParamsAndNavigate();
            }
        });

        return dialogRef;
    }
}
