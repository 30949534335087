<div class="add-something-dialog">
    <div class="add-something-dialog-available-things">
        <div class="title">Available email templates:</div>
        <div *ngIf="availableEmailTemplates?.length">
            <div class="add-thing-selector" *ngFor="let et of availableEmailTemplates">
                <!-- <span class="link" (click)="selectExisting(c)">{{c.label}}</span> -->
                <span>{{et.label}}</span>
                <button mat-raised-button color="primary" (click)="select(et)">Add</button>
            </div>
        </div>
        <div *ngIf="!availableEmailTemplates?.length">
            <span>No available email templates.</span>
        </div>
    </div>

    <form #createNewForm="ngForm" style="padding-top: 35px;" (ngSubmit)="createNew(createNewForm, emailTemplate)">
        <div>Or, create a new email template:</div>
        <div>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" [name]="'EmailTemplateName'" [(ngModel)]="emailTemplate.name" required />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'EmailTemplateLabel'" [(ngModel)]="emailTemplate.label" required />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Template Type Name</mat-label>
                <input matInput type="text" [name]="'TemplateTypeName'" [(ngModel)]="emailTemplate.template_type_name" required placeholder="donation_receipt" />
            </mat-form-field>
        </div>
        <div>
            <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="busy || !createNewForm.valid"
                (click)="createNewForm.ngSubmit.emit()"
            >
                <span>Create</span>
            </button>
        </div>
    </form>
</div>
