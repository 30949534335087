<div class="admin-campaign-participant-list-item">
    <div class="admin-list-person-card">
        <div class="admin-list-person-card-section">
            <div class="profile-photo" [style.backgroundImage]="'url(' + (campaignParticipant.user | profilePhotoUrl) + ')'"></div>
        </div>
        <div class="admin-list-person-card-section">
            <div class="admin-list-item-data admin-list-item-data-name">
                <a [routerLink]="['.', 'id', campaignParticipant.id] | linkFix">{{campaignParticipant.user.name}}</a>
            </div>
            <div class="admin-list-item-data admin-list-item-data-email">
                <span>{{emailAddress?.value || 'No email??'}}</span>
            </div>
            <div class="admin-list-item-data admin-list-item-data-email-confirmation-state" *ngIf="emailAddress?.value">
                <span>Email address </span>
                <span *ngIf="emailAddress.confirmed" style="color: rbga(0, 0, 0, 1);">confirmed.</span>
                <span *ngIf="!emailAddress.confirmed" style="color: darkgoldenrod;">unconfirmed.</span>
            </div>
            <div class="admin-list-item-data admin-list-item-data-phone">
                <span>{{phoneNumber?.value || '(No phone #)'}}</span>
            </div>
        </div>
        <div class="admin-list-person-card-section">
            <div class="admin-list-item-data admin-list-item-data-url">
                <a [routerLink]="['/', campaignParticipant.url] | linkFix" target="_blank">/ [fix for missions] </a>
            </div>
            <div class="admin-list-item-data admin-list-item-data-email-confirmation-state">
                <span *ngIf="campaignParticipant.visible" style="color: rbga(0, 0, 0, 1);">Listed</span>
                <span *ngIf="!campaignParticipant.visible" style="color: darkgoldenrod;">Unlisted</span>
            </div>

            <div class="admin-list-item-data admin-list-item-data-email-confirmation-state">
                <app-delete-campaign-participant-button
                    *ngIf="!deleted"
                    #deleteModal="deleteCampaignParticipant"
                    [campaign]="campaign"
                    [campaignParticipant]="campaignParticipant"
                    (deleted)="deletedCampaignParticipant($event)"
                >
                    <button mat-icon-button color="warn" (click)="deleteModal.openDialog()">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </app-delete-campaign-participant-button>

                <span *ngIf="deleted" style="color: darkgoldenrod;">Deleted</span>
            </div>
        </div>
    </div>
    <div class="admin-list-person-bottom-extra">
        <div>Campaign Participant ID: {{campaignParticipant.id}}</div>
        <div>User ID: {{campaignParticipant.user.id}}</div>
        <div>Person ID: {{campaignParticipant.person.id}}</div>
    </div>
</div>

