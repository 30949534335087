import { BkRoutes } from 'bkng';

import { id } from './id/id';

export const configs: BkRoutes = [
    {
        path: "id",
        label: "Config by ID",
        children: id,
    },
];
