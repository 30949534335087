import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import {
    IPaymentRequestOptions
} from '../checkout/checkout.component';

import { ICurrency } from '@amt/i-currency';
import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { IOrganization } from '@amt/i-organization';
import { IPaymentProcessorCustomer } from '@amt/i-payment-processor-customer';
import { CampaignService } from '@s/campaign.service';
import { ICountry } from '@amt/i-country';
import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { IPaymentSourceToken } from '@alt/i-payment-source-token';
import { EnvService } from '@s/env.service';
import { DonationReturnData } from '@alt/donation-return-data';

@Component({
    selector: 'app-donate',
    templateUrl: './donate.component.html',
    styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {
    clearedToLoad: boolean = false;
    country: ICountry;
    currency: ICurrency;
    data: CampaignGetResult;
    donation: CreateDonationBody;
    error: {
        messages?: string[];
        raw?: {
            code?: string;
            decline_code?: string;
            message?: string;
        };
        status?: string;
        statusCode?: number;
    };
    fragment: string;
    loadBlocker: Blocker;
    organization: IOrganization;
    paymentProcessorCustomer: IPaymentProcessorCustomer;
    paymentRequestOptions: IPaymentRequestOptions;
    success: DonationReturnData;

    constructor (
        public env: EnvService,
        public lbs: LoadBlockerService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
    ) {
        this.loadBlocker = lbs.register({});

        this.country = this.getCountryObject(this.env.data.geo.country) || <ICountry>({
            code: env.data.mode === 'production' ? 'US' : 'CA'
        });

        this.donation = {
            amount: 0,
            anonymous: false,
            campaign: this.env.data.campaign,
            currency: null,
            billingAddress: {
                address1: ``,
                address2: ``,
                city: ``,
                country_code: this.country.code,
                firstName: ``,
                lastName: ``,
                phone: {
                    country_code: env.data.user?.phone?.[0]?.country_code || this.country.code,
                    value: env.data.user?.phone?.[0]?.value || ''
                },
                postal_code: ``,
                state: ``,
            },
            donor: {
                email: env.data.user?.email[0]?.value || '',
                firstName: env.data.user?.first_name || '',
                lastName: env.data.user?.last_name || '',
                password: '',
                phone: {
                    country_code: env.data.user?.phone?.[0]?.country_code || this.country.code,
                    value: env.data.user?.phone?.[0]?.value || ''
                }
            },
            geo: env.data.geo,
            inMemoryOrHonor: {
                which: 'honor'
            },
            interval: `once`,
            merchant: env.data.merchant,
            organization: null,
            paymentMethodName: 'card',
            paymentSourceToken: <IPaymentSourceToken>{},
            savePaymentDetails: false,
            subscribeToMailingList: true
        };

        this.setOrganization(env.data.organization);
    }

    clearError (): void {
        delete this.error;
    }

    fetch (): Promise<void> {
        const getOpts: {
            id: string;
        } = {
            id: this.env.data.campaign?.id,
        };
        return this.campaignService.getById(getOpts).then((res: CampaignGetResult) => {
            if (this.env.data.mode !== 'production') {
                console.log("DonateComponent:", res);
            }
            this.data = res;
            return Promise.resolve();
        });
    }

    getCountryObject (cc: string): ICountry {
        if (!(cc && this.env.data?.countries)) return;
        return this.env.data.countries.filter((c) => { return c.code.toUpperCase() === cc.toUpperCase(); })[0];
    }

    getCurrencyObject (cc: string): ICurrency {
        if (!(cc && this.env?.data?.currencies)) return;
        return this.env.data.currencies.filter((c) => { return c.code.toUpperCase() === cc.toUpperCase(); })[0];
    }

    ngAfterViewInit (): void {
        try {
            document.querySelector('#' + this.fragment).scrollIntoView();
            setTimeout(() => {
                document.querySelector('#' + this.fragment).scrollIntoView();
            });
        }
        catch (e) {
            // do nothing?
        }
    }

    ngOnInit (): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
        });
        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.loadBlocker.unblock();
            this.clearedToLoad = true;
        });
    }

    setOrganization (org: IOrganization): void {
        if (!org?.id) {
            return;
        }

        this.organization = org;

        const cc = org.config.currency_code;
        this.currency = this.getCurrencyObject(cc);

        this.donation.currency = this.currency?.code;
        this.donation.organization = org;

        if (this.env.data.paymentProcessorCustomers?.length) {
            this.paymentProcessorCustomer = this.env.data.paymentProcessorCustomers.filter((ppc) => {
                return ppc.currency === this.organization.config.currency_code;
            })[0];
        }

        if (this.env.data.savedPaymentMethods?.length && this.paymentProcessorCustomer) {
            this.donation.savedPaymentMethod = this.env.data.savedPaymentMethods.filter((spm) => {
                return spm.payment_processor_customer_id === this.paymentProcessorCustomer.id;
            })[0];
            if (this.donation.savedPaymentMethod) {
                this.donation.paymentMethodName = `saved_payment`;
            }
            else {
                this.donation.paymentMethodName = `card`;
            }
        }

        this.paymentRequestOptions = {
            country: this.organization.config.country_code,
            currency: this.currency.code.toLowerCase(),
            total: {
                label: 'Donation',
                amount: 0,
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true
        };
    }
}
