import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ICampaign } from '@amt/i-campaign';
import { IMerchantCampaign } from '@amt/i-merchant-campaign';
import { CampaignService } from '@s/campaign.service';

export interface IAddCampaignDialogData {
    route?: ActivatedRoute;
}

export interface IAddCampaignDialogReturnData {
    campaign: ICampaign;
    merchantCampaign?: IMerchantCampaign;
}

@Component({
    selector: 'app-add-campaign-dialog',
    templateUrl: './add-campaign-dialog.component.html',
    styleUrls: ['./add-campaign-dialog.component.scss']
})
export class AddCampaignDialogComponent implements OnInit {
    busy: boolean = false;
    newCampaign: ICampaign = <ICampaign>{};

    constructor (
        public dialogRef: MatDialogRef<AddCampaignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddCampaignDialogData,
        public campaignService: CampaignService,
    ) {
    }

    createCampaign (): void {
        if (this.busy) {
            return;
        }

        this.busy = true;

        this.campaignService.create({
            campaign: this.newCampaign,
            merchantId: <string>(this.data.route.snapshot.params.merchantId),
        }).then((result) => {
            console.log(`Created campaign`, result);
            this.dialogRef.close(result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }
}
