import { Route } from '@angular/router';

import { TeamDonationCampaignOutletComponent } from '@c/team-donation-campaign-outlet/team-donation-campaign-outlet.component';

export const outletTeamDonationCampaign = (children: Array<Route>): Route => {
    return {
        component: TeamDonationCampaignOutletComponent,
        outlet: "team_donation_campaign",
        children,
    };
};
