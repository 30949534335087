import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { AccountService } from '@s/account.service';
import { AccountGetResult } from '@api-routes/account/types/account-get-result';
import { IEnv } from '@alt/i-env';
import { ISubscription } from '@amt/i-subscription';
import { EnvService } from '@s/env.service';
import { UserService } from '@s/user.service';

@Component({
    selector: 'app-crowdfund-user-account',
    templateUrl: './crowdfund-user-account.component.html',
    styleUrls: ['./crowdfund-user-account.component.scss']
})
export class CrowdfundUserAccountComponent implements OnInit {
    busy: boolean = false;
    clearedToLoad: boolean = false;
    data: AccountGetResult;
    env: IEnv;
    loadBlocker: Blocker;
    subscriptions: Array<ISubscription> = [];

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public accountService: AccountService,
        public userService: UserService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    cancelSubscription (subscription: ISubscription): Promise<void> {
        this.busy = true;
        return lastValueFrom(this.accountService.cancelSubscription(subscription)).then((result) => {
            console.log("Cancelled subscription", result);
            Object.assign(subscription, result);
        }).catch((err) => {
            console.error("Error cancelling subscription");
        }).finally(() => {
            this.busy = false;
        });
    }

    fetch (): Promise<void> {
        if (this.env.user?.id) {
            const params: {
                campaign_id?: string;
            } = {};
            if (this.env.campaign?.id) {
                params.campaign_id = this.env.campaign.id;
            }
            return this.accountService.get(params).then((response: AccountGetResult) => {
                console.log("CrowdfundUserAccountComponent:", response);
                this.data = this.data || <AccountGetResult>{};
                Object.assign(this.data, response);
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                this.loadBlocker.unblock();
                this.clearedToLoad = true;
            });
        }
        console.log("Not logged-in.");
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.loadBlocker.unblock();
                this.clearedToLoad = true;
                resolve();
            }, 5);
        });
    }

    joinCampaign (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.userService.missionCreate({
            userId: this.env.user.id,
            campaignId: this.env.campaign.id
        }).then((res) => {
            console.log(`Joined campaign`, res);
            return this.fetch();
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.fetch().catch((err) => {
            console.error(err);
        });
    }
}
