<form
    class="campaign-participant-profile-edit-container"
    #profileForm="bkForm"
    (bkSubmit)="save(profileForm)"
    bkForm
>
    <!-- <div class="edit-profile-tabs">
        <div>Profile</div>
        <div>Event</div>
    </div> -->

    <div class="section-title margin-top padding-top padding-bottom padding-left-right">
        <span>Profile</span>
    </div>

    <div class="campaign-participant-profile padding-top padding-bottom">
        <div class="campaign-participant-profile-photo padding-bottom padding-left-right">     
            <div class="photo">
                <div class="image">
                    <div
                        *ngIf="!previewProfilePhotoData?.length"
                        class="img"
                        [ngStyle]="{
                            'background-image': 'url(' + (participant | profilePhotoUrl) + ')'
                        }"
                    ></div>
                    <div
                        *ngIf="previewProfilePhotoData?.length"
                        class="img"
                        [ngStyle]="{
                            'background-image': 'url(' + previewProfilePhotoData + ')'
                        }"
                    ></div>
                </div>

                <div class="upload-photo">
                    <bk-upload-file
                        [accept]="'.jpg, .jpeg, .png'"
                        [uploadMethod]="uploadProfilePhoto"
                        [showUploadButton]="false"
                        (complete)="uploadedUserProfilePhoto($event)"
                        (status)="profilePhotoFileSelected(profileForm.ngForm, $event)"
                    >
                        <span>Change avatar</span>
                    </bk-upload-file>
                </div>
            </div>
        </div>

        <div class="campaign-participant-profile-details">
            <div class="campaign-participant-profile-edit-line twofer padding-top-none padding-bottom padding-left-right">
                <div class="input-holder" bkFormControlHolder>
                    <div class="input-label-above">First name</div>
                    <input
                        bkFormControl
                        name="ParticipantFirstName"
                        type="text"
                        [(ngModel)]="participant.first_name"
                        autocomplete="given-name"
                        required
                        [disabled]="saving"
                    />
                    <bk-validation-display></bk-validation-display>
                </div>

                <div class="input-holder" bkFormControlHolder>
                    <div class="input-label-above">Last name</div>
                    <input
                        bkFormControl
                        name="ParticipantLastName"
                        type="text"
                        [(ngModel)]="participant.last_name"
                        autocomplete="family-name"
                        required
                        [disabled]="saving"
                    />
                    <bk-validation-display></bk-validation-display>
                </div>
            </div>

            <div class="padding-top-none padding-bottom padding-left-right campaign-participant-profile-edit-email" *ngIf="participant?.email?.[0]?.value">
                <div class="input-holder">
                    <div class="input-label-above">E-mail address</div>
                    <div class="input disabled">
                        <span>{{participant.email[0].value}}</span>
                    </div>
                </div>
            </div>

            <div class="padding-top padding-bottom padding-left-right campaign-participant-profile-contact-info">
                <app-form-inputs-person-contact-info
                    [addressData]="person.data.address"
                    [phoneData]="person.phone"
                    [disableAllInputs]="saving"
                ></app-form-inputs-person-contact-info>
            </div>
        </div>
    </div>
    
    <bk-form-server-errors></bk-form-server-errors>

    <div class="campaign-participant-profile-edit-save-button-holder">
        <app-button
            class="campaign-participant-profile-edit-button"
            [disabled]="
                saving
                ||
                !profileForm.ngForm.dirty
            "
            (click)="profileForm.submit()"
            [busy]="saving"
        >
            <span *ngIf="!saved || profileForm.ngForm.dirty">Save</span>
            <span *ngIf="saved && !profileForm.ngForm.dirty">Saved</span>
        </app-button>
    </div>
</form>
