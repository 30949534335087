import { Component, Input, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

import { IEnv } from '@alt/i-env';
import { IMerchant } from '@amt/i-merchant';

import { MerchantService } from '@s/merchant.service';
import { IMerchantMailchimpIndex } from '@arhat/i-merchant-mailchimp-index';
import { IMailchimpIntegration } from '@amt/i-mailchimp-integration';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';

@Component({
    selector: 'app-mailchimp-contacts',
    templateUrl: './mailchimp-contacts.component.html',
    styleUrls: ['./mailchimp-contacts.component.scss']
})
export class MailchimpContactsComponent implements OnInit {
    busy: boolean = false;
    merchantMailchimpIndex: IMerchantMailchimpIndex;
    env: IEnv;
    @Input() hubspotIntegration: IHubspotIntegration;
    @Input() integration: IMailchimpIntegration;
    @Input() merchant: IMerchant;

    constructor (
        public envService: EnvService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;
    }

    getMailchimpIndex (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.getMailchimpIndex(this.merchant.id).then((result) => {
            console.log("Merchant's Mailchimp:", result);
            this.merchantMailchimpIndex = result;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.getMailchimpIndex();
    }

    syncContacts (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.syncMailchimpContactsWithHubspot(this.merchant.id, this.integration.id).then((result) => {
            console.log("Kicked-off sync:", result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
