<!-- [style.padding-top]="paddingTop" -->
<div
    class="responsive-iframe-container"
    [style.width]="width"
    [style.height]="height"
>
    <!--
    <iframe
        class="responsive-iframe"
        [src]="src | safe:'resourceUrl'"
        [attr.title]="title ? title : null"
        [attr.frameborder]="[undefined].includes(frameborder) ? null : frameborder"
        [attr.allow]="[undefined].includes(allow) ? null : allow"
        [attr.allowfullscreen]="[undefined].includes(allowfullscreen) ? null : allowfullscreen"
    ></iframe>

    Removed dynamic use of "allow" due to error NG0910:
    https://angular.io/errors/NG0910

    Just making relevant attrs static and true by default.
    -->

    <iframe
        class="responsive-iframe"
        [src]="src | safe:'resourceUrl'"
        [attr.title]="title ? title : null"
        [attr.frameborder]="[undefined].includes(frameborder) ? null : frameborder"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
    ></iframe>
</div>
