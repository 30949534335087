import { BkRoutes } from 'bkng';

import { outletLoggedIn } from '@ngr/outlet-logged-in';
import { outletNotLoggedIn } from '@ngr/outlet-not-logged-in';
import { outletMerchant } from '@ngr/outlet-merchant';
import { outletPlatform } from '@ngr/outlet-platform';
import { outletTeamDonationCampaign } from '@ngr/outlet-team-donation-campaign';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { AccountEmailConfirmComponent } from '@c/account-email-confirm/account-email-confirm.component';

export const email: BkRoutes = [
    {
        path: "confirm",
        label: "Confirm",
        ngRoute: routeStartingPoint([
            outletMerchant({component: AccountEmailConfirmComponent}),
            outletPlatform({component: AccountEmailConfirmComponent}),
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: AccountEmailConfirmComponent}),
                outletLoggedIn({component: AccountEmailConfirmComponent})
            ]),
        ]),
        children: [],
    },
];
