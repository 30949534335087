import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[bkBusy]'
})
export class BusyDirective implements OnChanges {
    @Input() bkBusy: boolean = false;

    element: ElementRef;
    renderer: Renderer2;

    constructor (element: ElementRef, renderer: Renderer2) {
        this.element = element;
        this.renderer = renderer;
        renderer.addClass(element.nativeElement, 'bk-busy');
    }

    busyChanged (change: {currentValue: unknown}): void {
        const element = this.element;
        const renderer = this.renderer;
        const busy = change.currentValue;
        if (busy) {
            renderer.removeClass(element.nativeElement, 'bk-busy-false');
            renderer.addClass(element.nativeElement, 'bk-busy-true');
        }
        else {
            renderer.addClass(element.nativeElement, 'bk-busy-false');
            renderer.removeClass(element.nativeElement, 'bk-busy-true');
        }
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.bkBusy) {
            this.busyChanged(changes.bkBusy);
        }
    }
}
