import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { lastValueFrom } from 'rxjs';

import { CommonEnvService as BkngEnvService } from 'bkng';

import { IEnv } from '@alt/i-env';

@Injectable({
    providedIn: 'root'
})
export class EnvService extends BkngEnvService <
    IEnv
> {
    constructor (
        http: HttpClient,
        titleService: Title,
        public route: ActivatedRoute,
    ) {
        super(
            http,
            titleService
        );
    }

    override async httpGet (
        params: Record<string, string | number>,
    ): Promise<IEnv> {
        const urlParams = new URLSearchParams(window.location.search);

        const viewmode = urlParams.get("viewmode");

        if (
            viewmode
        ) {
            params["viewmode"] = viewmode;
        }

        const data = <IEnv>(await lastValueFrom(
            this.http.get(
                this.url,
                {
                    params
                }
            )
        ));

        return data;
    }
}
