import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { IEmailAddress } from '@lib/model/types/i-email-address';
import { IUser } from '@lib/model/types/i-user';
import { primaryEmailAddress } from '@lib/primary-email-address';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-admin-user-list-item',
    templateUrl: './admin-user-list-item.component.html',
    styleUrl: './admin-user-list-item.component.scss'
})
export class AdminUserListItemComponent {
    @Input() busy: boolean;
    @Input() disabled: boolean;
    @Output() update = new EventEmitter<IUser>();
    @Input() user: IUser;

    constructor (
        public envService: EnvService,
    ) {}

    primaryEmailAddress (): IEmailAddress {
        return primaryEmailAddress(
            this.user.email
        );
    }

    toggleSuspension (): void {
        if (
            this.envService.data.user?.id === this.user.id
        ) {
            console.error("You can't toggle your own user's suspension.");
            return;
        }

        const newUserObj = Object.assign({}, this.user);

        newUserObj.suspended = newUserObj.suspended === false ? true : false;

        if (
            newUserObj.suspended === false
        ) {
            newUserObj.data.suspendedTitle = "";
            newUserObj.data.suspendedMessage = "";
        }

        this.update.emit(newUserObj);
    }
}
