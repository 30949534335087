import { ICampaign } from '@amt/i-campaign';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { ICampaignParticipantMission } from '@amt/i-campaign-participant-mission';
import { IPayment } from '@amt/i-payment';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PaymentService } from '@s/payment.service';

export interface IModifyPaymentMissionDialogData {
    campaign: ICampaign;
    campaigns: Array<ICampaign>;
    payment: IPayment;
}

export interface IModifyPaymentMissionReturnData {
    payment?: IPayment;
}

@Component({
    selector: 'app-modify-payment-mission-dialog',
    templateUrl: './modify-payment-mission-dialog.component.html',
    styleUrls: ['./modify-payment-mission-dialog.component.scss']
})
export class ModifyPaymentMissionDialogComponent implements OnInit {
    busy: boolean = false;
    selected: {
        campaign?: ICampaign;
        campaignId?: string;
        campaignParticipant?: ICampaignParticipant;
        campaignParticipantId?: string;
        campaignParticipantMission?: ICampaignParticipantMission;
        campaignParticipantMissionId?: string;
    } = {};

    constructor (
        public dialogRef: MatDialogRef<ModifyPaymentMissionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IModifyPaymentMissionDialogData,
        public paymentService: PaymentService,
    ) {}

    modify (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.paymentService.modifyCampaignParticipantMissionPayment({
            paymentId: this.data.payment.id,
            campaignId: this.selected.campaignId,
            campaignParticipantId: this.selected.campaignParticipantId,
            campaignParticipantMissionId: this.selected.campaignParticipantMissionId,
        }).then((result) => {
            console.log("Modified campaign participant mission payment", result);
            this.dialogRef.close(result);
        }).catch((err) => {
            console.error(`There was an error trying to modify campaign participant mission payment`, err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        const campaignId = this.data.payment.campaign_participant_mission?.campaign_id || this.data.campaign?.id;
        if (campaignId) {
            this.setCampaign(campaignId);
        }

        if (
            this.selected.campaign?.id
            &&
            this.data.payment.campaign_participant_mission?.campaign_participant_id
        ) {
            this.setCampaignParticipant(this.data.payment.campaign_participant_mission.campaign_participant_id);
        }

        if (
            this.selected.campaignParticipant?.id
            &&
            this.data.payment.campaign_participant_mission?.id
        ) {
            this.setCampaignParticipantMission(this.data.payment.campaign_participant_mission.id);
        }

        console.log("ModifyPaymentMissionDialogComponent:", this.data, this.selected);
    }

    setCampaign (campaignId: string): void {
        const campaigns = this.data.campaigns;
        this.selected.campaign = campaigns.filter((item) => {
            return (item.id === campaignId);
        })[0];

        this.selected.campaignId = this.selected.campaign?.id;

        if (this.selected.campaignParticipantId) {
            this.setCampaignParticipant(this.selected.campaignParticipantId);
        }
    }

    setCampaignParticipant (campaignParticipantId: string): void {
        this.selected.campaignParticipant = (this.selected.campaign?.participants || []).filter((item) => {
            return (item.id === campaignParticipantId);
        })[0];

        this.selected.campaignParticipantId = this.selected.campaignParticipant?.id;

        if (this.selected.campaignParticipantMissionId) {
            this.setCampaignParticipantMission(this.selected.campaignParticipantMissionId);
        }
    }

    setCampaignParticipantMission (campaignParticipantMissionId: string): void {
        this.selected.campaignParticipantMission = (this.selected.campaignParticipant?.missions || []).filter((item) => {
            return (item.id === campaignParticipantMissionId);
        })[0];
        this.selected.campaignParticipantMissionId = this.selected.campaignParticipantMission?.id;
    }
}
