import { Component, Input, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

import { ICampaignParticipantMission } from '@amt/i-campaign-participant-mission';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-crowdfunding-participant-mission-tile',
    templateUrl: './crowdfunding-participant-mission-tile.component.html',
    styleUrls: ['./crowdfunding-participant-mission-tile.component.scss']
})
export class CrowdfundingParticipantMissionTileComponent implements OnInit {
    env: IEnv;
    @Input() mission: ICampaignParticipantMission;

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    getInitials (): string {
        const mission = this.mission;
        const user = mission?.participant?.user;

        if (
            user?.id
        ) {
            let first: string = "";
            if (
                user.first_name
            ) {
                first = user.first_name.substring(0, 1).toUpperCase();
            }

            let last: string = "";
            if (
                user.last_name
            ) {
                last = user.last_name.substring(0, 1).toUpperCase();
            }

            return `${first}${last}`;
        }

        return "";
    }

    ngOnInit (): void {
    }

}
