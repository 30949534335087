import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { UserEditProfileViewComponent } from '@c/user-edit-profile-view/user-edit-profile-view.component';

export const id: BkRoutes = [
    {
        path: ":userId",
        label: "Merchant by ID Campaigns by ID Participant by ID Participant",
        ngRoute: routeStartingPoint([
            outletPlatform({component: UserEditProfileViewComponent}),
        ]),
        children: [],
    },
];
