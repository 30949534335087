import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import {
    NgModule,
    APP_INITIALIZER,
    ErrorHandler,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import {
    BkngModule,
    HttpXsrfInterceptor,
    RecaptchaService,
    RestService,
    StripeService,
} from 'bkng';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './angular-material.module';

import { AppComponent } from '@c/app.component';
import { ButtonComponent } from '@c/button/button.component';
import { CheckoutComponent } from '@c/checkout/checkout.component';
import { CheckoutSectionComponent } from '@c/checkout-section/checkout-section.component';
import { CheckoutSectionContributionComponent } from '@c/checkout-section-contribution/checkout-section-contribution.component';
import { CheckoutSectionDonorInfoComponent } from '@c/checkout-section-donor-info/checkout-section-donor-info.component';
import { CheckoutSectionEmailComponent } from '@c/checkout-section-email/checkout-section-email.component';
import { CheckoutSectionPaymentComponent } from '@c/checkout-section-payment/checkout-section-payment.component';
import { CheckoutSectionReviewDonationComponent } from '@c/checkout-section-review-donation/checkout-section-review-donation.component';
import { CheckoutSectionValidatorDirective } from '@classes/checkout-section-validator';
import { DonateComponent } from '@c/donate/donate.component';
import { HomeComponent } from '@c/home/home.component';
import { ResetPasswordDialogComponent } from '@c/reset-password-dialog/reset-password-dialog.component';
import { SignInDialogComponent } from '@c/sign-in-dialog/sign-in-dialog.component';
import { UserAccountComponent } from '@c/user-account/user-account.component';
import { DomainViewComponent } from '@c/domain-view/domain-view.component';
import { DomainEmailsViewComponent } from '@c/domain-emails-view/domain-emails-view.component';
import { DomainEmailViewComponent } from '@c/domain-email-view/domain-email-view.component';
import { OrganizationViewComponent } from '@c/organization-view/organization-view.component';
import { OrganizationEmailsViewComponent } from '@c/organization-emails-view/organization-emails-view.component';
import { OrganizationEmailViewComponent } from '@c/organization-email-view/organization-email-view.component';
import { EditEmailTemplateComponent } from '@c/edit-email-template/edit-email-template.component';
import { OrganizationDomainsViewComponent } from '@c/organization-domains-view/organization-domains-view.component';
import { DomainConfigsViewComponent } from '@c/domain-configs-view/domain-configs-view.component';
import { ConfigViewComponent } from '@c/config-view/config-view.component';
import { AddConfigComponent } from '@c/add-config/add-config.component';
import { AddConfigDialogComponent } from '@c/add-config-dialog/add-config-dialog.component';
import { AddOrganizationComponent } from '@c/add-organization/add-organization.component';
import { AddOrganizationDialogComponent } from '@c/add-organization-dialog/add-organization-dialog.component';
import { OrganizationConfigsViewComponent } from '@c/organization-configs-view/organization-configs-view.component';
import { AddEmailTemplateComponent } from '@c/add-email-template/add-email-template.component';
import { AddEmailTemplateDialogComponent } from '@c/add-email-template-dialog/add-email-template-dialog.component';
import { SlackChannelSelectComponent } from '@c/slack-channel-select/slack-channel-select.component';
import { DonationListDialogComponent } from '@c/donation-list-dialog/donation-list-dialog.component';
import { OrderListDialogComponent } from '@c/order-list-dialog/order-list-dialog.component';
import { PaymentListDialogComponent } from '@c/payment-list-dialog/payment-list-dialog.component';
import { MerchantViewComponent } from '@c/merchant-view/merchant-view.component';
import { DomainListItemComponent } from '@c/domain-list-item/domain-list-item.component';
import { AddDomainComponent } from '@c/add-domain/add-domain.component';
import { AddDomainDialogComponent } from '@c/add-domain-dialog/add-domain-dialog.component';
import { MerchantDomainsViewComponent } from '@c/merchant-domains-view/merchant-domains-view.component';
import { MerchantCampaignsViewComponent } from '@c/merchant-campaigns-view/merchant-campaigns-view.component';
import { MerchantWebhooksIncomingViewComponent } from '@c/merchant-webhooks-incoming-view/merchant-webhooks-incoming-view.component';
import { IncomingWebhookTableRowComponent } from '@c/incoming-webhook-table-row/incoming-webhook-table-row.component';
import { IncomingWebhookReprocessButtonComponent } from '@c/incoming-webhook-reprocess-button/incoming-webhook-reprocess-button.component';
import { AddCampaignComponent } from '@c/add-campaign/add-campaign.component';
import { AddCampaignDialogComponent } from '@c/add-campaign-dialog/add-campaign-dialog.component';
import { CheckboxComponent } from '@c/checkbox/checkbox.component';
import { DonateTeamComponent } from '@c/donate-team/donate-team.component';
import { DonateTeamParticipantComponent } from '@c/donate-team-participant/donate-team-participant.component';
import { CampaignViewComponent } from '@c/campaign-view/campaign-view.component';
import { AddCampaignParticipantComponent } from '@c/add-campaign-participant/add-campaign-participant.component';
import { AddCampaignParticipantDialogComponent } from '@c/add-campaign-participant-dialog/add-campaign-participant-dialog.component';
import { PersonViewComponent } from '@c/person-view/person-view.component';
import { CampaignEditComponent } from '@c/campaign-edit/campaign-edit.component';
import { PageEditComponent } from '@c/page-edit/page-edit.component';
import { UserEditProfileViewComponent } from '@c/user-edit-profile-view/user-edit-profile-view.component';
import { ProfilePhotoUrlPipe } from '@p/profile-photo-url.pipe';
import { CheckoutSectionCampaignParticipantComponent } from '@c/checkout-section-campaign-participant/checkout-section-campaign-participant.component';
import { CrowdfundLandingComponent } from '@c/crowdfund-landing/crowdfund-landing.component';
import { CrowdfundParticipantLandingComponent } from '@c/crowdfund-participant-landing/crowdfund-participant-landing.component';
import { CrowdfundParticipantDonateComponent } from '@c/crowdfund-participant-donate/crowdfund-participant-donate.component';
import { CrowdfundSupporterTileComponent } from '@c/crowdfund-supporter-tile/crowdfund-supporter-tile.component';
import { CrowdfundingParticipantTileComponent } from '@c/crowdfunding-participant-tile/crowdfunding-participant-tile.component';
import { NeedsUserOutletComponent } from '@c/needs-user-outlet/needs-user-outlet.component';
import { ResetPasswordComponent } from '@c/reset-password/reset-password.component';
import { CrowdfundSignUpComponent } from '@c/crowdfund-sign-up/crowdfund-sign-up.component';
import { CrowdfundSignUpFormComponent } from '@c/crowdfund-sign-up-form/crowdfund-sign-up-form.component';
import { AccountEmailConfirmComponent } from '@c/account-email-confirm/account-email-confirm.component';
import { CreateAccountSuccessComponent } from '@c/create-account-success/create-account-success.component';
import { CrowdfundUserAccountComponent } from '@c/crowdfund-user-account/crowdfund-user-account.component';
import { TeamDonationCampaignOutletComponent } from '@c/team-donation-campaign-outlet/team-donation-campaign-outlet.component';
import { LoginComponent } from '@c/login/login.component';
import { LoginFormComponent } from '@c/login-form/login-form.component';
import { CampaignParticipantProfileComponent } from '@c/campaign-participant-profile/campaign-participant-profile.component';
import { AccountPasswordResetComponent } from '@c/account-password-reset/account-password-reset.component';
import { ResetPasswordFormComponent } from '@c/reset-password-form/reset-password-form.component';
import { CrowdfundEmbedParticipantsComponent } from '@c/crowdfund-embed-participants/crowdfund-embed-participants.component';
import { CrowdfundHeaderComponent } from './components/crowdfund-header/crowdfund-header.component';
import { DomainConfigBannerPhotoPipe } from './pipes/domain-config-banner-photo.pipe';
import { CrowdfundAdminComponent } from './components/crowdfund-admin/crowdfund-admin.component';
import { CampaignAdminNavigationComponent } from './components/campaign-admin-navigation/campaign-admin-navigation.component';
import { NavBreadcrumbsComponent } from './components/nav-breadcrumbs/nav-breadcrumbs.component';
import { NavBreadcrumbComponent } from './components/nav-breadcrumb/nav-breadcrumb.component';
import { AdminCampaignParticipantsViewComponent } from './components/admin-campaign-participants-view/admin-campaign-participants-view.component';
import { AdminCampaignParticipantListItemComponent } from './components/admin-campaign-participant-list-item/admin-campaign-participant-list-item.component';
import { AdminCampaignParticipantViewComponent } from './components/admin-campaign-participant-view/admin-campaign-participant-view.component';
import { TestSlackChannelComponent } from './components/test-slack-channel/test-slack-channel.component';
import { DeleteCampaignParticipantButtonComponent } from './components/delete-campaign-participant-button/delete-campaign-participant-button.component';
import { DeleteCampaignParticipantModalComponent } from './components/delete-campaign-participant-modal/delete-campaign-participant-modal.component';
import { CrowdfundingParticipantMissionTileComponent } from './components/crowdfunding-participant-mission-tile/crowdfunding-participant-mission-tile.component';
import { CampaignParticipantProfileTabProfileComponent } from './components/campaign-participant-profile-tab-profile/campaign-participant-profile-tab-profile.component';
import { CampaignParticipantProfileTabMissionComponent } from './components/campaign-participant-profile-tab-mission/campaign-participant-profile-tab-mission.component';
import { AdminComponent } from './components/admin/admin.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MerchantOrdersViewComponent } from './components/merchant-orders-view/merchant-orders-view.component';
import { MerchantOrderListItemComponent } from './components/merchant-order-list-item/merchant-order-list-item.component';
import { MerchantOrderListItemPaymentComponent } from './components/merchant-order-list-item-payment/merchant-order-list-item-payment.component';
import { ModifyPaymentMissionDialogComponent } from './components/modify-payment-mission-dialog/modify-payment-mission-dialog.component';
import { MerchantListItemComponent } from './components/merchant-list-item/merchant-list-item.component';
import { IntegrationSettingGa4Component } from './components/integration-setting-ga4/integration-setting-ga4.component';
import { MerchantSlackOptionsComponent } from './components/merchant-slack-options/merchant-slack-options.component';
import { HubspotContactsComponent } from './components/hubspot-contacts/hubspot-contacts.component';
import { IntegrationSettingMailchimpComponent } from './components/integration-setting-mailchimp/integration-setting-mailchimp.component';
import { MailchimpContactsComponent } from './components/mailchimp-contacts/mailchimp-contacts.component';
import { CampaignEditConfigComponent } from './components/campaign-edit-config/campaign-edit-config.component';
import { CampaignEditConfigHubspotComponent } from './components/campaign-edit-config-hubspot/campaign-edit-config-hubspot.component';
import { HeaderHolderComponent } from './components/header-holder/header-holder.component';
import { SalesforceContactsComponent } from './components/salesforce-contacts/salesforce-contacts.component';
import { CampaignParticipantsComponent } from './components/campaign-participants/campaign-participants.component';
import { FormInputsPersonContactInfoComponent } from './components/form-inputs-person-contact-info/form-inputs-person-contact-info.component';
import { ResendVerificationEmailComponent } from './components/resend-verification-email/resend-verification-email.component';
import { ComPopComponent } from './components/com-pop/com-pop.component';
import { EnvService } from '@s/env.service';
import { ConsoleErrorDialogComponent } from './components/console-error-dialog/console-error-dialog.component';
import { ErrorInterceptor } from '@classes/error-interceptor';
import { MatDialog } from '@angular/material/dialog';
import { ViewNotFoundComponent } from '@c/view-not-found/view-not-found.component';
import { UsersViewComponent } from './components/users-view/users-view.component';
import { AdminUserListItemComponent } from './components/admin-user-list-item/admin-user-list-item.component';
import { HttpResponseInterceptor } from './http-response.interceptor';
import { MerchantLogoUrlPipe } from './pipes/merchant-logo-url.pipe';

export function initApp (
    envService: EnvService,
    recaptchaService: RecaptchaService,
) {
    return async (): Promise<unknown> => {
        const queryParams: {
            pathname?: string;
        } = {};

        if (window.location.pathname?.length > 1) {
            queryParams.pathname = encodeURIComponent(window.location.pathname);
        }

        return await envService.bootstrap(queryParams).then((result) => {
            setTimeout(() => {
                recaptchaService.addScript(
                    result.recaptcha.sitekey
                );
            }, 1);
        });
    };
}

@NgModule({
    declarations: [
        AppComponent,
        ButtonComponent,
        CheckoutComponent,
        CheckoutSectionComponent,
        CheckoutSectionContributionComponent,
        CheckoutSectionDonorInfoComponent,
        CheckoutSectionEmailComponent,
        CheckoutSectionPaymentComponent,
        CheckoutSectionReviewDonationComponent,
        CheckoutSectionValidatorDirective,
        DonateComponent,
        HomeComponent,
        ResetPasswordDialogComponent,
        SignInDialogComponent,
        UserAccountComponent,
        DomainViewComponent,
        DomainEmailsViewComponent,
        DomainEmailViewComponent,
        OrganizationViewComponent,
        OrganizationEmailsViewComponent,
        OrganizationEmailViewComponent,
        EditEmailTemplateComponent,
        OrganizationDomainsViewComponent,
        DomainConfigsViewComponent,
        ConfigViewComponent,
        AddConfigComponent,
        AddConfigDialogComponent,
        AddOrganizationComponent,
        AddOrganizationDialogComponent,
        OrganizationConfigsViewComponent,
        AddEmailTemplateComponent,
        AddEmailTemplateDialogComponent,
        SlackChannelSelectComponent,
        DonationListDialogComponent,
        OrderListDialogComponent,
        PaymentListDialogComponent,
        MerchantViewComponent,
        DomainListItemComponent,
        AddDomainComponent,
        AddDomainDialogComponent,
        MerchantDomainsViewComponent,
        MerchantCampaignsViewComponent,
        MerchantWebhooksIncomingViewComponent,
        IncomingWebhookTableRowComponent,
        IncomingWebhookReprocessButtonComponent,
        AddCampaignComponent,
        AddCampaignDialogComponent,
        CheckboxComponent,
        DonateTeamComponent,
        DonateTeamParticipantComponent,
        CampaignViewComponent,
        AddCampaignParticipantComponent,
        AddCampaignParticipantDialogComponent,
        PersonViewComponent,
        CampaignEditComponent,
        PageEditComponent,
        UserEditProfileViewComponent,
        ProfilePhotoUrlPipe,
        CheckoutSectionCampaignParticipantComponent,
        CrowdfundLandingComponent,
        CrowdfundParticipantLandingComponent,
        CrowdfundParticipantDonateComponent,
        CrowdfundSupporterTileComponent,
        CrowdfundingParticipantTileComponent,
        NeedsUserOutletComponent,
        ResetPasswordComponent,
        CrowdfundSignUpComponent,
        CrowdfundSignUpFormComponent,
        AccountEmailConfirmComponent,
        CreateAccountSuccessComponent,
        CrowdfundUserAccountComponent,
        TeamDonationCampaignOutletComponent,
        LoginComponent,
        LoginFormComponent,
        CampaignParticipantProfileComponent,
        AccountPasswordResetComponent,
        ResetPasswordFormComponent,
        CrowdfundEmbedParticipantsComponent,
        CrowdfundHeaderComponent,
        DomainConfigBannerPhotoPipe,
        CrowdfundAdminComponent,
        CampaignAdminNavigationComponent,
        NavBreadcrumbsComponent,
        NavBreadcrumbComponent,
        AdminCampaignParticipantsViewComponent,
        AdminCampaignParticipantListItemComponent,
        AdminCampaignParticipantViewComponent,
        TestSlackChannelComponent,
        DeleteCampaignParticipantButtonComponent,
        DeleteCampaignParticipantModalComponent,
        CrowdfundingParticipantMissionTileComponent,
        CampaignParticipantProfileTabProfileComponent,
        CampaignParticipantProfileTabMissionComponent,
        AdminComponent,
        ProgressBarComponent,
        MerchantOrdersViewComponent,
        MerchantOrderListItemComponent,
        MerchantOrderListItemPaymentComponent,
        ModifyPaymentMissionDialogComponent,
        MerchantListItemComponent,
        IntegrationSettingGa4Component,
        MerchantSlackOptionsComponent,
        HubspotContactsComponent,
        IntegrationSettingMailchimpComponent,
        MailchimpContactsComponent,
        CampaignEditConfigComponent,
        CampaignEditConfigHubspotComponent,
        HeaderHolderComponent,
        SalesforceContactsComponent,
        CampaignParticipantsComponent,
        FormInputsPersonContactInfoComponent,
        ResendVerificationEmailComponent,
        ConsoleErrorDialogComponent,
        ComPopComponent,
        ViewNotFoundComponent,
        UsersViewComponent,
        AdminUserListItemComponent,
        MerchantLogoUrlPipe,
    ],
    imports: [
        HttpClientModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        MonacoEditorModule.forRoot(),
        ShareButtonsModule,
        BkngModule,
        AppRoutingModule,
        ShareIconsModule,
    ],
    providers: [
        {
            deps: [
                HttpClient,
                EnvService,
            ],
            provide: RestService,
        },
        {
            deps: [
                EnvService,
            ],
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXsrfInterceptor,
            multi: true
        },
        {
            deps: [
                EnvService,
                MatDialog,
            ],
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true
        },
        {
            deps: [
                EnvService,
                RestService,
                MatDialog,
            ],
            provide: ErrorHandler,
            useClass: ErrorInterceptor,
        },
        {
            deps: [
                EnvService
            ],
            provide: StripeService,
        },
        {
            deps: [
                EnvService,
                RecaptchaService,
            ],
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor (
    ) {
    }
}
