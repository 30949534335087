import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-console-error-dialog',
    templateUrl: './console-error-dialog.component.html',
    styleUrls: ['./console-error-dialog.component.scss']
})
export class ConsoleErrorDialogComponent implements OnInit {
    constructor (
        public dialogRef: MatDialogRef<ConsoleErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            error: {
                message?: string;
            },
        },
    ) {
        dialogRef.disableClose = true;
    }

    close (): void {
        this.dialogRef.close();
    }

    ngOnInit (): void {
    }
}
