import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
    StripeService,
} from 'bkng';

import { CampaignService } from '@s/campaign.service';
import { ICountry } from '@amt/i-country';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { GetCampaignParticipantMissionResult } from '@api-routes/campaign/types/get-campaign-participant-mission-result';

@Component({
    selector: 'app-crowdfund-participant-landing',
    templateUrl: './crowdfund-participant-landing.component.html',
    styleUrls: ['./crowdfund-participant-landing.component.scss'],
})
export class CrowdfundParticipantLandingComponent implements OnInit {
    clearedToLoad: boolean = false;
    country: ICountry;
    data: GetCampaignParticipantMissionResult;
    env: IEnv;
    eventCountry: ICountry;
    loadBlocker: Blocker;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
        public ss: StripeService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    fetch (): Promise<void> {
        return this.campaignService.getParticipantMissionPageData({
            campaignId: this.env.campaign.id,
            missionUrl: <string>(this.route.snapshot.params.campaignParticipantMissionUrl),
        }).then((res) => {
            console.log("CrowdfundParticipantLandingComponent:", res);

            this.data = res;

            if (res.participant) {
                this.country = this.env.countries.filter((c) => c.code === res.participant.data.countryCode)[0];
            }

            if (res.mission) {
                this.eventCountry = this.env.countries.filter((c) => c.code === res.mission.mission.data.eventLocationCountry)[0];
            }

            return Promise.resolve();
        });
    }

    getCurrentAmount (): number {
        const cc = this.env.campaign.config.public?.fundraisingGoalCurrencyCode || 'USD';
        return parseFloat(`${this.data.mission?.info?.amountByCurrency?.[cc] || 0}`);
    }

    ngOnInit (): void {
        this.route.params.subscribe((params) => {
            setTimeout(() => {
                this.fetch().catch((err) => {
                    console.error(err);
                }).finally(() => {
                    this.loadBlocker.unblock();
                    this.clearedToLoad = true;
                });
            });
        });
    }
}
