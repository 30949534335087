<div class="add-config-dialog">
    <div class="add-config-dialog-available-configs">
        <div class="title">Available configs:</div>
        <div *ngIf="availableConfigs?.length">
            <div *ngFor="let c of availableConfigs">
                <span class="link" (click)="selectExisting(c)">{{c.label}}</span>
            </div>
        </div>
        <div *ngIf="!availableConfigs?.length">
            <span>No available configs.</span>
        </div>
    </div>
    <div style="padding-top: 35px;">
        <div>
            <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'ConfigLabel'" [(ngModel)]="config.label" />
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="primary" [disabled]="!config.label" (click)="createNewConfig(config)">Create</button>
        </div>
    </div>
</div>
