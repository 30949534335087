<div style="padding: 10px 0px;">
    <div style="border: 1px solid grey; padding: 10px;">
        <div class="name" style="font-size: 16px;">
            <a [routerLink]="['merchant/id', merchant.id] | linkFix">{{merchant.label}}</a>
        </div>
        <div class="owner" style="font-size: 11px; color: #aaa;" *ngIf="env.user.id == merchant.owner_id">You own this merchant account.</div>
        <div><span>Domains:</span><span *ngFor="let domain of merchant.domains, let last = last"><a [routerLink]="['domain/id', domain.id] | linkFix">{{domain.full}}</a><span *ngIf="!last">, </span></span></div>
        <div *ngIf="merchant.mpps?.length" style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;">
            <div>Payment processors:</div>
            <div *ngFor="let mpp of merchant.mpps" style="margin: 12px 0px; padding: 5px 8px; background-color: rgba(245, 245, 245, 1);">
                <div>{{mpp.ppa_data.settings.dashboard.display_name}}</div>
                <div *ngIf="mpp.is_primary" style="font-weight: 600; font-size: 11px;">Primary</div>
                <div>(Stripe: {{mpp.payment_processor_account?.external_id}})</div>
                <!-- <div style="padding-top: 5px; font-size: 10px;"><a class="" [href]="connectStripeUrl(merchant)" target="_self">Reconnect ...</a></div> -->
            </div>
        </div>

        <div *ngIf="env.stripe" style="padding-top: 15px;">
            <div style="padding-bottom: 5px;"><a class="button small" [href]="connectStripeUrl(merchant)" target="_self">Add/Reconnect Stripe account</a></div>
        </div>

        <div *ngIf="!merchant.slack" style="padding-top: 15px;">
            <div style="padding-bottom: 5px;"><a class="button small" [href]="connectSlackUrl(merchant)" target="_self">Add MERCHANT Slack account</a></div>
        </div>
        <div *ngIf="merchant.slack" style="padding-top: 15px;">
            <div>A merchant Slack account is connected. <a [href]="connectSlackUrl(merchant)" target="_self">Reconnect ...</a></div>
            <!-- <app-merchant-slack-options [merchant]="merchant"></app-merchant-slack-options> -->
        </div>

        <div style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;">
            <div>Google Analytics 4:</div>
            <app-integration-setting-ga4
                [busy]="busy"
                [integration]="merchant.analytics.ga4"
                (updated)="saveIntegration($event)"
            ></app-integration-setting-ga4>
        </div>

        <div style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;">
            <div *ngIf="!hubspot?.id">
                <div style="padding-bottom: 5px;"><a class="button small" [href]="connectHubspotUrl(merchant)" target="_self">Connect HubSpot Account</a></div>
            </div>
            <div *ngIf="hubspot?.id">
                <app-hubspot-contacts
                    [merchant]="merchant"
                    [integration]="hubspot"
                    [canCopyProdSettings]="hubspotProd?.id ? true : false"
                ></app-hubspot-contacts>
            </div>
        </div>

        <div style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;" *ngIf="env.mode !== 'production'">
            <div *ngIf="!hubspotProd?.id">
                <div style="padding-bottom: 5px;"><a class="button small" [href]="connectHubspotUrl(merchant, true)" target="_self">Connect HubSpot PROD</a></div>
            </div>
            <!-- <div *ngIf="hubspotProd?.id">
                <app-hubspot-contacts
                    [merchant]="merchant"
                    [integration]="hubspotProd"
                ></app-hubspot-contacts>
            </div> -->
        </div>

        <div *ngIf="!merchant.mailchimp?.id" style="padding-top: 15px;">
            <div style="padding-bottom: 5px;"><a class="button small" [href]="connectMailchimpUrl(merchant)" target="_self">Connect Mailchimp Account</a></div>
        </div>

        <div *ngIf="merchant.mailchimp?.id" style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;">
            <div>Mailchimp:</div>

            <app-mailchimp-contacts
                [merchant]="merchant"
                [hubspotIntegration]="hubspot"
            ></app-mailchimp-contacts>

            <app-integration-setting-mailchimp
                [busy]="busy"
                [integration]="merchant.mailchimp"
                (updated)="saveIntegration($event)"
            ></app-integration-setting-mailchimp>
        </div>

        <div style="border-top: 2px solid #a1a1a1; margin-top: 22px; padding-top: 10px;">
            <div style="padding-bottom: 5px;">
                <a class="button small" [href]="connectSalesForceUrl(merchant)" target="_self">
                    <span *ngIf="!merchant.salesforce?.id">Connect SalesForce</span>
                    <span *ngIf="merchant.salesforce?.id">RE-Connect SalesForce</span>
                </a>
            </div>

            <div *ngIf="merchant.salesforce?.id" style="padding-top: 15px;">
                <div>SalesForce is connected.</div>
    
                <app-salesforce-contacts
                    [merchant]="merchant"
                    [hubspotIntegration]="hubspot"
                ></app-salesforce-contacts>
            </div>
        </div>
    </div>
</div>
