<div class="donate showing" *ngIf="clearedToLoad">
    <app-crowdfund-header [showAccountButtons]="false"></app-crowdfund-header>

    <div class="checkout-holder">
        <div class="checkout-content">
            <app-crowdfund-sign-up-form
                [campaign]="campaign"
            ></app-crowdfund-sign-up-form>
        </div>
    </div>
</div>
