import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserSignupOptions } from '@api-routes/user/types/user-signup-options';

import {
    FormDirective,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';

import { ICampaign } from '@amt/i-campaign';
import { ICountry } from '@amt/i-country';

import { SignInDialogData, SignInDialogService } from '@c/sign-in-dialog/sign-in-dialog.component';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-crowdfund-sign-up-form',
    templateUrl: './crowdfund-sign-up-form.component.html',
    styleUrls: ['./crowdfund-sign-up-form.component.scss']
})
export class CrowdfundSignUpFormComponent implements OnInit {
    busy: boolean = false;
    @Input() campaign: ICampaign;
    country: ICountry;
    env: IEnv;
    options: UserSignupOptions = {};

    constructor (
        public recaptchaService: RecaptchaService,
        public userService: UserService,
        public envService: EnvService,
        public router: Router,
        public route: ActivatedRoute,
        public signInDialogService: SignInDialogService,
    ) {
        const env = this.env = envService.data;

        this.country = this.getCountryObject(env.geo.country) || <ICountry>{
            code: env.mode === 'production' ? 'US' : 'CA'
        };

        this.options.phone = {
            country_code: this.country.code,
            value: ''
        };
    }

    getCountryObject (cc: string): ICountry {
        if (!(cc && this.env?.countries)) {
            return null;
        }
        return this.env.countries.filter((c) => { return c.code.toUpperCase() === cc.toUpperCase(); })[0];
    }

    ngOnInit (): void {
        if (this.campaign?.id) {
            this.options.campaignId = this.campaign.id;
        }
        if (this.env.merchant?.id) {
            this.options.merchantId = this.env.merchant.id;
        }
    }

    openSignInDialog (options: SignInDialogData = {}): void {
        const dialogRef = this.signInDialogService.open(options);
        dialogRef.afterClosed().subscribe((result) => {
            // if (!options.redirect) {
            //     this.fetch();
            // }
        });
    }

    submit (form: FormDirective): void {
        if (this.busy || !form.ngForm.valid) {
            return;
        }
        this.busy = true;
        this.recaptchaService.execute(
            this.env.recaptcha.sitekey,
            {action: 'crowdfund_sign_up'},
        ).then((recaptchaToken) => {
            this.options.recaptchaToken = recaptchaToken;
        }).then(() => {
            return this.userService.signUp(this.options);
        }).then((result) => {
            console.log("Signed-up!", result);
            this.router.navigate(['./success'], {
                relativeTo: this.route,
                queryParams: {},
            }).catch((err) => {
                console.error(err);
            });
            form.onSuccess();
        }).catch((err: unknown) => {
            console.error(err);
            form.onError(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
