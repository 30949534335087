<div class="add-something-dialog">
    <form #createNewForm="ngForm" (ngSubmit)="addCampaignParticipant()">
        <div>
            <mat-form-field>
                <mat-label>Participant Email</mat-label>
                <input matInput type="text" [name]="'CampaignParticipantEmail'" placeholder="i.e. you@example.com" [(ngModel)]="email" required />
            </mat-form-field>
        </div>
        <div>
            <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="busy || !createNewForm.valid"
                (click)="createNewForm.ngSubmit.emit()"
            >Add</button>
        </div>
    </form>
</div>
