import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    CheckoutSectionComponent
} from '@c/checkout-section/checkout-section.component';

export interface ICheckoutSectionValidator {
    active: boolean;
    check (): Promise<boolean>;
}

@Directive({
    selector: '[appCheckoutSectionValidator]'
})
export class CheckoutSectionValidatorDirective implements OnInit, OnDestroy {
    @Input('validatorActive') active: boolean = true;
    @Input('checkoutSection') checkoutSection: CheckoutSectionComponent;
    @Input('appCheckoutSectionValidator') reqProm: (() => Promise<boolean>);

    constructor (
    ) {
    }

    check (): Promise<boolean> {
        return this.reqProm();
    }

    ngOnDestroy (): void {
        this.checkoutSection.removeValidator(this);
    }

    ngOnInit (): void {
        this.checkoutSection.addValidator(this);
    }
}
