import { Component, Inject, OnInit } from '@angular/core';

import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

export interface ComPopOptAct {
    action: (
        pop: ComPopComponent,
    ) => void;
    label: string;
    color?: "primary" | "accent" | "warn" | "basic";
    busyLabel?: string;
}

export interface ComPopOpt {
    title: string;
    body: string;
    actions: Array<ComPopOptAct>;
}

@Component({
    selector: 'app-com-pop',
    templateUrl: './com-pop.component.html',
    styleUrls: ['./com-pop.component.scss']
})
export class ComPopComponent implements OnInit {
    busy: boolean = false;

    constructor (
        public dialog: MatDialogRef<ComPopComponent>,
        @Inject(MAT_DIALOG_DATA) public options: ComPopOpt,
    ) {
        dialog.disableClose = true;
    }

    ngOnInit (): void {
    }

    setBusy (state: boolean): void {
        this.busy = state;
    }
}
