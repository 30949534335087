import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { ConfigViewComponent } from '@c/config-view/config-view.component';

export const id: BkRoutes = [
    {
        path: ":configId",
        label: "Domain Config",
        ngRoute: routeStartingPoint([
            outletPlatform({component: ConfigViewComponent}),
        ]),
        children: [

        ],
    },
];
