import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
} from '@angular/core';

import {
    FormDirective,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
    busy: boolean = false;
    @Input() credentials: {
        campaignId?: string;
        email: string;
        password?: string;
        recaptchaToken?: string;
    } = {
        email: "",
    };
    @Output() forgotPassword = new EventEmitter<unknown>();
    @Output() success = new EventEmitter<unknown>();
    succeeded: boolean = false;

    constructor (
        public recaptchaService: RecaptchaService,
        public userService: UserService,
        public env: EnvService,
    ) { }

    forgotPass (): void {
        this.forgotPassword.emit();
    }

    ngOnInit (): void {
    }

    async signIn (frm: FormDirective): Promise<unknown> {
        if (this.busy) {
            return Promise.resolve();
        }

        this.busy = true;

        return await this.recaptchaService.execute(
            this.env.data.recaptcha.sitekey,
            {action: 'sign_in'},
        ).then(async (recaptchaToken) => {
            this.credentials.recaptchaToken = recaptchaToken;
            if (this.env.data?.campaign?.id) {
                this.credentials.campaignId = this.env.data.campaign.id;
            }

            return await this.userService.signIn(
                this.credentials
            ).then((res: unknown) => {
                this.succeeded = true;

                return this.env.get({user_action: 'signed_in'}).then((envRes) => {
                    frm.onSuccess();
                    this.success.emit(res);
                    return res;
                });
            });
        }).catch((err: unknown) => {
            console.error(err);
            frm.onError(err);
        }).finally(() => {
            this.busy = false;
        });
    }

}
