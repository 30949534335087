import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';



import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { MerchantService } from '@s/merchant.service';
import { IEnv } from '@alt/i-env';
import { MerchantOrdersGetResult } from '@api-routes/merchant/types/merchant-orders-get-result';
import { MerchantOrdersGetOptions } from '@api-routes/merchant/types/merchant-orders-get-options';

@Component({
    selector: 'app-merchant-orders-view',
    templateUrl: './merchant-orders-view.component.html',
    styleUrls: ['./merchant-orders-view.component.scss']
})
export class MerchantOrdersViewComponent implements OnInit {
    blocker: Blocker;
    busy: boolean = false;
    data: MerchantOrdersGetResult;
    env: IEnv;
    limit: number = 25;
    offset: number = 0;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public merchantService: MerchantService,
        public route: ActivatedRoute,
        public router: Router,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});
    }

    fetch (): Promise<void> {
        this.busy = true;

        const options: MerchantOrdersGetOptions = {
            merchantId: <string>(this.route.snapshot.params.merchantId),
        };

        if (
            this.route.snapshot.queryParams.limit
        ) {
            options.limit = <string>(this.route.snapshot.queryParams.limit);
        }
        else {
            options.limit = String(this.limit);
        }

        if (
            this.route.snapshot.queryParams.offset
        ) {
            options.offset = <string>(this.route.snapshot.queryParams.offset);
        }

        return this.merchantService.getOrders(
            options
        ).then((response) => {
            console.log("MerchantOrdersViewComponent:", response);
            this.data = response;
            return Promise.resolve();
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.route.queryParams.subscribe((params) => {
            setTimeout(() => {
                this.fetch().catch(console.error).finally(() => {
                    this.blocker.unblock();
                });
            });
        });
    }

    page (
        direction: "prev" | "next"
    ): void {
        const limit = <string>(this.route.snapshot.queryParams.limit) || String(this.limit);
        this.limit = parseInt(limit, 10);
        if (
            this.limit < 1
        ) {
            this.limit = 1;
        }
        else if (this.limit > 100) {
            this.limit = 100;
        }

        const offset = <string>(this.route.snapshot.queryParams.offset) || String(this.offset);
        this.offset = parseInt(offset, 10);

        if (
            direction === "prev"
        ) {
            this.offset = this.offset - this.limit;
            if (
                this.offset < 0
            ) {
                this.offset = 0;
            }
        }
        else if (
            direction === "next"
        ) {
            this.offset = this.offset + this.limit;
        }

        const queryParams: {
            limit: string;
            offset: string;
        } = {
            limit: String(this.limit),
            offset: String(this.offset),
        };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
            }
        ).catch((err) => {
            console.error(err);
        });
    }
}
