<a [routerLink]="['.', mission?.url] | linkFix" class="participant-tile-container">
    <div class="participant-tile-profile-photo" *ngIf="(mission.participant.user | profilePhotoUrl)">
        <div class="profile-photo" [style.backgroundImage]="'url(' + (mission.participant.user | profilePhotoUrl) + ')'"></div>
    </div>

    <div class="participant-tile-profile-photo" *ngIf="!(mission.participant.user | profilePhotoUrl)">
        <div class="profile-photo no-photo">
            <div class="initials">{{getInitials()}}</div>
        </div>
    </div>

    <div class="participant-tile-name">
        <span>{{mission.participant.user?.name}}</span>
    </div>
    
    <div class="participant-tile-raised">
        <b [title]="mission.mission.data.eventType || ''">{{mission.mission.data.eventType || '&nbsp;'}}</b>
    </div>

    <div class="participant-tile-raised" *ngIf="env.mode === 'development'">
        <b># Supporters: {{mission.info.numBenefactors}}</b>
    </div>
</a>
