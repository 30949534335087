import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { DomainViewComponent } from '@c/domain-view/domain-view.component';
import { DomainConfigsViewComponent } from '@c/domain-configs-view/domain-configs-view.component';

import { configs } from './configs/configs';

export const id: BkRoutes = [
    {
        path: ":domainId",
        label: "Merchant by ID Domains by ID Domain",
        ngRoute: routeStartingPoint([
            outletPlatform({component: DomainViewComponent}),
        ]),
        children: [
            {
                path: "configs",
                label: "Merchant by ID Campaigns by ID Domain Configs",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: DomainConfigsViewComponent}),
                ]),
                children: configs,
            }
        ],
    },
];
