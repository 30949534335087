import { BkRoute } from 'bkng';

import { outletLoggedIn } from '@ngr/outlet-logged-in';
import { outletTeamDonationCampaign } from '@ngr/outlet-team-donation-campaign';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { id } from './id/id';

import { AdminCampaignParticipantsViewComponent } from '@c/admin-campaign-participants-view/admin-campaign-participants-view.component';

export const participants: BkRoute = {
    path: "participants",
    label: "Participants",
    ngRoute: routeStartingPoint([
        outletTeamDonationCampaign([
            outletLoggedIn({component: AdminCampaignParticipantsViewComponent})
        ])
    ]),
    children: [
        {
            path: "id",
            label: "Participant by ID",
            children: id,
        },
    ],
};
