import {
    ErrorHandler,
    Injectable,
} from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    CommonEnvService
} from './env.service';

import {
    RestService
} from './rest.service';

const errorsToReport = [];
let numberOfErrorReports = 0;
const maxErrorReports = 5;

type EnvBasic = {
    api?: {
        pathPrefix?: string;
    };
    mode?: string;
    errors?: {
        report?: boolean;
        reportTo?: string;
    };
};

@Injectable({
    providedIn: 'root'
})
export class BkErrorHandler <
    ENV extends EnvBasic = EnvBasic
> implements ErrorHandler {
    reporting: boolean = false;

    constructor (
        public envService: CommonEnvService<ENV>,
        public restService: RestService,
    ) {
        //if (env.logToServer) {
        //}
    }

    addErrorToReport (data: unknown): void {
        if (errorsToReport.length >= 5) {
            return; // buffer's full
        }
        errorsToReport.push(data);
    }

    handleError (error: {
        message?: string;
    }): void {
        const env = this.envService.data;

        let reportErrors = typeof(env.errors?.report) === 'undefined' ? true : (env.errors?.report ? true : false);

        if (numberOfErrorReports > maxErrorReports) {
            reportErrors = false;
        }

        if (!reportErrors) {
            return;
        }

        if (
            env.mode === 'production'
            &&
            `${error?.message}`.trim().toLowerCase().includes(`cannot match any routes`)
        ) {
            // perhaps there should be a better way of handling this.
            console.warn(error);
            return;
        }

        const data: {
            errorVariableType?: string;
            href?: string;
            message?: string;
            raw?: unknown;
            string?: string;
        } = {
            errorVariableType: String(Object.prototype.toString.call(error)),
            href: window.location.href,
        };

        if (
            [
                `[object Error]`,
                `[object Object]`
            ].indexOf(
                String(Object.prototype.toString.call(error))
            ) > -1
        ) {
            try {
                data.string = JSON.stringify(error, Object.getOwnPropertyNames(error));
            }
            catch (e) {
                data.string = `Could not stringify ${Object.prototype.toString.call(error)} ${(<{message?: string;}>e)?.message}`;
            }
        }
        else if (
            [
                `[object Array]`
            ].indexOf(
                String(Object.prototype.toString.call(error))
            ) > -1
        ) {
            try {
                data.string = JSON.stringify(error, Object.getOwnPropertyNames(error));
            }
            catch (e) {
                data.string = `Could not stringify ${Object.prototype.toString.call(error)} ${(<{message?: string;}>e)?.message}`;
            }
        }
        else if (typeof(error) === 'string') {
            data.string = error;
        }
        else {
            data.raw = error;
        }

        if (error?.message) {
            data.message = error.message;
        }

        this.addErrorToReport(data);

        this.startReportingErrors();

        console.error(error);

        this.pop(error);
    }

    logToServer (...args: unknown[]): Promise<unknown> {
        const env = this.envService.data;

        if (env?.api?.pathPrefix && (env !== args[1])) {
            const txt = JSON.stringify(args, null, 4);
            return this.restService.$post(`report_log`, {jsonStr: txt}).toPromise().then(() => {
                // local.CL("Reported.");
            }).catch((e) => {
                console.error("Did not report", e);
            });
        }
    }

    pop (
        error: {
            message?: string;
        },
    ): void {
        // optionally implement a popup by extending this class and overriding the pop method.
    }

    reportErrors (): Promise<unknown> {
        if (numberOfErrorReports > maxErrorReports) {
            return Promise.resolve();
        }

        const env = this.envService.data;

        return lastValueFrom(
            this.restService.$post(
                env.errors?.reportTo || "/report_error",
                errorsToReport.splice(0, errorsToReport.length),
                {responseType: 'text'}
            )
        ).catch((err) => {
            console.error("There was a problem when attempting to report errors", err);
        }).finally(() => {
            numberOfErrorReports++;
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(null);
                }, (env.mode === 'production' ? 5000 : 500) * numberOfErrorReports);
            }).then(() => {
                if (errorsToReport.length) {
                    return this.reportErrors();
                }
                this.reporting = false;
                return Promise.resolve();
            }).catch(console.error);
        });
    }

    startReportingErrors (): void {
        if (this.reporting) {
            return;
        }
        this.reporting = true;
        this.reportErrors().catch(console.error).finally(() => {
            this.reporting = false;
        });
    }
}
