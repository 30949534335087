import { Component, Input, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

import { IEnv } from '@alt/i-env';
import { IMerchant } from '@amt/i-merchant';

import { MerchantService } from '@s/merchant.service';
import { IMerchantSalesforceIndex } from '@arhat/i-merchant-salesforce-index';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';
import { ISalesforceIntegration } from '@amt/i-salesforce-integration';

@Component({
    selector: 'app-salesforce-contacts',
    templateUrl: './salesforce-contacts.component.html',
    styleUrls: ['./salesforce-contacts.component.scss']
})
export class SalesforceContactsComponent implements OnInit {
    busy: boolean = false;
    env: IEnv;
    @Input() hubspotIntegration: IHubspotIntegration;
    @Input() integration: ISalesforceIntegration;
    @Input() merchant: IMerchant;
    merchantSalesforceIndex: IMerchantSalesforceIndex;

    constructor (
        public envService: EnvService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;
    }

    getSalesforceIndex (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.getSalesforceIndex(this.merchant.id).then((result) => {
            console.log("Merchant's SalesForce:", result);
            this.merchantSalesforceIndex = result;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.getSalesforceIndex();
    }

    syncWithHubspot (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.syncSalesforceWithHubspot(this.merchant.id, this.hubspotIntegration.id).then((result) => {
            console.log("Kicked-off sync:", result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}

