import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IIncomingWebhook } from '@amt/i-incoming-webhook';
import { IMerchant } from '@amt/i-merchant';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { MerchantService } from '@s/merchant.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-merchant-webhooks-incoming-view',
    templateUrl: './merchant-webhooks-incoming-view.component.html',
    styleUrls: ['./merchant-webhooks-incoming-view.component.scss']
})
export class MerchantWebhooksIncomingViewComponent implements OnInit {
    blocker: Blocker;
    env: IEnv;
    merchant: IMerchant;
    incomingWebhooks: Array<IIncomingWebhook>;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public merchantService: MerchantService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    fetch (): Promise<void> {
        return this.merchantService.getWebhooksIncoming(
            <string>(this.route.snapshot.params.merchantId)
        ).then((res) => {
            console.log("MerchantWebhooksIncomingViewComponent:", res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
