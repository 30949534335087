import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { IUser } from '@amt/i-user';

import { AccountService } from '@s/account.service';

@Component({
    selector: 'app-resend-verification-email',
    templateUrl: './resend-verification-email.component.html',
    styleUrls: ['./resend-verification-email.component.scss']
})
export class ResendVerificationEmailComponent implements OnInit {
    busy: boolean = false;
    @Input() user: IUser;

    constructor (
        public accountService: AccountService,
    ) {
    }

    canShowResendEmailVerification (): boolean {
        if (this.user.data.lastSentEmailConfirmationEmailAt) {
            const lastSentDate = new Date(this.user.data.lastSentEmailConfirmationEmailAt);
            const now = new Date();
            const diff = now.getTime() - lastSentDate.getTime();
            if (diff < (1000 * 60 * 3)) {
                return false;
            }
        }
        return true;
    }

    ngOnInit (): void {
    }

    resendEmailVerification (): Promise<void> {
        if (
            this.busy
        ) {
            return;
        }
        this.busy = true;
        this.accountService.resendEmailVerification(
            this.user.id,
            this.user.email[0].id
        ).then((res: {
            user: {
                data: IUser["data"];
            };
        }) => {
            console.log(`Resent`, res);
            this.user.data = res.user.data;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
