<div class="donate showing" *ngIf="clearedToLoad">
    <app-header-holder></app-header-holder>

    <div class="checkout-holder">
        <div class="checkout-content">
            <app-reset-password-form
            ></app-reset-password-form>
        </div>
    </div>
</div>
