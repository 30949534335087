import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Output() action = new EventEmitter<unknown>();
    @Input() busy: boolean = false;
    @Input() disabled: boolean = false;

    constructor (
    ) {
    }

    doAction (event: unknown): void {
        if (this.disabled) {
            return;
        }
        this.action.emit(event);
    }

    ngOnInit (): void {
    }
}
