<div class="platform-view-component" *ngIf="organization?.id">
    <div>Organization: {{organization.label}}</div>
    <div>
        <div>
            <a [routerLink]="['domains'] | linkFix">Domains ({{domains ? domains.length : 0}})</a>
        </div>
        <div>
            <a [routerLink]="['configs'] | linkFix">Configurations ({{configs ? configs.length : 0}})</a>
        </div>
        <div *ngIf="configs?.length">
            <a [routerLink]="['emails'] | linkFix">E-mails ({{emailTemplates ? emailTemplates.length : 0}})</a>
        </div>
        <div *ngIf="!configs?.length">
            <span>Add a config to see e-mails.</span>
        </div>
    </div>
    <div style="padding-top: 65px; width: 50%;">
        <div class="drop-down-text">
            <mat-form-field style="width: 100%;">
                <mat-label>Organization Label</mat-label>
                <input matInput type="text" [name]="'OrganizationLabel'" [(ngModel)]="organization.label" (ngModelChange)="doSave()" />
            </mat-form-field>
        </div>
        <div class="drop-down-text">
            <mat-form-field style="width: 100%;">
                <mat-label>Donation Drop-Down Label</mat-label>
                <input matInput type="text" [name]="'DonationDropDownLabel'" [(ngModel)]="organization.data.donationDropDownLabel" (ngModelChange)="doSave()" />
            </mat-form-field>
        </div>
        <div class="drop-down-text">
            <mat-form-field style="width: 100%;">
                <mat-label>Donation Drop-Down Description</mat-label>
                <textarea matInput [name]="'DonationDropDownDescription'" [(ngModel)]="organization.data.donationDropDownDescription" (ngModelChange)="doSave()" rows="5"></textarea>
            </mat-form-field>
        </div>

        <div class="receipt-signature-text">
            <mat-form-field style="width: 100%;">
                <mat-label>Receipt Signature HTML</mat-label>
                <textarea matInput [name]="'DonationDropDownDescription'" [(ngModel)]="organization.data.donationReceiptSignatureHtml" (ngModelChange)="doSave()" rows="5"></textarea>
            </mat-form-field>
        </div>
    </div>
</div>
