/*
    This should be re-written
    hence eslint rule-silencing
*/

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */

import {
    Directive,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';

import {
    SelectComponent,
    ISelectListItem
} from './select.component';

/*
    event.keyCode 40 is down arrow key
     38 is up, 39 right, 37 left
    event.keyCode 9 is tab
*/

@Directive({
    selector: '[bkSelectItem]'
})
export class SelectItemDirective {
    @Input() selectComponent: SelectComponent;
    @Input() selectItem: any;

    item: ISelectListItem;

    constructor (private element: ElementRef) {

    }

    ngAfterViewInit (): void {
        this.item = this.selectItem;
        this.item.element = this.element;
    }

    @HostListener('click', ['$event'])
    onClickHandler (e: MouseEvent): void {
        const sc = this.selectComponent;
        sc.protected = true;
        sc.selectItem(this.selectItem);
    }

    onDownHandler (e: any): void {
        this.selectComponent.protectedFromBlur = true;
    }

    @HostListener('focus', ['$event'])
    onFocusHandler (e: FocusEvent): void {
        const sc = this.selectComponent;
        sc.makeSuggestion(this.item);
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler (e: KeyboardEvent): void {
        // e.stopPropagation();
        e.preventDefault();

        const sc = this.selectComponent;

        //let index = sc.list.indexOf(this.item);

        /*let prevIndex = index - 1;
        let nextIndex = index + 1;*/

        if (e.keyCode === 9) {
            sc.selectItem(this.item);
        }

        if ([13].indexOf(e.keyCode) > -1) {
            sc.selectItem(this.item);
            e.stopPropagation();
        }

        if (e.keyCode === 27) {
            // escape
            e.stopPropagation();
            return;
        }

        if (e.keyCode === 38) {
            const prevListItem = sc.getPrevShowingListItem(this.item);
            if (!prevListItem) {
                if (sc.selectInput && sc.selectInput.el) {
                    setTimeout(() => {
                        sc.selectInput.el.nativeElement.focus();
                    }, 1);
                    return;
                }
            }
            if (prevListItem) {
                setTimeout(() => {
                    prevListItem.focus();
                }, 1);
                return;
            }
        }

        if (e.keyCode === 40) {
            const nextListItem = sc.getNextShowingListItem(this.item);
            if (nextListItem) {
                setTimeout(() => {
                    nextListItem.focus();
                }, 1);
                return;
            }
        }
    }

    @HostListener('mousedown', ['$event'])
    onMouseDownHandler (e: MouseEvent): void {
        this.onDownHandler(e);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStartHandler (e: TouchEvent): void {
        this.onDownHandler(e);
    }

    @HostListener('keyup', ['$event'])
    onKeyUpHandler (e: KeyboardEvent): void {
        const sc = this.selectComponent;

        if (sc.open) {
            if (e.keyCode === 27) {
                // escape
                e.stopPropagation();
                sc.toggle(false);
                return;
            }
        }
    }
}
