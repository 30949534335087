<div class="platform-view-component" *ngIf="data?.person?.id && data?.user?.id">
    <div>
        <span>User: {{data.user.first_name}} {{data.user.last_name}}</span>
    </div>

    <div style="padding-top: 8px; font-size: 12px;">
        <span>Email: {{data.user.email[0].value}}</span>
    </div>

    <app-campaign-participant-profile
        *ngIf="data.campaignParticipant?.id"
        [campaign]="data.campaign"
        [campaignParticipant]="data.campaignParticipant"
        [campaignParticipantMission]="data.campaignParticipantMission"
        [participant]="data.user"
        [person]="data.person"
        (createdMission)="fetch()"
        (uploadedProfilePhoto)="fetch()"
    ></app-campaign-participant-profile>
</div>
