import { Injectable } from '@angular/core';

import { BkRoute } from 'bkng';

export interface ICrumb {
    bkRoute: BkRoute;
    navigable: boolean;
    pathPieces: string[];
    routerLink?: Array<string>;
    text?: string;
}

export interface IBreadCrumbTrail {
    [trailName: string]: Array<ICrumb>;
}

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    breadCrumbTrail: IBreadCrumbTrail = {
        default: [],
    };

    constructor () {
    }
}
