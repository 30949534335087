import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { CampaignService } from '@s/campaign.service';

import {
    IAddCampaignParticipantDialogReturnData
} from '../add-campaign-participant-dialog/add-campaign-participant-dialog.component';

import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-campaign-participants',
    templateUrl: './campaign-participants.component.html',
    styleUrls: ['./campaign-participants.component.scss']
})
export class CampaignParticipantsComponent implements OnInit {
    blocker: Blocker;
    // campaign: ICampaign;
    data: CampaignGetResult;
    env: IEnv;
    //participants?: Array<IPerson> = [];
    saveSubject = new Subject();

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public campaignService: CampaignService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.blocker.unblock();
        });

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch((err) => {
                console.error(err);
            });
        });
    }

    addedCampaignParticipant (data: IAddCampaignParticipantDialogReturnData): void {
        this.fetch().catch((err) => {
            console.error(err);
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    fetch (): Promise<CampaignGetResult> {
        return this.campaignService.getById({
            admin: true,
            id: <string>(this.route.snapshot.params.campaignId),
            merchantId: <string>(this.route.snapshot.params.merchantId),
            showParticipants: 'all',
        }).then((res) => {
            console.log("CampaignParticipantsComponent:", res);
            this.data = Object.assign({}, res);
            this.data.participants = (this.data.participants || []).map((p) => {
                p = Object.assign({}, p);
                p.missions = (this.data.missions || []).filter((m) => {
                    return m.campaign_participant_id === p.id;
                });
                p.numMissionPayments = p.missions.reduce((prevValue, m) => {
                    return prevValue + (m.payments?.length || 0);
                }, 0);
                return p;
            });
            return res;
        });
    }

    ngOnInit (): void {
    }

    save (): Promise<void> {
        const data = {
            campaign: this.data.campaign,
        };
        return this.campaignService.save(
            this.data.campaign.id,
            data,
        ).then((res) => {
            return Promise.resolve();
        });
    }
}
