import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { CampaignViewComponent } from '@c/campaign-view/campaign-view.component';

import { participant } from './participant/participant';
import { CampaignParticipantsComponent } from '@c/campaign-participants/campaign-participants.component';

export const id: BkRoutes = [
    {
        path: ":campaignId",
        label: "Merchant by ID Campaigns by ID Campaign",
        ngRoute: routeStartingPoint([
            outletPlatform({component: CampaignViewComponent}),
        ]),
        children: [
            {
                path: "participant",
                label: "Merchant by ID Campaigns by ID Participant",
                children: participant,
            },
            {
                path: "participants",
                label: "Merchant by ID Campaign Participants",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: CampaignParticipantsComponent}),
                ]),
                children: <BkRoutes>[].concat(participant),
            }
        ],
    },
];
