import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'countryCodeToName'
})
export class CountryCodeToNamePipe implements PipeTransform {
    constructor (
    ) {
    }

    transform (
        countryCode: string,
        countries: {
            code?: string;
            name?: string;
        }[]
    ): string {
        if (!countryCode) {
            return;
        }

        const countryObj = countries.filter((c) => {
            return c.code.toUpperCase() === countryCode.toUpperCase();
        })[0];

        return countryObj.name;
    }
}
