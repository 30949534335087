import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    RestService
} from 'bkng';

import { EnvService } from './env.service';

import { GetCampaignOptions } from '@api-routes/admin/types/get-campaign-options';
import { GetCampaignQuery } from '@api-routes/admin/types/get-campaign-query';
import { GetCampaignResult } from '@api-routes/admin/types/get-campaign-result';
import { UserGetResult } from '@api-routes/admin/types/user-get-result';

@Injectable({
    providedIn: 'root'
})
export class AdminService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `admin`;
    }

    campaign (
        options: GetCampaignOptions
    ): Promise<GetCampaignResult> {
        const params: GetCampaignQuery = {};

        if (
            options.campaignId
        ) {
            params.campaign_id = options.campaignId;
        }

        if (
            options.campaignParticipantId
        ) {
            params.campaign_participant_id = options.campaignParticipantId;
        }

        return lastValueFrom(
            this.$get(
                `campaign`,
                {
                    params: params
                }
            )
        ).then((result: unknown) => {
            return <GetCampaignResult>result;
        });
    }

    deleteCampaignParticipant (campaignId: string, campaignParticipantId: string): Promise<void> {
        return lastValueFrom(
            this.$delete(
                `campaign/id/${campaignId}/participant/id/${campaignParticipantId}`
            )
        );
    }

    async user (): Promise<UserGetResult> {
        const queryParams = {};

        const response = await lastValueFrom(
            this.$get(
                `user`,
                {
                    params: queryParams
                }
            )
        );

        // return <GetCampaignResult>response;

        return <UserGetResult><unknown>response;
    }
}
