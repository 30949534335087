import {
    Injectable
} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';

import { CommonEnvService } from './env.service';

interface Env {
    csrfToken: string;
}

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
    constructor (
        public envService: CommonEnvService<Env>
    ) {
    }

    intercept (
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const headerName = 'x-csrf-token';
        const csrfToken = this.envService?.data?.csrfToken;
        if (
            (typeof(csrfToken) === 'string')
            &&
            !req.headers.has(headerName)
        ) {
            req = req.clone({ headers: req.headers.set(headerName, csrfToken) });
        }
        return next.handle(req);
    }
}
