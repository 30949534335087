import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { EnvService } from '@s/env.service';

import { EmailTemplateService } from '@s/email-template.service';
import { IEmailTemplate } from '@amt/i-email-template';
import { NgForm } from '@angular/forms';
import { IEnv } from '@alt/i-env';

export interface ICreateOptions {
    label: string;
    name: string;
    template_type_name: string;
}

export interface IAddEmailTemplateDialogData {
    availableEmailTemplates: Array<IEmailTemplate>;
}

export interface IAddEmailTemplateDialogReturnData {
    emailTemplate: IEmailTemplate;
}

@Component({
    selector: 'app-add-email-template-dialog',
    templateUrl: './add-email-template-dialog.component.html',
    styleUrls: ['./add-email-template-dialog.component.scss']
})
export class AddEmailTemplateDialogComponent implements OnInit {
    availableEmailTemplates: Array<IEmailTemplate>;
    busy: boolean = false;
    env: IEnv;
    emailTemplate: ICreateOptions = {
        label: '',
        name: '',
        template_type_name: '',
    };

    constructor (
        public dialogRef: MatDialogRef<AddEmailTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddEmailTemplateDialogData,
        public emailTemplateService: EmailTemplateService,
        public envService: EnvService,
    ) {
        this.env = envService.data;
        Object.assign(this, data);
    }

    createNew (form: NgForm, options: ICreateOptions): Promise<IEmailTemplate> {
        if (!form.valid || this.busy) {
            return Promise.resolve(<IEmailTemplate>undefined);
        }
        this.busy = true;
        return this.emailTemplateService.create(options).then((result) => {
            this.select(result.emailTemplate);
            return result.emailTemplate;
        }).catch((err) => {
            console.error(err);
            return Promise.resolve(<IEmailTemplate>undefined);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }

    select (emailTemplate: IEmailTemplate): void {
        const returnData: IAddEmailTemplateDialogReturnData = {
            emailTemplate,
        };
        this.dialogRef.close(returnData);
    }
}
