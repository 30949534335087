<div class="header-holder">
    <div class="header-bg">
        <div class="header">

            <div *ngIf="env.merchant?.id">
                <div class="header-inner">
                    <a *ngIf="env.merchant.logo_file?.id" href="{{env.merchant.data.websiteUrl}}">
                        <img src="{{env.merchant | merchantLogoUrl}}" bkLoadBlocker>
                    </a>
                    <span *ngIf="!env.merchant?.logo_file" style="font-weight: 400; font-size: 28px; padding: 32px 0px;">{{env.merchant.name}}</span>
                </div>
            </div>

            <div *ngIf="!env.merchant?.id">
                <div class="header-inner">
                    <span style="font-weight: 400; font-size: 28px; padding: 32px 0px;">HareRaiser</span>
                </div>
            </div>

        </div>
    </div>
</div>
