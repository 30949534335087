import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '@amt/i-user';

@Pipe({
    name: 'profilePhotoUrl',
    pure: false,
})
export class ProfilePhotoUrlPipe implements PipeTransform {

    transform (user: IUser): string {
        if (!user.profile_photo) {
            return ""; // '/assets/placeholder.jpeg';
        }
        return `/files/type/user_profile_photo/id/${user.profile_photo.id}${user.profile_photo.extname}`;
    }

}
