import { Pipe, PipeTransform } from '@angular/core';
// import * as moment from 'moment';

@Pipe({
    name: 'friendlyDateTime',
    pure: false,
})
export class FriendlyDateTimePipe implements PipeTransform {
    transform (
        date: unknown, // moment.MomentInput,
    ): string {
        // return moment(date).local().format('MMM Do, YYYY @ h:mm a');
        return "DATE-DATE-DATE"; // retired moment.
    }
}
