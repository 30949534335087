<form
    class="platform-view-component"
    #frm="ngForm"
    *ngIf="data?.domain?.id"
    (ngSubmit)="save(frm)"
>
    <div>
        <span>Domain:</span>
        <span>{{data.domain.full}}</span>
    </div>
    <div>
        <!-- <div>
            <a [routerLink]="['emails']">E-mails</a>
        </div> -->
        <div>
            <a [routerLink]="['configs'] | linkFix">Configurations</a>
        </div>
    </div>


    <div style="padding-top: 35px; display: flex;">
        <mat-form-field>
            <mat-label>Domain sub</mat-label>
            <input
                matInput
                type="text"
                [name]="'DomainSub'"
                placeholder="i.e. fun"
                [(ngModel)]="data.domain.sub"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Domain top</mat-label>
            <input
                matInput
                type="text"
                [name]="'DomainTop'"
                placeholder="i.e. example.com"
                [(ngModel)]="data.domain.top"
                required
            />
        </mat-form-field>
    </div>

    <div style="padding-top: 15px;">
        <app-button
            [busy]="busy"
            [disabled]="frm.pristine || busy || !frm.valid"
            (action)="frm.ngSubmit.emit()"
        >Save {{data.domain.sub || ''}}{{data.domain.sub ? '.' : ''}}{{data.domain.top}}</app-button>
    </div>

    <div style="padding-top: 35px;">
        <app-button
            [busy]="busy"
            [disabled]="busy"
            (action)="registerPaymentMethod()"
        >{{data.domain.data?.paymentMethodDomain?.stripe?.created ? "RE-register" : "Register"}} Payment Method Domain</app-button>
    </div>
</form>
