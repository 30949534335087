import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';

import {
    FormDirective,
} from 'bkng';

import { CheckoutComponent } from '../checkout/checkout.component';
import { ICheckoutSectionValidator } from '@classes/checkout-section-validator';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { IOrganization } from '@amt/i-organization';
import { GTAG } from '@c/app.component';

declare const gtag: GTAG;

@Component({
    selector: 'app-checkout-section',
    templateUrl: './checkout-section.component.html',
    styleUrls: ['./checkout-section.component.scss'],
    exportAs: 'section'
})
export class CheckoutSectionComponent implements OnInit {
    activating: boolean = false;
    @Input() active: boolean = false;
    activated: boolean = false;
    @Input() completed: boolean = false;
    @Input() data: unknown = {};
    @Input() enabled: boolean = true;
    env: IEnv;
    initialized: boolean = false;
    nextSection: CheckoutSectionComponent;
    number: number;
    @Output() onComplete = new EventEmitter<unknown>();
    @Output() onNext = new EventEmitter<unknown>();
    @Output() onValid = new EventEmitter<unknown>();
    @Output() organizationChange = new EventEmitter<IOrganization>();
    previousSection: CheckoutSectionComponent;
    sectionName: string = this.constructor.name;
    valid: boolean = false;
    validating: boolean = false;
    validators: Array<ICheckoutSectionValidator> = [];

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public router: Router,
    ) {
        this.env = envService.data;

        checkout.sections.push(this);
        this.number = checkout.sections.length;
        this.previousSection = checkout.sections[checkout.sections.indexOf(this) - 1];

        if (this.previousSection) {
            this.previousSection.nextSection = this;
        }
    }

    activate (): Promise<boolean> {
        if (this.activating) {
            return Promise.resolve(false);
        }
        if (!this.activated) {
            this.onFirstActivation();
        }
        this.activating = true;
        this.active = true;
        this.activated = true;
        return Promise.resolve(true).finally(() => {
            this.activating = false;
        });
    }

    async activateNextIncomplete (): Promise<void> {
        if (this.nextSection) {
            if (this.nextSection.completed) {
                await this.nextSection.activateNextIncomplete();
                return;
            }
            else {
                await this.nextSection.activate();
                return;
            }
        }
    }

    addValidator (req: ICheckoutSectionValidator): void {
        if (this.validators.indexOf(req) < 0) {
            this.validators.push(req);
        }
    }

    close (): Promise<boolean> {
        this.active = false;
        return Promise.resolve(true);
    }

    closeOtherActiveSections (): Promise<boolean> {
        const activeSections = this.checkout.sections.filter((s) => ((s !== this) && s.active));
        const activeSectionsCloseFns = activeSections.map((as) => as.close());

        return Promise.all(activeSectionsCloseFns).then((results) => {
            if (results.indexOf(false) < 0) {
                return true;
            }
            return false;
        });
    }

    complete (): Promise<boolean> {
        return this.validate().then((ok) => {
            if (ok) {
                this.completed = true;
                this.onComplete.emit();

                const gtagData = {
                    donations_platform: true,
                    page_path: this.router.url,
                    section_name: this.sectionName,
                    valid: ok,
                };
                if (this.sectionName?.length && typeof(gtag) !== 'undefined') {
                    gtag('event', 'donation_section_completed', gtagData);
                }

                return true;
            }
            return false;
        });
    }

    completeAndClose (): Promise<boolean> {
        return this.complete().then((ok) => {
            if (ok) return this.close();
            return false;
        });
    }

    edit (): Promise<boolean> {
        if (this.enabled && this.initialized) {
            return this.closeOtherActiveSections().then((ok) => {
                if (ok) {
                    return this.activate();
                }
                return false;
            });
        }
        return Promise.resolve(false);
    }

    isEditable (): boolean {
        return (this.activated || this.completed) && this.enabled && !this.active;
    }

    async next (): Promise<void> {
        await this.completeAndClose().then((ok) => {
            if (ok) {
                this.onNext.emit();
            }
        });
    }

    ngOnInit (): void {
        this.initialized = true;
        setTimeout(() => {
            if (!this.checkout.hasActive()) {
                this.checkout.activateFirstEnabledAndIncomplete().catch((err) => {
                    console.error(err);
                });
            }
        });
        this.onInit();
    }

    onFirstActivation (): void {
        // implement custom method in extended class if need be.
    }

    onInit (): void {
    }

    removeValidator (req: ICheckoutSectionValidator): void {
        if (this.validators.indexOf(req) > -1) {
            this.validators.splice(this.validators.indexOf(req), 1);
        }
    }

    sectionValidateForm (frm: FormDirective) {
        // convenience fn
        return (): Promise<boolean> => {
            return frm.submit();
        };
    }

    runValidationFunctions (): Promise<boolean> {
        if (this.validators.length) {
            this.validating = true;
            const vFns = this.validators.filter((cr) => cr.active).map((cr) => cr.check());
            return Promise.all(vFns).then((results) => {
                if (results.indexOf(false) < 0) {
                    return true;
                }
                return false;
            });
        }
        return Promise.resolve(true);
    }

    validate (): Promise<boolean> {
        this.validating = true;
        return this.runValidationFunctions().then((ok) => {
            this.valid = ok;
            if (ok) {
                this.onValid.emit();
                return true;
            }
            return false;
        }).finally(() => {
            setTimeout(() => {
                this.validating = false;
            }, 1);
        });
    }

    validateAndClose (): Promise<boolean> {
        return this.validate().then((ok) => {
            if (ok) return this.close();
            return false;
        });
    }

    validateAndCloseOtherActiveSections (): Promise<boolean> {
        const activeSections = this.checkout.sections.filter((s) => ((s !== this) && s.active));
        const activeSectionsValidateAndCloseFns = activeSections.map((as) => as.validateAndClose());

        return Promise.all(activeSectionsValidateAndCloseFns).then((results) => {
            if (results.indexOf(false) < 0) {
                return true;
            }
            return false;
        });
    }
}
