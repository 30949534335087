import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { OrganizationConfigsViewComponent } from '@c/organization-configs-view/organization-configs-view.component';
import { OrganizationDomainsViewComponent } from '@c/organization-domains-view/organization-domains-view.component';
import { OrganizationEmailsViewComponent } from '@c/organization-emails-view/organization-emails-view.component';
import { OrganizationViewComponent } from '@c/organization-view/organization-view.component';

import { configs } from './configs/configs';
import { domains } from './domains/domains';
import { emails } from './emails/emails';

export const id: BkRoutes = [
    {
        path: ":organizationId",
        label: "Organization by ID",
        ngRoute: routeStartingPoint([
            outletPlatform({component: OrganizationViewComponent}),
        ]),
        children: [
            {
                path: "configs",
                label: "Configs",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: OrganizationConfigsViewComponent}),
                ]),
                children: configs,
            },
            {
                label: "Domains",
                path: "domains",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: OrganizationDomainsViewComponent}),
                ]),
                children: domains,
            },
            {
                label: "Emails",
                path: "emails",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: OrganizationEmailsViewComponent}),
                ]),
                children: emails,
            },
        ],
    },
];
