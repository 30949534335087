<div class="checkout-section checkout-section-donor-info" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title"><span class="num">{{number}}.</span><span>Fundraiser</span></div>
        <div class="checkout-section-edit-button" *ngIf="isEditable()"><span (bkClick)="edit()">Edit</span></div>
    </div>

    <form name="sectionForm" bkForm #sectionForm="bkForm" [ngClass]="{'bk-hidden': !active}" [appCheckoutSectionValidator]="sectionValidateForm(sectionForm)" [checkoutSection]="this">
        <div class="button-holder">
            <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>

    <div class="checkout-section-detail-view checkout-section-detail-view-payment" *ngIf="!active && (activated || completed)">
        <div>
            <div class="participant-card">
                <a
                    class="profile-photo"
                    [style.backgroundImage]="'url(' + (donation.campaignParticipant.user | profilePhotoUrl) + ')'"
                    [routerLink]="['/', donation.campaignParticipant.url]"
                ></a>
                <div class="participant-card-data">
                    <div class="participant-card-data-name">
                        <a [routerLink]="['/', donation.campaignParticipant.url]" style="color: rgb(51, 51, 51);">{{donation.campaignParticipant.user?.name}}</a>
                    </div>
                </div>
            </div>
            <div class="muted" style="padding-top: 15px;">
                <span>On behalf of {{env.merchant.label}}'s <span style="white-space: nowrap;">"{{env.campaign.label}}" campaign.</span></span>
            </div>
        </div>
    </div>
</div>
