import { lastValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    RestService
} from 'bkng';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `payment`;
    }

    modifyCampaignParticipantMissionPayment (options: {
        paymentId: string;
        campaignId: string;
        campaignParticipantId: string;
        campaignParticipantMissionId: string;
    }): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${options.paymentId}/modify/mission/payment`, options));
    }

    search (params: unknown): Promise<unknown> {
        return lastValueFrom(this.$get(`search`, {
            params
        }));
    }
}
