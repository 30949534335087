<div class="donate showing" *ngIf="data">
    <app-crowdfund-header [showAccountButtons]="true"></app-crowdfund-header>

    <div class="crowdfund-common-container crowdfund-top" *ngIf="env?.domain?.config?.page?.id && data.mission?.id">
        <div class="crowdfund-top-video" *ngIf="data.mission.mission.data.landingVideoEmbedUrl">
            <bk-responsive-iframe
                [src]="data.mission.mission.data.landingVideoEmbedUrl"
                [initialWidth]="data.mission.mission.data.landingVideoWidth || 560"
                [initialHeight]="data.mission.mission.data.landingVideoHeight || 315"
                [title]="'Video player'"
                [frameborder]="0"
            ></bk-responsive-iframe>
            <!--
            Removed allow and allowfullscreen due to NG0910 error

            [allow]="'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'"
            [allowfullscreen]="true"
            -->
        </div>
        
        <div class="crowdfund-top-campaign-data">
            <div class="crowdfund-top-campaign-data-title-container">
                <div class="crowdfund-top-campaign-data-title-container-inner">
                    <div
                        class="crowdfund-top-campaign-data-pre-title"
                        *ngIf="env.domain.config.page.data.preTitle"
                        [innerHtml]="env.domain.config.page.data.preTitle"
                    ></div>
                    <div
                        class="crowdfund-top-campaign-data-title"
                        *ngIf="env.domain.config.page.title"
                        [innerHtml]="env.domain.config.page.title"
                    ></div>
                </div>
                <!-- <div class="crowdfund-top-campaign-data-title-donate">
                    <a *ngIf="participant?.id" class="button callout primary" [routerLink]="[participant?.url, 'donate']">DONATE</a>
                </div> -->
            </div>

            <div class="crowdfund-top-participant-container" [ngClass]="data.mission.mission.data.landingVideoEmbedUrl ? 'with-media' : 'without-media'">
                <div class="crowdfund-top-participant">
                    <div class="crowdfund-top-participant-card">
                        <div class="profile-photo" [style.backgroundImage]="'url(' + (data.participant.user | profilePhotoUrl) + ')'"></div>
                        <div class="participant-card-data">
                            <div class="participant-card-data-decoration">
                                <span>Fundraiser</span>
                            </div>
                            <div class="participant-card-data-name">
                                <span>{{data.participant.user.name}}</span>
                            </div>
                            <div class="participant-card-data-country" *ngIf="country?.code">
                                <span>{{country.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="crowdfund-top-campaign-data-status-numbers">

                    <div class="crowdfund-top-campaign-data-status">
                        <div>
                            <div class="crowdfund-top-campaign-data-status-progress">
                                <span>{{data.mission?.info?.numBenefactors || 0}}</span>
                            </div>
                            <div class="crowdfund-top-campaign-data-status-goal">
                                <span>Supporters</span>
                            </div>
                        </div>
                    </div>

                    <div class="crowdfund-top-campaign-data-status" *ngIf="data.mission.mission.data.fundraisingGoal">
                        <div>
                            <div class="progress-bar-container">
                                <app-progress-bar [denominator]="this.data.mission.mission.data.fundraisingGoal" [numerator]="getCurrentAmount()"></app-progress-bar>
                            </div>

                            <div class="crowdfund-top-campaign-data-status-progress">
                                <span>{{getCurrentAmount() | currency : 'USD' : 'symbol' : '1.0-0'}} / {{data.mission.mission.data.fundraisingGoal | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                                 
                            </div>

                            <div class="crowdfund-top-campaign-raised-and-goal">
                                <span>Raised</span><span>Goal</span>
                            </div>

                            <div class="crowdfund-top-campaign-data-status-goal">
                                <!-- <span>Campaign Goal: {{env.campaign.data.fundraisingGoal | currency : 'USD' : 'symbol' : '1.0-0'}}</span> -->
                            </div>

                            <!-- <div class="crowdfund-top-campaign-data-status-goal">
                                <span>Goal</span>
                                <span>Mission Goal: {{data.mission.mission.data.fundraisingGoal | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                            </div> -->
                        </div>
                    </div>

                    <div class="crowdfund-top-campaign-data-status" *ngIf="!data.mission.mission.data.fundraisingGoal">
                        <div>
                            <div class="crowdfund-top-campaign-data-status-progress">
                                <span>{{getCurrentAmount() | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                            </div>

                            <div class="crowdfund-top-campaign-data-status-goal">
                                <span>Raised</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="crowdfund-common-container crowdfund-common-container-side-padding crowdfund-ctrls with-top-border small-screen">
        <div class="crowdfund-ctrls-right-donate">
            <a *ngIf="data.participant?.id" class="button callout primary" [routerLink]="['.', 'donate'] | linkFix">DONATE</a>
            <share-buttons
                *ngIf="data.mission"
                [theme] = "'default'"
                [include] = "[
                'facebook',
                'twitter',
                'linkedin',
                'pinterest',
                'copy'
                ]"
                [show]="6"
                [url]="''"
                [autoSetMeta]="false"
                [showText]="true"
                [description]="data.mission.mission.data.why || ''"
            ></share-buttons>
        </div>
    </div>

    <div class="crowdfund-ctrls with-top-border">
        <div class="crowdfund-ctrls-left crowdfund-common-container-side-padding">
            <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" [ngClass]="['about', '', null, undefined].includes(route.snapshot.fragment) ? 'active' : 'inactive'">About</a>
            <!-- <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" fragment="participants" [ngClass]="route.snapshot.fragment === 'participants' ? 'active' : 'inactive'">Participants</a> -->
            <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" fragment="supporters" [ngClass]="route.snapshot.fragment === 'supporters' ? 'active' : 'inactive'">Supporters</a>
        </div>
        <div class="crowdfund-ctrls-right crowdfund-common-container-side-padding">
            <div class="crowdfund-ctrls-right-donate">
                <a *ngIf="data.mission?.id" class="button callout primary" [routerLink]="['.', 'donate'] | linkFix">DONATE</a>
                <share-buttons
                    *ngIf="data.mission?.id"
                    [theme] = "'default'"
                    [include] = "[
                    'facebook',
                    'twitter', 
                    'linkedin', 
                    'pinterest',
                    'copy'
                    ]"
                    [show]="6"
                    [url]="''"
                    [autoSetMeta]="false"
                    [showText]="true"
                    [description]="data.mission.mission.data.why || ''"
                ></share-buttons>
            </div>
        </div>
    </div>

    <div id="about" class="crowdfund-common-container crowdfund-mid with-top-border">
        <div class="crowdfund-mid-inner-container first-callout" *ngIf="data.mission?.id">
            <div *ngIf="data.mission.mission.data.eventType || data.mission.mission.data.calloutTitle">
                <div class="callout-title crowdfund-common-container-side-padding" [innerHtml]="(data.mission.mission.data.eventType || data.mission.mission.data.calloutTitle) | safe:'html'"></div>
            </div>

            <div *ngIf="data.mission.mission.data.eventLocationCity || data.mission.mission.data.eventLocationState || data.mission.mission.data.eventLocationCountry">
                <div class="callout-subtitle-event-location crowdfund-common-container-side-padding">
                    <span *ngIf="data.mission.mission.data.eventLocationCity">{{data.mission.mission.data.eventLocationCity}}</span>
                    <span *ngIf="data.mission.mission.data.eventLocationCity && (data.mission.mission.data.eventLocationState || data.mission.mission.data.eventLocationCountry)">,&nbsp;</span>
                    <span *ngIf="data.mission.mission.data.eventLocationState">{{data.mission.mission.data.eventLocationState}}</span>
                    <span *ngIf="data.mission.mission.data.eventLocationState && data.mission.mission.data.eventLocationCountry">,&nbsp;</span>
                    <span *ngIf="eventCountry?.name">{{eventCountry.name}}</span>
                </div>
            </div>

            <div *ngIf="data.mission.mission.data.when">
                <div class="callout-subtitle-event-location crowdfund-common-container-side-padding">
                    <span>{{data.mission.mission.data.when}}</span>
                </div>
            </div>

            <div>
                <div class="callout-content crowdfund-common-container-side-padding" [innerHtml]="(data.mission.mission.data.why || '') | safe:'html'"></div>
            </div>

            <div>
                <div class="callout-content crowdfund-common-container-side-padding" [innerHtml]="(data.mission.mission.data.who || '') | safe:'html'"></div>
            </div>
        </div>

        <div class="crowdfund-mid-inner-container second-callout">
            <div>
                <div class="callout-title crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.rightCalloutTitle | safe:'html'"></div>
            </div>
            <div>
                <div class="callout-content crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.rightCalloutText | safe:'html'"></div>
            </div>
        </div>
    </div>

    <div id="supporters" class="crowdfund-common-container crowdfund-supporters with-top-border" *ngIf="data.participant?.id">
        <div class="crowdfund-common-container-title crowdfund-common-container-side-padding">Supporters</div>

        <div *ngIf="data.supporters?.length" class="crowdfund-supporters-list-container crowdfund-common-list-side-padding">
            <div *ngFor="let supporter of data.supporters" class="crowdfund-supporters-list-iteration">
                <app-crowdfund-supporter-tile
                    [supporter]="supporter"
                ></app-crowdfund-supporter-tile>
            </div>
        </div>

        <div *ngIf="!data.supporters?.length" class="crowdfund-supporters-list-container crowdfund-common-list-side-padding">
            <div class="">Be the first to support {{data.participant.user.first_name}}! <a class="" [routerLink]="['.', 'donate']">Make a donation!</a></div>
        </div>
    </div>
</div>
