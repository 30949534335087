import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';

import { IOrganization } from '@amt/i-organization';

import { AddOrganizationDialogComponent, IAddOrganizationDialogData } from '../add-organization-dialog/add-organization-dialog.component';

@Component({
    selector: 'app-add-organization',
    templateUrl: './add-organization.component.html',
    styleUrls: ['./add-organization.component.scss']
})
export class AddOrganizationComponent implements OnInit {
    @Input() data: IAddOrganizationDialogData;
    @Output() selected = new EventEmitter<IOrganization>();

    constructor (
        public dialog: MatDialog,
    ) { }

    ngOnInit (): void {
    }

    openDialog (): MatDialogRef<AddOrganizationDialogComponent> {
        const dialogRef = this.dialog.open(AddOrganizationDialogComponent, {
            width: '556px',
            data: this.data
        });

        dialogRef.afterClosed().subscribe((result: IOrganization) => {
            if (result) {
                this.selected.emit(result);
            }
        });

        return dialogRef;
    }
}
