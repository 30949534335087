<div class="donate showing" *ngIf="clearedToLoad">
    <app-header-holder></app-header-holder>

    <div class="checkout-holder">
        <div class="checkout-content">

            <div class="checkout-section checkout-section-donor-info active" *ngIf="!serverErrorMessages?.length">
                <div class="checkout-section-title-holder">
                    <div class="checkout-section-title">
                        <!-- <span class="num">{{number}}.</span> -->
                        <span>{{busy ? 'Confirming ...' : 'Confirmed!'}}</span>
                    </div>
                </div>
                
                <div class="text" *ngIf="busy">We're confirming your email address.</div>
                <div class="text" *ngIf="!busy">Your e-mail address is confirmed!</div>

                <div class="" style="display: flex; padding-top: 25px;">
                    <a class="button callout primary" href="/account">
                        <span *ngIf="env.user?.id">Go to your account page</span>
                        <span *ngIf="!env.user?.id">Log in</span>
                    </a>
                </div>
            </div>

            <div class="checkout-section checkout-section-donor-info active" *ngIf="!busy && serverErrorMessages?.length">
                <div class="checkout-section-title-holder">
                    <div class="checkout-section-title">
                        <!-- <span class="num">{{number}}.</span> -->
                        <span>Problems</span>
                    </div>
                </div>
                
                <div class="text" style="padding-bottom: 15px;">Sorry, there were issues !</div>

                <div class="form">
                    <div class="error-messages showing" *ngIf="serverErrorMessages?.length">
                        <div *ngFor="let sem of serverErrorMessages" [innerHtml]="sem" class="error-message"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
