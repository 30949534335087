import { Pipe, PipeTransform } from '@angular/core';
import { IMerchant } from '@lib/model/types/i-merchant';

@Pipe({
    name: 'merchantLogoUrl',
    pure: false,
})
export class MerchantLogoUrlPipe implements PipeTransform {

    transform (
        merchant: IMerchant,
    ): string {
        if (!merchant?.logo_file) {
            return '/icon.ico';
        }
        return `/files/type/merchant_logo/id/${merchant.logo_file.id}${merchant.logo_file.extname}`;
    }

}
