import { BkRoutes } from 'bkng';

import { outletLoggedIn } from '@ngr/outlet-logged-in';
import { outletNotLoggedIn } from '@ngr/outlet-not-logged-in';
import { outletMerchant } from '@ngr/outlet-merchant';
import { outletPlatform } from '@ngr/outlet-platform';
import { outletTeamDonationCampaign } from '@ngr/outlet-team-donation-campaign';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { AccountPasswordResetComponent } from '@c/account-password-reset/account-password-reset.component';

export const password: BkRoutes = [
    {
        path: "reset",
        label: "Reset",
        ngRoute: routeStartingPoint([
            outletMerchant({component: AccountPasswordResetComponent}),
            outletPlatform({component: AccountPasswordResetComponent}),
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: AccountPasswordResetComponent}),
                outletLoggedIn({component: AccountPasswordResetComponent})
            ])
        ]),
        children: [],
    },
];
