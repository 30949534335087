export type GTAG = (
    thing: string,
    description: string,
    data: unknown,
) => void;

declare const gtag: GTAG;

import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import {
    RecaptchaService,
    LoadBlockerService
} from 'bkng';

import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    env: IEnv;

    constructor (
        public envService: EnvService,
        private recaptchaService: RecaptchaService,
        public lbs: LoadBlockerService,
        private router: Router,
    ) {
        this.env = envService.data;

        if (typeof(gtag) !== 'undefined') {
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                // Track page view.
                gtag('event', 'page_view', {
                    donations_platform: true,
                    page_path: event.urlAfterRedirects,
                });
            });
        }
    }
}
