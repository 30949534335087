import { Injectable } from '@angular/core';

/*
(<any>window).recaptchaLoadedFn = () => {
    console.log('test 1');
}
*/

type RecaptchaLoadedFn = () => void;

type GCap = {
    execute: (
        sitekey: string,
        options: unknown,
    ) => {
        then: (
            fn: (token: string) => void
        ) => void;
    };
};

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    grecaptcha: GCap;

    constructor () {
        (<{recaptchaLoadedFn: RecaptchaLoadedFn}><unknown>window).recaptchaLoadedFn = () => {
            // loaded
            this.grecaptcha = (<{grecaptcha: GCap;}><unknown>window).grecaptcha;
        };
    }

    addScript (
        key: string
    ): void {
        if (this.grecaptcha) {
            console.warn("Recaptcha already loaded.");
            return;
        }

        const script = document.createElement('script');
        // const lang = this.lang ? '&hl=' + this.lang : '';
        script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaLoadedFn&render=${key}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    async execute (
        sitekey: string,
        options: unknown
    ): Promise<string> {
        if (!this.grecaptcha) {
            return await Promise.reject(new Error(`Recaptcha not loaded.`));
        }

        // grecaptcha.execute( ...  does not return catch!
        // ^ whenever that was written.
        return await new Promise((resolve, reject) => {
            try {
                this.grecaptcha.execute(
                    sitekey,
                    options
                ).then((token) => {
                    resolve(token);
                });
            }
            catch (e) {
                reject(e);
            }
        });
    }
}
