<div
    *ngIf="
        user?.email?.[0]?.id
        &&
        user?.data
        &&
        !user?.email?.[0]?.confirmed
    "
    class="please-confirm-email-message-container"
>
    <div>Please check your e-mail ("<b>{{user.email?.[0]?.value}}</b>") to confirm your account!</div>
    
    <div
        class="resend"
        *ngIf="canShowResendEmailVerification()"
    >
        <span>Didn't get it? Click <span class="link" (click)="resendEmailVerification()">here</span> to resend.</span>
    </div>
    
    <div
        class="resend"
        *ngIf="!canShowResendEmailVerification()"
    >
        <span>Verification email resent. Please wait a few minutes to try again.</span>
    </div>
</div>
