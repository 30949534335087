<app-login
    (success)="loggedIn()"
></app-login>

<div class="donate showing" *ngIf="env?.user?.id">
    <app-header-holder></app-header-holder>
    <div class="checkout-holder">
        <div class="checkout-content">
            <div class="checkout-section checkout-subscriptions active">
                <div class="checkout-section-title-holder">
                    <div class="checkout-section-title">
                        <span *ngIf="!env?.user">Your account</span>
                        <span *ngIf="env?.user">{{env.user.name}}</span>
                    </div>
                </div>

                <p>This is your Pyzon account page.</p>

                <div class="subscriptions-list">
                    <p *ngIf="!subscriptions.length">You currently do not have any active subscriptions.</p>
                    
                    <div class="title" *ngIf="subscriptions.length">Your subscriptions:</div>

                    <div class="subscription" *ngFor="let subscription of subscriptions" [ngClass]="{'active': subscription.active || !subscription.cancelled_at}">
                        <div *ngIf="subscription.id && subscription.cancelled_at">Subscription cancelled!</div>
                        <div *ngIf="subscription.id && !subscription.cancelled_at">
                            <div class="main-data">
                                <div class="who-to">
                                    <span>{{subscription.order.data.merchant.name}}</span>
                                </div>
                                <div class="details">
                                    <div class="amount-interval">
                                        <span>{{subscription.order.data.donation.amount | currency}} <span>({{subscription.order.data.donation.intervalStr.toLowerCase()}})</span></span>
                                    </div>
                                    <div class="started">
                                        <span>Started: {{subscription.order.created_at | friendlyDate}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="who-for" *ngIf="subscription.order.data.donation.inMemoryOrHonor.value">
                                <div *ngIf="subscription.order.data.donation.inMemoryOrHonor.which == 'honor'">
                                    <span>In honor of {{subscription.order.data.donation.inMemoryOrHonor.value}}</span>
                                </div>
                                <div *ngIf="subscription.order.data.donation.inMemoryOrHonor.which == 'memory'">
                                    <span>In memory of {{subscription.order.data.donation.inMemoryOrHonor.value}}</span>
                                </div>
                            </div>

                            <div class="control-buttons" [ngClass]="{'pad': !subscription.order.data.donation.inMemoryOrHonor.value}" *ngIf="subscription.active || !subscription.cancelled_at">
                                <app-button (action)="cancelSubscription(subscription)" [busy]="busy">Cancel</app-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="padding-top: 15px; font-size: 12px; color: #a1a1a1;">{{env.user.email[0].value}}</div>
                <div style="padding-top: 55px;"><a class="button" href="/api/v1/user/auth/logout" target="_self">Log out</a></div>
            </div>
        </div>
    </div>
</div>

