import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
    RoutesService,
} from 'bkng';

import { AdminService } from '@s/admin.service';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { IEnv } from '@alt/i-env';
import { IUser } from '@amt/i-user';
import { ISubscription } from '@amt/i-subscription';
import { EnvService } from '@s/env.service';
import { GetCampaignOptions } from '@api-routes/admin/types/get-campaign-options';
import { GetCampaignResult } from '@api-routes/admin/types/get-campaign-result';

@Component({
    selector: 'app-crowdfund-admin',
    templateUrl: './crowdfund-admin.component.html',
    styleUrls: ['./crowdfund-admin.component.scss']
})
export class CrowdfundAdminComponent implements OnInit {
    busy: boolean = false;
    campaignParticipant: ICampaignParticipant;
    clearedToLoad: boolean = false;
    data: GetCampaignResult;
    env: IEnv;
    loadBlocker: Blocker;
    subscriptions: Array<ISubscription> = [];
    user?: IUser;

    constructor (
        public envService: EnvService,
        public route: ActivatedRoute,
        public lbs: LoadBlockerService,
        public adminService: AdminService,
        public routesService: RoutesService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    fetch (): Promise<void> {
        if (this.env.merchantUserRoles?.admin) {
            const options: GetCampaignOptions = {
                campaignId: this.env.campaign.id,
            };
            return this.adminService.campaign(
                options
            ).then((response) => {
                console.log("CrowdfundAdminComponent:", response);
                this.data = response;
                this.routesService.setBkRouteData({
                    activatedRoute: this.route,
                    data: response,
                });
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                this.loadBlocker.unblock();
                this.clearedToLoad = true;
            });
        }
        console.log("Not logged in ...");
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.loadBlocker.unblock();
                this.clearedToLoad = true;
                resolve();
            }, 5);
        });
    }

    ngOnInit (): void {
        this.fetch().catch((err) => {
            console.error(err);
        });
    }
}
