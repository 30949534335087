<div class="donate showing" *ngIf="data && clearedToLoad">
    <app-crowdfund-header
        [showAccountButtons]="false"
        [showCampaignAdminNav]="env.merchantUserRoles?.admin"
    ></app-crowdfund-header>

    <app-resend-verification-email
        [user]="data.user"
    ></app-resend-verification-email>

    <div class="checkout-holder">
        <div class="checkout-content">
            <div
                class="checkout-section checkout-section-donor-info active"
                [ngClass]="{
                    'checkout-section-large': true
                }"
                style="padding: 15px;"
            >
                <div *ngIf="data.campaignParticipantMission?.initialized">
                    <app-campaign-participant-profile
                        [campaignParticipant]="data.campaignParticipant"
                        [campaignParticipantMission]="data.campaignParticipantMission"
                        [participant]="data.user"
                        [person]="data.person"
                        (createdMission)="fetch()"
                        (uploadedProfilePhoto)="fetch()"
                    ></app-campaign-participant-profile>
                </div>

                <div *ngIf="!data.campaignParticipantMission?.initialized && env.campaign?.id">
                    <app-button [busy]="busy" [disabled]="busy" (click)="joinCampaign()">Join campaign "{{env.campaign.label}}"!</app-button>
                </div>
                
                <div style="padding-top: 15px; font-size: 12px; opacity: 0.7;">
                    <a href="/api/v1/user/auth/logout">Log-out</a>
                </div>
            </div>
        </div>
    </div>
</div>
