import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';
import { CampaignService } from '@s/campaign.service';
import { SignInDialogData, SignInDialogService } from '@c/sign-in-dialog/sign-in-dialog.component';
import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-crowdfund-landing',
    templateUrl: './crowdfund-landing.component.html',
    styleUrls: ['./crowdfund-landing.component.scss']
})
export class CrowdfundLandingComponent implements OnInit {
    clearedToLoad: boolean = false;
    data: CampaignGetResult;
    env: IEnv;
    fragment: string;
    loadBlocker: Blocker;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
        public signInDialogService: SignInDialogService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            setTimeout(() => {
                this.loadBlocker.unblock();
                this.clearedToLoad = true;
            });
        });
    }

    fetch (): Promise<void> {
        return this.campaignService.getById({
            id: this.env.campaign.id,
        }).then((res) => {
            if (this.env.mode !== 'production') {
                console.log("CrowdfundLandingComponent", res);
            }
            else {
                console.log(res);
            }
            this.data = res;
            return Promise.resolve();
        });
    }

    getCurrentAmount (): number {
        const cc = this.env.campaign.config.public.fundraisingGoalCurrencyCode || 'USD';
        return parseFloat(String(this.env.campaign.info?.amountByCurrency?.[cc] || 0));
    }

    ngOnInit (): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
        });
    }

    openSignInDialog (options: SignInDialogData = {}): void {
        const dialogRef = this.signInDialogService.open(options);
        dialogRef.afterClosed().subscribe((result) => {
            if (!options.redirect) {
                this.fetch().catch(console.error);
            }
        });
    }
}
