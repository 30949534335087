<div class="interface-navigation">
    <div class="interface-navigation-main-menu" [matMenuTriggerFor]="mainMenu">
        <button mat-icon-button color="primary" aria-label="Menu">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #mainMenu="matMenu">
            <a mat-menu-item class="main-menu-nav-item" [routerLink]="['/admin']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <span>Admin</span>
            </a>
            <a mat-menu-item class="main-menu-nav-item" [routerLink]="['/', 'admin', 'campaign', 'participants']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <span>Participants</span>
            </a>
        </mat-menu>
    </div>
    <div class="interface-navigation-user-menu" [matMenuTriggerFor]="userMenu">
        <div class="user-image" [ngStyle]="{'background-image': 'url(' + (env.user | profilePhotoUrl) + ')'}"></div>
        <mat-menu #userMenu="matMenu">
            <a mat-menu-item [routerLink]="['/account']">
                <span>{{env.user.name}}</span>
            </a>
            <a mat-menu-item href="/api/v1/user/auth/logout">Log out</a>
        </mat-menu>
    </div>
</div>
