import { HttpClient, HttpParams, HttpEvent } from '@angular/common/http';
import {
    // Inject,
    Injectable,
} from '@angular/core';
import { Observable, share } from 'rxjs';
import {
    // ENV_SERVICE,
    CommonEnvService
} from './env.service';
import { HttpResponseType } from './types/http-response-type';

@Injectable({
    providedIn: 'root'
})
export class RestService <
    ENVDATA = unknown
> {
    namespace = '';

    constructor (
        public http: HttpClient,
        public envService: CommonEnvService<ENVDATA>,
        // @Inject(ENV_SERVICE) public envService: EnvService
    ) {
    }

    $getPrefix (): string {
        const env = <{
            api?: {
                pathPrefix?: string;
            };
        }>(this.envService.data);

        let prefix = ``;
        if (env?.api) {
            if (env.api.pathPrefix) {
                prefix = env.api.pathPrefix;
            }
        }

        return prefix;
    }

    $getUrl (url: string = ''): string {
        url = url || '';
        let urlToReturn = this.$getPrefix();

        if (this.namespace) {
            if (!/\/$/.test(urlToReturn) && !/^\//.test(this.namespace)) {
                urlToReturn = `${urlToReturn}/`;
            }
            urlToReturn = `${urlToReturn}${this.namespace}`;
        }

        if (url) {
            if (!/\/$/.test(urlToReturn) && !/^\//.test(url)) {
                urlToReturn = `${urlToReturn}/`;
            }
            urlToReturn = `${urlToReturn}${url}`;
        }

        if (!/^\//.test(urlToReturn)) {
            urlToReturn = `/${urlToReturn}`;
        }

        return urlToReturn;
    }

    $get (
        url: string = '',
        options: unknown = {}
    ): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(
            http.get(
                url,
                options
            )
        ) as Observable<ArrayBuffer>;
    }

    $delete <
        T = string
    > (
        url: string,
        options: {
            params?: Record<string, string>;
            responseType?: HttpResponseType
        } = {}
    ): Observable<T> {
        options = options || {};
        options.responseType = options.responseType || "text";

        const http = this.http;

        url = this.$getUrl(url);

        const request = <Observable<T>>http.delete(
            url,
            <unknown>options
        );

        return this.$watch(
            request
        );
    }

    $post (
        url: string,
        body?: unknown,
        options?: {
            observe?: 'events';
            params?: HttpParams | {
                [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
            };
            reportProgress?: boolean;
            responseType?: 'json';
        }
    ): Observable<ArrayBuffer>;

    $post (
        url: string,
        body?: unknown,
        options?: {
            params?: HttpParams | {
                [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
            };
            reportProgress?: boolean;
            responseType?: 'text';
        }
    ): Observable<HttpEvent<string>>;

    $post (
        url: string = '',
        body: unknown = {},
        options: {
            observe?: string;
            params?: HttpParams | {
                [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
            };
            reportProgress?: boolean;
            responseType?: string;
        } = {}
    ): Observable<unknown> {
        const http = this.http;

        url = this.$getUrl(url);

        // const test = http.post(url, body, {
        //     // responseType: `blob`,
        //     // responseType: 'text',
        //     // responseType: 'json',
        //     reportProgress: true,
        //     observe: `events`,
        // });

        return this.$watch(
            http.post(
                url,
                body,
                <unknown>options
            )
        ) as Observable<unknown>;
    }

    $put (
        url: string = '',
        body: unknown = {},
        options: {
            params?: HttpParams | {
                [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
            };
        } = {}
    ): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(
            http.put(url, body, options)
        ) as Observable<ArrayBuffer>;
    }

    $watch <T> (
        request: Observable<T>
    ): Observable<T> {
        request = request.pipe(share()); // avoid duplicate calls due to subscribe below. (Subscribe causes dupe. Don't fully understand yet.)

        request.subscribe({
            error: (err: {
                status?: number;
            }) => {
                if (err?.status) {
                    if ([
                        401,
                        403,
                        405,
                    ].includes(err.status)) {
                        this.envService.get({action: `refresh-after-rest-error-${err.status}`}).catch((err: {status: number;}) => {
                            console.warn(`Could not refresh environment.json after rest error ${String(err?.status)}.`);
                        });
                    }
                }
            },
        }); // next, complete also available

        return request;
    }
}
