import { ICampaign } from '@amt/i-campaign';
import { IPayment } from '@amt/i-payment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { IModifyPaymentMissionDialogData, IModifyPaymentMissionReturnData, ModifyPaymentMissionDialogComponent } from '@c/modify-payment-mission-dialog/modify-payment-mission-dialog.component';

@Component({
    selector: 'app-merchant-order-list-item-payment',
    templateUrl: './merchant-order-list-item-payment.component.html',
    styleUrls: ['./merchant-order-list-item-payment.component.scss']
})
export class MerchantOrderListItemPaymentComponent implements OnInit {
    @Input() campaign: ICampaign;
    @Input() campaigns: Array<ICampaign> = [];
    @Output() modifiedPaymentMission = new EventEmitter<IModifyPaymentMissionReturnData>();
    @Input() payment: IPayment;

    constructor (
        public dialog: MatDialog,
    ) { }

    modifyPaymentMission (): MatDialogRef<ModifyPaymentMissionDialogComponent> {
        const dialogData: IModifyPaymentMissionDialogData = {
            campaign: this.campaign,
            campaigns: this.campaigns,
            payment: this.payment,
        };

        const dialogRef = this.dialog.open(ModifyPaymentMissionDialogComponent, {
            width: '556px',
            data: dialogData
        });

        dialogRef.afterClosed().subscribe((result: IModifyPaymentMissionReturnData) => {
            console.log(`Closed modify payment mission dialog.`, result);
            if (result?.payment?.id) {
                this.modifiedPaymentMission.emit(result);
            }
        });

        return dialogRef;
    }

    ngOnInit (): void {
    }

    paymentDirection (
        amount: string | number
    ): "inbound" | "outbound" {
        return parseInt(String(amount), 10) >= 0 ? "inbound" : "outbound";
    }
}
