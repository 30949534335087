import { Component, OnInit } from '@angular/core';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
    blocker: Blocker;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
    ) {
        this.blocker = lbs.register({});
    }

    ngOnInit (): void {
        this.blocker.unblock();
    }
}
