import {
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    LoadBlockerService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { ResetPasswordDialogService } from '@c/reset-password-dialog/reset-password-dialog.component';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { UserLoginCredentials } from '@api-routes/user/types/user-login-credentials';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    clearedToLoad: boolean = false;
    credentials: UserLoginCredentials = {
        email: "",
    };
    env: IEnv;
    @Output() success = new EventEmitter<unknown>();

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public userService: UserService,
        public router: Router,
        public route: ActivatedRoute,
        public resetPasswordDialogService: ResetPasswordDialogService,
    ) {
        this.env = envService.data;

        const blocker = lbs.register({});

        setTimeout(() => {
            blocker.unblock();
            this.clearedToLoad = true;
        });
    }

    forgotPass (): void {
        this.resetPasswordDialogService.open({credentials: this.credentials});
    }

    loggedIn (event: unknown): void {
        this.success.emit(event);
    }

    ngOnInit (): void {
    }
}
