import {
    Component,
    Input,
} from '@angular/core';

import {
    RecaptchaService,
} from 'bkng';

import {
    CheckoutSectionComponent
} from '../checkout-section/checkout-section.component';

import { CheckoutComponent, IPaymentRequestOptions } from '../checkout/checkout.component';

import {
    DonateComponent
} from '../donate/donate.component';

import { OrderService } from '@s/order.service';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { Router } from '@angular/router';
import { EnvService } from '@s/env.service';
import { GTAG } from '@c/app.component';

import { MatDialog } from '@angular/material/dialog';

declare const gtag: GTAG;

type PAYREQEVENT = {
    complete: (desc: string) => void;
    payerPhone: CreateDonationBody["billingAddress"]["phone"]["iso"];
    token?: CreateDonationBody["paymentSourceToken"];
};

@Component({
    selector: 'app-checkout-section-review-donation',
    templateUrl: './checkout-section-review-donation.component.html',
    styleUrls: ['./checkout-section-review-donation.component.scss'],
    exportAs: 'section',
})
export class CheckoutSectionReviewDonationComponent extends CheckoutSectionComponent {
    @Input('donateComponent') dc: DonateComponent;
    donating: boolean = false;
    @Input() donation: CreateDonationBody;
    @Input() paymentRequestOptions: IPaymentRequestOptions;
    paymentRequestPromise: {
        resolve: (event: unknown) => unknown;
        reject: (event: unknown) => unknown;
    };
    sectionName: string = "review_donation";

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public RS: RecaptchaService,
        public orderService: OrderService,
        public router: Router,
        public dialog: MatDialog,
    ) {
        super(checkout, envService, router);

        this.checkout.paymentRequestCancelled.subscribe((prCancelledEvent) => {
            if (this.paymentRequestPromise?.reject) {
                this.paymentRequestPromise.reject(prCancelledEvent);
            }
        });

        this.checkout.paymentRequestToken.subscribe((prTokenEvent) => {
            if (this.paymentRequestPromise?.resolve) {
                this.paymentRequestPromise.resolve(prTokenEvent);
            }
        });

        this.checkout.paymentRequestPossible.subscribe((prPossibleEvent) => {
            if (prPossibleEvent && !this.donation.savedPaymentMethod) {
                this.donation.paymentMethodName = 'payment_request';
            }
        });
    }

    async donate (): Promise<unknown> {
        const donation = this.donation;
        this.donating = true;

        return await this.checkout.validateAllSections().then((ok) => {
            if (!ok) {
                return this.checkout.activateFirstInvalid();
            }

            /*const allSectionsValidateFns = this.checkout.sections.map((s) => s.validate());
        return Promise.all(allSectionsValidateFns).then((results) => {
            if (results.indexOf(false) > -1) {
                return this.checkout.activateFirstInvalid();
            }
            // end of testing shit*/

            if (donation.paymentMethodName === 'payment_request') {
                let label = 'One-time donation';
                if (donation.interval === 'month') {
                    label = 'Donation (billed monthly)';
                }
                else if (donation.interval === 'year') {
                    label = 'Donation (billed yearly)';
                }

                const prUpdateOpts: {
                    currency?: string;
                    total: {
                        label: string;
                        amount: number;
                    }
                } = {
                    total: {
                        label: label,
                        amount: parseInt((donation.amount * 100).toString(), 10)
                    }
                };

                if (this.paymentRequestOptions?.currency) {
                    prUpdateOpts.currency = this.paymentRequestOptions.currency;
                }

                this.checkout.paymentRequest.update(prUpdateOpts);
                this.checkout.paymentRequest.show();

                return new Promise((resolve, reject) => {
                    this.paymentRequestPromise = {resolve, reject};
                }).then((prEvent: PAYREQEVENT) => {
                    const prToken = donation.paymentSourceToken = prEvent.token;

                    if (this.env.mode !== `production`) console.log('Payment request token:', prToken);

                    donation.billingAddress.phone.iso = donation.billingAddress.phone.iso || prEvent.payerPhone;

                    const ba = prToken.card;

                    if (ba) {
                        const names = ba.name.split(' ');
                        const firstName = names.shift();
                        const lastName = names.join(' ');

                        donation.billingAddress.firstName = donation.billingAddress.firstName || firstName;
                        donation.billingAddress.lastName = donation.billingAddress.lastName || lastName;

                        donation.billingAddress.address1 = donation.billingAddress.address1 || ba.address_line1;
                        donation.billingAddress.address2 = donation.billingAddress.address2 || ba.address_line2;

                        donation.billingAddress.city = donation.billingAddress.city || ba.address_city;
                        donation.billingAddress.state = donation.billingAddress.state || ba.address_state;
                        donation.billingAddress.postal_code = donation.billingAddress.postal_code || ba.address_zip;

                        donation.billingAddress.country_code = donation.billingAddress.country_code || ba.address_country || ba.country;
                    }

                    return this.submitDonation(donation, prEvent);
                }).catch((err) => {
                    // rejected by user?
                    if (err) {
                        console.error(err);
                    }
                });
            }
            return this.submitDonation(donation);
        }).catch((err) => {
            if (this.env.mode !== 'production') console.error(err);
            if (this.env.mode === 'production') throw (err);
        }).finally(() => {
            this.donating = false;
        });
    }

    giveCreateAccountOption (): boolean {
        const donation = this.donation;
        const env = this.env;
        if (env.user?.id) {
            // a user is already logged-in, don't show
            return false;
        }
        if (donation.interval !== 'once') {
            // no choice, you have to create an account, so hide
            return false;
        }
        if (donation.savePaymentDetails) {
            // in order to save payment details, you need to create an account, hide option
            return false;
        }
        return true;
    }

    showCreateAccountPasswordField (): boolean {
        const donation = this.donation;
        const env = this.env;
        if (env.user?.id) {
            // a user is already logged-in, don't show
            return false;
        }
        if (donation.interval !== 'once') {
            // recurring donation, show password field
            return true;
        }
        if (donation.savePaymentDetails) {
            // saving payment details, show password field
            return true;
        }
        return donation.createAccount;
    }

    submitDonation (
        donation: CreateDonationBody,
        prEv?: PAYREQEVENT
    ): Promise<unknown> {
        if (this.env.mode !== `production`) {
            console.log('Creating donation:', donation, prEv || '');
        }
        return this.RS.execute(
            this.env.recaptcha.sitekey,
            {action: 'make_donation'},
        ).then((recaptchaToken) => {
            donation.recaptchaToken = recaptchaToken;
            const env = this.env;
            return this.orderService.create(
                donation
            ).then((res) => {
                if (this.env.mode !== `production`) {
                    console.log(`Success`, res);
                }

                // if (res.user && res.createdUser && env && !env.user) {
                //     env.user = env.user || {};
                //     Object.assign(env.user, res.user);
                // }

                this.dc.success = res;

                if (prEv) {
                    prEv.complete('success');
                }

                const gtagData = {
                    amount: donation.amount,
                    donations_platform: true,
                    donor_email: donation.donor.email,
                    page_path: this.router.url,
                };
                if (typeof(gtag) !== 'undefined') {
                    gtag('event', 'donation_submitted', gtagData);
                }

                return res;
            }).catch((err: {
                error?: unknown;
            }) => {
                if (err) {
                    this.dc.error = err?.error;

                    if (env.mode === `production`) {
                        console.error(err);
                    }
                    else {
                        console.log("Error while submitting donation", err);
                    }

                    if (prEv) {
                        prEv.complete('fail');
                    }
                }
                // rejected by user?
            });
        });
    }
}
