import { Component,
    Input,
    OnInit
} from '@angular/core';

import { EnvService } from '@s/env.service';

import { IMerchant } from '@amt/i-merchant';
import { IEnv } from '@alt/i-env';
import { MerchantService } from '@s/merchant.service';
import { IIntegration } from '@amt/i-integration';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';
// import { IGa4Integration } from '@amt/i-ga4-integration';

@Component({
    selector: 'app-merchant-list-item',
    templateUrl: './merchant-list-item.component.html',
    styleUrls: ['./merchant-list-item.component.scss']
})
export class MerchantListItemComponent implements OnInit {
    busy: boolean = false;
    env: IEnv;
    hubspot: IHubspotIntegration;
    hubspotProd: IHubspotIntegration; // For use in dev mode only.
    @Input() merchant: IMerchant;

    constructor (
        public envService: EnvService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;
    }

    connectHubspotUrl (m: IMerchant, prod?: boolean): string {
        let url: string = `/auth/application/hubspot/connect?merchant_id=${m.id}`;
        if (prod) {
            url = `${url}&prod=1`;
        }
        return url;
    }

    connectMailchimpUrl (m: IMerchant): string {
        return `/auth/application/mailchimp/connect?merchant_id=${m.id}`;
    }

    connectSalesForceUrl (m: IMerchant): string {
        return `/auth/application/salesforce/connect?merchant_id=${m.id}`;
    }

    connectSlackUrl (m: IMerchant): string {
        const base = `/auth/application/slack/connect?`;
        if (m?.id) {
            return `${base}merchant_id=${m.id}`;
        }
        return `${base}system=1`;
    }

    connectStripeUrl (m: IMerchant): string {
        if (!this.env?.stripe) return;
        return `/auth/application/payment/stripe/connect?merchant_id=${m.id}`;
    }

    ngOnInit (): void {
        this.merchant.analytics = this.merchant.analytics || {};
        // this.merchant.analytics.ga4 = this.merchant.analytics.ga4 || <IGa4Integration>{};

        if (this.merchant?.hubspot?.length) {
            // "prod" here indicates prod hubspot integration,
            // for fetching properties Jules made. Hacky, I know.
            // intended only to be temporary for putting Jules at ease.
            this.hubspot = this.merchant.hubspot.filter((item: IHubspotIntegration) => {
                if (this.env.mode === `production`) {
                    // take the first one.
                    return true;
                }
                return !item.options.prod;
            })[0];
            if (this.env.mode !== `production`) {
                this.hubspotProd = this.merchant.hubspot.filter((item: IHubspotIntegration) => {
                    return item.options.prod;
                })[0];
            }
        }
    }

    async saveIntegration (
        integration: Partial<IIntegration>,
    ): Promise<void> {
        if (this.busy) {
            return await Promise.resolve();
        }

        this.busy = true;

        try {
            if (
                integration.id
            ) {
                const result = await this.merchantsService.updateIntegration(
                    this.merchant.id,
                    integration.id,
                    integration,
                );

                console.log("Update integration result:", result);

                Object.assign(
                    this.merchant,
                    result.merchant,
                );
            }
            else {
                const result = await this.merchantsService.createIntegration(
                    this.merchant.id,
                    integration.app,
                    integration,
                );

                console.log("Create integration result:", result);

                Object.assign(
                    this.merchant,
                    result.merchant,
                );
            }
        }
        catch (err) {
            console.error(err);
        }

        this.busy = false;
    }
}
