<h2 mat-dialog-title>{{options.title}}</h2>

<mat-dialog-content>
    <pre [innerHTML]="options.body | safe" style="white-space: pre-wrap;"></pre>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        *ngFor="let act of options.actions"
        mat-raised-button
        [color]="act.color || 'basic'"
        (click)="act.action(this)"
        [disabled]="busy"
    >
        <span>{{busy && act.busyLabel ? act.busyLabel : act.label}}</span>
    </button>
</mat-dialog-actions>
