import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-nav-breadcrumb',
    templateUrl: './nav-breadcrumb.component.html',
    styleUrls: ['./nav-breadcrumb.component.scss']
})
export class NavBreadcrumbComponent implements OnInit {
    @Input() navigable: boolean = false;
    @Input() routerLink: string | string[] = ['.'];
    @Input() text: string = 'NOCRUMBTXT';

    constructor () { }

    ngOnInit (): void {
    }
}
