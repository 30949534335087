import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { DomainService } from '@s/domain.service';
import { IEnv } from '@alt/i-env';
import { DomainGetResult } from '@api-routes/domain/types/domain-get-result';
import { NgForm } from '@angular/forms';
import { DomainGetOptions } from '@api-routes/domain/types/domain-get-options';

@Component({
    selector: 'app-domain-view',
    templateUrl: './domain-view.component.html',
    styleUrls: ['./domain-view.component.scss']
})
export class DomainViewComponent implements OnInit {
    blocker: Blocker;
    busy: boolean = false;
    data?: DomainGetResult;
    env: IEnv;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public domainService: DomainService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});
    }

    async fetch (): Promise<void> {
        if (this.busy) {
            return;
        }

        this.busy = true;
        const getOpts: DomainGetOptions = {};

        if (
            this.route.snapshot.params.domainId
        ) {
            getOpts.id = <string>(this.route.snapshot.params.domainId);
        }

        if (
            this.route.snapshot.params.merchantId
        ) {
            getOpts.merchantId = <string>(this.route.snapshot.params.merchantId);
        }

        if (
            this.route.snapshot.params.organizationId
        ) {
            getOpts.organizationId = <string>(this.route.snapshot.params.organizationId);
        }

        return await this.domainService.getById(
            getOpts
        ).then((res) => {
            console.log("DomainViewComponent got:", res);
            this.data = res;
            return Promise.resolve();
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    async registerPaymentMethod (): Promise<void> {
        this.busy = true;

        try {
            const response = await this.domainService.registerPaymentMethod({
                domainId: <string>(this.route.snapshot.params.domainId),
            });

            Object.assign(
                this.data.domain.data,
                response.domain.data
            );

            console.log("Registered payment method domain:", response);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.busy = false;
        }
    }

    async save (
        frm: NgForm,
    ): Promise<void> {
        if (
            this.busy
            ||
            !frm.valid
        ) {
            return;
        }

        this.busy = true;

        const res = await this.domainService.save({
            id: <string>(this.route.snapshot.params.domainId),
            top: this.data.domain.top,
            sub: this.data.domain.sub,
        }).finally(() => {
            this.busy = false;
        });

        console.log("Saved:", res);

        Object.assign(
            this.data.domain,
            res.domain,
        );

        frm.form.markAsPristine();
    }
}
