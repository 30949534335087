import { Stripe, StripeConstructorOptions } from '@stripe/stripe-js';

type Env = {
    stripe?: {
        key?: string;
    };
};

declare const Stripe: (
    publishableKey: string,
    options?: StripeConstructorOptions,
) => Stripe;

import {
    Injectable
} from '@angular/core';

import { CommonEnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    env: Env;
    stripe: Stripe;

    constructor (
        public envService: CommonEnvService
    ) {
        const env = this.env = <Env>(envService.data);

        if (env.stripe?.key) {
            if (typeof(Stripe) !== 'undefined') {
                this.stripe = Stripe(env.stripe.key);
            }
            else {
                this.stripe = <Stripe><unknown>{
                    createToken: (card) => {
                        return new Promise(null);
                    },
                    elements: () => {
                        return {
                            create: () => {
                                return {
                                    destroy: () => {
                                        return;
                                    },
                                    mount: () => {
                                        return {};
                                    },
                                    on: () => {
                                        return {};
                                    }
                                };
                            }
                        };
                    },
                    paymentRequest: () => {
                        return {
                            canMakePayment: () => {
                                return Promise.resolve({});
                            },
                            on: () => {
                                return {};
                            }
                        };
                    }
                };
            }
        }
    }
}
