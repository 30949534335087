<div style="flex: 0 0 100%;">
    <mat-form-field style="width: 100%;">
        <mat-label>Campaign Config Label</mat-label>
        <input matInput type="text" [name]="'ConfigLabel'" [(ngModel)]="config.label" (ngModelChange)="doSave()" />
    </mat-form-field>
</div>

<div style="flex: 0 0 100%;">
    <mat-form-field style="width: 100%;">
        <mat-label>Fundraising Goal</mat-label>
        <input matInput type="number" [name]="'FundraisingGoal'" [(ngModel)]="config.public.fundraisingGoal" (ngModelChange)="doSave()" />
    </mat-form-field>
</div>

<div style="flex: 0 0 100%;">
    <mat-form-field appearance="fill" style="min-width: 350px;">  
        <mat-label>Currency</mat-label>
        <mat-select [name]="'SelectedCurrency'" [(ngModel)]="config.public.fundraisingGoalCurrencyCode" (ngModelChange)="doSave()">
            <mat-option *ngFor="let c of env.currencies" [value]="c.code">
                <span>{{c.code}} - {{c.name}} ({{c.symbol}})</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="slack" style="border: 2px solid grey; padding: 5px;">
    <div>
        <div style="flex: 0 0 100%;">
            <app-slack-channel-select
                [name]="'CampaignParticipantSignupNotificationSlackChannel'"
                [integrationId]="merchant.slack.id"
                [(ngModel)]="slack.events.participant.signup.channel"
                (ngModelChange)="doSave()"
                [label]="'Participant Signup Notification Slack Channel'"
            ></app-slack-channel-select>
        </div>

        <div *ngIf="slack.events.participant.signup.channel">
            <app-test-slack-channel
                [merchant]="merchant"
                [channel]="slack.events.participant.signup.channel"
            ></app-test-slack-channel>
        </div>
    </div>

    <div style="padding-top: 35px;">
        <div style="flex: 0 0 100%;">
            <app-slack-channel-select
                [name]="'Donation_Notification_Channel'"
                [integrationId]="merchant.slack.id"
                [(ngModel)]="slack.events.donation.channel"
                (ngModelChange)="doSave()"
                [label]="'Donation Notification Channel'"
            ></app-slack-channel-select>
        </div>

        <div *ngIf="slack.events.donation.channel">
            <app-test-slack-channel
                [merchant]="merchant"
                [channel]="slack.events.donation.channel"
            ></app-test-slack-channel>
        </div>
    </div>
</div>

<div *ngFor="let hubspot of hubspots" style="padding-top: 35px;">
    <div>
        <b>Hubspot:</b>    
    </div>
    <app-campaign-edit-config-hubspot
        [config]="hubspot.config"
        [integration]="hubspot.integration"
        [merchant]="merchant"
        (updated)="save()"
    ></app-campaign-edit-config-hubspot>
</div>
