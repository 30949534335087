<div class="platform-view-component" *ngIf="organization?.id">
    <div>
        <span>Organization: {{organization.label}} (Domains)</span>
    </div>
    <div style="padding-top: 15px;" *ngIf="domains?.length">
        <div *ngFor="let d of domains" style="padding: 10px 0px;">
            <app-domain-list-item [domain]="d"></app-domain-list-item>
        </div>
    </div>
    <div style="padding-top: 25px;">
        <app-add-domain [data]="data" (created)="createdDomain($event)">
            <span>Add Domain</span>
        </app-add-domain>
    </div>
</div>
