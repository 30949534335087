import { Component, Input, OnInit } from '@angular/core';
import { IIncomingWebhook } from '@amt/i-incoming-webhook';
import { MerchantService } from '@s/merchant.service';

@Component({
    selector: 'app-incoming-webhook-reprocess-button',
    templateUrl: './incoming-webhook-reprocess-button.component.html',
    styleUrls: ['./incoming-webhook-reprocess-button.component.scss']
})
export class IncomingWebhookReprocessButtonComponent implements OnInit {
    busy: boolean = false;
    requestSent: boolean = false;
    @Input() webhook: IIncomingWebhook;

    constructor (
        public merchantService: MerchantService,
    ) {

    }

    ngOnInit (): void {
    }

    reprocess (): void {
        if (this.busy) {
            return;
        }

        console.log(`Reprocessing ${this.webhook.id} ...`);
        this.busy = true;

        this.merchantService.reprocessWebhook(this.webhook.id, {
            campaignParticipantMissionId: this.webhook.extra.campaignParticipantMissionId,
            externalCustomerId: this.webhook.extra.externalCustomerId,
        }).then((res) => {
            console.log("Sent reprocess request.", res);
            this.requestSent = true;
            this.webhook.processed = false;
            this.webhook.errored = false;
            this.webhook.ignored = false;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setTimeout(() => {
                this.busy = false;
            }, 500);
        });
    }
}
