/*
    This should be re-written
    hence eslint rule-silencing
*/

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */

import {
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

import { SelectComponent } from './select.component';
import { SelectInputDirective } from './select-input.directive';

@Directive({
    selector: '[bkSelectDisplay]',
    exportAs: 'SelectDisplayDirective'
})
export class SelectDisplayDirective {
    @Input() selectComponent: SelectComponent;

    constructor (public el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler (e: KeyboardEvent): void {
        if (e.key || e.keyCode) {
            const si: SelectInputDirective = this.selectComponent.selectInput;

            if (!si) return;

            si.el.nativeElement.focus();

            const propEvent: any = new Event(e.type, e);

            propEvent.altKey = e.altKey;
            propEvent.code = e.code;
            propEvent.ctrlKey = e.ctrlKey;
            // propEvent.isComposing = e.isComposing;
            propEvent.key = e.key;
            propEvent.keyCode = e.keyCode;
            propEvent.location = e.location;
            propEvent.metaKey = e.metaKey;
            propEvent.repeat = e.repeat;
            propEvent.shiftKey = e.shiftKey;
            propEvent.which = e.which;

            si.el.nativeElement.dispatchEvent(propEvent);
        }
    }

    /*
    @HostListener('blur', ['$event'])
    blurHandler(e) {
        console.log('Blurred display', e);
    }
    */
}
