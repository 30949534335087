import { BkRoutes } from 'bkng';

import { template } from './template/template';

export const emails: BkRoutes = [
    {
        path: "template",
        label: "Template",
        children: template,
    },
];
