import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IDomain } from '@amt/i-domain';
import { IEmailTemplate } from '@amt/i-email-template';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { DomainService } from '@s/domain.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-domain-emails-view',
    templateUrl: './domain-emails-view.component.html',
    styleUrls: ['./domain-emails-view.component.scss']
})
export class DomainEmailsViewComponent implements OnInit {
    blocker: Blocker;
    domain: IDomain;
    emailTemplates: Array<IEmailTemplate> = [];
    env: IEnv;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public domainService: DomainService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    fetch (): Promise<void> {
        return this.domainService.getById({
            id: <string>(this.route.snapshot.params.domainId),
            merchantId: <string>(this.route.snapshot.params.merchantId),
        }).then((res) => {
            console.log(res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
