<div class="edit-email-template-component-internal-root" *ngIf="emailTemplate?.id">
    <div style="padding-top: 15px;">
        <div>
            <span style="font-size: 12px; opacity: 0.67;">{{emailTemplate.template_type.label}}</span>
            <span class="link" style="font-size: 12px; margin-left: 9px;" (click)="selectExamplePayment()">Select Example Payment</span>
        </div>
        <div style="display: flex;">
            <mat-form-field style="flex: 0 1 350px;">
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'EmailTemplateLabel'" [(ngModel)]="emailTemplate.label" (ngModelChange)="didChange()" />
            </mat-form-field>
            <mat-form-field style="margin-left: 15px; flex: 1 0 auto;">
                <mat-label>Subject</mat-label>
                <input matInput type="text" [name]="'EmailTemplateSubject'" [(ngModel)]="emailTemplate.subject" (ngModelChange)="didChange()" />
            </mat-form-field>
            <mat-form-field style="margin-left: 15px; flex: 0 1 350px;">
                <mat-label>From</mat-label>
                <input matInput type="text" [name]="'EmailTemplateFrom'" [(ngModel)]="emailTemplate.data.from" (ngModelChange)="didChange()" />
            </mat-form-field>
        </div>
    </div>

    <div class="panels">
        <div class="panel edit-panel">
            <ngx-monaco-editor
                style="height: 100%"
                [options]="editorOptions"
                [(ngModel)]="emailTemplate.html"
                (ngModelChange)="didChange()"
            ></ngx-monaco-editor>
        </div>
        <div class="panel view-panel" [innerHtml]="sanitize(emailTemplate.renderedHtml)"></div>
    </div>
</div>
