<td style="padding: 3px 5px;">
    <div>
        <span class="link" (click)="open = !open">{{open ? 'V' : '>'}} &nbsp;</span>
        <span>{{webhook.received_at | friendlyDateTime}}</span>
    </div>
    <div *ngIf="open" style="padding: 5px; border: 1px solid grey;">
        <pre
            style="white-space: pre-wrap; overflow: hidden;"
            [innerHtml]="webhook | stirfry"
            style="padding: 8px 0px; font-size: 12px;"
        ></pre>
        <div *ngIf="!webhook?.body?.data?.object?.customer">
            <input type="text" placeholder="External customer id" [(ngModel)]="webhook.extra.externalCustomerId" />
        </div>
        <div>
            <input type="text" placeholder="Campaign participant mission id" [(ngModel)]="webhook.extra.campaignParticipantMissionId" />
        </div>
        <app-incoming-webhook-reprocess-button
            [webhook]="webhook"
        ></app-incoming-webhook-reprocess-button>
    </div>
</td>

<td *ngIf="!open" style="padding: 3px 5px;">
    <span>{{webhook.body?.data?.object?.billing_details?.name || ' '}}</span>
</td>

<td *ngIf="!open" style="padding: 3px 5px;">
    <span *ngIf="webhook.body?.data?.object?.amount">
        <span>{{((webhook.body.data.object.amount / 100) | currency)}}</span>
        <span>&nbsp;{{webhook.body.data.object.currency.toUpperCase()}}</span>
    </span>
    <span>&nbsp;</span>
</td>

<td *ngIf="!open" style="padding: 3px 5px;">
    <span *ngIf="webhook.processed">Processed</span>
    <span *ngIf="webhook.ignored">Ignored</span>
    <span *ngIf="webhook.errored">Errored</span>
</td>

<td *ngIf="!open" style="padding: 3px 5px;">
    <app-incoming-webhook-reprocess-button
        [webhook]="webhook"
    ></app-incoming-webhook-reprocess-button>
</td>
