import {
    Component,
    forwardRef,
    Input,
    OnInit
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Channel } from '@slack/web-api/dist/types/response/ConversationsListResponse';

import { IntegrationService } from '@s/integration.service';

@Component({
    selector: 'app-slack-channel-select',
    templateUrl: './slack-channel-select.component.html',
    styleUrls: ['./slack-channel-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SlackChannelSelectComponent),
            multi: true
        }
    ],
})
export class SlackChannelSelectComponent implements ControlValueAccessor, OnInit {
    channels: Array<Channel> = [];
    @Input() label: string = 'Slack Channel';
    @Input() integrationId: string;
    onChange;
    onTouched;
    selectedChannel: string;

    constructor (
        public integrationService: IntegrationService,
    ) {
    }

    ngOnInit (): void {
        if (!this.integrationId) {
            return;
        }
        this.integrationService.getSlackChannels(this.integrationId).then((result) => {
            this.channels = result.channels;
        }).catch((err) => {
            console.error(err);
        });
    }

    registerOnChange (fn: unknown): void {
        this.onChange = fn;
    }

    registerOnTouched (fn: unknown): void {
        this.onTouched = fn;
    }

    writeValue (val: string): void {
        if (
            (this.onChange && this.onTouched)
        ) {
            this.selectedChannel = val;
        }
    }
}
