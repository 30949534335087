import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { configs } from './configs/configs';
import { emails } from './emails/emails';

import { DomainEmailsViewComponent } from '@c/domain-emails-view/domain-emails-view.component';
import { DomainViewComponent } from '@c/domain-view/domain-view.component';
import { DomainConfigsViewComponent } from '@c/domain-configs-view/domain-configs-view.component';

export const id: BkRoutes = [
    {
        path: ":domainId",
        label: "Domain",
        ngRoute: routeStartingPoint([
            outletPlatform({component: DomainViewComponent}),
        ]),
        children: [
            {
                path: "configs",
                label: "Configurations",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: DomainConfigsViewComponent}),
                ]),
                children: configs
            },
            {
                path: "emails",
                label: "Emails",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: DomainEmailsViewComponent}),
                ]),
                children: emails
            }
        ],
    },
];
