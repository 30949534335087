import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import {
    RestService
} from 'bkng';
import { IMerchantIndex } from '@arhat/i-merchant-index';
import { IMerchantHubspotIndex } from '@arhat/i-merchant-hubspot-index';
import { IMerchantMailchimpIndex } from '@arhat/i-merchant-mailchimp-index';
import { IMerchantSalesforceIndex } from '@arhat/i-merchant-salesforce-index';
import { EnvService } from './env.service';
import { MerchantIntegrationPutQuery } from '@api-routes/merchant/types/merchant-integration-put-query';
import { MerchantIntegrationPutBody } from '@api-routes/merchant/types/merchant-integration-put-body';
import { MerchantIntegrationPostQuery } from '@api-routes/merchant/types/merchant-integration-post-query';
import { MerchantIntegrationPostResult } from '@api-routes/merchant/types/merchant-integration-post-result';
import { MerchantIntegrationPutResult } from '@api-routes/merchant/types/merchant-integration-put-result';
import { MerchantOrdersGetQuery } from '@api-routes/merchant/types/merchant-orders-get-query';
import { MerchantOrdersGetResult } from '@api-routes/merchant/types/merchant-orders-get-result';
import { MerchantOrdersGetOptions } from '@api-routes/merchant/types/merchant-orders-get-options';
import { MerchantPostOptions } from '@api-routes/merchant/types/merchant-post-options';
import { MerchantPostResponse } from '@api-routes/merchant/types/merchant-post-response';
import { MerchantPostQuery } from '@api-routes/merchant/types/merchant-post-query';

@Injectable({
    providedIn: 'root'
})
export class MerchantService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `merchant`;
    }

    copyHubspotProdSettingsToDev (merchantId: string, integrationId: string): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${merchantId}/hubspot/id/${integrationId}/copy_prod_settings_to_dev`, {})).then((result) => {
            return <unknown>result;
        });
    }

    create (newMerchant: unknown): Observable<ArrayBuffer> {
        return this.$post(`create`, newMerchant);
    }

    async createIntegration (
        merchantId: string,
        appName: string,
        integration: MerchantIntegrationPutBody,
    ): Promise<MerchantIntegrationPostResult> {
        const q: MerchantIntegrationPostQuery = {
            merchant_id: merchantId,
            app_name: appName,
        };

        const result = await <unknown>(lastValueFrom(
            this.$post(
                `integration`,
                integration,
                {
                    params: q,
                },
            )
        ));

        return <MerchantIntegrationPostResult>result;
    }

    fixPhoneNumbers (): Promise<unknown> {
        return lastValueFrom(this.$post(`fix_phone_numbers`));
    }

    getById (id: string): Promise<unknown> {
        return lastValueFrom(this.$get(``, {
            params: {
                id
            }
        }));
    }

    getDonationCsv (merchantId: string): Observable<ArrayBuffer> {
        return this.$get(`id/${merchantId}/get_donation_csv`, {responseType: `text/csv`});
    }

    getCadDonationCsv (merchantId: string): Observable<ArrayBuffer> {
        return this.$get(`id/${merchantId}/get_cad_donation_csv`, {responseType: `text/csv`});
    }

    getHubspotIndex (merchantId: string, integrationId: string): Promise<IMerchantHubspotIndex> {
        return lastValueFrom(this.$get(`id/${merchantId}/hubspot/id/${integrationId}`, {
            params: {}
        })).then((result: unknown) => {
            return <IMerchantHubspotIndex>result;
        });
    }

    getMailchimpIndex (merchantId: string): Promise<IMerchantMailchimpIndex> {
        return lastValueFrom(
            this.$get(
                `id/${merchantId}/mailchimp`,
                {
                    params: {}
                }
            )
        ).then((result: unknown) => {
            return <IMerchantMailchimpIndex>result;
        });
    }

    async getOrders (
        options: MerchantOrdersGetOptions,
    ): Promise<MerchantOrdersGetResult> {
        const queryParams: MerchantOrdersGetQuery = {
            merchant_id: options.merchantId,
        };

        if (
            options.limit
        ) {
            queryParams.limit = options.limit;
        }

        if (
            options.offset
        ) {
            queryParams.offset = options.offset;
        }

        const response = await lastValueFrom(
            this.$get(
                `orders`,
                {
                    params: queryParams
                }
            )
        );

        return <unknown>response;
    }

    getSalesforceIndex (merchantId: string): Promise<IMerchantSalesforceIndex> {
        return lastValueFrom(this.$get(`id/${merchantId}/salesforce`, {
            params: {}
        })).then((result: unknown) => {
            return <IMerchantSalesforceIndex>result;
        });
    }

    getWebhooksIncoming (id: string): Promise<unknown> {
        return lastValueFrom(this.$get(`id/${id}/webhooks/incoming`, {
            params: {}
        }));
    }

    index (): Promise<IMerchantIndex> {
        return lastValueFrom(this.$get(``, {
            params: {}
        })).then((result) => {
            return <unknown>result;
        });
    }

    reprocessWebhook (webhookId: string, body: {
        campaignParticipantMissionId?: string;
        externalCustomerId?: string;
    } = {}): Promise<unknown> {
        return lastValueFrom(this.$post(`webhook/id/${webhookId}/reprocess`, body));
    }

    async save (
        options: MerchantPostOptions,
    ): Promise<MerchantPostResponse> {
        const queryParams: MerchantPostQuery = {
            merchant_id: options.merchantId,
        };

        const body = new FormData();

        body.set(
            "body",
            JSON.stringify(options.body),
        );

        if (options.logoFile) {
            body.append(
                "logo-file",
                options.logoFile,
                options.logoFile.name,
            );
        }

        const response = await lastValueFrom(
            this.$put(
                "",
                body,
                {
                    params: queryParams,
                }
            )
        );

        return <MerchantPostResponse><unknown>response;
    }

    sendSlackTestMessage (merchantId: string, options: {
        channel?: string;
        messageText: string;
    }): Promise<unknown> {
        const params: {
            channel?: string;
        } = {};
        if (options.channel) {
            params.channel = options.channel;
        }
        return lastValueFrom(this.$post(`id/${merchantId}/integration/slack/test`, {
            text: options.messageText
        }, {
            params
        }));
    }

    syncContactsWithHubspot (merchantId: string, integrationId: string): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${merchantId}/contacts/sync/with/hubspot/id/${integrationId}`, {})).then((result) => {
            return <unknown>result;
        });
    }

    syncMailchimpContactsWithHubspot (merchantId: string, hubspotIntegrationId: string): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${merchantId}/mailchimp/sync/contacts/with/hubspot/id/${hubspotIntegrationId}`, {})).then((result) => {
            return <unknown>result;
        });
    }

    syncSalesforceWithHubspot (merchantId: string, hubspotIntegrationId: string): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${merchantId}/salesforce/sync/with/hubspot/id/${hubspotIntegrationId}`, {})).then((result) => {
            return <unknown>result;
        });
    }

    async updateIntegration (
        merchantId: string,
        integrationId: string,
        integration: MerchantIntegrationPutBody,
    ): Promise<MerchantIntegrationPutResult> {
        const q: MerchantIntegrationPutQuery = {
            merchant_id: merchantId,
            integration_id: integrationId,
        };

        const result = await lastValueFrom(
            this.$put(
                `integration`,
                integration,
                {
                    params: q,
                },
            )
        );

        return <MerchantIntegrationPutResult><unknown>result;
    }
}
