import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { ValidationDisplayComponent } from '../validation-display/validation-display.component';

@Component({
    selector: 'bk-validation-display-element',
    templateUrl: './validation-display-element.component.html',
    styleUrls: ['./validation-display-element.component.scss']
})
export class ValidationDisplayElementComponent implements OnInit {
    @Input() item: {
        active?: boolean;
        error?: {
            error?: unknown;
        };
        hasActivated?: boolean;
        key?: string;
    };

    classes: {
        error?: boolean;
        errored?: boolean;
    } = {};

    constructor (
        public validationDisplay: ValidationDisplayComponent
    ) {
    }

    handleClasses (): void {
        this.classes.error = this.item.active;
        this.classes.errored = this.item.hasActivated;
    }

    ngOnInit (): void {
        const item = this.item;
        this.classes[`requirement-key-${item.key}`] = true;
        this.validationDisplay.updated.subscribe(() => {
            this.handleClasses();
        });
        this.handleClasses();
    }
}
