import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BkRoute, RoutesService } from 'bkng';
import { BreadcrumbService, ICrumb } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-nav-breadcrumbs',
    templateUrl: './nav-breadcrumbs.component.html',
    styleUrls: ['./nav-breadcrumbs.component.scss']
})
export class NavBreadcrumbsComponent implements OnInit {
    crumbs: Array<ICrumb>;
    @Input() maxCrumbs: number = 5;
    name: string = `default`;

    constructor (
        public breadCrumbService: BreadcrumbService,
        public route: ActivatedRoute,
        public router: Router,
        public routesService: RoutesService,
    ) {
        this.setCrumbsArray();

        // this.route.data.subscribe((data) => {
        //     if (data?.bkRoute) {
        //         this.dropCrumbs(data.bkRoute);
        //     }
        // });

        this.routesService.onData.pipe().subscribe((data) => {
            if (route.snapshot.data?.bkRoute) {
                this.dropCrumbs(
                    <BkRoute>(route.snapshot.data.bkRoute)
                );
            }
        });


        if (route.snapshot.data?.bkRoute) {
            this.dropCrumbs(
                <BkRoute>(route.snapshot.data.bkRoute)
            );
        }
    }

    setCrumbsArray (crumbs?: Array<ICrumb>): Array<ICrumb> {
        if (crumbs) {
            this.crumbs = this.breadCrumbService.breadCrumbTrail[this.name] = crumbs;
        }
        else {
            this.crumbs = this.breadCrumbService.breadCrumbTrail[this.name] = this.breadCrumbService.breadCrumbTrail[this.name] || [];
        }
        return this.crumbs;
    }

    dropCrumb (bkRoute: BkRoute): void {
        const navigable = (bkRoute.ngRoute && this.crumbs.length) ? true : false;

        let txt = bkRoute?.info?.crumb || bkRoute.label;

        const maxLength = 30;
        if (txt.length > maxLength) {
            txt = `... ${txt.substring(txt.length - maxLength, txt.length)}`;
        }

        const pathPieces = bkRoute.pathFromRoot.split(`/`);

        const crumb: ICrumb = {
            bkRoute: bkRoute,
            navigable,
            pathPieces: pathPieces,
            text: txt,
        };

        if (
            bkRoute.parent
            &&
            (this.crumbs.length < this.maxCrumbs)
        ) {
            if (navigable && this.crumbs.length) {
                crumb.routerLink = this.router.url.split('/').splice(0, crumb.pathPieces.length + 1); // plus one to include front slash.
            }
            //if (bkRoute.ngRoute) {
            //    this.crumbs.unshift(crumb);
            //}
            this.crumbs.unshift(crumb);
            this.dropCrumb(bkRoute.parent);
        }
    }

    dropCrumbs (bkRoute: BkRoute): void {
        this.setCrumbsArray([]);
        this.dropCrumb(bkRoute);
    }

    ngOnInit (): void {
        this.setCrumbsArray();
    }
}
