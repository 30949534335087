import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    RestService
} from 'bkng';
import { lastValueFrom } from 'rxjs';
import { EnvService } from './env.service';
import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { DonationReturnData } from '@alt/donation-return-data';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `order`;
    }

    create (
        options: CreateDonationBody
    ): Promise<DonationReturnData> {
        return lastValueFrom(
            this.$post(
                ``,
                options
            )
        ).then((response: unknown) => {
            return <DonationReturnData>response;
        });
    }

    search (params: unknown): Promise<unknown> {
        return lastValueFrom(this.$get(`search`, {
            params
        }));
    }
}
