import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { MerchantWebhooksIncomingViewComponent } from '@c/merchant-webhooks-incoming-view/merchant-webhooks-incoming-view.component';

export const webhooks: BkRoutes = [
    {
        path: "incoming",
        label: "Merchant Webhooks: Incoming",
        ngRoute: routeStartingPoint([
            outletPlatform({component: MerchantWebhooksIncomingViewComponent}),
        ]),
    },
];
