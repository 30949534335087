<div>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>"Exists in DonPlat Property":</mat-label>
        <mat-select [name]="'mtMissionStatusProperty'" [(ngModel)]="config.sync.existence.propertyName" (ngModelChange)="changedExistenceHubspotProperty()">
            <mat-option *ngFor="let item of propertiesContactsFiltered" [value]="item.name">
                <span>{{item.label || item.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="config.sync.existence.propertyName" style="font-size: 11px; color: grey; padding: 0px 0px 25px 0px;">
    <span>"Exists in DonPlat" propertyName: {{config.sync.existence.propertyName}}</span>
</div>

<div *ngIf="config.sync.existence.property?.name">
    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Exists:</mat-label>
        <mat-select
            [name]="'mtExistenceExists'"
            [(ngModel)]="config.sync.existence.existsTrue"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.existence.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Does Not Exist:</mat-label>
        <mat-select
            [name]="'mtExistenceDoesNotExist'"
            [(ngModel)]="config.sync.existence.existsFalse"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.existence.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>MT Mission Status Property:</mat-label>
        <mat-select [name]="'mtMissionStatusProperty'" [(ngModel)]="config.sync.mission.status.propertyName" (ngModelChange)="changedMtMissionHubspotProperty()">
            <mat-option *ngFor="let item of propertiesContactsFiltered" [value]="item.name">
                <span>{{item.label || item.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="config.sync.mission.status.property?.name">
    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Has NO MT mission:</mat-label>
        <mat-select
            [name]="'mtMissionStatusOptionNoMission'"
            [(ngModel)]="config.sync.mission.status.options.none"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.mission.status.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Has INITIATED a mission:</mat-label>
        <mat-select
            [name]="'mtMissionStatusOptionActiveMission'"
            [(ngModel)]="config.sync.mission.status.options.initiated"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.mission.status.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Has MT mission & RAISED:</mat-label>
        <mat-select
            [name]="'mtMissionStatusOptionInactiveMission'"
            [(ngModel)]="config.sync.mission.status.options.raised"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.mission.status.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>"Is Mission Supporter Property":</mat-label>
        <mat-select [name]="'mtMissionSupporterProperty'" [(ngModel)]="config.sync.missionSupporter.propertyName" (ngModelChange)="changedMissionSupporterHubspotProperty()">
            <mat-option *ngFor="let item of propertiesContactsFiltered" [value]="item.name">
                <span>{{item.label || item.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="config.sync.missionSupporter.property?.name">
    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Is Supporter:</mat-label>
        <mat-select
            [name]="'mtMissionSupporterPropertySettingValueTrue'"
            [(ngModel)]="config.sync.missionSupporter.valueTrue"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.missionSupporter.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Is Not a Supporter:</mat-label>
        <mat-select
            [name]="'mtMissionSupporterPropertySettingValueFalse'"
            [(ngModel)]="config.sync.missionSupporter.valueFalse"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of config.sync.missionSupporter.property.options" [value]="item.value">
                <span>{{item.label || item.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="merchantHubspotIndex?.pipelines" style="padding: 25px 0px;">
    <div>
        <b>Hubspot Donation Sync:</b>
    </div>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Pipeline:</mat-label>
        <mat-select
            [name]="'hsDonationSyncPipeline'"
            [(ngModel)]="config.sync.donation.pipelineId"
            (ngModelChange)="changedDonationPipeline()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.pipelines.deals" [value]="item.id">
                <span>{{item.label || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="config.sync.donation.pipeline?.id">
        <mat-form-field appearance="fill" style="width: 100%;">  
            <mat-label>Stage: Received</mat-label>
            <mat-select
                [name]="'hsDonationSyncPipelineStageReceived'"
                [(ngModel)]="config.sync.donation.received.stageId"
                (ngModelChange)="updated.emit()"
            >
                <mat-option *ngFor="let item of config.sync.donation.pipeline.stages" [value]="item.id">
                    <span>{{item.label || item.id}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="config.sync.donation.pipeline?.id">
        <mat-form-field appearance="fill" style="width: 100%;">  
            <mat-label>Stage: Lost</mat-label>
            <mat-select
                [name]="'hsDonationSyncPipelineStageReceived'"
                [(ngModel)]="config.sync.donation.lost.stageId"
                (ngModelChange)="updated.emit()"
            >
                <mat-option *ngFor="let item of config.sync.donation.pipeline.stages" [value]="item.id">
                    <span>{{item.label || item.id}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Product - One-Time:</mat-label>
        <mat-select
            [name]="'hsOneTimeDonationSyncProduct'"
            [(ngModel)]="config.sync.donation.productIds.once"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.products" [value]="item.id">
                <span>{{item.properties?.name || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Product - Monthly:</mat-label>
        <mat-select
            [name]="'hsMonthlyDonationSyncProduct'"
            [(ngModel)]="config.sync.donation.productIds.month"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.products" [value]="item.id">
                <span>{{item.properties?.name || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Product - Yearly:</mat-label>
        <mat-select
            [name]="'hsYearlyDonationSyncProduct'"
            [(ngModel)]="config.sync.donation.productIds.year"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.products" [value]="item.id">
                <span>{{item.properties?.name || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Deal-to-Contact Association Type:</mat-label>
        <mat-select
            [name]="'hsDonationDealToContactAssociationType'"
            [(ngModel)]="config.sync.donation.association.dealsToContacts.typeId"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.associations.dealsToContacts" [value]="item.typeId">
                <span>{{item?.label || (item.typeId + ' (' + item.category + ')')}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>


<div *ngIf="merchantHubspotIndex?.pipelines" style="padding: 25px 0px;">
    <div>
        <b>Hubspot Mission/Deal Sync:</b>
    </div>

    <div>
        <span style="padding: 5px; font-size: 11px;" class="link" (click)="clearMissionSyncSettings()">Clear</span>
    </div>

    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>Pipeline:</mat-label>
        <mat-select
            [name]="'hsMissionDealSyncPipeline'"
            [(ngModel)]="config.sync.mission.pipeline.pipelineId"
            (ngModelChange)="changedParticipationPipeline()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.pipelines.deals" [value]="item.id">
                <span>{{item.label || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="config.sync.mission.pipeline.pipeline?.id">
        <mat-form-field appearance="fill" style="width: 100%;">  
            <mat-label>Stage: Pre-Initialization</mat-label>
            <mat-select
                [name]="'hsMissionDealSyncPipelineStagePreInitialization'"
                [(ngModel)]="config.sync.mission.pipeline.preInitialization.stageId"
                (ngModelChange)="updated.emit()"
            >
                <mat-option *ngFor="let item of config.sync.mission.pipeline.pipeline.stages" [value]="item.id">
                    <span>{{item.label || item.id}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="config.sync.mission.pipeline.pipeline?.id">
        <mat-form-field appearance="fill" style="width: 100%;">  
            <mat-label>Stage: Initialized</mat-label>
            <mat-select
                [name]="'hsMissionDealSyncPipelineStageInitialized'"
                [(ngModel)]="config.sync.mission.pipeline.initialized.stageId"
                (ngModelChange)="updated.emit()"
            >
                <mat-option *ngFor="let item of config.sync.mission.pipeline.pipeline.stages" [value]="item.id">
                    <span>{{item.label || item.id}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="config.sync.mission.pipeline.pipeline?.id">
        <mat-form-field appearance="fill" style="width: 100%;">  
            <mat-label>Stage: Raised</mat-label>
            <mat-select
                [name]="'hsMissionDealSyncPipelineStageRaised'"
                [(ngModel)]="config.sync.mission.pipeline.raised.stageId"
                (ngModelChange)="updated.emit()"
            >
                <mat-option *ngFor="let item of config.sync.mission.pipeline.pipeline.stages" [value]="item.id">
                    <span>{{item.label || item.id}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Product (Mission):</mat-label>
        <mat-select
            [name]="'hsMissionDealSyncProduct'"
            [(ngModel)]="config.sync.mission.productId"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.products" [value]="item.id">
                <span>{{item.properties?.name || item.id}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Deal-to-Contact Association Type:</mat-label>
        <mat-select
            [name]="'hsChampionDealToContactAssociationType'"
            [(ngModel)]="config.sync.mission.association.dealsToContacts.typeId"
            (ngModelChange)="updated.emit()"
        >
            <mat-option *ngFor="let item of merchantHubspotIndex.associations.dealsToContacts" [value]="item.typeId">
                <span>{{item?.label || (item.typeId + ' (' + item.category + ')')}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div>
        <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Mission URL Property:</mat-label>
            <mat-select [name]="'mtMissionUrlProperty'" [(ngModel)]="config.sync.mission.url.propertyName" (ngModelChange)="changedMtMissionHubspotProperty()">
                <mat-option *ngFor="let item of propertiesDealsFiltered" [value]="item.name">
                    <span>{{item.label || item.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
