import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { RestService } from 'bkng';

import { ILoginData } from '@alt/i-login-data';
import { IUserIndex } from '@arhat/i-user-index';
import { EnvService } from './env.service';
import { UserLoginCredentials } from '@api-routes/user/types/user-login-credentials';
import { UserWriteQuery } from '@api-routes/user/types/user-write-query';
import { UserWriteBody } from '@api-routes/user/types/user-write-body';
import { UserWriteResult } from '@api-routes/user/types/user-write-result';
import { UserSignupOptions } from '@api-routes/user/types/user-signup-options';
import { UserMissionPostQuery } from '@api-routes/user/types/user-mission-post-query';
import { UserMissionPostResponse } from '@api-routes/user/types/user-mission-post-response';

@Injectable({
    providedIn: 'root'
})
export class UserService extends RestService {
    constructor (http: HttpClient, envService: EnvService) {
        super(http, envService);
        this.namespace = `user`;
    }

    // checkUserExists (credentials: unknown): Promise<unknown> {
    //     return lastValueFrom(this.$get('exists', {params: credentials}));
    // }

    confirmEmail (credentials: ILoginData): Promise<unknown> {
        return lastValueFrom(this.$post('confirm_email', credentials)).then((res) => {
            return this.envService.get({user_action: 'confirm_email'}).then((envRes) => {
                return res;
            });
        });
    }

    getById (params: unknown): Promise<IUserIndex> {
        return lastValueFrom(this.$get(``, {
            params
        })).then((result: unknown) => {
            return <IUserIndex>result;
        });
    }

    async missionCreate (
        options: {
            campaignId: string;
            userId: string;
        }
    ): Promise<UserMissionPostResponse> {
        const queryParams: UserMissionPostQuery = {
            campaign_id: options.campaignId,
            user_id: options.userId,
        };

        const result = await lastValueFrom(
            this.$post(
                `mission`,
                {},
                {
                    params: queryParams,
                }
            )
        );

        return <unknown>result;
    }

    resetPassword (options: {
        password: string;
        recaptchaToken: string;
        token: string;
        userId: string;
    }): Promise<unknown> {
        return lastValueFrom(this.$post('reset_password', options)).then((res) => {
            return this.envService.get({user_action: `reset_password`}).then((envRes) => {
                return res;
            });
        });
    }

    async save (
        userId: string,
        data: UserWriteBody,
    ): Promise<UserWriteResult> {
        const queryParams: UserWriteQuery = {
            id: userId,
        };

        const response = await lastValueFrom<unknown>(
            this.$put(
                "",
                data,
                {
                    params: queryParams,
                }
            )
        );

        return <UserWriteResult>response;
    }

    sendPasswordResetLink (credentials: UserLoginCredentials): Promise<unknown> {
        return lastValueFrom(
            this.$post('send_password_reset_link', credentials)
        );
    }

    signIn (credentials: UserLoginCredentials): Promise<unknown> {
        return lastValueFrom(
            this.$post('auth', credentials)
        ).then((res) => {
            return this.envService.get({user_action: `logged_in`}).then((envRes) => {
                return res;
            });
        });
    }

    signUp (options: UserSignupOptions): Promise<unknown> {
        return lastValueFrom(this.$post('sign_up', options)).then((res) => {
            return this.envService.get({useraction: 1, signin: 1}).then((envRes) => {
                return res;
            });
        });
    }

    uploadProfilePhoto (
        userId: string,
        options: {
            fileToUpload: File;
        }
    ): Observable<unknown> {
        const queryParams: UserWriteQuery = {
            id: userId,
        };

        const uri = `/profile_photo`;

        const formData: FormData = new FormData();

        formData.append(
            'fileKey',
            options.fileToUpload,
            options.fileToUpload.name
        );

        return this.$post(uri, formData, {
            params: queryParams,
            reportProgress: true,
            observe: `events`,
        });
    }
}
