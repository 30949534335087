<div class="button" (bkButton)="doAction($event)" [bkBusy]="busy" [ngClass]="[disabled ? 'disabled' : '']">
    <div class="working-display-content">
        <div class="working-display-original-content">
            <ng-content></ng-content>
        </div>
        <div class="waiter lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
