import {
    Component,
    Input,
} from '@angular/core';

import { CheckoutComponent } from '../checkout/checkout.component';

import {
    CheckoutSectionComponent
} from '../checkout-section/checkout-section.component';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { Donor } from '@alt/donor';

import { Router } from '@angular/router';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-checkout-section-donor-info',
    templateUrl: './checkout-section-donor-info.component.html',
    styleUrls: ['./checkout-section-donor-info.component.scss'],
    exportAs: 'section'
})
export class CheckoutSectionDonorInfoComponent extends CheckoutSectionComponent {
    @Input() donation: CreateDonationBody;
    @Input() donor: Donor;
    @Input() hasAnonymousOption: boolean = false;
    sectionName: string = "donor_info";

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public router: Router,
    ) {
        super(checkout, envService, router);
    }
}

