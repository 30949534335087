import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs';

import {
    RestService
} from 'bkng';
import { IEmailTemplate } from '@amt/i-email-template';
import { lastValueFrom } from 'rxjs';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class EmailTemplateService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `email_template`;
    }

    create (options: unknown): Promise<{emailTemplate: IEmailTemplate}> {
        return lastValueFrom(
            this.$post(`create`, options)
        ).then((res: unknown) => {
            return <{emailTemplate: IEmailTemplate}>res;
        });
    }

    getById (id: string, params: unknown = {}): Promise<unknown> {
        return lastValueFrom(
            this.$get(
                `id/${id}`,
                {
                    params
                }
            )
        );
    }

    save (
        id: string,
        emailTemplate: unknown,
        queryParams: {
            payment_id?: string;
        } = {}
    ): Promise<unknown> {
        return lastValueFrom(
            this.$post(
                `id/${id}/save`,
                emailTemplate,
                {
                    params: queryParams
                }
            )
        );
    }
}
