<div class="platform-view-component" *ngIf="merchant?.id">
    <div>
        <span>Merchant: {{merchant.label}} (Incoming Webhooks)</span>
    </div>
    <div style="padding-top: 15px;" *ngIf="incomingWebhooks?.length">
        <table>
            <tr>
                <th style="padding: 3px 5px;">Date</th>
                <th style="padding: 3px 5px;">Customer?</th>
                <th style="padding: 3px 5px;">Amount?</th>
                <th style="padding: 3px 5px;">Status</th>
                <th style="padding: 3px 5px;">&nbsp;</th>
            </tr>
            <tr
                *ngFor="let iw of incomingWebhooks"
                app-incoming-webhook-table-row
                [webhook]="iw"
                style="overflow: hidden;"
            ></tr>
        </table>
    </div>
</div>
