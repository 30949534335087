import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDomain } from '@amt/i-domain';

import { IEmailTemplate } from '@amt/i-email-template';
import { IOrganization } from '@amt/i-organization';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { OrganizationService } from '@s/organization.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-organization-emails-view',
    templateUrl: './organization-emails-view.component.html',
    styleUrls: ['./organization-emails-view.component.scss']
})
export class OrganizationEmailsViewComponent implements OnInit {
    blocker: Blocker;
    domains: Array<IDomain>;
    emailTemplates: Array<IEmailTemplate> = [];
    env: IEnv;
    organization: IOrganization;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public organizationService: OrganizationService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    fetch (): Promise<void> {
        return this.organizationService.getById({
            organizationId: <string>(this.route.snapshot.params.organizationId),
        }).then((res) => {
            console.log(res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
