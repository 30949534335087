import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IEnv } from '@alt/i-env';
import { ICampaign } from '@amt/i-campaign';
import { Component, Input, OnInit } from '@angular/core';
import { CampaignService } from '@s/campaign.service';
import { EnvService } from '@s/env.service';
import { IMerchant } from '@amt/i-merchant';
import {
    ConfigCampaignCommon,
    IConfigCampaignIntegrationHubspot,
    IConfigCampaignIntegrationSlack,
} from '@amt/i-config-campaign-common';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';
import { MerchantService } from '@s/merchant.service';

@Component({
    selector: 'app-campaign-edit-config',
    templateUrl: './campaign-edit-config.component.html',
    styleUrls: ['./campaign-edit-config.component.scss']
})
export class CampaignEditConfigComponent implements OnInit {
    busy: boolean = false;
    @Input() campaign: ICampaign;
    @Input() config: ConfigCampaignCommon;
    @Input() merchant: IMerchant;
    env: IEnv;
    hubspots: Array<{
        config: IConfigCampaignIntegrationHubspot;
        integration: IHubspotIntegration;
    }> = [];
    saveSubject = new Subject();
    slack: IConfigCampaignIntegrationSlack;

    constructor (
        public envService: EnvService,
        public campaignService: CampaignService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch(console.error);
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    ngOnInit (): void {
        if (this.merchant.slack?.id) {
            this.config.internal.integration = this.config.internal.integration || {};
            this.config.internal.integration.slack = this.config.internal.integration.slack || {};
            this.slack = this.config.internal.integration.slack[this.merchant.slack.id] = this.config.internal.integration.slack[this.merchant.slack.id] || {};
            this.slack.events = this.slack.events || {};
            this.slack.events.donation = this.slack.events.donation || {};
            this.slack.events.participant = this.slack.events.participant || {};
            this.slack.events.participant.signup = this.slack.events.participant.signup || {};
        }

        [
            'internal',
            'data',
        ].forEach((configObjectKey: "internal" | "data") => {
            const configObject = this.config[configObjectKey];
            [
                'fundraisingGoal',
                'fundraisingGoalCurrencyCode',
                'participantSignupNotificationSlackChannel',
            ].forEach((keyToDelete) => {
                delete configObject[keyToDelete];
            });
        });

        delete (this.config.public as Record<string, string>).participantSignupNotificationSlackChannel;

        if (this.merchant.hubspot?.length) {
            this.config.internal.integration = this.config.internal.integration || {};
            this.config.internal.integration.hubspot = this.config.internal.integration.hubspot || {};

            this.merchant.hubspot.forEach((merchantHubspotIntegration) => {
                const hubspot = this.config.internal.integration.hubspot[merchantHubspotIntegration.id] = this.config.internal.integration.hubspot[merchantHubspotIntegration.id] || {};

                if (merchantHubspotIntegration.options.prod) {
                    return;
                }

                this.hubspots.push({
                    config: hubspot,
                    integration: merchantHubspotIntegration,
                });
            });
        }
    }

    save (): Promise<void> {
        console.log(this.config.internal.integration);
        return this.campaignService.saveConfig(this.campaign.id, this.config.id, this.config).then((res) => {
            return Promise.resolve();
        });
    }
}
