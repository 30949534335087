import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ICampaign } from '@amt/i-campaign';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { CampaignService } from '@s/campaign.service';

export interface IAddCampaignParticipantDialogData {
    route?: ActivatedRoute;
}

export interface IAddCampaignParticipantDialogReturnData {
    campaign: ICampaign;
    campaignParticipant: ICampaignParticipant;
}

@Component({
    selector: 'app-add-campaign-participant-dialog',
    templateUrl: './add-campaign-participant-dialog.component.html',
    styleUrls: ['./add-campaign-participant-dialog.component.scss']
})
export class AddCampaignParticipantDialogComponent implements OnInit {
    busy: boolean = false;
    email: string = '';

    constructor (
        public dialogRef: MatDialogRef<AddCampaignParticipantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddCampaignParticipantDialogData,
        public campaignService: CampaignService,
    ) {
    }

    addCampaignParticipant (): void {
        if (this.busy) {
            return;
        }

        const options = {
            email: this.email,
        };

        this.busy = true;

        this.campaignService.addParticipant(
            <string>(this.data.route.snapshot.params.campaignId),
            options
        ).then((result) => {
            console.log(`Added campaign participant`, result);
            this.dialogRef.close(result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }
}
