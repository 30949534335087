import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { UserService } from '@s/user.service';

import { IUserIndex } from '@arhat/i-user-index';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-user-edit-profile-view',
    templateUrl: './user-edit-profile-view.component.html',
    styleUrls: ['./user-edit-profile-view.component.scss']
})
export class UserEditProfileViewComponent implements OnInit {
    blocker: Blocker;
    data: IUserIndex;
    env: IEnv;
    saveSubject = new Subject();
    uploadProfilePhoto: (options: {
        fileToUpload: File;
    }) => Observable<unknown>;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public userService: UserService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.blocker.unblock();
        });

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch((err) => {
                console.error(err);
            });
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    fetch (): Promise<unknown> {
        const params: {
            campaign_id?: string;
            campaign_participant_url?: string;
            id?: string;
        } = {};
        if (this.route.snapshot.params.userId) {
            params.id = <string>(this.route.snapshot.params.userId);
        }
        else if (this.route.snapshot.params.campaignParticipantUrl) {
            params.campaign_participant_url = <string>(this.route.snapshot.params.campaignParticipantUrl);
        }
        if (this.route.snapshot.params.campaignId) {
            params.campaign_id = <string>(this.route.snapshot.params.campaignId);
        }
        else if (this.env.campaign?.id) {
            params.campaign_id = this.env.campaign.id;
        }
        return this.userService.getById(params).then((res) => {
            this.data = res;
            console.log("UserEditProfileViewComponent:", res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
        this.uploadProfilePhoto = (options: {
            fileToUpload: File;
        }): Observable<unknown> => {
            return this.userService.uploadProfilePhoto(this.data.user.id, options);
        };
    }

    async save (): Promise<void> {
        await this.userService.save(this.data.user.id, {
            campaignParticipant: this.data.campaignParticipant,
            user: this.data.user,
        });
    }

    async uploadedUserProfilePhoto (event: unknown): Promise<void> {
        await this.fetch();
    }
}
