import { Component, Input, OnInit } from '@angular/core';
import { IIncomingWebhook } from '@amt/i-incoming-webhook';

@Component({
    selector: '[app-incoming-webhook-table-row]',
    templateUrl: './incoming-webhook-table-row.component.html',
    styleUrls: ['./incoming-webhook-table-row.component.scss']
})
export class IncomingWebhookTableRowComponent implements OnInit {
    open: boolean = false;
    @Input() webhook: IIncomingWebhook;

    constructor () { }

    ngOnInit (): void {
    }

}
