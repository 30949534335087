<div mat-dialog-title>
    <span>Remove Participant</span>
</div>

<div mat-dialog-content>
    <p>Are you sure you want to remove {{data.campaignParticipant.user.name}} from the campaign "{{data.campaign.label}}"?</p>
    
    
</div>

<div mat-dialog-actions>
    <button type="button" mat-raised-button cdkFocusInitial (click)="closeDialog()">
        <span>Dismiss</span>
    </button>
    <button type="button" mat-raised-button color="warn" (click)="deleteParticipant()">
        <span>Delete</span>
    </button>
</div>
