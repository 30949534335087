import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { IDomain } from '@amt/i-domain';

import { ConfigService } from '@s/config.service';
import { EnvService } from '@s/env.service';

import { ICampaign } from '@amt/i-campaign';
import { IConfig } from '@amt/i-config';
import { IConfigCampaign } from '@amt/i-config-campaign';
import { IConfigType } from '@amt/i-config-type';
import { IOrganization } from '@amt/i-organization';
import { IConfigCountry } from '@amt/i-config-country';
import { IConfigCurrency } from '@amt/i-config-currency';
import { IConfigPaymentProcessorAccount } from '@amt/i-config-payment-processor-account';
import { ILayout } from '@amt/i-layout';
import { IPaymentProcessorAccount } from '@amt/i-payment-processor-account';

import {
    IAddOrganizationDialogData,
    IAddOrganizationDialogReturnData
} from '../add-organization-dialog/add-organization-dialog.component';
import { IEmailTemplate } from '@amt/i-email-template';
import { IAddEmailTemplateDialogData, IAddEmailTemplateDialogReturnData } from '../add-email-template-dialog/add-email-template-dialog.component';
import { ICurrency } from '@amt/i-currency';
import { IDomainConfig } from '@amt/i-domain-config';
import { IPage } from '@amt/i-page';
import { IConfigPage } from '@amt/i-config-page';
import { IEnv } from '@alt/i-env';
import { ConfigGetResult } from '@api-routes/config/types/config-get-result';
import { ConfigGetOptions } from '@api-routes/config/types/config-get-options';

@Component({
    selector: 'app-config-view',
    templateUrl: './config-view.component.html',
    styleUrls: ['./config-view.component.scss']
})
export class ConfigViewComponent implements OnInit {
    addCountryDefaultOrganizationData: IAddOrganizationDialogData = {
        availableCountries: null,
        availableOrganizations: null,
    };
    addDefaultOrganizationData: IAddOrganizationDialogData = {
        availableCountries: null,
        availableOrganizations: null,
    };
    addEmailTemplateData: IAddEmailTemplateDialogData = {
        availableEmailTemplates: null,
    };
    availableCampaigns: Array<ICampaign> = [];
    availableCountries: Array<{
        code: string;
        name: string;
    }> = [];
    availableCurrencies: Array<ICurrency> = [];
    availableDefaultOrganizations: Array<IOrganization>;
    availablePaymentProcessorAccounts: Array<IPaymentProcessorAccount> = [];
    blocker: Blocker;
    campaign: ICampaign;
    config: IConfig;
    configCampaign: IConfigCampaign = <IConfigCampaign>{};
    configCountry: IConfigCountry = <IConfigCountry>{};
    configCurrency: IConfigCurrency = <IConfigCurrency>{};
    countryDefaultOrganizations: Array<IOrganization>;
    configPaymentProcessorAccount: IConfigPaymentProcessorAccount = <IConfigPaymentProcessorAccount>{};
    configType: IConfigType = <IConfigType>{};
    defaultOrganization: IOrganization;
    domain: IDomain;
    domainConfig: IDomainConfig;
    emailTemplates: Array<IEmailTemplate>;
    env: IEnv;
    layouts: Array<ILayout> = [];
    organizations: Array<IOrganization>;
    page: IPage;
    saveSubject = new Subject();
    uploadBannerPhoto: (options: {
        fileToUpload: File;
    }) => Observable<unknown>;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public configService: ConfigService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});
        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.blocker.unblock();
        });
        this.availableCountries = this.env.countries;
        this.availableCurrencies = this.env.currencies;

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch((err) => {
                console.error(err);
            });
        });
    }

    addCountryDefaultOrganization (
        org: IOrganization
    ): Promise<IAddOrganizationDialogReturnData> {
        return this.configService.addCountryDefaultOrganization(
            this.config.id,
            org.country.code,
            org.id
        ).then(async (result) => {
            await this.fetch();
            console.log("addCountryDefaultOrganization", result);
            return result;
        });
    }

    addDefaultOrganization (
        data: IOrganization
    ): Promise<IAddOrganizationDialogReturnData> {
        return this.configService.addDefaultOrganization(this.config.id, data.id).then(async (result) => {
            await this.fetch();
            console.log("addDefaultOrganization", result);
            return result;
        });
    }

    addEmailTemplate (
        data: IEmailTemplate
    ): Promise<IAddEmailTemplateDialogReturnData> {
        return this.configService.addEmailTemplate(
            this.config.id,
            data.id
        ).then(async (result) => {
            await this.fetch();
            console.log("addEmailTemplate", result);
            return result;
        });
    }

    createNewPage (): Promise<{
        config: IConfig;
        configPage: IConfigPage;
        page: IPage;
    }> {
        return this.configService.createNewPage(this.config.id).then(async (result) => {
            await this.fetch();
            console.log("createNewPage", result);
            return result;
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    fetch (): Promise<ConfigGetResult> {
        const options: ConfigGetOptions = {};

        if (this.route.snapshot.params.configId) {
            options.id = <string>(this.route.snapshot.params.configId);
        }

        if (this.route.snapshot.params.domainId) {
            options.domainId = <string>(this.route.snapshot.params.domainId);
        }

        return this.configService.getById(
            options
        ).then((res) => {
            console.log("ConfigViewComponent:", res);

            this.addCountryDefaultOrganizationData.availableCountries = res.availableCountryDefaultCountries;
            this.addCountryDefaultOrganizationData.availableOrganizations = res.organizations;

            // this.addDefaultOrganizationData.availableCountries = res.availableCountryDefaultCountries;
            this.addDefaultOrganizationData.availableOrganizations = res.availableDefaultOrganizations;

            this.addEmailTemplateData.availableEmailTemplates = res.availableEmailTemplates || [];

            Object.assign(this, res);

            return Promise.resolve(res);
        });
    }

    ngOnInit (): void {
        this.uploadBannerPhoto = (options: {
            fileToUpload: File;
        }): Observable<unknown> => {
            return this.configService.uploadBannerPhoto({
                configId: this.config.id,
                fileToUpload: options.fileToUpload,
            });
        };
    }

    save (): Promise<unknown> {
        const data: {
            config: IConfig;
            domainConfig?: IDomainConfig;
        } = {
            config: this.config,
        };

        if (this.domainConfig?.config_id) {
            data.domainConfig = this.domainConfig;
        }

        return this.configService.save(this.config.id, data).then((res) => {
            return Promise.resolve();
        });
    }

    setCampaign (): void {
        this.configService.setCampaign(this.config.id, this.configCampaign.campaign_id).then(async (result) => {
            await this.fetch();
            console.log("setPaymentProcessorAccount", result);
            this.selectCampaign(this.configCampaign.campaign_id);
            return result;
        }).catch((err) => {
            console.error(err);
        });
    }

    setCountry (): void {
        this.configService.setCountry(this.config.id, this.configCountry.country_code).then(async (result) => {
            await this.fetch();
            console.log("setCountry", result);
            return result;
        }).catch((err) => {
            console.error(err);
        });
    }

    setCurrency (): void {
        this.configService.setCurrency(this.config.id, this.configCurrency.currency_code).then(async (result) => {
            await this.fetch();
            console.log("setCurrency", result);
            return result;
        }).catch((err) => {
            console.error(err);
        });
    }

    setPaymentProcessorAccount (): void {
        this.configService.setPaymentProcessorAccount(
            this.config.id,
            this.configPaymentProcessorAccount.payment_processor_account_id
        ).then(async (result) => {
            await this.fetch();
            console.log("setPaymentProcessorAccount", result);
            return result;
        }).catch((err) => {
            console.error(err);
        });
    }

    selectCampaign (campaignId: string): ICampaign {
        const campaign = this.availableCampaigns.filter((ac) => {
            return ac.id === campaignId;
        })[0];
        this.campaign = campaign;
        return campaign;
    }
}
