import { Component, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-header-holder',
    templateUrl: './header-holder.component.html',
    styleUrls: ['./header-holder.component.scss']
})
export class HeaderHolderComponent implements OnInit {
    env: IEnv;

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }

}
