import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { ICountry } from '@amt/i-country';
import { IOrganization } from '@amt/i-organization';
import { OrganizationService } from '@s/organization.service';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

export interface ICreateOptions {
    label: string;
}

export interface IAddOrganizationDialogData {
    availableCountries?: Array<{
        code: string;
        name: string;
    }>;
    availableOrganizations: Array<IOrganization>;
}

export interface IAddOrganizationDialogReturnData {
    country?: ICountry;
    organization: IOrganization;
}

@Component({
    selector: 'app-add-organization-dialog',
    templateUrl: './add-organization-dialog.component.html',
    styleUrls: ['./add-organization-dialog.component.scss']
})
export class AddOrganizationDialogComponent implements OnInit {
    availableCountries: Array<ICountry>;
    availableOrganizations: Array<IOrganization>;
    countryCode: string;
    env: IEnv;
    organization: ICreateOptions = {
        label: '',
    };

    constructor (
        public dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddOrganizationDialogData,
        public organizationService: OrganizationService,
        public envService: EnvService,
    ) {
        this.env = envService.data;
        Object.assign(this, data);
    }

    createNew (options: ICreateOptions): Promise<IOrganization> {
        return this.organizationService.create(options).then((result) => {
            const returnData: IAddOrganizationDialogReturnData = {
                organization: result.organization
            };
            this.dialogRef.close(returnData);
            return result.organization;
        });
    }

    ngOnInit (): void {
    }

    selectExisting (organization: IOrganization): void {
        const returnData: IAddOrganizationDialogReturnData = {
            organization,
        };
        if (this.countryCode) {
            returnData.country = this.availableCountries.filter((ac) => {
                return ac.code === this.countryCode;
            })[0];
        }
        this.dialogRef.close(returnData);
    }
}
