import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RoutesService } from 'bkng';

import { routes } from './routes/root';

export function initRoutes (routesService: RoutesService) {
    return (): Promise<unknown> => {
        return Promise.resolve().then(() => {
            return routesService.gobble(routes).then((result) => {

                // const rts = result.filter((item) => {
                //     return true;
                // }).map((item) => {
                //     return item.path;
                // });
                // console.log("Routes:", rts);

                return result;
            });
        });
    };
}

@NgModule({
    providers: [
        {
            deps: [
                RoutesService,
            ],
            provide: APP_INITIALIZER,
            useFactory: initRoutes,
            multi: true
        },
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot([], {
            // enableTracing: true,
            scrollPositionRestoration: 'enabled'
        }),
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }
