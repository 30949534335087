<div style="display: flex; justify-content: space-between;" *ngIf="data?.orders">
    <app-button
        (action)="page('prev')"
        [busy]="busy"
        [disabled]="busy || (offset === 0)"
    >Prev</app-button>

    <app-button
        (action)="page('next')"
        [busy]="busy"
        [disabled]="busy || (data.orders.length < limit)"
    >Next</app-button>
</div>
<div class="platform-view-component" *ngIf="data?.orders">
    <div>
        <span>{{data.merchant.label}}: Orders</span>
    </div>
    <div class="merchant-orders">
        <div class="merchant-order" *ngFor="let ord of data.orders">
            <app-merchant-order-list-item (modifiedPaymentMission)="fetch()" [campaigns]="data.campaigns" [order]="ord"></app-merchant-order-list-item>
        </div>
    </div>
</div>
