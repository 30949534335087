import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    RestService
} from 'bkng';

import { IConfig } from '@amt/i-config';
import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { EnvService } from './env.service';
import { GetCampaignParticipantMissionResult } from '@api-routes/campaign/types/get-campaign-participant-mission-result';
import { GetCampaignParticipantMissionOptions } from '@api-routes/campaign/types/get-campaign-participant-mission-options';
import { GetCampaignParticipantMissionQuery } from '@api-routes/campaign/types/get-campaign-participant-mission-query';
import { CampaignPostQuery } from '@api-routes/campaign/types/campaign-post-query';
import { CampaignPostBody } from '@api-routes/campaign/types/campaign-post-body';
import { CampaignPostResult } from '@api-routes/campaign/types/campaign-post-result';

@Injectable({
    providedIn: 'root'
})
export class CampaignService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService,
    ) {
        super(http, envService);
        this.namespace = `campaign`;
    }

    addParticipant (
        campaignId: string,
        options: {
            email?: string;
        }
    ): Promise<unknown> {
        return lastValueFrom(
            this.$post(
                `id/${campaignId}/add_participant`,
                options
            )
        );
    }

    create (
        options: unknown
    ): Promise<unknown> {
        return lastValueFrom(
            this.$post(
                `create`,
                options
            )
        );
    }

    delete (
        campaignId: string
    ): Promise<unknown> {
        return lastValueFrom(this.$delete(`id/${campaignId}`));
    }

    getById (
        options: {
            admin?: boolean;
            id: string;
            merchantId?: string;
            missionUrl?: string;
            participantUrl?: string;
            showParticipants?: 'all';
        }
    ): Promise<CampaignGetResult> {
        const params: {
            admin?: boolean;
            id?: string;
            merchant_id?: string;
            mission_url?: string;
            participant_url?: string;
            show_participants?: string;
        } = {};
        if (options.id) {
            params.id = options.id;
        }
        if (options.admin) {
            params.admin = options.admin;
        }
        if (options.merchantId) {
            params.merchant_id = options.merchantId;
        }
        if (options.missionUrl) {
            params.mission_url = options.missionUrl;
        }
        if (options.participantUrl) {
            params.participant_url = options.participantUrl;
        }
        if (options.showParticipants) {
            params.show_participants = options.showParticipants;
        }
        return lastValueFrom(
            this.$get(
                ``,
                {
                    params,
                }
            )
        ).then((response: unknown) => {
            return <CampaignGetResult>response;
        });
    }

    getParticipantMissionPageData (
        options: GetCampaignParticipantMissionOptions
    ): Promise<GetCampaignParticipantMissionResult> {
        const params: GetCampaignParticipantMissionQuery = {
            campaign_id: options.campaignId,
            mission_url: options.missionUrl,
        };

        return lastValueFrom(
            this.$get(
                `/participant/mission`,
                {
                    params: params
                }
            )
        ).then((result: unknown) => {
            return <GetCampaignParticipantMissionResult>result;
        });
    }

    async save (
        campaignId: string,
        body: CampaignPostBody,
    ): Promise<CampaignPostResult> {
        const queryParams: CampaignPostQuery = {
            campaign_id: campaignId,
        };

        const response = await lastValueFrom(
            this.$post(
                "",
                body,
                {
                    params: queryParams,
                }
            )
        );

        return <CampaignPostResult><unknown>response;
    }

    saveConfig (
        campaignId: string,
        configId: string,
        data: Partial<IConfig>,
    ): Promise<unknown> {
        return lastValueFrom(this.$post(`id/${campaignId}/config/id/${configId}/save`, data));
    }
}
