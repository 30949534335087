import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDomain } from '@amt/i-domain';

import { IEmailTemplate } from '@amt/i-email-template';
import { IMerchant } from '@amt/i-merchant';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';
import { IAddDomainDialogData } from '../add-domain-dialog/add-domain-dialog.component';

import { MerchantService } from '@s/merchant.service';
import { IEnv } from '@alt/i-env';
import { DomainPostResult } from '@api-routes/domain/types/domain-post-result';

@Component({
    selector: 'app-merchant-domains-view',
    templateUrl: './merchant-domains-view.component.html',
    styleUrls: ['./merchant-domains-view.component.scss']
})
export class MerchantDomainsViewComponent implements OnInit {
    blocker: Blocker;
    domains: Array<IDomain>;
    emailTemplates: Array<IEmailTemplate> = [];
    env: IEnv;
    merchant: IMerchant;
    data: IAddDomainDialogData;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public merchantService: MerchantService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    async createdDomain (data: DomainPostResult): Promise<void> {
        await this.fetch();
    }

    fetch (): Promise<void> {
        return this.merchantService.getById(
            <string>(this.route.snapshot.params.merchantId)
        ).then((res) => {
            console.log("MerchantDomainsViewComponent", res);
            Object.assign(this, res);
            this.data = res;
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
