import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EnvService } from '@s/env.service';

import { ICampaign } from '@amt/i-campaign';
import { CampaignService } from '@s/campaign.service';
import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { IEnv } from '@alt/i-env';
import { CampaignPostBody } from '@api-routes/campaign/types/campaign-post-body';

@Component({
    selector: 'app-campaign-edit',
    templateUrl: './campaign-edit.component.html',
    styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditComponent implements OnInit {
    @Input() campaign: ICampaign;
    @Input() data: CampaignGetResult;
    env: IEnv;
    saveSubject = new Subject();

    constructor (
        public envService: EnvService,
        public campaignService: CampaignService,
    ) {
        this.env = envService.data;

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch(console.error);
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    ngOnInit (): void {
    }

    save (): Promise<void> {
        const data: CampaignPostBody = {
            campaign: {
                label: this.campaign.label,
                data: this.campaign.data,
                status: this.campaign.status,
            },
        };

        return this.campaignService.save(
            this.campaign.id,
            data
        ).then((res) => {
            return Promise.resolve();
        });
    }
}
