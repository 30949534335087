import { BkRoutes } from 'bkng';

import { outletLoggedIn } from './outlet-logged-in';
import { outletNotLoggedIn } from './outlet-not-logged-in';
import { outletTeamDonationCampaign } from './outlet-team-donation-campaign';
import { routeStartingPoint } from './route-starting-point';

import { CrowdfundParticipantDonateComponent } from '@c/crowdfund-participant-donate/crowdfund-participant-donate.component';
// import { NeedsUserOutletComponent } from '@c/needs-user-outlet/needs-user-outlet.component';
import { UserEditProfileViewComponent } from '@c/user-edit-profile-view/user-edit-profile-view.component';

export const campaignParticipant: BkRoutes = [
    {
        path: "admin",
        label: "Campaign Participant Admin",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletLoggedIn({component: UserEditProfileViewComponent})
            ])
        ]),
        children: [],
    },
    {
        path: "donate",
        label: "Campaign Participant Donate",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: CrowdfundParticipantDonateComponent}),
                outletLoggedIn({component: CrowdfundParticipantDonateComponent}),
            ])
        ]),
        children: [],
    },
];
