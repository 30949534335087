import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { campaigns } from './campaigns/campaigns';
import { domains } from './domains/domains';
import { webhooks } from './webhooks/webhooks';

import { MerchantCampaignsViewComponent } from '@c/merchant-campaigns-view/merchant-campaigns-view.component';
import { MerchantViewComponent } from '@c/merchant-view/merchant-view.component';
import { MerchantDomainsViewComponent } from '@c/merchant-domains-view/merchant-domains-view.component';
import { MerchantOrdersViewComponent } from '@c/merchant-orders-view/merchant-orders-view.component';

export const id: BkRoutes = [
    {
        path: ":merchantId",
        label: "Merchant by ID",
        ngRoute: routeStartingPoint([
            outletPlatform({component: MerchantViewComponent}),
        ]),
        children: [
            {
                label: "Merchant Campaigns",
                path: "campaigns",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: MerchantCampaignsViewComponent}),
                ]),
                children: campaigns,
            },
            {
                label: "Merchant Domains",
                path: "domains",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: MerchantDomainsViewComponent}),
                ]),
                children: domains,
            },
            {
                label: "Merchant Webhooks",
                path: "webhooks",
                children: webhooks,
            },
            {
                label: "Orders",
                path: "orders",
                ngRoute: routeStartingPoint([
                    outletPlatform({component: MerchantOrdersViewComponent}),
                ]),
            },
        ],
    },
];
