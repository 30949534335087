import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IConfig } from '@amt/i-config';
import { IOrganization } from '@amt/i-organization';
import { IEmailTemplate } from '@amt/i-email-template';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { IAddConfigDialogData } from '../add-config-dialog/add-config-dialog.component';

import { OrganizationService } from '@s/organization.service';
import { IEnv } from '@alt/i-env';
import { DomainAvailableConfigsGetResult } from '@api-routes/domain/types/domain-available-configs-get-result';

@Component({
    selector: 'app-organization-configs-view',
    templateUrl: './organization-configs-view.component.html',
    styleUrls: ['./organization-configs-view.component.scss']
})
export class OrganizationConfigsViewComponent implements OnInit {
    addConfigData: IAddConfigDialogData = {
        configTypeName: 'organization',
        getAvailableConfigs: () => {
            return this.getAvailableConfigs();
        }
    };
    blocker: Blocker;
    configs: Array<IConfig> = [];
    organization: IOrganization;
    emailTemplates: Array<IEmailTemplate> = [];
    env: IEnv;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public organizationService: OrganizationService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    async addConfig (config: IConfig): Promise<IConfig> {
        return await this.organizationService.addConfig(this.organization.id, config.id).then(async (result) => {
            await this.fetch();
            return result.config;
        });
    }

    fetch (): Promise<void> {
        return this.organizationService.getById({
            organizationId: <string>(this.route.snapshot.params.organizationId),
        }).then((res) => {
            console.log(res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    async getAvailableConfigs (): Promise<DomainAvailableConfigsGetResult> {
        return await this.organizationService.getAvailableConfigs(
            <string>(this.route.snapshot.params.organizationId)
        ).then((result) => {
            return {availableConfigs: result};
        });
    }

    ngOnInit (): void {
    }
}
