import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import {
    MAT_CHECKBOX_DEFAULT_OPTIONS,
    MatCheckboxDefaultOptions
} from '@angular/material/checkbox';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
    ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
    @Input() checked: boolean = false;
    @Output() clicked = new EventEmitter<unknown>();
    clickedSinceLastWrite: boolean = false;
    @Input() color = 'accent';
    @Input() disabled: boolean = false;
    private onChange?: (val: unknown) => void;
    private onTouched?: () => void;

    constructor () { }

    click (event: unknown): void {
        // "click" event will not fire if disabled=true

        this.clickedSinceLastWrite = true;

        this.clicked.emit(event);
    }

    ngOnInit (): void {
    }

    registerOnChange (
        fn: (val?: unknown) => unknown
    ): void {
        this.onChange = fn;
    }

    registerOnTouched (
        fn: (val?: unknown) => unknown
    ): void {
        this.onTouched = fn;
    }

    writeValue (
        value: unknown
    ): void {
        const changed: boolean = (value !== this.checked);
        this.checked = <boolean>value;
        if (changed && this.onChange && this.clickedSinceLastWrite) { // ugh, is there a better way?
            this.onChange(value);
        }
    }
}
