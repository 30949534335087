import {
    Component,
    HostListener,
    Inject,
    Injectable,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/*import {
    NgForm
} from '@angular/forms';*/
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { EnvService } from '@s/env.service';

import { ResetPasswordDialogService } from '../reset-password-dialog/reset-password-dialog.component';
import { UserService } from '@s/user.service';

export interface SignInDialogData {
    credentials?: unknown;
    redirect?: string;
    showWelcomeBack?: boolean;
}

@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss']
})
export class SignInDialogComponent implements OnInit {
    busy: boolean = false;
    credentials: unknown = {};
    env: unknown;
    showWelcomeBack: boolean = false;
    success: boolean = false;

    // @ViewChild('signInForm', {static: false}) signInForm: NgForm;
    // signInForm: FormDirective = {} as FormDirective;

    constructor (
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SignInDialogData,
        public US: UserService,
        public RPDS: ResetPasswordDialogService,
        public envService: EnvService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        dialogRef.disableClose = true;
        if (data?.credentials) {
            Object.assign(this.credentials, data.credentials);
        }
        if (data?.showWelcomeBack) this.showWelcomeBack = data.showWelcomeBack;
    }

    ngOnInit (): void {
    }

    close (): void {
        this.dialogRef.close();
    }

    forgotPass (): void {
        this.dialogRef.close();
        this.RPDS.open({credentials: this.credentials});
    }

    @HostListener('window:keyup.esc') onKeyUp (): void {
        this.dialogRef.close();
    }

    onSuccess (event: unknown): void {
        this.success = true;
        setTimeout(() => {
            this.dialogRef.close(event);
            if (this.data.redirect) {
                this.router.navigate([this.data.redirect || './'], {
                    relativeTo: this.route,
                    queryParams: {},
                }).catch(console.error);
            }
        }, 2500);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignInDialogService {

    constructor (public dialog: MatDialog) {
    }

    open (options: SignInDialogData = {}): MatDialogRef<SignInDialogComponent> {
        const dialogRef = this.dialog.open(SignInDialogComponent, { // width: '550px',
            width: '364px',
            data: options,
        });

        /*dialogRef.afterClosed().subscribe(result => {
            // do something
        });*/

        return dialogRef;
    }
}
