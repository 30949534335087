<div class="platform-view-component" *ngIf="domain?.id">
    <div>
        <span>Domain: {{domain.full}}</span>
    </div>
    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;">
        <div style="padding-bottom: 15px;">Configs:</div>
        <div *ngIf="configs?.length">
            <div *ngFor="let config of configs">
                <a [routerLink]="['id', config.id] | linkFix">{{config.label}}</a>
            </div>
        </div>
        <div *ngIf="!configs?.length">No configs.</div>
        <div>
            <div style="padding-top: 25px;">
                <app-add-config [data]="addConfigData" (selected)="addConfig($event)"></app-add-config>
            </div>
        </div>
    </div>
</div>
