import { BkRouteInfo, BkRoutes } from 'bkng';

import { campaign } from './campaign/campaign';

import { GetCampaignResult } from '@api-routes/admin/types/get-campaign-result';
import { routeStartingPoint } from '@ngr/route-starting-point';
import { outletAdmin } from '@ngr/outlet-admin';
import { UsersViewComponent } from '@c/users-view/users-view.component';

export const admin: BkRoutes = [
    {
        path: "users",
        label: "Users",
        ngRoute: routeStartingPoint([
            outletAdmin({
                component: UsersViewComponent
            }),
        ]),
        setInfo: (data: GetCampaignResult): BkRouteInfo => {
            return {
                crumb: `Users`,
            };
        },
        children: [],
    },
    {
        path: "campaign",
        label: "Campaign",
        setInfo: (data: GetCampaignResult): BkRouteInfo => {
            return {
                crumb: `Campaign: ${data?.campaign?.id ? data.campaign.label : `[No label]`}`,
            };
        },
        children: campaign,
    },
];
