
<div class="donate showing">
    <div class="header-holder">
        <div class="header-bg">
        <div class="header"><span *ngIf="!env.merchant?.logo_file" style="font-weight: 400; font-size: 28px; padding: 32px 0px;">HareRaiser</span></div>
        </div>
    </div>
    <div class="checkout-holder">
        <div class="checkout-content">
            <div class="checkout-section active">
                <div>
                    <p>HareRaiser is a Donation & CrowdFunding SaaS platform, by <a href="https://shewk.co">Shewk.</a></p>
                    <p>Please direct all inquiries to info&#64;shewk.co.</p>
                </div>
                <div style="padding-top: 35px;" *ngIf="!env.user">
                    <div class="button" (bkClick)="openSignInDialog()">Sign in</div>
                </div>
                <div *ngIf="data && env.user?.id">
                    <div class="hi" style="padding-top: 35px;"><span>Hi, {{env.user.first_name || env.user.email[0].value}}</span>
                        <div *ngIf="env.user?.data?.googleProfile" class="wee-avatar" [ngStyle]="{'background-image': (env.user.data.googleProfile.picture | styleBgImageUrl)}"></div>
                    </div>
                    
                    <!-- <div style="padding-top: 35px;" *ngIf="env.user?.is_super">
                        <div class="button" (bkClick)="txfrFromOldDb()">Txfr</div>
                    </div> -->

                    <div style="padding-top: 35px;" *ngIf="data.merchants?.length">
                        <div><span><b>Merchants</b> ({{data.merchants.length}}):</span></div>
                        <div *ngFor="let m of data.merchants">
                            <app-merchant-list-item [merchant]="m"></app-merchant-list-item>
                        </div>
                    </div>

                    <div style="padding-top: 35px;" *ngIf="data.organizations?.length">
                        <div><span><b>Organizations</b> ({{data.organizations.length}}):</span></div>
                        <div *ngFor="let org of data.organizations">
                            <div style="padding: 10px 0px;">
                                <div style="border: 1px solid grey; padding: 10px;">
                                    <div class="name" style="font-size: 16px;">
                                        <a [routerLink]="['organization/id', org.id] | linkFix">{{org.label}}</a>
                                    </div>
                                    <div>
                                        <span>Domains: </span>
                                        <span *ngFor="let domain of org.domains, let last = last">
                                            <a [routerLink]="['domain/id', domain.id] | linkFix">{{domain.full}}</a>
                                            <span *ngIf="!last">, </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="padding-top: 35px;" *ngIf="data.paymentProcessorAccounts?.length">
                        <div><span><b>Payment Processor Accounts</b> ({{data.paymentProcessorAccounts.length}}):</span></div>
                        <div *ngFor="let ppa of data.paymentProcessorAccounts">
                            <div style="padding: 10px 0px;">
                                <div style="border: 1px solid grey; padding: 10px;">
                                    <div class="name" style="font-size: 16px;">{{ppa.data.settings.dashboard.display_name}}</div>
                                    <div class="info">Stripe ID: {{ppa.external_id}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="env.user.is_super && !env.slack" style="padding-top: 15px;">
                        <div style="padding-bottom: 5px;"><a class="button small" [href]="connectSlackUrl(null)" target="_self">Add SYSTEM Slack account</a></div>
                    </div>

                    <div style="padding-top: 15px; font-size: 12px; color: #a1a1a1;">{{env.user.email[0].value}}</div>
                    <div style="padding-top: 55px;"><a class="button" href="/api/v1/user/auth/logout" target="_self">Log out</a></div>
                </div>
            </div>
        </div>
    </div>
</div>
