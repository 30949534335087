<div class="add-something-dialog">
    <div *ngIf="data.availableDomains?.length">
        <div style="padding-bottom: 15px; font-size: 16px; font-weight: 500;">Select from existing:</div>
        <div *ngFor="let d of data.availableDomains" style="padding: 10px 0px;">
            <button mat-raised-button color="basic" (click)="addExistingDomain(d)">
                <span>{{d.full || d.id}}</span>
            </button>
        </div>
    </div>
    <div style="padding: 25px 0px;">
        <span>OR, create a new one:</span>
    </div>
    <form #createNewDomainForm="ngForm" (ngSubmit)="createDomain()">
        <div>
            <mat-form-field>
                <mat-label>Domain (Top)</mat-label>
                <input matInput type="text" [name]="'DomainTop'" placeholder="i.e. example.com" [(ngModel)]="newDomain.top" required />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Subdomain (Sub)</mat-label>
                <input matInput type="text" [name]="'DomainSub'" placeholder="i.e. donate" [(ngModel)]="newDomain.sub" />
            </mat-form-field>
        </div>
        <div *ngIf="createNewDomainForm.valid" style="padding-top: 10px; padding-bottom: 25px;">
            <span>New domain will be: </span>
            <b *ngIf="newDomain.sub">{{newDomain.sub}}.</b>
            <b>{{newDomain.top}}</b>
        </div>
        <div>
            <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="busy || !createNewDomainForm.valid"
                (click)="createNewDomainForm.ngSubmit.emit()"
            >Create</button>
        </div>
    </form>
</div>
