
<div class="checkout-section checkout-section-contribution" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title"><span class="num">{{number}}.</span><span>Payment</span></div>
        <div class="checkout-section-edit-button" *ngIf="isEditable()"><span (click)="edit()">Edit</span></div>
    </div>
    <div class="pill-choices-holder" *ngIf="active && !donation.savedPaymentMethod">
        <div class="pill-choices">
        <div class="pill-choice" *ngIf="checkout.canMakePaymentRequestResult" [ngClass]="{active: donation.paymentMethodName == 'payment_request'}" (click)="selectPaymentMethod('payment_request')"><span *ngIf="checkout.canMakePaymentRequestResult?.applePay">Apple Pay</span><span *ngIf="!checkout.canMakePaymentRequestResult?.applePay">GPay/Other</span></div>
        <div class="pill-choice" [ngClass]="{active: donation.paymentMethodName == 'card'}" (click)="selectPaymentMethod('card')">Credit card</div>
        </div>
    </div>
    <form class="payment-form" id="payment-form" name="paymentForm" bkForm #paymentForm="bkForm" [ngClass]="{'bk-hidden': !(active && donation.paymentMethodName === 'card')}" [appCheckoutSectionValidator]="sectionValidateCardPaymentForm(paymentForm)" [checkoutSection]="this" [validatorActive]="donation.paymentMethodName === 'card'">
        <div class="payment-inputs">
            <div class="input-holder" bkFormControlHolder>
                <!--
                    removed [(ngModel)]="stripeCard" from the following
                    actually just need "ngModel" to be defined as below.
                    Instead of setting "stripeCard" implicitly (via ngModel),
                    we now use setCard(...) since it's more explicit/obvious.
                -->
                <div
                    class="stripe-elements-card"
                    bkFormControl
                    name="cc"
                    bkStripeElementsCard
                    #stripeCardElement="stripeCard"
                    [ngClass]="{ready: stripeCardElement.ready}"
                    ngModel
                    (cardReady)="setCard($event)"
                    (cardChange)="setCard($event)"
                ></div>
                <bk-validation-display></bk-validation-display>
                <div class="payment-relax-message"><span>Orders are secure and encrypted.</span></div>
            </div>
        </div>
        <div class="billing-address-form">
            <div class="billing-address-form-title">Billing Information</div>
            <app-form-inputs-person-contact-info
                [personData]="donation.billingAddress"
                [addressData]="donation.billingAddress"
                [phoneData]="donation.billingAddress.phone"
                [showInputLabels]="false"
                [disableAllInputs]="validating"
                (onPressedEnter)="next()"
            ></app-form-inputs-person-contact-info>
        </div>
        <div class="checkout-section-error" *ngIf="paymentTokenCreationError" [innerHtml]="paymentTokenCreationError"></div>
        <div class="input-holder checkbox-input-holder" bkFormControl *ngIf="donation.interval == 'once'">
        <input type="checkbox" name="savePaymentCheckbox" [(ngModel)]="donation.savePaymentDetails">
        <div class="checkbox-input-label muted"><span>Save payment details for future donations</span></div>
        </div>
        <div class="button-holder">
            <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>
    <form class="payment-request-form" id="payment-request-form" name="paymentRequestForm" bkForm #paymentRequestForm="bkForm" [ngClass]="{'bk-hidden': !(active && donation.paymentMethodName === 'payment_request')}" [appCheckoutSectionValidator]="sectionValidateForm(paymentRequestForm)" [checkoutSection]="this" [validatorActive]="donation.paymentMethodName === 'payment_request'"> 
        <div class="browser-payment-method-selected">
        <div class="logo-holder">
            <div class="logo" [ngClass]="{'apple-pay': checkout.canMakePaymentRequestResult?.applePay, 'g-pay': !checkout.canMakePaymentRequestResult?.applePay}"></div>
        </div>
        <div class="info"><span *ngIf="checkout.canMakePaymentRequestResult?.applePay">ApplePay is selected for use at checkout.</span><span *ngIf="!checkout.canMakePaymentRequestResult?.applePay">GPay or a similar browser-stored payment method is selected for use at checkout.</span></div>
        </div>
        <div class="input-holder checkbox-input-holder" bkFormControl style="margin-top: 15px;" *ngIf="donation.interval == 'once'">
        <input type="checkbox" name="savePaymentCheckbox" [(ngModel)]="donation.savePaymentDetails">
        <div class="checkbox-input-label muted"><span>Save payment details for future donations</span></div>
        </div>
        <div class="button-holder">
        <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>
    <form class="saved-payment-form" id="saved-payment-form" name="savedPaymentForm" bkForm #savedPaymentForm="bkForm" [ngClass]="{'bk-hidden': !(active && donation.paymentMethodName === 'saved_payment')}">
        <div class="saved-payment-details" *ngIf="donation.savedPaymentMethod">
        <div class="payment-details-box" *ngIf="donation.savedPaymentMethod.info?.owner.name">
            <div class="title">{{donation.savedPaymentMethod.info.owner.name}}</div>
            <div class="more-details">
            <div class="address-1">{{donation.savedPaymentMethod.info.owner.address.line1}}</div>
            <div class="address-2" *ngIf="donation.savedPaymentMethod.info.owner.address.line2">{{donation.savedPaymentMethod.info.owner.address.line2}}</div>
            <div class="city-state"><span class="city">{{donation.savedPaymentMethod.info.owner.address.city}}, </span><span class="state">{{donation.savedPaymentMethod.info.owner.address.state}}</span></div>
            <div class="postal-code">{{donation.savedPaymentMethod.info.owner.address.postal_code}}</div>
            <div class="country">{{donation.savedPaymentMethod.info.owner.address.country_code | countryCodeToName:env.countries}}</div>
            </div>
        </div>
        <div class="payment-details-box">
            <div class="title">XXXX-{{donation.savedPaymentMethod.info.last4}}</div>
            <div class="more-details">
            <div class="expiry">Exp. {{donation.savedPaymentMethod.info.exp_month}}/{{donation.savedPaymentMethod.info.exp_year}}</div>
            <div class="brand">{{donation.savedPaymentMethod.info.brand.substring(0, 1).toUpperCase()}}{{donation.savedPaymentMethod.info.brand.substring(1, donation.savedPaymentMethod.info.brand.length)}}</div>
            </div>
        </div>
        </div>
        <div class="button-holder">
        <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>
    <div class="checkout-section-detail-view checkout-section-detail-view-payment" *ngIf="!active && (activated || completed) && (donation.paymentMethodName == 'card') && paymentForm.ngForm.valid">
        <div class="payment-details-box">
        <div class="title">{{donation.billingAddress.firstName}} {{donation.billingAddress.lastName}}</div>
        <div class="more-details">
            <div class="phone" *ngIf="donation.billingAddress.phone.value">{{donation.billingAddress.phone.value}}</div>
            <div class="address-1">{{donation.billingAddress.address1}}</div>
            <div class="address-2" *ngIf="donation.billingAddress.address2">{{donation.billingAddress.address2}}</div>
            <div class="city-state"><span class="city">{{donation.billingAddress.city}}, </span><span class="state">{{donation.billingAddress.state}}</span></div>
            <div class="postal-code">{{donation.billingAddress.postal_code}}</div>
            <div class="country">{{donation.billingAddress.country_code | countryCodeToName:env.countries}}</div>
        </div>
        </div>
        <div class="payment-details-box" *ngIf="donation.paymentSourceToken?.card">
        <div class="title">XXXX-{{donation.paymentSourceToken.card.last4}}</div>
        <div class="more-details">
            <div class="expiry">Exp. {{donation.paymentSourceToken?.card.exp_month}}/{{donation.paymentSourceToken?.card.exp_year}}</div>
        </div>
        </div>
    </div>
    <div class="checkout-section-detail-view" *ngIf="!active && completed && donation.paymentMethodName == 'payment_request'">
        <div class="checkout-section-detail-payment-request">
        <div class="logo" [ngClass]="{'apple-pay': checkout.canMakePaymentRequestResult?.applePay, 'g-pay': !checkout.canMakePaymentRequestResult?.applePay}"></div><span *ngIf="!checkout.canMakePaymentRequestResult?.applePay">(or similar)</span>
        </div>
    </div>
    <div class="checkout-section-detail-view muted" *ngIf="!active && (activated || completed) && (donation.interval === 'once' && donation.savePaymentDetails)"><span>Payment details will be saved.</span></div>
    <div class="checkout-section-detail-view" *ngIf="!active && (activated || completed) && (donation.paymentMethodName === 'saved_payment')">
        <div class="saved-payment-details" *ngIf="donation.savedPaymentMethod">
        <div class="payment-details-box" *ngIf="donation.savedPaymentMethod.info?.owner.name">
            <div class="title">{{donation.savedPaymentMethod.info.owner.name}}</div>
            <div class="more-details">
            <div class="address-1">{{donation.savedPaymentMethod.info.owner.address.line1}}</div>
            <div class="address-2" *ngIf="donation.savedPaymentMethod.info.owner.address.line2">{{donation.savedPaymentMethod.info.owner.address.line2}}</div>
            <div class="city-state"><span class="city">{{donation.savedPaymentMethod.info.owner.address.city}}, </span><span class="state">{{donation.savedPaymentMethod.info.owner.address.state}}</span></div>
            <div class="postal-code">{{donation.savedPaymentMethod.info.owner.address.postal_code}}</div>
            <div class="country">{{donation.savedPaymentMethod.info.owner.address.country_code | countryCodeToName:env.countries}}</div>
            </div>
        </div>
        <div class="payment-details-box">
            <div class="title">XXXX-{{donation.savedPaymentMethod.info.last4}}</div>
            <div class="more-details">
            <div class="expiry">Exp. {{donation.savedPaymentMethod.info.exp_month}}/{{donation.savedPaymentMethod.info.exp_year}}</div>
            <div class="brand">{{donation.savedPaymentMethod.info.brand.substring(0, 1).toUpperCase()}}{{donation.savedPaymentMethod.info.brand.substring(1, donation.savedPaymentMethod.info.brand.length)}}</div>
            </div>
        </div>
        </div>
    </div>
</div>
