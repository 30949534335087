import {
    Directive,
    EventEmitter,
    Output
} from '@angular/core';
import {
    AbstractControl,
} from '@angular/forms';

import { FormDirective } from './form.directive';
import { FormControlDirective } from './form-control.directive';

@Directive({
    selector: '[bkFormControlHolder]',
    exportAs: 'bkFormControlHolder'
})
export class FormControlHolderDirective {
    bkFormControl: FormControlDirective;
    control: AbstractControl;
    @Output() registeredFormControl = new EventEmitter<unknown>();

    constructor (
        public bkForm: FormDirective
    ) {
    }

    registerFormControl (bkFormControl: FormControlDirective): void {
        this.bkFormControl = bkFormControl;
        this.registeredFormControl.emit(bkFormControl);
    }
}
