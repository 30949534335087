import { Pipe, PipeTransform } from '@angular/core';
import { IConfig } from '@amt/i-config';

@Pipe({
    name: 'domainConfigBannerPhoto',
    pure: false,
})
export class DomainConfigBannerPhotoPipe implements PipeTransform {

    transform (config: IConfig): string {
        if (!config.banner_photo) {
            return ""; // '/assets/placeholder.jpeg';
        }
        return `/files/type/banner_photo/id/${config.banner_photo.id}${config.banner_photo.extname}`;
    }

}
