import {
    Component,
    HostListener,
    Inject,
    Injectable,
    OnInit
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

import {
    FormDirective,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { SignInDialogService } from '../sign-in-dialog/sign-in-dialog.component';
import { IEnv } from '@alt/i-env';

export interface ResetPassDialogData {
    credentials: unknown;
}

@Component({
    selector: 'app-reset-password-dialog',
    templateUrl: './reset-password-dialog.component.html',
    styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {
    busy: boolean = false;
    credentials: {
        email: string;
        recaptchaToken?: string;
        user_id: string;
    } = {
        email: "",
        user_id: null,
    };
    env: IEnv;
    result: unknown;

    constructor (
        public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ResetPassDialogData,
        public US: UserService,
        public SIDS: SignInDialogService,
        public RS: RecaptchaService,
    ) {
        dialogRef.disableClose = true;
        if (data && data.credentials) {
            Object.assign(this.credentials, data.credentials);
        }
    }

    ngOnInit (): void {
    }

    close (): void {
        this.dialogRef.close();
    }

    @HostListener('window:keyup.esc') onKeyUp (): void {
        this.dialogRef.close();
    }

    resetPass (frm: FormDirective): void {
        this.busy = true;
        this.RS.execute(
            this.env.recaptcha.sitekey,
            {action: 'send_password_reset_link'}
        ).then((recaptchaToken) => {
            this.credentials.recaptchaToken = recaptchaToken;
            return this.US.sendPasswordResetLink(
                this.credentials
            ).then((res) => {
                this.result = res;
                frm.onSuccess();
            }).catch((err) => {
                console.error(err);
                frm.onError(<unknown>err);
            });
        }).finally(() => {
            this.busy = false;
        }).catch(console.error);
    }

    signIn (): void {
        this.dialogRef.close();
        this.SIDS.open(this.data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordDialogService {

    constructor (public dialog: MatDialog) {
    }

    open (data: unknown = {}): MatDialogRef<ResetPasswordDialogComponent> {
        const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
            width: '364px',
            data
        });

        /*dialogRef.afterClosed().subscribe(result => {
            // do something
        });*/

        return dialogRef;
    }
}
