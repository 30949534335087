import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EnvService } from '@s/env.service';

import { IEnv } from '@alt/i-env';
import { IMerchant } from '@amt/i-merchant';

import { MerchantService } from '@s/merchant.service';
import { IMerchantHubspotIndex } from '@arhat/i-merchant-hubspot-index';
import { IHubspotProperty } from '@bk/lib/hubspot/types/i-hubspot-property';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';

@Component({
    selector: 'app-hubspot-contacts',
    templateUrl: './hubspot-contacts.component.html',
    styleUrls: ['./hubspot-contacts.component.scss']
})
export class HubspotContactsComponent implements OnInit {
    busy: boolean = false;
    @Input() canCopyProdSettings: boolean = false;
    @Input() integration: IHubspotIntegration;
    merchantHubspotIndex: IMerchantHubspotIndex;
    env: IEnv;
    @Input() merchant: IMerchant;
    propertiesContacts: Array<IHubspotProperty>;
    propertiesContactsFiltered: Array<IHubspotProperty>;
    @Output() updated = new EventEmitter<unknown>();

    constructor (
        public envService: EnvService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;
    }

    copyProdSettingsToDev (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.copyHubspotProdSettingsToDev(this.merchant.id, this.integration.id).then((result) => {
            console.log("Kicked-off copy of prod settings to dev:", result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    getHubspotIndex (): void {
        if (this.busy) {
            return;
        }

        this.busy = true;
        this.merchantsService.getHubspotIndex(this.merchant.id, this.integration.id).then((result) => {
            console.log("Merchant's Hubspot:", result);
            this.merchantHubspotIndex = result;

            this.propertiesContacts = result?.properties?.contacts?.results || [];

            this.propertiesContactsFiltered = this.propertiesContacts.filter((item) => {
                return !item.hubspotDefined;
            });

            console.log("Hubspot Contacts Properties:", this.propertiesContactsFiltered);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.getHubspotIndex();
    }

    syncContactsWithHubspot (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.syncContactsWithHubspot(this.merchant.id, this.integration.id).then((result) => {
            console.log("Kicked-off sync:", result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
