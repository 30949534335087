import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { IDomain } from '@amt/i-domain';
import { DomainService } from '@s/domain.service';
import { DomainPostOptions } from '@api-routes/domain/types/domain-post-options';

export interface IAddDomainDialogData {
    availableDomains?: Array<IDomain>;
    route?: ActivatedRoute;
}

@Component({
    selector: 'app-add-domain-dialog',
    templateUrl: './add-domain-dialog.component.html',
    styleUrls: ['./add-domain-dialog.component.scss']
})
export class AddDomainDialogComponent implements OnInit {
    busy: boolean = false;
    newDomain: IDomain = <IDomain>{};

    constructor (
        public dialogRef: MatDialogRef<AddDomainDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddDomainDialogData,
        public domainService: DomainService,
    ) {
    }

    addExistingDomain (
        domain: IDomain
    ): void {
        if (this.busy) {
            return;
        }

        this.busy = true;

        const options: DomainPostOptions = {
            body: {
                domain: {
                    id: domain.id,
                    // top: domain.top,
                    // sub: domain.sub,
                },
            },
            organizationId: <string>(this.data.route.snapshot.params.organizationId),
            merchantId: <string>(this.data.route.snapshot.params.merchantId),
        };

        this.domainService.create(
            options
        ).then((result) => {
            console.log(`Added existing domain`, result);
            this.dialogRef.close(result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    createDomain (): void {
        if (this.busy) {
            return;
        }

        this.busy = true;

        this.domainService.create({
            body: {
                domain: {
                    top: this.newDomain.top,
                    sub: this.newDomain.sub,
                },
            },
            organizationId: <string>(this.data.route.snapshot.params.organizationId),
            merchantId: <string>(this.data.route.snapshot.params.merchantId),
        }).then((result) => {
            console.log(`Created domain`, result);
            this.dialogRef.close(result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }
}
