import {
    Component,
    Optional,
} from '@angular/core';

import { FormControlHolderDirective } from '../form-control-holder.directive';
import {
    ValidationDisplayComponent
} from '../validation-display/validation-display.component';

@Component({
    selector: 'bk-validation-display-hints',
    templateUrl: './validation-display-hints.component.html',
    styleUrls: ['./validation-display-hints.component.scss']
})
export class ValidationDisplayHintsComponent extends ValidationDisplayComponent {

    constructor (
        @Optional() public bkFormControlHolder: FormControlHolderDirective
    ) {
        super(bkFormControlHolder);
    }

    makeItemsArray (): void {
        this.itemsArray =  this.ctrl.itemsArray.filter((item: {error?: {error?: unknown; hint?: unknown;}}) => {
            const err = item.error || {};
            return err.hint ? true : false;
        });
    }
}
