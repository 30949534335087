/*
If you're getting an "NG0203" error ("NG0203 inject() must be called from an injection context")
You may need to add the angular modules to the project's tsconfig's "paths" object

"@angular/*": [
    "./node_modules/@angular/*"
],
*/

import { Injectable } from '@angular/core';
import { Router, Routes, Route } from '@angular/router';
import { Subject } from 'rxjs';

import { updateObject } from '../lib/utils';
import { BkRoute } from './types/bk-route';
import { SetBkRouteDataOptions } from './types/set-bk-route-data-options';
import { SetBkRouteInfoOptions } from './types/set-bk-route-info-options';
import { SetBkRouteOnData } from './types/set-bk-route-on-data';

export { SetBkRouteDataOptions } from './types/set-bk-route-data-options';
export { BkRoute } from './types/bk-route';
export { BkRouteInfo } from './types/bk-route-info';
export type BkRoutes = Array<BkRoute>;

@Injectable({
    providedIn: 'root'
})
export class RoutesService {
    ngRoutesToLoadByPath: {
        [path: string]: Route;
    } = {};
    ngRoutesToLoad: Routes = [];
    onData: Subject<SetBkRouteOnData> = new Subject();

    constructor (
        public router: Router,
    ) {
    }

    comparePaths (path: string): number {
        const parts = path.split("/");

        let score = 0;

        parts.forEach((part, partIndex) => {
            if (part === '**') {
                // wildcard, super broad
                score = score - 1;
                return;
            }

            if (part.length > 0) {
                score = score + 1;
            }
        });

        return score;
    }

    getPropagators (
        existingPropagators: BkRoute[],
        bkRoutes: BkRoute[],
        relativeTo: BkRoute,
    ): BkRoute[] {
        const propagators = (<BkRoute[]>[]).concat(existingPropagators);
        (bkRoutes || []).forEach((rte) => {
            if (
                rte.propagate
                &&
                !rte.propagated
                &&
                !propagators.includes(rte)
                &&
                rte !== relativeTo
            ) {
                propagators.push(rte);
            }
        });
        return propagators;
    }

    getRelativeByLocateTags (
        relative: BkRoute,
        tags: {value: string;}[],
    ): BkRoute[] {
        let result: BkRoute[] = [];

        const hasTags = (relative.tags || []).filter((tag) => {
            const inSpec = tags.filter((st) => {
                return tag === st.value;
            });
            return inSpec.length ? true : false;
        });

        if (hasTags.length) {
            result.push(relative);
        }

        if (relative.parent) {
            result = result.concat(
                this.getRelativeByLocateTags(relative.parent, tags)
            );
        }

        return result;
    }

    getSortedPaths (unsortedPaths: Array<string>): Array<string> {
        const sortedPaths = unsortedPaths.sort((a, b) => {
            const partsA = a.split("/");
            const partsB = b.split("/");

            if (partsA.length > partsB.length) {
                return -1;
            }

            if (partsA.length < partsB.length) {
                return 1;
            }

            let returnComparisonValue = 0;

            for (let i = 0; i < partsA.length; i++) {
                const partA = partsA[i];
                const partB = partsB[i];

                let partComparisonValue = partA.localeCompare(partB);

                if ([partA, partB].includes("**")) {
                    partComparisonValue = partA === "**" ? -1 : 1;
                }

                if (partComparisonValue > 0) {
                    returnComparisonValue = -1;
                    break;
                }

                if (partComparisonValue < 0) {
                    returnComparisonValue = 1;
                    break;
                }
            }

            return returnComparisonValue;
        });

        return sortedPaths;
    }

    gobble (
        routes: BkRoutes
    ): Promise<Routes> {
        routes.forEach((rte) => {
            this.munchBkRoute({
                bkRoute: rte,
                parent: null,
                propagate: this.getPropagators(
                    [],
                    routes,
                    rte,
                )
            }, 0);
        });

        const routePaths = this.getSortedPaths(Object.keys(this.ngRoutesToLoadByPath));
        routePaths.forEach((p) => {
            this.ngRoutesToLoad.push(this.ngRoutesToLoadByPath[p]);
        });

        // console.log("ngRoutesToLoad", this.ngRoutesToLoad);

        this.router.resetConfig(this.ngRoutesToLoad);

        return Promise.resolve(this.ngRoutesToLoad);
    }

    loadNgRoute (ngRoute: Route): void {
        if (this.ngRoutesToLoadByPath[ngRoute.path]) {
            this.mergeNgRoutes(this.ngRoutesToLoadByPath[ngRoute.path], ngRoute);
        }
        else {
            this.ngRoutesToLoadByPath[ngRoute.path] = ngRoute;
        }
    }

    mergeNgRoutes (targetNgRoute: Route, sourceNgRoute: Route): void {
        if (sourceNgRoute.children?.length) {
            targetNgRoute.children = targetNgRoute.children || [];
            sourceNgRoute.children.forEach((sourceChild) => {
                const targetChild = targetNgRoute.children.filter((child) => {
                    return (
                        child.path === sourceChild.path
                        &&
                        child.outlet === sourceChild.outlet
                    );
                })[0];
                if (targetChild) {
                    this.mergeNgRoutes(targetChild, sourceChild);
                }
                else {
                    targetNgRoute.children.push(updateObject({}, sourceChild));
                }
            });
        }
    }

    munchBkRoute (
        options: {
            bkRoute: BkRoute;
            parent: BkRoute;
            propagate: BkRoute[];
        },
        depth: number,
    ): BkRoute {
        const bkRoute = options.bkRoute;
        const parent = options.parent;

        if (parent) {
            bkRoute.parent = parent;
        }

        bkRoute.children = bkRoute.children || [];
        bkRoute.depth = depth;

        if (
            bkRoute.ngRoute
            &&
            !bkRoute.propagate
            &&
            !bkRoute.propagated
            &&
            !bkRoute.propagatedFrom
        ) {
            options.propagate.forEach((propagateRoute) => {
                if (
                    bkRoute !== propagateRoute
                ) {
                    const propCopy = updateObject(
                        {},
                        propagateRoute
                    );

                    propCopy.propagated = true;
                    propCopy.propagatedFrom = propagateRoute.propagatedFrom || propagateRoute;

                    bkRoute.children.push(propCopy);
                }
            });
        }

        bkRoute.path = bkRoute.path || "";
        bkRoute.pathFromRoot = `${bkRoute.path || ''}`;
        if (parent?.pathFromRoot) {
            bkRoute.pathFromRoot = `${parent.pathFromRoot}${bkRoute.pathFromRoot.length ? '/' : ''}${bkRoute.pathFromRoot}`;
        }

        if (bkRoute.ngRoute) {
            const ngRoute: Route = updateObject({}, bkRoute.ngRoute);
            this.munchNgRoute(ngRoute);
            ngRoute.path = bkRoute.ngRoute.path = bkRoute.pathFromRoot;
            this.loadNgRoute(ngRoute);
            ngRoute.data = bkRoute.ngRoute.data || {};
            ngRoute.data.bkRoute = bkRoute;
        }

        if (bkRoute.children?.length) {
            bkRoute.children.forEach((child) => {
                let propagators: BkRoute[] = [];

                if (
                    bkRoute.propagate
                    ||
                    bkRoute.propagated
                    ||
                    bkRoute.propagatedFrom
                ) {
                    // do nothing;
                }
                else {
                    propagators = this.getPropagators(
                        options.propagate,
                        bkRoute.children,
                        child,
                    );
                }

                this.munchBkRoute({
                    bkRoute: child,
                    parent: bkRoute,
                    propagate: propagators
                }, depth + 1);
            });
        }

        return bkRoute;
    }

    munchNgRoute (ngRoute: Route): Route {
        if (ngRoute.children) {
            ngRoute.children.forEach((child) => {
                this.munchNgRoute(child);
            });
        }
        ngRoute.path = ngRoute.path || "";
        return ngRoute;
    }

    setBkRouteData (
        options: SetBkRouteDataOptions
    ): void {
        if (!options.activatedRoute) {
            throw new Error(`You must pass ng activated route to setBkRouteData`);
        }

        const bkRoute: BkRoute = <BkRoute>(options.activatedRoute.snapshot.data.bkRoute);

        this.setBkRouteInfo({
            activatedRoute: options.activatedRoute,
            bkRoute: bkRoute,
            data: options.data,
        });

        this.onData.next({
            bkRoute: bkRoute,
            data: options.data,
        });
    }

    setBkRouteInfo (
        options: SetBkRouteInfoOptions
    ): void {
        if (!options.activatedRoute) {
            throw new Error(`You must pass ng activated route to setBkRouteInfo`);
        }

        const bkRoute = options.bkRoute;

        if (bkRoute.setInfo) {
            bkRoute.info = bkRoute.setInfo(options.data);
        }

        if (
            bkRoute.parent
        ) {
            const nextSetInfoOptions = Object.assign({}, options);
            nextSetInfoOptions.bkRoute = bkRoute.parent;
            this.setBkRouteInfo(nextSetInfoOptions);
        }
    }

    resolvePath (
        bkRoute: BkRoute
    ): string {
        if (
            bkRoute === undefined
        ) {
            throw new Error("Can't resolve path from undefined object.");
        }

        const currentUrlPieces = (window.location.pathname).split("/").filter((p) => {
            return p?.length;
        });

        const bkRouteUrlPieces = bkRoute.pathFromRoot.split("/").filter((p) => {
            return p?.length;
        });

        if (
            currentUrlPieces.length < bkRouteUrlPieces.length
        ) {
            return undefined;
        }

        const resolvedPieces = currentUrlPieces.slice(0, bkRouteUrlPieces.length).join("/");

        const resolvedPath = `/${resolvedPieces}`;

        return resolvedPath;
    }
}
