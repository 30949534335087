import { IPhoneNumber } from "@amt/i-phone-number";

export function primaryPhoneNumber (
    phoneNumbers: Array<IPhoneNumber> = [],
): IPhoneNumber {
    if (
        !Array.isArray(phoneNumbers)
    ) {
        console.error("This isn't an array!", phoneNumbers);
        throw new Error(`primaryPhoneNumber requires an array of phone numbers.`);
    }

    const primaryPhoneNumber = phoneNumbers.filter((pn) => {
        return pn.primary;
    })[0];

    if (
        primaryPhoneNumber
    ) {
        return primaryPhoneNumber;
    }

    const firstConfirmedPhoneNumber = phoneNumbers.filter((pn) => {
        return pn.confirmed;
    })[0];

    if (
        firstConfirmedPhoneNumber
    ) {
        return firstConfirmedPhoneNumber;
    }

    return phoneNumbers[0];
}
