import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { IMailchimpIntegration } from '@amt/i-mailchimp-integration';

@Component({
    selector: 'app-integration-setting-mailchimp',
    templateUrl: './integration-setting-mailchimp.component.html',
    styleUrls: ['./integration-setting-mailchimp.component.scss']
})
export class IntegrationSettingMailchimpComponent implements OnInit {
    @Input() busy: boolean = false;
    @Input() integration: Partial<IMailchimpIntegration>;
    @Output() updated = new EventEmitter<Partial<IMailchimpIntegration>>();

    constructor () { }

    ngOnInit (): void {
        this.integration.app = this.integration.app || `mailchimp`;
        this.integration.data = this.integration.data || {};
        this.integration.options = this.integration.options || {};
    }
}
