import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    FormDirective,
} from 'bkng';

import { ICampaignParticipantMission } from '@amt/i-campaign-participant-mission';
import { IUser } from '@amt/i-user';
import { UserService } from '@s/user.service';
import { NgForm } from '@angular/forms';
import { IEnv } from '@alt/i-env';
import { AccountGetResult } from '@api-routes/account/types//account-get-result';
import { IMission } from '@amt/i-mission';
import { ICampaign } from '@amt/i-campaign';
import { EnvService } from '@s/env.service';
import { ICountry } from '@amt/i-country';
// import { ICountry } from '@amt/i-country';

@Component({
    selector: 'app-campaign-participant-profile-tab-mission',
    templateUrl: './campaign-participant-profile-tab-mission.component.html',
    styleUrls: ['./campaign-participant-profile-tab-mission.component.scss']
})
export class CampaignParticipantProfileTabMissionComponent implements OnInit {
    busy: boolean = false;
    @Input() campaign: ICampaign;
    @Input() campaignParticipantMission: ICampaignParticipantMission;
    @Input() mission: IMission;
    // country: ICountry;
    @Output() createdMission = new EventEmitter<unknown>();
    env: IEnv;
    @Input() participant: IUser;
    paymentAmounts: {
        absolute: number;
        byCurrency: {
            currencyCode: string;
            amount: number;
        }[];
    } = {
        absolute: 0,
        byCurrency: [],
    };
    saved: boolean = false;
    saving: boolean = false;
    tab: string = 'profile';

    constructor (
        public envService: EnvService,
        public userService: UserService,
    ) {
        this.env = envService.data;
    }

    campaignParticipantMissionUrl (): string {
        if (this.campaignParticipantMission?.url) {
            return `https://${this.env.domain?.full}/${this.campaignParticipantMission.url}`;
        }
        return '';
    }

    createCampaignParticipantMission (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.userService.missionCreate({
            campaignId: this.env.campaign.id,
            userId: this.participant.id,
        }).then((res) => {
            console.log(`Created campaign participant mission`, res);
            this.campaignParticipantMission = res.campaignParticipantMission;
            this.createdMission.emit(res);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    getCountryObject (cc: string): ICountry {
        if (!(cc && this.env?.countries)) return;
        return this.env.countries.filter((c) => { return c.code.toUpperCase() === cc.toUpperCase(); })[0];
    }

    ngOnInit (): void {
        if (this.campaignParticipantMission.payments?.length) {
            this.campaignParticipantMission.payments.forEach((pay) => {
                const amount = parseFloat(`${pay.amount}`);
                this.paymentAmounts.absolute = this.paymentAmounts.absolute + amount;
                let paymentAmountByCurrency = this.paymentAmounts.byCurrency.filter((item) => {
                    return item.currencyCode === pay.currency_code;
                })[0];
                if (paymentAmountByCurrency) {
                    paymentAmountByCurrency.amount = paymentAmountByCurrency.amount + amount;
                }
                else {
                    paymentAmountByCurrency = {
                        currencyCode: pay.currency_code,
                        amount: amount,
                    };
                    this.paymentAmounts.byCurrency.push(paymentAmountByCurrency);
                }
            });

        }
    }

    async $save (): Promise<void> {
        if (this.saving) {
            return await Promise.resolve();
        }

        this.saved = false;
        this.saving = true;
        const data: AccountGetResult = {
            campaignParticipantMission: this.campaignParticipantMission, // only save the mission
        };

        try {
            const response = await this.userService.save(
                this.participant.id, // participant is user, for some reason?
                {
                    // campaignParticipant: data.campaignParticipant,
                    campaignParticipantMission: data.campaignParticipantMission,
                    // person: data.person,
                    user: this.participant,
                }
            );

            console.log("Saved", response);

            this.saved = true;
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setTimeout(() => {
                this.saving = false;
            }, 350);
        }
    }

    async save (form: FormDirective): Promise<void> {
        if (
            !form.ngForm.valid
            ||
            !form.ngForm.dirty
            ||
            this.saving
        ) {
            return await Promise.resolve();
        }

        await this.$save();

        form.ngForm.form.markAsPristine();
    }

    toggleVisible (form: NgForm): void {
        this.campaignParticipantMission.visible = !this.campaignParticipantMission.visible;
        form.form.markAsDirty();
    }
}
