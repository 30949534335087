
<div class="checkout-section checkout-section-contribution" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title">
            <span class="num">{{number}}.</span><span>Your contribution</span>
        </div>
        <div class="checkout-section-edit-button" *ngIf="isEditable()">
            <span (bkClick)="edit()">Edit</span>
        </div>
    </div>
    <form name="contributionForm" bkForm #contributionForm="bkForm" [ngClass]="{'bk-hidden': !active}" [appCheckoutSectionValidator]="sectionValidateForm(contributionForm)" [checkoutSection]="this">
        <div class="pill-choices-holder" *ngIf="hasFrequencyOption">
            <div class="pill-choices-title">
                <span>Frequency</span>
            </div>

            <div class="pill-choices pill-choices-3">
                <div class="pill-choice" [ngClass]="{active: donation.interval == 'once'}" (click)="donation.interval = 'once'">Once</div>
                <div class="pill-choice" [ngClass]="{active: donation.interval == 'month'}" (click)="donation.interval = 'month'">Monthly</div>
                <div class="pill-choice" [ngClass]="{active: donation.interval == 'year'}" (click)="donation.interval = 'year'">Yearly</div>
            </div>
        </div>

        <div class="amount-input-selections">
            <div class="input-holder checkbox-input-holder" bkFormControlHolder>
                <input type="radio" name="presetAmount" [(ngModel)]="donation.presetAmount" [value]="100" (change)="clearOtherAmount()">
                <div class="checkbox-input-label"><span>$100</span></div>
            </div>

            <div class="input-holder checkbox-input-holder" bkFormControlHolder>
                <input type="radio" name="presetAmount" [(ngModel)]="donation.presetAmount" [value]="250" (change)="clearOtherAmount()">
                <div class="checkbox-input-label"><span>$250</span></div>
            </div>

            <div class="input-holder checkbox-input-holder" bkFormControlHolder>
                <input type="radio" name="presetAmount" [(ngModel)]="donation.presetAmount" [value]="500" (change)="clearOtherAmount()">
                <div class="checkbox-input-label"><span>$500</span></div>
            </div>

            <div class="input-holder checkbox-input-holder" bkFormControlHolder>
                <input type="radio" name="presetAmount" [(ngModel)]="donation.presetAmount" [value]="1000" (change)="clearOtherAmount()">
                <div class="checkbox-input-label"><span>$1000</span></div>
            </div>
        </div>

        <div class="input-holder" bkFormControlHolder>
            <div class="input-label" *ngIf="!donation.otherAmount">
                <span>Other Amount</span>
            </div>

            <input
                bkFormControl
                bkCurrencyInputFilter
                [config]="amountValidators"
                [currency]="currency"
                type="text"
                name="otherAmount"
                [(ngModel)]="donation.otherAmount"
                (input)="clearPresetAmount()"
                (keyup.enter)="next()"
            >
            <bk-validation-display></bk-validation-display>
        </div>

        <!-- <div class="misc-customerinfo gift-is-tax-deductible">
            <span>Your gift is tax deductible</span>
        </div> -->

        <div class="input-holder" bkFormControlHolder>
            <div class="label" style="color: #a1a1a1; font-weight: 600; padding-top: 15px; padding-bottom: 5px;">
                <span>Organization</span>
            </div>
            
            <!-- <bk-select
                bkFormControl
                name="SelectOrganization"
                [selectList]="env.organizations"
                [selectListPrimaryKey]="'id'"
                [selectListOptions]="{displayFn: orgDropDownDisplay}"
                [(ngModel)]="selectedOrganizationId"
                (ngModelChange)="setOrganization()"
                required
                class="bk-select-organization"
            ></bk-select> -->

            <!-- <div class="label" style="color: #a1a1a1; font-weight: 600; font-size: 15px; padding-top: 15px; padding-bottom: 5px;">
                <span>Organization</span>
            </div> -->

            <div class="select-with-chevron">
                <select
                    bkFormControl
                    required
                    [name]="'OrgSelector'"
                    [(ngModel)]="selectedOrganizationId"
                    (ngModelChange)="setOrganization()"
                >
                    <option *ngFor="let org of env.organizations" [value]="org.id">
                        <span>{{org.data?.donationDropDownLabel || org.label}}</span>
                    </option>
                </select>
                <div class="select-chevron">
                    <span></span>
                </div>
            </div>

            <!-- <mat-form-field  style="width: 100%;" appearance="outline">
                <mat-label>Organization</mat-label>
                <mat-select [name]="'OrgSelector'" [(ngModel)]="selectedOrganizationId" (ngModelChange)="setOrganization()">
                    <mat-option *ngFor="let org of env.organizations" [value]="org.id">
                        <span>{{org.data?.donationDropDownLabel || org.label}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <bk-validation-display></bk-validation-display>

            <div class="misc-customerinfo selected-org-description" *ngIf="donation.organization?.data?.donationDropDownDescription">
                <span>{{donation.organization.data.donationDropDownDescription}}</span>
            </div>
        </div>

        <div class="input-holder checkbox-input-holder" bkFormControlHolder style="padding-top: 15px;">
            <input type="checkbox" name="inHonorOrMemoryCheckbox" [(ngModel)]="donation.inHonorOrMemoryOption">
            <div class="checkbox-input-label muted">
                <span>My gift is in honor or memory of someone</span>
            </div>
        </div>

        <div *ngIf="donation.inHonorOrMemoryOption">
            <div class="pill-choices-holder">
                <div class="pill-choices pill-choices-2">
                <div class="pill-choice" [ngClass]="{active: donation.inMemoryOrHonor.which == 'honor'}" (click)="donation.inMemoryOrHonor.which = 'honor'">In Honor Of</div>
                <div class="pill-choice" [ngClass]="{active: donation.inMemoryOrHonor.which == 'memory'}" (click)="donation.inMemoryOrHonor.which = 'memory'">In Memory Of</div>
                </div>
            </div>
            <div class="celebrate-someone">
                <span>Celebrate someone important to you.</span>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label" *ngIf="!donation.inMemoryOrHonor.value">Name</div>
                <input bkFormControl name="inHonorOrMemoryOfName" type="text" [(ngModel)]="donation.inMemoryOrHonor.value" required (keyup.enter)="next()">
                <bk-validation-display></bk-validation-display>
            </div>
        </div>

        <div class="input-holder checkbox-input-holder" bkFormControlHolder style="padding-top: 15px;" *ngIf="hasMessageOption">
            <input type="checkbox" name="leaveMessageCheckbox" [(ngModel)]="donation.messageOption">
            <div class="checkbox-input-label muted">
                <span>I'd like to add a message.</span>
            </div>
        </div>

        <div *ngIf="donation.messageOption">
            <div class="celebrate-someone">
                <span>A message to be published with the gift amount.</span>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label" *ngIf="!donation.message">Type here ...</div>
                <textarea bkFormControl [name]="'DonationMessage'" [(ngModel)]="donation.message" rows="3" style="font-size: 14px;" maxlength="500" required></textarea>
                <bk-validation-display></bk-validation-display>
            </div>
        </div>

        <div class="button-holder">
            <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>

    <div class="checkout-section-detail-view muted" *ngIf="!active && completed">
        <div>
            <span>You will make a {{donation.interval == 'once' ? 'one-time' : donation.interval + 'ly'}} donation of <b class="nowrap">{{donation.amount | currency:currency.symbol}} {{currency.code}}</b> </span>
            <span class="nowrap"> to {{donation.organization.label}}.</span>
        </div>
        <div *ngIf="donation?.inHonorOrMemoryOption" style="padding-top: 15px;">
            <span>
                <span *ngIf="donation.inMemoryOrHonor.which === 'honor'">In honor of </span>
                <span *ngIf="donation.inMemoryOrHonor.which === 'memory'">In memory of </span>
            </span>
            <b>{{donation.inMemoryOrHonor.value}}</b>
        </div>
        <div *ngIf="donation?.messageOption" style="padding-top: 15px;">
            <span>"{{donation.message}}"</span>
        </div>
    </div>
</div>
