<div>{{user.name}}</div>

<div>{{primaryEmailAddress().value}}</div>

<div>
    <div>
        <app-checkbox
            [checked]="user.suspended"
            (clicked)="toggleSuspension()"
            name="UserSuspendedToggle"
            [color]="'warn'"
            [disabled]="busy || disabled"
        >
            <span>Suspended</span>
        </app-checkbox>
    </div>

    <div *ngIf="user.suspended">
        <div>
            <mat-form-field>
                <mat-label>Suspension Title</mat-label>
                <input
                    matInput
                    type="text"
                    [name]="'SuspensionTitle'"
                    [(ngModel)]="user.data.suspendedTitle"
                />
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Suspension Message</mat-label>
                <textarea
                    matInput
                    [name]="'SuspensionMessage'"
                    [(ngModel)]="user.data.suspendedMessage"
                    rows="5"
                ></textarea>
            </mat-form-field>
        </div>

        <div>
            <app-button
                [busy]="busy"
                [disabled]="busy"
                (action)="update.emit(user)"
            >Update</app-button>
        </div>
    </div>
</div>
