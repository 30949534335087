
<div class="donate showing">
    <app-crowdfund-header
        [showAccountButtons]="false"
        [showCampaignAdminNav]="true"
    ></app-crowdfund-header>

    <app-checkout *ngIf="clearedToLoad" [ngClass]="{'bk-hidden': (success || error || !env.data.merchant.has_payment_processors)}" [paymentRequestOptions]="paymentRequestOptions">
        <app-checkout-section-campaign-participant
            [donation]="donation"
            [donor]="donation.donor"
            [enabled]="!completed()"
            [completed]="completed()"
            #sec="section"
            (onNext)="sec.activateNextIncomplete()"
        ></app-checkout-section-campaign-participant>
        <app-checkout-section-email
            [donation]="donation"
            [donor]="donation.donor"
            [enabled]="!env.data.user?.id"
            [completed]="env.data.user?.id ? true : false"
            #sec="section"
            (onNext)="sec.activateNextIncomplete()"
        ></app-checkout-section-email>
        <app-checkout-section-donor-info
            [donation]="donation"
            [donor]="donation.donor"
            #sec="section"
            (onNext)="sec.activateNextIncomplete()"
            [hasAnonymousOption]="true"
        ></app-checkout-section-donor-info>
        <app-checkout-section-contribution
            [donation]="donation"
            [currency]="currency"
            #sec="section"
            (onNext)="sec.activateNextIncomplete()"
            (organizationChange)="setOrganization($event)"
            [hasFrequencyOption]="true"
            [hasMessageOption]="true"
        ></app-checkout-section-contribution>
        <app-checkout-section-payment
            [donation]="donation"
            #sec="section"
            (onNext)="sec.activateNextIncomplete()"
        ></app-checkout-section-payment>
        <app-checkout-section-review-donation
            [donateComponent]="this"
            [donation]="donation"
            #sec="section"
            [paymentRequestOptions]="paymentRequestOptions"
        ></app-checkout-section-review-donation>
    </app-checkout>
    <div class="checkout-holder" *ngIf="success">
        <div class="checkout-content">
        <div class="checkout-section active">
            <div>
                <p>Thank you for your generous support.</p>
                <p>You should receive an e-mail confirmation shortly.</p>
                <div style="font-size: 13px; line-height: 1.5; opacity: 0.75; padding-top: 12px;">
                    <div>Your Receipt #: {{success.receipts.organization.number}}</div>
                    <div>Order ID: {{success.order.id}}</div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="checkout-holder" *ngIf="error">
        <div class="checkout-content">
        <div class="checkout-section active">
            <div>
            <p>An error occurred:</p>
            </div>
            <div class="error-detail" *ngIf="!error.raw">
            <p>Code: {{error.status || error.statusCode}}</p>
            <div *ngIf="error.messages">
                <p *ngFor="let message of error.messages">{{message}}</p>
            </div>
            <pre *ngIf="!error.messages" [innerHtml]="error | stirfry" style="overflow: auto;"></pre>
            </div>
            <div class="error-detail" *ngIf="error.raw">
            <div *ngIf="error.raw.message" style="padding-bottom: 10px;"><b>{{error.raw.message}}</b></div>
            <div *ngIf="error.raw.code"><b>Code:</b>&nbsp;&nbsp;<i>{{error.raw.code}}</i></div>
            <div *ngIf="error.raw.decline_code"><b>Decline Code:</b>&nbsp;&nbsp;<i>{{error.raw.decline_code}}</i></div>
            </div>
            <div class="error-detail-buttons" style="padding-top: 20px;">
            <div *ngIf="env.data.mode !== 'production'" class="button" (bkButton)="clearError()">Clear error</div>
            </div>
        </div>
        </div>
    </div>
    <div class="checkout-holder" *ngIf="!error && !success && !env.data.merchant.has_payment_processors">
        <div class="checkout-content">
        <div class="checkout-section active">
            <div>
            <p>{{env.data.merchant.name || 'Merchant'}} does not yet have any connected payment processors.</p>
            </div>
        </div>
        </div>
    </div>
</div>
