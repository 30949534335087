import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IDomain } from '@amt/i-domain';
import { IEmailTemplate } from '@amt/i-email-template';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { DomainService } from '@s/domain.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-domain-email-view',
    templateUrl: './domain-email-view.component.html',
    styleUrls: ['./domain-email-view.component.scss']
})
export class DomainEmailViewComponent implements OnInit {
    blocker: Blocker;
    domain: IDomain;
    emailTemplate: IEmailTemplate;
    env: IEnv;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public domainService: DomainService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});
        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    fetch (): Promise<void> {
        return this.domainService.getEmailTemplate(
            <string>(this.route.snapshot.params.domainId),
            <string>(this.route.snapshot.params.emailTemplateId)
        ).then((res) => {
            console.log("Domain got:", res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
