import { Component, Input, OnInit } from '@angular/core';
import { IDomain } from '@amt/i-domain';
import { DomainService } from '@s/domain.service';

import * as promises from '@bkngSrc/lib/promises';
import { DomainDeleteOptions } from '@api-routes/domain/types/domain-delete-options';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-domain-list-item',
    templateUrl: './domain-list-item.component.html',
    styleUrls: ['./domain-list-item.component.scss']
})
export class DomainListItemComponent implements OnInit {
    busy: boolean = false;
    deleting: boolean = false;
    deleted: boolean = false;
    @Input() domain: IDomain;
    willDelete: boolean = false;

    constructor (
        public domainService: DomainService,
        public route: ActivatedRoute,
    ) { }

    async delete (): Promise<void> {
        if (this.busy) {
            return;
        }
        if (!this.willDelete) {
            this.busy = true;

            await promises.timeout(2500).then(() => {
                this.busy = false;
                this.willDelete = true;
                return promises.timeout(1500);
            }).then(() => {
                if (this.deleting || this.deleted) {
                    return;
                }
                this.willDelete = false;
            });

            return;
        }

        this.busy = true;
        this.deleting = true;

        const options: DomainDeleteOptions = {
            domainId: this.domain.id,
        };

        if (
            this.route.snapshot.params.organizationId
        ) {
            // from data passed to list item instead?
            options.organizationId = <string>(this.route.snapshot.params.organizationId);
        }

        await this.domainService.delete(
            options
        ).then((result) => {
            console.log(`Deleted domain ${this.domain.id}`, result);
            this.deleted = true;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
            this.willDelete = false;
            this.deleting = false;
        });
    }

    ngOnInit (): void {
    }

}
