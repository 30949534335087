<div *ngIf="campaign?.id">
    <mat-form-field style="width: 100%;">
        <mat-label>Campaign Label</mat-label>
        <input matInput type="text" [name]="'CampaignLabel'" [(ngModel)]="campaign.label" (ngModelChange)="doSave()" />
    </mat-form-field>

    <div *ngFor="let item of campaign.configs" style="padding-top: 35px;">
        <app-campaign-edit-config
            [campaign]="campaign"
            [config]="item"
            [merchant]="data.merchant"
        ></app-campaign-edit-config>
    </div>
</div>
