<div class="supporter-container" *ngIf="supporter">
    <!-- <div class="supporter-profile-photo" *ngIf="supporter.benefactor">
        <div class="profile-photo" [style.backgroundImage]="'url(' + (supporter.benefactor | profilePhotoUrl) + ')'"></div>
    </div> -->

    <div class="supporter-name">
        <span>{{supporter?.benefactor?.name || 'Anonymous'}}</span>
    </div>
    
    <div class="supporter-donated">
        <span>Donated</span>
        <span>&nbsp;</span>
        <b>{{getForDisplayAmountDonated() | currency : 'USD' : 'symbol' : '1.0-0'}}</b>
    </div>

    <div class="supporter-contributions" *ngIf="contributions?.length">
        <div style="width: 100%;">
            <div class="supporter-contribution"  *ngFor="let c of contributions">
                <div class="supporter-message" *ngIf="c.message">"{{c.message}}"</div>
                <div class="supporter-honor-memory" *ngIf="c.inMemoryOrHonor?.value">
                    <span *ngIf="c.inMemoryOrHonor.which === 'honor'">In honor of {{c.inMemoryOrHonor.value}}</span>
                    <span *ngIf="c.inMemoryOrHonor.which === 'memory'">In memory of {{c.inMemoryOrHonor.value}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
