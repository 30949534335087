import {
    Component,
    Input,
} from '@angular/core';

import {
    FormDirective,
    StripeElementsCardDirective,
} from 'bkng';

import {
    CheckoutSectionComponent
} from '../checkout-section/checkout-section.component';

import { CheckoutComponent } from '../checkout/checkout.component';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';

import { Router } from '@angular/router';
import { EnvService } from '@s/env.service';
import { PaymentMethodName } from '@alt/payment-method-name';

@Component({
    selector: 'app-checkout-section-payment',
    templateUrl: './checkout-section-payment.component.html',
    styleUrls: ['./checkout-section-payment.component.scss'],
    exportAs: 'section'
})
export class CheckoutSectionPaymentComponent extends CheckoutSectionComponent {
    @Input() donation: CreateDonationBody;
    paymentTokenCreationError: unknown;
    sectionName: string = "payment_method";
    stripeCard: StripeElementsCardDirective;

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public router: Router,
    ) {
        super(checkout, envService, router);
    }

    onFirstActivation (): void {
        this.donation.billingAddress.firstName = this.donation.donor.firstName;
        this.donation.billingAddress.lastName = this.donation.donor.lastName;
        this.donation.billingAddress.address1 = this.donation.donor.address1;
        this.donation.billingAddress.address2 = this.donation.donor.address2;
        this.donation.billingAddress.city = this.donation.donor.city;
        this.donation.billingAddress.state = this.donation.donor.state;
        this.donation.billingAddress.country_code = this.donation.donor.country_code;
        this.donation.billingAddress.postal_code = this.donation.donor.postal_code;
        this.donation.billingAddress.selectedCountry = this.donation.donor.selectedCountry;
        this.donation.billingAddress.phone = Object.assign(Object.assign({}, this.donation.billingAddress.phone), this.donation.donor.phone || {});
    }

    selectPaymentMethod (method: PaymentMethodName): void {
        this.donation.paymentMethodName = method;
    }

    sectionValidateCardPaymentForm (frm: FormDirective)  {
        // convenience fn
        return (): Promise<boolean> => {
            return frm.submit().then((ok) => {
                if (!ok) {
                    return false;
                }
                if (
                    this.donation.paymentMethodName === 'card'
                    &&
                    this.stripeCard?.getStripeToken
                ) {
                    if (this.stripeCard?.stripeToken?.id && !this.stripeCard?.stripeToken?.used) {
                        return true;
                    }
                    return this.stripeCard.getStripeToken().then((result) => {
                        this.donation.paymentSourceToken = result.token;
                        return true;
                    }).catch((err) => {
                        console.error(err);
                        return false;
                    });
                }
                return true;
            });

        };
    }

    setCard (card: StripeElementsCardDirective): void {
        this.stripeCard = card;
    }
}
