import {
    // BkRouteInfo,
    BkRoutes
} from 'bkng';

import { outletLoggedIn } from './outlet-logged-in';
import { outletNotLoggedIn } from './outlet-not-logged-in';
import { outletMerchant } from './outlet-merchant';
import { outletPlatform } from './outlet-platform';
import { outletTeamDonationCampaign } from './outlet-team-donation-campaign';
import { routeStartingPoint } from './route-starting-point';

import { CrowdfundLandingComponent } from '@c/crowdfund-landing/crowdfund-landing.component';

import { signup } from './signup/signup';
import { CrowdfundParticipantLandingComponent } from '@c/crowdfund-participant-landing/crowdfund-participant-landing.component';
import { CrowdfundSignUpComponent } from '@c/crowdfund-sign-up/crowdfund-sign-up.component';
import { CrowdfundUserAccountComponent } from '@c/crowdfund-user-account/crowdfund-user-account.component';
import { UserAccountComponent } from '@c/user-account/user-account.component';
import { CrowdfundAdminComponent } from '@c/crowdfund-admin/crowdfund-admin.component';
import { outletAdmin } from './outlet-admin';
import { auth } from './auth';
import { account } from './account/account';
import { admin } from './admin/admin';
import { campaignParticipant } from './campaign-participant';
import { domain } from './domain/domain';
import { merchant } from './merchant/merchant';
import { organization } from './organization/organization';
import { ViewNotFoundComponent } from '@c/view-not-found/view-not-found.component';

const children: BkRoutes = [
    {
        path: ":campaignParticipantMissionUrl",
        label: "Campaign Participant",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: CrowdfundParticipantLandingComponent}),
                outletLoggedIn({component: CrowdfundParticipantLandingComponent}),
            ])
        ]),
        children: campaignParticipant,
    },
    {
        path: "account",
        label: "Account",
        ngRoute: routeStartingPoint([
            outletPlatform({component: UserAccountComponent}),
            outletMerchant({component: UserAccountComponent}),
            outletTeamDonationCampaign([
                outletLoggedIn({component: CrowdfundUserAccountComponent}),
            ])
        ]),
        children: account,
    },
    {
        path: "admin",
        label: "Administration",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletLoggedIn({component: CrowdfundAdminComponent}),
            ]),
            outletAdmin(),
            // outletMerchant(),
        ]),
        children: admin,
    },
    {
        path: "domain",
        label: "Domain",
        children: domain,
    },

    // the following "embed" path was commented before lintup.
    // {
    //     path: "embed",
    //     label: "Embed",
    //     children: embed,
    // },

    {
        path: "merchant",
        label: "Merchant",
        children: merchant,
    },
    {
        path: "organization",
        label: "Organization",
        children: organization,
    },
    {
        path: "signup",
        label: "Campaign Participant",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: CrowdfundSignUpComponent}),
                outletLoggedIn({component: CrowdfundSignUpComponent}),
            ])
        ]),
        children: signup,
    },
];

export const routes: BkRoutes = [
    {
        path: "",
        label: "Home",
        ngRoute: routeStartingPoint([
            outletMerchant(),
            outletPlatform(),
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: CrowdfundLandingComponent}),
                outletLoggedIn({component: CrowdfundLandingComponent}),
            ]),
        ]),
        children: children.concat(auth),
    },
    {
        path: "**",
        label: "View Not Found",
        ngRoute: routeStartingPoint([
            outletMerchant({component: ViewNotFoundComponent}),
            outletPlatform({component: ViewNotFoundComponent}),
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: ViewNotFoundComponent}),
                outletLoggedIn({component: ViewNotFoundComponent}),
            ]),
        ]),
    },
];
