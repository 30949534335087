<div class="donate showing" *ngIf="clearedToLoad">
    <app-crowdfund-header
        [showAccountButtons]="false"
        [showCampaignAdminNav]="env.merchantUserRoles?.admin"
    ></app-crowdfund-header>

    <div class="crowdfund-admin-common-padding crowdfund-admin-nav-breadcrumbs">
        <app-nav-breadcrumbs></app-nav-breadcrumbs>
    </div>

    <div class="crowdfund-admin-common-padding">
        <div class="crowdfund-admin-section-title-holder">
            <div class="crowdfund-admin-section-title">
                <span>Participant</span>
            </div>
        </div>

        <app-resend-verification-email
            [user]="campaignParticipant.user"
        ></app-resend-verification-email>

        <div *ngIf="data">
            <app-campaign-participant-profile
                [campaignParticipant]="campaignParticipant"
                [campaignParticipantMission]="campaignParticipantMission"
                [participant]="campaignParticipant.user"
                [person]="campaignParticipant.person"
                (createdMission)="fetch()"
                (uploadedProfilePhoto)="fetch()"
            ></app-campaign-participant-profile>
        </div>
    </div>
</div>
