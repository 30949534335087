<div class="donate showing" *ngIf="clearedToLoad">
    <app-crowdfund-header></app-crowdfund-header>

    <div
        class="crowdfund-common-container crowdfund-landing-callout"
        *ngIf="env?.domain?.config?.page?.id"
        style="background-repeat: no-repeat; background-position: center center; background-size: cover;"
        [ngStyle]="{
            'background-image': 'url(' + (env.domain.config | domainConfigBannerPhoto) + ')'
        }"
    >
        <div class="callout-contents">
            <div class="callout-title" [innerHtml]="env.domain.config.page.data.leftCalloutTitle | safe:'html'"></div>
            <div class="callout-text" [innerHtml]="env.domain.config.page.data.leftCalloutText | safe:'html'"></div>
        </div>
    </div>

    <div id="participants" class="crowdfund-common-container crowdfund-participants with-top-border">
        <div class="crowdfund-common-container-title crowdfund-common-container-side-padding">Missions</div>

        <div *ngIf="data?.missions" class="crowdfund-participants-list-container crowdfund-common-list-side-padding">
            <div *ngFor="let mission of data.missions">
                <app-crowdfunding-participant-mission-tile [mission]="mission"></app-crowdfunding-participant-mission-tile>
            </div>
        </div>
    </div>

    <!-- <div class="crowdfund-ctrls with-top-border">
        <div class="crowdfund-ctrls-left crowdfund-common-container-side-padding">
            <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" [ngClass]="['about', '', null, undefined].includes(route.snapshot.fragment) ? 'active' : 'inactive'">About</a>
            <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" fragment="participants" [ngClass]="route.snapshot.fragment === 'participants' ? 'active' : 'inactive'">Participants</a>
            <a class="crowdfund-ctrls-left-nav-item" [routerLink]="['.']" fragment="supporters" [ngClass]="route.snapshot.fragment === 'supporters' ? 'active' : 'inactive'">Supporters</a>
        </div>
        <div class="crowdfund-ctrls-right">
            <div class="crowdfund-ctrls-right-donate crowdfund-common-container-side-padding" *ngIf="!env?.user?.id">
                <a class="button callout primary" [routerLink]="['.', 'signup']">Sign Up</a>
            </div>
            <div class="crowdfund-ctrls-right-donate crowdfund-common-container-side-padding" *ngIf="env?.user?.id">
                <a class="button callout primary" [routerLink]="['.', 'account']">Account</a>
            </div>
        </div>
    </div>

    <div id="about" class="crowdfund-common-container crowdfund-mid with-top-border">
        <div class="crowdfund-mid-inner-container first-callout">
            <div>
                <div class="callout-title crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.leftCalloutTitle | safe:'html'"></div>
            </div>
            <div>
                <div class="callout-content crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.leftCalloutText | safe:'html'"></div>
            </div>
        </div>

        <div class="crowdfund-mid-inner-container second-callout">
            <div>
                <div class="callout-title crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.rightCalloutTitle | safe:'html'"></div>
            </div>
            <div>
                <div class="callout-content crowdfund-common-container-side-padding" [innerHtml]="env.domain.config.page.data.rightCalloutText | safe:'html'"></div>
            </div>
        </div>
    </div>

    <div id="participants" class="crowdfund-common-container crowdfund-participants with-top-border">
        <div class="crowdfund-common-container-title crowdfund-common-container-side-padding">Participants</div>

        <div class="crowdfund-participants-list-container crowdfund-common-list-side-padding">
            <div *ngFor="let participant of data.participants">
                <app-crowdfunding-participant-tile [participant]="participant"></app-crowdfunding-participant-tile>
            </div>
        </div>
    </div>

    <div id="supporters" class="crowdfund-common-container crowdfund-supporters with-top-border">
        <div class="crowdfund-common-container-title crowdfund-common-container-side-padding">Supporters</div>

        <div class="crowdfund-supporters-list-container crowdfund-common-list-side-padding">
            <div *ngFor="let supporter of data.supporters" class="crowdfund-supporters-list-iteration">
                <app-crowdfund-supporter-tile [supporter]="supporter"></app-crowdfund-supporter-tile>
            </div>
        </div>
    </div> -->
</div>
