import {
    Injectable,
} from '@angular/core';

import { LoadBlockerDirective } from './load-blocker.directive';

export interface BlockerRegistration {
    blockerDirective?: LoadBlockerDirective;
}

export interface Blocker {
    block(): unknown;
    blocking: boolean;
    blockerDirective?: LoadBlockerDirective;
    id: number;
    registration: BlockerRegistration;
    unblock(): unknown;
}

export interface BlockerCategory {
    members: Array<Blocker>;
    hasCleared: boolean;
    isClear: boolean;
}

export interface Blockers {
    [key: string]: BlockerCategory;
}

@Injectable({
    providedIn: 'root'
})
export class LoadBlockerService {
    blockerCount: number = 0;
    blockers: Blockers = {
        all: {
            members: [],
            hasCleared: false,
            isClear: false,
        }
    };

    constructor (
    ) {
    }

    checkBlockers (): void {
        setTimeout(() => {
            Object.keys(this.blockers).forEach((k) => {
                const category: BlockerCategory = this.blockers[k];
                let isClear = true;
                for (const m of category.members) {
                    if (m.blocking) {
                        isClear = false;
                        break;
                    }
                }
                category.isClear = isClear;
                if (category.isClear) {
                    category.hasCleared = true;
                }
            });
        });
    }

    register (registration: BlockerRegistration): Blocker {
        this.blockerCount++;

        const blocker: Blocker = {
            block: (): void => {
                blocker.blocking = true;
                this.checkBlockers();
            },
            blocking: true,
            id: this.blockerCount,
            registration,
            unblock: (): void => {
                blocker.blocking = false;
                this.checkBlockers();
            }
        };

        if (registration.blockerDirective) blocker.blockerDirective = registration.blockerDirective;

        this.blockers.all.members.push(blocker);

        this.checkBlockers();

        return blocker;
    }
}
