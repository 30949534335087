import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    RestService
} from 'bkng';

import { EnvService } from './env.service';
import { DomainGetOptions } from '@api-routes/domain/types/domain-get-options';
import { DomainGetQuery } from '@api-routes/domain/types/domain-get-query';
import { DomainGetResult } from '@api-routes/domain/types/domain-get-result';
import { DomainSaveOptions } from '@api-routes/domain/types/domain-save-options';
import { DomainSaveQuery } from '@api-routes/domain/types/domain-save-query';
import { DomainSaveResult } from '@api-routes/domain/types/domain-save-result';
import { DomainSaveBody } from '@api-routes/domain/types/domain-save-body';
import { DomainPostOptions } from '@api-routes/domain/types/domain-post-options';
import { DomainPostQuery } from '@api-routes/domain/types/domain-post-query';
import { DomainPostResult } from '@api-routes/domain/types/domain-post-result';
import { DomainDeleteQuery } from '@api-routes/domain/types/domain-delete-query';
import { DomainDeleteOptions } from '@api-routes/domain/types/domain-delete-options';

import { DomainAvailableConfigsGetOptions } from '@api-routes/domain/types/domain-available-configs-get-options';
import { DomainAvailableConfigsGetQuery } from '@api-routes/domain/types/domain-available-configs-get-query';
import { DomainAvailableConfigsGetResult } from '@api-routes/domain/types/domain-available-configs-get-result';

import { DomainConfigPostOptions } from '@api-routes/domain/types/domain-config-post-options';
import { DomainConfigPostQuery } from '@api-routes/domain/types/domain-config-post-query';
import { DomainConfigPostResult } from '@api-routes/domain/types/domain-config-post-result';

import { DomainPaymentMethodPostOptions } from '@api-routes/domain/types/domain-payment-method-post-options';
import { DomainPaymentMethodPostQuery } from '@api-routes/domain/types/domain-payment-method-post-query';
import { DomainPaymentMethodResponse } from '@api-routes/domain/types/domain-payment-method-post-response';

@Injectable({
    providedIn: 'root'
})
export class DomainService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `domain`;
    }

    async addConfig (
        options: DomainConfigPostOptions,
    ): Promise<DomainConfigPostResult> {
        const queryParams: DomainConfigPostQuery = {
            domain_id: options.domainId,
        };

        if (
            options.configId
        ) {
            queryParams.config_id = options.configId;
        }

        const response = await lastValueFrom(
            this.$post(
                "config",
                {},
                {
                    params: queryParams
                }
            )
        );

        return <DomainConfigPostResult><unknown>response;
    }

    async create (
        options: DomainPostOptions
    ): Promise<DomainPostResult> {
        const queryParams: DomainPostQuery = {};

        if (
            options.merchantId
        ) {
            queryParams.merchant_id = options.merchantId;
        }

        if (
            options.organizationId
        ) {
            queryParams.organization_id = options.organizationId;
        }

        const response = await lastValueFrom(
            this.$post(
                "",
                options.body,
                {
                    params: queryParams,
                },
            )
        );

        return <DomainPostResult><unknown>response;
    }

    async delete (
        options: DomainDeleteOptions
    ): Promise<unknown> {
        const queryParams: DomainDeleteQuery = {
            domain_id: options.domainId,
        };

        if (
            options.organizationId
        ) {
            queryParams.organization_id = options.organizationId;
        }

        return await lastValueFrom(
            this.$delete(
                "",
                {
                    params: queryParams,
                }
            )
        );
    }

    async getAvailableConfigs (
        options: DomainAvailableConfigsGetOptions
    ): Promise<DomainAvailableConfigsGetResult> {
        const queryParams: DomainAvailableConfigsGetQuery = {
            domain_id: options.domainId,
        };

        const response = await lastValueFrom(
            this.$get(
                "available_configs",
                {
                    params: queryParams,
                }
            )
        );

        return <DomainAvailableConfigsGetResult><unknown>response;
    }

    getEmailTemplate (domainId: string, emailTemplateId: string): Promise<unknown> {
        return lastValueFrom(
            this.$get(
                `email_template`,
                {
                    params: {
                        domain_id: domainId,
                        email_template_id: emailTemplateId,
                    }
                }
            ));
    }

    async getById (
        options: DomainGetOptions
    ): Promise<DomainGetResult> {
        const params: DomainGetQuery = {};

        if (
            options.id
        ) {
            params.id = options.id;
        }

        if (
            options.merchantId
        ) {
            params.merchant_id = options.merchantId;
        }

        if (
            options.organizationId
        ) {
            params.organization_id = options.organizationId;
        }

        const result = await lastValueFrom(
            this.$get(``, {
                params: params
            })
        );
        return <DomainGetResult><unknown>result;
    }

    async registerPaymentMethod (
        options: DomainPaymentMethodPostOptions
    ): Promise<DomainPaymentMethodResponse> {
        const params: DomainPaymentMethodPostQuery = {
            domain_id: options.domainId,
        };

        const result = await lastValueFrom(
            this.$post(
                "payment_method",
                {},
                {
                    params: params
                }
            )
        );

        return <DomainPaymentMethodResponse><unknown>result;
    }

    async save (
        options: DomainSaveOptions
    ): Promise<DomainSaveResult> {
        options.id;
        const params: DomainSaveQuery = {
            id: options.id,
        };

        const body: DomainSaveBody = {
            sub: options.sub,
            top: options.top,
        };

        const result = await lastValueFrom(
            this.$put(
                ``,
                body,
                {
                    params: params
                },
            )
        );
        return <DomainSaveResult><unknown>result;
    }
}
