import {
    CanMakePaymentResult,
    PaymentRequest as StripePaymentRequest
} from '@stripe/stripe-js';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    StripeService
} from 'bkng';

import { CheckoutSectionComponent } from '../checkout-section/checkout-section.component';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { timeout } from '@bkngSrc/lib/promises';

export interface IPaymentRequestOptions {
    country: string
    currency: string,
    total: {
        label: string,
        amount: number,
    };
    requestPayerName: boolean;
    requestPayerEmail: boolean;
    requestPayerPhone: boolean;
}

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    canMakePaymentRequestResult: CanMakePaymentResult;
    @Input() data: unknown = {};
    env: IEnv;
    paymentRequest: StripePaymentRequest;
    @Output() paymentRequestCancelled = new EventEmitter<unknown>();
    @Input() paymentRequestOptions: IPaymentRequestOptions;
    @Output() paymentRequestPossible = new EventEmitter<unknown>();
    @Output() paymentRequestToken = new EventEmitter<unknown>();
    sections: Array<CheckoutSectionComponent> = [];

    constructor (
        public envService: EnvService,
        public stripe: StripeService,
    ) {
        this.env = envService.data;
    }

    async activateFirstEnabledAndIncomplete (): Promise<void> {
        for (const section of this.sections) {
            if (section.enabled && !section.completed) {
                await section.activate();
                break;
            }
        }
    }

    async activateFirstInvalid (): Promise<void> {
        for (const section of this.sections) {
            if (section.enabled && !section.valid) {
                await section.activate();
                await timeout(0);
                await section.validate();
                break;
            }
        }
    }

    checkForPaymentRequest (): void {
        if (!this.stripe.stripe) {
            return;
        }

        const paymentRequest = this.paymentRequest = this.stripe.stripe.paymentRequest(
            this.paymentRequestOptions
        );

        paymentRequest.on(
            "cancel",
            () => {
                this.paymentRequestCancelled.emit();
            }
        );

        paymentRequest.on("token", (ev) => {
            this.paymentRequestToken.emit(ev);
        });

        paymentRequest.canMakePayment().then((result) => {
            this.paymentRequestPossible.emit(result);
            this.canMakePaymentRequestResult = result;
        }).catch((err) => {
            console.error(err);
        });
    }

    getActiveSectionsArray (): CheckoutSectionComponent[] {
        return this.sections.filter((s) => {
            return s.active;
        });
    }

    hasActive (): boolean {
        const activeSectionsArray = this.getActiveSectionsArray();
        return (activeSectionsArray.length ? true : false);
    }

    ngOnInit (): void {
        if (this.paymentRequestOptions) {
            this.checkForPaymentRequest();
        }
    }

    validateAllSections (): Promise<boolean> {
        const allSectionsValidateFns = this.sections.map((s) => s.validate());
        return Promise.all(allSectionsValidateFns).then((results) => {
            if (results.indexOf(false) < 0) {
                return true;
            }
            return false;
        });
    }
}
