import { IEmailAddress } from "@amt/i-email-address";

export function primaryEmailAddress (
    emailAddresses: Array<IEmailAddress> = [],
): IEmailAddress {
    if (
        !Array.isArray(emailAddresses)
    ) {
        console.error("This isn't an array!", emailAddresses);
        throw new Error(`primaryEmailAddress requires an array of email addresses.`);
    }

    const primaryEmailAddress = emailAddresses.filter((ea) => {
        return ea.primary;
    })[0];

    if (
        primaryEmailAddress
    ) {
        return primaryEmailAddress;
    }

    const firstConfirmedEmailAddress = emailAddresses.filter((ea) => {
        return ea.confirmed;
    })[0];

    if (
        firstConfirmedEmailAddress
    ) {
        return firstConfirmedEmailAddress;
    }

    return emailAddresses[0];
}
