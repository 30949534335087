import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { CampaignService } from '@s/campaign.service';

import {
    IAddCampaignParticipantDialogReturnData
} from '../add-campaign-participant-dialog/add-campaign-participant-dialog.component';

import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { ICampaign } from '@amt/i-campaign';
import { CampaignPostBody } from '@api-routes/campaign/types/campaign-post-body';

@Component({
    selector: 'app-campaign-view',
    templateUrl: './campaign-view.component.html',
    styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnInit {
    blocker: Blocker;
    busy: boolean = false;
    // campaign: ICampaign;
    data: CampaignGetResult;
    env: IEnv;
    //participants?: Array<IPerson> = [];
    saveSubject: Subject<ICampaign> = new Subject();

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public campaignService: CampaignService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.blocker.unblock();
        });

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe((data) => {
            this.save(
                data,
            ).catch((err) => {
                console.error(err);
            });
        });
    }

    addedCampaignParticipant (
        data: IAddCampaignParticipantDialogReturnData
    ): void {
        this.fetch().catch((err) => {
            console.error(err);
        });
    }

    doSave (): void {
        this.saveSubject.next(
            this.data.campaign,
        );
    }

    async fetch (): Promise<void> {
        this.busy = true;

        try {
            const response = await this.campaignService.getById({
                admin: true,
                id: <string>(this.route.snapshot.params.campaignId),
                merchantId: <string>(this.route.snapshot.params.merchantId),
                showParticipants: 'all',
            });

            console.log("CampaignViewComponent:", response);
            this.data = Object.assign(this.data || {}, response);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.busy = false;
        }
    }

    ngOnInit (): void {
    }

    async save (
        campaign: ICampaign,
    ): Promise<void> {
        this.busy = true;

        const body: CampaignPostBody = {
            campaign: {
                label: campaign.label,
                data: campaign.data,
                status: campaign.status,
            },
        };

        try {
            const response = await this.campaignService.save(
                campaign.id,
                body
            );

            Object.assign(this.data.campaign, response.campaign);
        }
        catch (err) {
            throw err;
        }
        finally {
            this.busy = false;
        }
    }

    toggleStatus (): void {
        const campaign = Object.assign({}, this.data.campaign);

        if (
            campaign.status === "closed"
        ) {
            campaign.status = "open";
        }
        else {
            campaign.status = "closed";
        }

        this.save(campaign).catch(console.error);
    }
}
