<div *ngIf="merchantMailchimpIndex">
    <div>
        <b>Mailchimp Info/Actions</b>
    </div>

    <div *ngIf="merchantMailchimpIndex.lists?.length">
        <div>Lists:</div>
        <div *ngFor="let list of merchantMailchimpIndex.lists">
            <div>{{list.list.name}}, member count: {{list.members.total_items}}</div>
        </div>
        <div>IntegrationContactCount: {{merchantMailchimpIndex.system.integrationContactCount || 0}}</div>
    </div>

    <div style="padding-top: 35px;">
        <div><b>Segments:</b></div>
        <div *ngIf="merchantMailchimpIndex.lists?.length">
            <div *ngFor="let item of merchantMailchimpIndex.lists[0].segments.segments">
                <div>{{item.name}}</div>
            </div>
        </div>
        <div *ngIf="!merchantMailchimpIndex.lists?.length">
            <span>No segments.</span>
        </div>
    </div>

    <app-button (action)="syncContacts()" [busy]="busy">Mailchimp -> Hubspot</app-button>
</div>
