import { ICampaign } from '@amt/i-campaign';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from '@s/admin.service';

import { IEmailAddress } from '@lib/model/types/i-email-address';
import { IPhoneNumber } from '@lib/model/types/i-phone-number';
import { primaryEmailAddress } from '@lib/primary-email-address';
import { primaryPhoneNumber } from '@lib/primary-phone-number';

@Component({
    selector: 'app-admin-campaign-participant-list-item',
    templateUrl: './admin-campaign-participant-list-item.component.html',
    styleUrls: ['./admin-campaign-participant-list-item.component.scss']
})
export class AdminCampaignParticipantListItemComponent implements OnInit {
    @Input() campaign: ICampaign;
    @Input() campaignParticipant: ICampaignParticipant;
    deleted: boolean = false;
    emailAddress: IEmailAddress;
    phoneNumber: IPhoneNumber;

    constructor (
        public adminService: AdminService,
    ) { }

    deletedCampaignParticipant (deleted: boolean): void {
        this.deleted = deleted;
    }

    ngOnInit (): void {
        this.emailAddress = primaryEmailAddress(
            this.campaignParticipant.user.email
        );

        this.phoneNumber = primaryPhoneNumber(
            this.campaignParticipant.user.phone
        );
    }

}
