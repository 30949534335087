import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IEmailTemplate } from '@amt/i-email-template';
import { IOrganization } from '@amt/i-organization';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { OrganizationService } from '@s/organization.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-organization-email-view',
    templateUrl: './organization-email-view.component.html',
    styleUrls: ['./organization-email-view.component.scss']
})
export class OrganizationEmailViewComponent implements OnInit {
    blocker: Blocker;
    emailTemplate: IEmailTemplate;
    env: IEnv;
    organization: IOrganization;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public organizationService: OrganizationService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});
        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    fetch (): Promise<void> {
        return this.organizationService.getEmailTemplate(
            <string>(this.route.snapshot.params.organizationId),
            <string>(this.route.snapshot.params.emailTemplateId)
        ).then((res) => {
            console.log("Organization got:", res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
