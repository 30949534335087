import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { PaymentService } from '@s/payment.service';

import { IPayment } from '@amt/i-payment';

export interface IPaymentListDialogData {
    anything: true;
}

@Component({
    selector: 'app-payment-list-dialog',
    templateUrl: './payment-list-dialog.component.html',
    styleUrls: ['./payment-list-dialog.component.scss']
})
export class PaymentListDialogComponent implements OnInit {
    payments: Array<IPayment> = [];

    constructor (
        public dialogRef: MatDialogRef<PaymentListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPaymentListDialogData,
        public paymentService: PaymentService,
    ) {
    }

    ngOnInit (): void {
        this.paymentService.search({}).then((result) => {
            Object.assign(this, result);
        }).catch((err) => {
            console.error(err);
        });
    }

    selectPayment (payment: IPayment): void {
        this.dialogRef.close(payment);
    }
}
