import {
    Component,
    Input,
} from '@angular/core';

import {
    FormDirective,
} from 'bkng';

import { UserService } from '@s/user.service';
import { CheckoutComponent } from '../checkout/checkout.component';
import { CheckoutSectionComponent } from '../checkout-section/checkout-section.component';
import { SignInDialogService } from '../sign-in-dialog/sign-in-dialog.component';

import { CreateDonationBody } from '@api-routes/order/types/create-donation-body';
import { Donor } from '@alt/donor';

import { Router } from '@angular/router';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-checkout-section-email',
    templateUrl: './checkout-section-email.component.html',
    styleUrls: ['./checkout-section-email.component.scss'],
    exportAs: 'section'
})
export class CheckoutSectionEmailComponent extends CheckoutSectionComponent {
    @Input() donation: CreateDonationBody;
    @Input() donor: Donor;
    sectionName: string = "donor_email";

    constructor (
        public checkout: CheckoutComponent,
        public envService: EnvService,
        public US: UserService,
        public SIDS: SignInDialogService,
        public router: Router,
    ) {
        super(checkout, envService, router);
    }

    sectionValidateForm (frm: FormDirective)  {
        return (): Promise<boolean> => {
            return frm.submit().then((ok) => {
                if (ok) {
                    if (this.completed) {
                        return true;
                    }
                    return this.submitEmailForm(frm);
                }
                return false;
            });
        };
    }

    async submitEmailForm (frm: FormDirective): Promise<boolean> {
        return await new Promise((resolve, reject) => {
            if (this.env.user?.id) {
                // return scope.completeSection(section);
                frm.onSuccess();
                resolve(true);
                return;
            }

            // this.US.checkUserExists({email: this.donor.email}).then((res) => {
            Promise.resolve().then(() => {
                /*
                if (res.user?.email) {
                    const dialogRef = this.SIDS.open({
                        credentials: {
                            email: this.donor.email
                        },
                        showWelcomeBack: true
                    });
                    return dialogRef.afterClosed().subscribe((result: any) => {
                        if (result?.user?.id) {
                            this.donor.firstName = result.user.first_name;
                            this.donor.lastName = result.user.last_name;

                            if (result.user.data?.phone?.value) {
                                this.donor.phone = result.user.data.phone;
                            }

                            if (this.env.savedPaymentMethods?.length) {
                                this.donation.paymentMethodName = `saved_payment`;
                                this.donation.savedPaymentMethod = this.env.savedPaymentMethods[0];
                            }

                            this.complete();
                            frm.onSuccess();

                            return resolve(true);
                        }
                        resolve(false);
                    });
                }
                */
                frm.onSuccess();
                resolve(true);
            }).catch((err: unknown) => {
                console.error(err);
                frm.onError(err);
                resolve(false);
            });
        });
    }
}
