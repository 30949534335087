<div>
    <mat-form-field appearance="fill" style="width: 100%;">  
        <mat-label>{{label}}</mat-label>
        <mat-select [name]="'SelectedSlackChannel'" [(ngModel)]="selectedChannel" (ngModelChange)="onChange($event)">
            <mat-option *ngFor="let c of channels" [value]="c.id">
                <span>{{c.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
