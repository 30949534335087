import { Component, OnInit } from '@angular/core';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-view-not-found',
    templateUrl: './view-not-found.component.html',
    styleUrls: ['./view-not-found.component.scss']
})
export class ViewNotFoundComponent implements OnInit {

    blocker: Blocker;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
    ) {
        this.blocker = lbs.register({});
    }

    ngOnInit (): void {
        this.blocker.unblock();
    }
}
