import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { IGa4Integration } from '@amt/i-ga4-integration';

@Component({
    selector: 'app-integration-setting-ga4',
    templateUrl: './integration-setting-ga4.component.html',
    styleUrls: ['./integration-setting-ga4.component.scss']
})
export class IntegrationSettingGa4Component implements OnInit {
    @Input() busy: boolean = false;
    @Input() integration: Partial<IGa4Integration>;
    @Output() updated = new EventEmitter<Partial<IGa4Integration>>();

    constructor () { }

    ngOnInit (): void {
        this.integration.app = this.integration.app || `google_analytics_4`;
        this.integration.options = this.integration.options || {};
    }
}
