import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { OrganizationEmailViewComponent } from '@c/organization-email-view/organization-email-view.component';

export const id: BkRoutes = [
    {
        path: ":emailTemplateId",
        label: "Organization Emails Template by ID Template",
        ngRoute: routeStartingPoint([
            outletPlatform({component: OrganizationEmailViewComponent}),
        ]),
        children: [
        ],
    },
];
