<div class="payment-container">
    <div class="payment-data">
        <div class="payment-date">{{payment.created_at | friendlyDateTime}}</div>
        <div
            class="payment-amount"
            [ngClass]="paymentDirection(payment.amount)"
        >{{payment.amount | currency}} {{payment.currency_code}}</div>
        <div class="payment-campaign-participant" *ngIf="payment.campaign_participant_mission?.id">
            <span>Campaign Participant &amp; Mission: </span>
            <span>{{payment.campaign_participant_mission.person.name}}, </span>
            <span *ngIf="payment.campaign_participant_mission.mission?.data.eventType">{{payment.campaign_participant_mission.mission.data.eventType}}, </span>
            <b *ngIf="!payment.campaign_participant_mission.mission?.data.eventType">(WTF, no mission data?), </b>
            <span>/{{payment.campaign_participant_mission.url}} &nbsp;</span>
        </div>
        <div>
            <span class="link" (click)="modifyPaymentMission()">Modify Campaign Participant Mission</span>
        </div>
    </div>
</div>
<!-- <pre [innerHtml]="payment | stirfry"></pre> -->
