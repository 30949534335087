import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { IConfig } from '@amt/i-config';
import { ConfigService } from '@s/config.service';

export interface ICreateConfigOptions {
    configTypeName: string;
    label: string;
}

export interface IAddConfigDialogData {
    //createConfig: (options: IAddConfigDialogCreateConfigOptions) => Promise<IConfig>;
    configTypeName: string;
    getAvailableConfigs: () => Promise<{
        availableConfigs: IConfig[];
    }>;
}

@Component({
    selector: 'app-add-config-dialog',
    templateUrl: './add-config-dialog.component.html',
    styleUrls: ['./add-config-dialog.component.scss']
})
export class AddConfigDialogComponent implements OnInit {
    availableConfigs: Array<IConfig>;
    config: ICreateConfigOptions = {
        configTypeName: null,
        label: '',
    };

    constructor (
        public dialogRef: MatDialogRef<AddConfigDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddConfigDialogData,
        public configService: ConfigService,
    ) {
        this.config.configTypeName = data.configTypeName;
    }

    createNewConfig (options: ICreateConfigOptions): Promise<IConfig> {
        return this.configService.create(options).then((result) => {
            this.dialogRef.close(result.config);
            return result.config;
        });
    }

    ngOnInit (): void {
        this.data.getAvailableConfigs().then((result) => {
            console.log("Got available configs:", result);
            Object.assign(this, result);
        }).catch(console.error);
    }

    selectExisting (config: IConfig): void {
        this.dialogRef.close(config);
    }
}
