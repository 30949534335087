import {
    Component,
    OnInit
} from '@angular/core';

import {
    LoadBlockerService,
} from 'bkng';

import { MerchantService } from '@s/merchant.service';
import { UserService } from '@s/user.service';
import { SignInDialogService } from '../sign-in-dialog/sign-in-dialog.component';
import { IMerchantIndex } from '@arhat/i-merchant-index';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    busy: boolean = false;
    data: IMerchantIndex;
    env: IEnv;
    window: Window = window;

    constructor (
        public envService: EnvService,
        public signInDialogService: SignInDialogService,
        public merchantsService: MerchantService,
        public lbs: LoadBlockerService,
        public userService: UserService,
    ) {
        this.env = envService.data;

        const blocker = lbs.register({});

        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            blocker.unblock();
        });
    }

    connectSlackUrl (m: {id: string}): string {
        const base = `/auth/application/slack/connect?`;
        if (m?.id) {
            return `${base}merchant_id=${m.id}`;
        }
        return `${base}system=1`;
    }

    fetch (): Promise<unknown> {
        const env = this.env;
        if (
            env.user?.is_admin
            &&
            !env.merchant?.id
        ) {
            return this.merchantsService.index().then((res) => {
                console.log("HomeComponent:", res);
                this.data = Object.assign({}, res);
                console.log("Merchant:", this.data.merchants[0]);
                return Promise.resolve();
            }).catch((err: {
                error?: unknown;
            }) => {
                console.error("HomeComponent Error:", err?.error || '', err);
            });
        }
        console.log("HomeComponent didn't fetch.");
        return Promise.resolve();
    }

    fixPhoneNumbers (): Promise<void> {
        if (this.busy) {
            return Promise.resolve();
        }
        this.busy = true;
        return this.merchantsService.fixPhoneNumbers().then((res) => {
            console.log("Fixed phone numbers:", res);
            return Promise.resolve();
        }).catch((err: {
            error?: unknown;
        }) => {
            console.error("Error fixing phone numbers:", err?.error || '', err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
    }

    openSignInDialog (): void {
        const dialogRef = this.signInDialogService.open();
        dialogRef.afterClosed().subscribe((result) => {
            this.fetch().catch((err) => {
                console.error(err);
            });
        });
    }
}
