import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'stirfry',
    pure: false,
})
export class StirfryPipe implements PipeTransform {
    transform (
        obj: unknown,
        // ...args: unknown[] // potential future implementations
    ): string {
        const stringifiedJson = JSON.stringify(
            obj,
            null, 4
        );
        //console.log(stringifiedJson);
        return stringifiedJson;
    }
}
