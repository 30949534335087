import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';

import { CampaignService } from '@s/campaign.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-create-account-success',
    templateUrl: './create-account-success.component.html',
    styleUrls: ['./create-account-success.component.scss']
})
export class CreateAccountSuccessComponent implements OnInit {
    clearedToLoad: boolean = false;
    env: IEnv;
    loadBlocker: Blocker;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    ngOnInit (): void {
        setTimeout(() => {
            this.loadBlocker.unblock();
            this.clearedToLoad = true;
        });
    }
}
