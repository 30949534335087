import { CommonModule } from '@angular/common';
import {
    NgModule
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';

import { BusyDirective } from './busy.directive';
import { ButtonDirective } from './button.directive';
import { ClickDirective } from './click.directive';
import { CountryCodeToNamePipe } from './country-code-to-name.pipe';
import { CurrencyInputFilterDirective } from './currency-input-filter.directive';
import { DaysSinceDatePipe } from './days-since-date.pipe';
import { FormDirective } from './form.directive';
import { FriendlyDatePipe } from './friendly-date.pipe';
import { FriendlyDateTimePipe } from './friendly-date-time.pipe';
import { LoadBlockDirective } from './load-block.directive';
import { LoadBlockerDirective } from './load-blocker.directive';
import { LogTimePipe } from './log-time.pipe';
import { LogDatePipe } from './log-date.pipe';
import { PhoneFilterDirective } from './phone-filter.directive';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { ResponsiveIframeComponent } from './responsive-iframe/responsive-iframe.component';
import { SafePipe } from './safe.pipe';
import { SelectComponent } from './select/select.component';
import { SelectCountriesComponent } from './select-countries/select-countries.component';
import { SelectDiallingCodesComponent } from './select-dialling-codes/select-dialling-codes.component';
import { SelectDisplayDirective } from './select/select-display.directive';
import { SelectListHolderDirective } from './select/select-list-holder.directive';
import { SelectInputDirective } from './select/select-input.directive';
import { SelectItemDirective } from './select/select-item.directive';
import { SelectItemsDirective } from './select/select-items.directive';
import { StirfryPipe } from './stirfry.pipe';
import { FormControlDirective } from './form-control.directive';
import { FormControlHolderDirective } from './form-control-holder.directive';
import { FormServerErrorsComponent } from './form-server-errors/form-server-errors.component';
import { StyleBgImageUrlPipe } from './style-bg-image-url.pipe';
import { ValidationDisplayComponent } from './validation-display/validation-display.component';
import { ValidationDisplayElementComponent } from './validation-display-element/validation-display-element.component';
import { ValidationDisplayHintsComponent } from './validation-display-hints/validation-display-hints.component';
import { StripeElementsCardDirective } from './stripe-elements-card.directive';
import { TextInputFilterDirective } from './text-input-filter.directive';
import { TimeAgoPipe } from './time-ago.pipe';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ValidationDisplayHintsElementComponent } from './validation-display-hints-element/validation-display-hints-element.component';
import { WordCountDirective } from './word-count.directive';
import { WordCountPipe } from './word-count.pipe';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { ParseJsonStringPipe } from './parse-json-string.pipe';
import { LinkFixPipe } from './link-fix.pipe';
import { TimeDiffPipe } from './time-diff.pipe';

@NgModule({
    declarations: [
        BusyDirective,
        ButtonDirective,
        ClickDirective,
        CountryCodeToNamePipe,
        CurrencyInputFilterDirective,
        DaysSinceDatePipe,
        FormControlDirective,
        FormControlHolderDirective,
        FormDirective,
        FormServerErrorsComponent,
        FriendlyDatePipe,
        FriendlyDateTimePipe,
        LoadBlockDirective,
        LoadBlockerDirective,
        LogDatePipe,
        LogTimePipe,
        ParseJsonStringPipe,
        LinkFixPipe,
        PhoneFilterDirective,
        PhoneNumberInputComponent,
        ResponsiveIframeComponent,
        SafePipe,
        SelectComponent,
        SelectCountriesComponent,
        SelectDiallingCodesComponent,
        SelectDisplayDirective,
        SelectListHolderDirective,
        SelectInputDirective,
        SelectItemDirective,
        SelectItemsDirective,
        StirfryPipe,
        StripeElementsCardDirective,
        StyleBgImageUrlPipe,
        TextInputFilterDirective,
        TimeAgoPipe,
        UploadFileComponent,
        ValidationDisplayComponent,
        ValidationDisplayElementComponent,
        ValidationDisplayHintsComponent,
        ValidationDisplayHintsElementComponent,
        WordCountDirective,
        WordCountPipe,
        FileUploadButtonComponent,
        TimeDiffPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    exports: [
        BusyDirective,
        ButtonDirective,
        ClickDirective,
        CountryCodeToNamePipe,
        CurrencyInputFilterDirective,
        DaysSinceDatePipe,
        FileUploadButtonComponent,
        FormControlDirective,
        FormControlHolderDirective,
        FormDirective,
        FormServerErrorsComponent,
        FriendlyDatePipe,
        FriendlyDateTimePipe,
        LinkFixPipe,
        LoadBlockDirective,
        LoadBlockerDirective,
        LogDatePipe,
        LogTimePipe,
        ParseJsonStringPipe,
        PhoneFilterDirective,
        PhoneNumberInputComponent,
        ResponsiveIframeComponent,
        SafePipe,
        SelectComponent,
        SelectCountriesComponent,
        SelectDiallingCodesComponent,
        SelectDisplayDirective,
        SelectInputDirective,
        StirfryPipe,
        StripeElementsCardDirective,
        StyleBgImageUrlPipe,
        TextInputFilterDirective,
        TimeAgoPipe,
        UploadFileComponent,
        ValidationDisplayComponent,
        ValidationDisplayElementComponent,
        ValidationDisplayHintsComponent,
        ValidationDisplayHintsElementComponent,
        WordCountDirective,
        WordCountPipe,
        TimeDiffPipe,
    ],
})
export class BkngModule {
    constructor (
    ) {
    }
}
