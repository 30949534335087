import { Component, OnInit } from '@angular/core';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';
import { AdminService } from '@s/admin.service';
import { UserGetResult } from '@api-routes/admin/types/user-get-result';
import { IUser } from '@lib/model/types/i-user';
import { UserService } from '@s/user.service';
import { UserWriteBody } from '@api-routes/user/types/user-write-body';



@Component({
    selector: 'app-users-view',
    templateUrl: './users-view.component.html',
    styleUrl: './users-view.component.scss'
})
export class UsersViewComponent implements OnInit {
    blocker: Blocker;
    busy: boolean = false;
    data?: UserGetResult;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public adminService: AdminService,
        public userService: UserService,
    ) {
        this.blocker = lbs.register({});
    }

    async fetch (): Promise<void> {
        const response = await this.adminService.user();

        console.log("UsersViewComponent got:", response);

        this.data = response;
    }

    async ngOnInit (): Promise<void> {
        try {
            await this.fetch().catch((err) => {
                console.error(err);
            });
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.blocker.unblock();
        }
    }

    async saveUser (
        updatedUserObject: UserWriteBody["user"],
        existingUserObject: IUser,
    ): Promise<void> {
        this.busy = true;

        try {
            const response = await this.userService.save(existingUserObject.id, {
                user: updatedUserObject
            });

            console.log("Saved user:", response);

            Object.assign(existingUserObject, response.user);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.busy = false;
        }
    }
}
