import {
    Observable,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    Injectable
} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { ComPopComponent, ComPopOpt } from '@c/com-pop/com-pop.component';
import { EnvService } from '@s/env.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor (
        public envService: EnvService,
        private dialog: MatDialog,
    ) {
    }

    intercept (
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) =>  {
                const error = <{
                    code?: string;
                    message?: string;
                    title?: string;
                }>(err.error);

                if (
                    error?.code
                ) {
                    let title = `${err.status.toString()}: ${error.code}`;

                    if (
                        error?.title
                    ) {
                        title = error.title;
                    }

                    const body = error?.message || error?.code || "Sorry, something went wrong!";

                    const popOpts: ComPopOpt = {
                        title: title,
                        body: body,
                        actions: [
                            {
                                label: "OK",
                                action: (pop) => {
                                    pop.dialog.close();
                                },
                            }
                        ],
                    };

                    this.dialog.open(ComPopComponent, {
                        // width: '500px',
                        data: popOpts,
                    });
                }

                throw err;
            })
        );
    }
}
