<div class="checkout-section checkout-section-donor-info active">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title">
            <!-- <span class="num">{{number}}.</span> -->
            <span>Reset Password</span>
        </div>
    </div>
    <div class="checkout-section-subtitle-holder">
        <div class="checkout-section-subtitle">
            <span>Use the form below to reset your password.</span>
        </div>
    </div>

    <form
        name="ResetPasswordForm"
        bkForm
        #resetPasswordForm="bkForm"
        (bkSubmit)="submit(resetPasswordForm)"
    >
        <div class="input-holder" bkFormControlHolder>
            <div class="input-label" *ngIf="!options.password">Password</div>
            <input
                bkFormControl
                #passControl="bkFormControl"
                type="password"
                name="password"
                [(ngModel)]="options.password"
                required
                (keyup.enter)="resetPasswordForm.submit()"
                autocomplete="new-password"
                [validatorName]="'new-password'"
            >
            <bk-validation-display-hints
                [ngClass]="{
                    'bk-hidden': (!passControl.control?.dirty && !passControl.control?.touched && !resetPasswordForm.submissionAttempted)
                }"
            ></bk-validation-display-hints>
        </div>

        <bk-form-server-errors></bk-form-server-errors>

        <div class="button-holder">
            <app-button (action)="resetPasswordForm.submit()" [busy]="busy">Submit</app-button>
        </div>
    </form>
</div>
