import {
    ElementRef,
    Injectable,
    Renderer2
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    constructor () {

    }

    ensureElementOnScreen (element: ElementRef, renderer: Renderer2): () => void {
        const nativeElement = <HTMLElement>element.nativeElement;

        let rect = nativeElement.getBoundingClientRect();
        let diff = (rect.x + rect.width) - window.innerWidth;

        if (diff >= -15) {
            renderer.setStyle(nativeElement, 'left', `auto`);
            renderer.setStyle(nativeElement, 'right', `0px`);

            rect = nativeElement.getBoundingClientRect();

            diff = (rect.x + rect.width) - window.innerWidth;

            const pos = diff + 30;

            renderer.setStyle(nativeElement, 'right', `${pos}px`);
        }

        return () => {
            renderer.setStyle(nativeElement, 'left', null);
            renderer.setStyle(nativeElement, 'right', null);
        };
    }
}
