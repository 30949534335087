import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    LoadBlockerService,
} from 'bkng';
import { CampaignService } from '@s/campaign.service';

import { DonateComponent } from '../donate/donate.component';
import { CampaignGetResult } from '@api-routes/campaign/types/campaign-get-result';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-crowdfund-participant-donate',
    templateUrl: './crowdfund-participant-donate.component.html',
    styleUrls: ['./crowdfund-participant-donate.component.scss']
})
export class CrowdfundParticipantDonateComponent extends DonateComponent {
    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
    ) {
        super(envService, lbs, route, campaignService);
    }

    completed (): boolean {
        if (this.donation.campaignParticipant?.id) {
            return true;
        }
        return false;
    }

    fetch (): Promise<void> {
        const getOpts: {
            id: string;
            participantUrl?: string;
            missionUrl?: string;
        } = {
            id: this.env.data.campaign?.id,
        };
        if (this.route.snapshot.params.campaignParticipantUrl) {
            getOpts.participantUrl = <string>(this.route.snapshot.params.campaignParticipantUrl);
        }
        if (this.route.snapshot.params.campaignParticipantMissionUrl) {
            getOpts.missionUrl = <string>(this.route.snapshot.params.campaignParticipantMissionUrl);
        }
        return this.campaignService.getById(getOpts).then((res: CampaignGetResult) => {
            if (this.env.data.mode !== 'production') {
                console.log("CrowdfundParticipantDonateComponent:", res);
            }
            this.data = res;
            if (res.mission?.id) {
                this.donation.campaignParticipantMission = res.mission;
                this.donation.campaignParticipantMissionUrl = res.mission.url;
            }
            if (res.participant?.id) {
                this.donation.campaignParticipant = res.participant;
                this.donation.campaignParticipantUrl = res.participant.url;
            }
            return Promise.resolve();
        });
    }
}
