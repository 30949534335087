import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SlackTeamChannels } from '@arhat/slack-team-channels';

import {
    KCache,
    RestService,
} from 'bkng';
import { lastValueFrom } from 'rxjs';
import { EnvService } from './env.service';

type GatherData = {
    slacks: SlackTeamChannels[];
};

@Injectable({
    providedIn: 'root'
})
export class IntegrationService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `integration`;
    }

    slackChannelsCache = new KCache<SlackTeamChannels, GatherData>((integrationIds) => {
        return lastValueFrom(
            this.$get(`/slack/channels`, {
                params: {
                    integration_ids: integrationIds.join(',')
                }
            })
        ).then((result: unknown) => {
            return <GatherData>result;
        });
    }, (cached, result) => {
        result.slacks.forEach((item) => {
            cached[item.id] = item;
        });
        return Promise.resolve();
    });

    getSlackChannels (integrationId: string): Promise<SlackTeamChannels> {
        return this.slackChannelsCache.get(integrationId).then((result) => {
            return result;
        });
    }
}
