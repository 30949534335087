import { lastValueFrom, Observable } from 'rxjs';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    FormDirective,
    UploadFileComponent
} from 'bkng';

import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { ICampaignParticipantMission } from '@amt/i-campaign-participant-mission';
import { IUser } from '@amt/i-user';
import { UserService } from '@s/user.service';
import { NgForm } from '@angular/forms';
import { IEnv } from '@alt/i-env';
import { AccountGetResult } from '@api-routes/account/types/account-get-result';
import { IPerson } from '@amt/i-person';
import { IMission } from '@amt/i-mission';
import { ICampaign } from '@amt/i-campaign';
import { EnvService } from '@s/env.service';
import { UserWriteResult } from '@api-routes/user/types/user-write-result';
// import { ICountry } from '@amt/i-country';

@Component({
    selector: 'app-campaign-participant-profile',
    templateUrl: './campaign-participant-profile.component.html',
    styleUrls: ['./campaign-participant-profile.component.scss']
})
export class CampaignParticipantProfileComponent implements OnInit {
    @Input() campaign: ICampaign;
    @Input() campaignParticipant: ICampaignParticipant;
    @Input() campaignParticipantMission: ICampaignParticipantMission;
    @Input() mission: IMission;
    // country: ICountry;
    @Output() createdMission = new EventEmitter<unknown>();
    env: IEnv;
    @Input() participant: IUser;
    @Input() person: IPerson;
    previewProfilePhotoData: unknown;
    saved: boolean = false;
    saving: boolean = false;
    tab: string = 'profile';
    @Output() uploadedProfilePhoto = new EventEmitter<unknown>();
    uploadFileOptions: {
        fileToUpload: File;
    };
    uploadProfilePhoto: (options: {
        fileToUpload: File;
    }) => Observable<unknown>;

    constructor (
        public envService: EnvService,
        public userService: UserService,
    ) {
        this.env = envService.data;
    }

    getCountryObject (cc: string): unknown {
        if (!(cc && this.env?.countries)) return;
        return this.env.countries.filter((c) => { return c.code.toUpperCase() === cc.toUpperCase(); })[0];
    }

    ngOnInit (): void {
        this.uploadProfilePhoto = (options: {
            fileToUpload: File;
        }): Observable<unknown> => {
            return this.userService.uploadProfilePhoto(this.participant.id, options);
        };

        if (
            !this.person?.id
        ) {
            throw new Error(`CampaignParticipantProfileComponent: No person id detected in person object!`);
        }

        this.person.phone = this.person.phone || {};
        this.person.phone.country_code = this.person.phone.country_code || this.env.geo.country || 'US';
        this.person.phone.value = this.person.phone.value || '';
    }

    profilePhotoFileSelected (form: NgForm, uploadFileComponent: UploadFileComponent): void {
        this.uploadFileOptions = uploadFileComponent;
        if (FileReader && uploadFileComponent.fileToUpload) {
            const fr = new FileReader();
            fr.onload = (whateverThisIs) => {
                this.previewProfilePhotoData = whateverThisIs.target.result;
            };
            fr.readAsDataURL(uploadFileComponent.fileToUpload);
        }
        else if (!uploadFileComponent.fileToUpload) {
            this.previewProfilePhotoData = null;
        }
        form.form.markAsDirty();
    }

    $save (): Promise<UserWriteResult> {
        if (this.saving) {
            return Promise.resolve(<UserWriteResult>null);
        }
        this.saved = false;
        this.saving = true;
        const data: AccountGetResult = {
            campaignParticipant: this.campaignParticipant,
            campaignParticipantMission: this.campaignParticipantMission,
            user: this.participant,
        };
        return Promise.resolve().then(() => {
            if (this.previewProfilePhotoData) {
                return lastValueFrom(this.uploadProfilePhoto(this.uploadFileOptions)).then((result) => {
                    return result;
                });
            }
            return Promise.resolve();
        }).then(() => {
            return this.userService.save(data.user.id, {
                campaignParticipant: data.campaignParticipant,
                campaignParticipantMission: data.campaignParticipantMission,
                person: data.person,
                user: data.user,
            });
        }).then((res) => {
            console.log("Saved", res);
            this.saved = true;
            return res;
        }).finally(() => {
            setTimeout(() => {
                this.saving = false;
            }, 350);
        });
    }

    save (form: FormDirective): Promise<UserWriteResult> {
        if (
            !form.ngForm.valid
            ||
            !form.ngForm.dirty
            ||
            this.saving
        ) {
            return Promise.resolve(<UserWriteResult>undefined);
        }
        return this.$save().then((res) => {
            form.ngForm.form.markAsPristine();
            return res;
        }).catch((err) => {
            console.error(err);
            return <UserWriteResult>undefined;
        });
    }

    toggleVisible (form: NgForm): void {
        this.campaignParticipant.visible = !this.campaignParticipant.visible;
        form.form.markAsDirty();
    }

    uploadedUserProfilePhoto (event: unknown): void {
        this.uploadedProfilePhoto.emit(event);
    }
}
