<div class="header-holder">
    <div class="header-bg">
        <div class="header">
            <div>
                <div class="header-inner">
                    <a *ngIf="env.merchant.logo_file?.id" [routerLink]="['/']">
                        <img src="{{env.merchant | merchantLogoUrl}}" bkLoadBlocker>
                    </a>
                    <span *ngIf="!env.merchant.logo_file?.id" style="font-weight: 400; font-size: 28px; padding: 32px 0px;">{{env.merchant.name}}</span>
                </div>
            </div>

            <div class="controls" *ngIf="showAccountButtons">
                <div *ngIf="env?.user?.id">
                    <a class="button callout plain" [routerLink]="['/', 'account']">Account</a>
                </div>
                <div *ngIf="!env?.user?.id">
                    <div class="button callout plain" (bkClick)="openSignInDialog({})">Log-in</div>
                </div>
                <div *ngIf="!env?.user?.id">
                    <a class="button callout primary" [routerLink]="['/', 'signup']">Sign Up!</a>
                </div>
            </div>

            <div class="controls" *ngIf="showCampaignAdminNav && env.merchantUserRoles?.admin">
                <app-campaign-admin-navigation></app-campaign-admin-navigation>
            </div>
        </div>
    </div>
</div>
