<!--
    
    ** NOTICE **

    THIS SHOULD BE DEPRECATED. USE INSTEAD: bk-file-upload-button component !

    ** NOTICE **

-->

<div class="upload-file-component">
    <div *ngIf="!uploadingFile && !uploadedFileSuccessfully">
        <div *ngIf="!fileToUpload">
            <input
                #fileInput
                hidden="true"
                type="file"
                onclick="this.value=null"
                (change)="fileChanged($event)"
                [accept]="accept"
                style="opacity: 0;"
            />
            <button
                type="button"
                [ngClass]="uploadingFile ? 'disabled' : 'primary'"
                (click)="clickFileInput(fileInput)"
                [disabled]="uploadingFile || disabled"
            ><ng-content></ng-content></button>
        </div>

        <div *ngIf="fileToUpload && !uploadingFile">
            <div style="display: flex; align-items: center;">
                <button
                    *ngIf="showUploadButton"
                    [ngClass]="uploadingFile ? 'disabled' : 'primary'"
                    (click)="uploadFile()"
                    [disabled]="disabled"
                >
                    <span>Upload {{fileToUpload.name}}</span>
                </button>
                <div style="padding-left: 15px;">
                    <button class="warn" (click)="clearFile()">
                        <span>Clear</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="uploadingFile">
        <span>Uploading {{fileToUpload.name}} ... {{uploadFileProgressPercent}}%</span>
    </div>

    <div *ngIf="uploadedFileSuccessfully">
        <span>Successfully uploaded <b>{{fileToUpload.name}}</b></span>
    </div>

    <div *ngIf="uploadFileError">
        <div class="error">Error uploading file:</div>
        <pre [innerHtml]="uploadFileError | stirfry"></pre>
    </div>
</div>
