<form bkForm #signInForm="bkForm" (bkSubmit)="signIn(signInForm)">

    <div class="input-holder" bkFormControlHolder>
        <div class="input-label" *ngIf="!credentials.email">Email</div>
        <input
            type="email"
            name="email"
            [(ngModel)]="credentials.email"
            required
            (keyup.enter)="signInForm.submit()"
            bkFormControl
        />
        <bk-validation-display></bk-validation-display>
    </div>

    <div class="input-holder" bkFormControlHolder>
        <div class="input-label" *ngIf="!credentials.password">Password</div>
        <input
            type="password"
            name="password"
            [(ngModel)]="credentials.password"
            required
            (keyup.enter)="signInForm.submit()"
            bkFormControl
        />
        <div class="under-password-input">
            <div>
                <bk-validation-display></bk-validation-display>
            </div>
            <div class="forgot-pass">
                <span class="link" (bkButton)="forgotPass()">Forgot password</span>
            </div>
        </div>
    </div>
    
    <bk-form-server-errors></bk-form-server-errors>
</form>
<div class="button-holder">
    <app-button (action)="signInForm.submit()" [busy]="busy">Submit</app-button>
</div>
