import { IMerchant } from '@amt/i-merchant';
import { Component, Input, OnInit } from '@angular/core';
import { MerchantService } from '@s/merchant.service';

@Component({
    selector: 'app-test-slack-channel',
    templateUrl: './test-slack-channel.component.html',
    styleUrls: ['./test-slack-channel.component.scss']
})
export class TestSlackChannelComponent implements OnInit {
    @Input() channel: string;
    @Input() merchant: IMerchant;
    sendingSlackTestMessage: boolean = false;
    slackTestMessageText: string = "";

    constructor (
        public merchantsService: MerchantService,
    ) { }

    ngOnInit (): void {
    }

    sendSlackTestMessage (): void {
        if (this.sendingSlackTestMessage || !this.slackTestMessageText) {
            return;
        }
        this.sendingSlackTestMessage = true;
        const options: {
            channel?: string;
            messageText: string;
        } = {
            messageText: this.slackTestMessageText,
        };
        if (this.channel) {
            options.channel = this.channel;
        }
        this.merchantsService.sendSlackTestMessage(this.merchant.id, options).then((res) => {
            console.log(`Slack test message response:`, res);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.sendingSlackTestMessage = false;
        });
    }

}
