import { Pipe, PipeTransform } from '@angular/core';
// import * as moment from 'moment';

@Pipe({
    name: 'friendlyDate',
    pure: false,
})
export class FriendlyDatePipe implements PipeTransform {
    transform (
        date: unknown, // moment.MomentInput,
    ): string {
        // return moment(date).local().format('MMMM Do, YYYY');
        return "DATE-DATE-DATE"; // retired moment.
    }
}
