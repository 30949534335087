<h2 mat-dialog-title>Error!</h2>

<mat-dialog-content
>
    <div>An error was thrown! Please check the JavaScript console.</div>
    <div *ngIf="data?.error?.message" style="padding-top: 20px;">{{data.error.message}}</div>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-raised-button
        [color]="'warn'"
        (click)="close()"
    >
        <span>OK</span>
    </button>
</mat-dialog-actions>
