import { ICampaign } from '@amt/i-campaign';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CampaignService } from '@s/campaign.service';
import { AdminService } from '@s/admin.service';

export interface IDeleteCampaignParticipantDialogData {
    campaign: ICampaign;
    campaignParticipant: ICampaignParticipant;
}

@Component({
    selector: 'app-delete-campaign-participant-modal',
    templateUrl: './delete-campaign-participant-modal.component.html',
    styleUrls: ['./delete-campaign-participant-modal.component.scss']
})
export class DeleteCampaignParticipantModalComponent implements OnInit {
    deleted: boolean = false;


    constructor (
        public dialogRef: MatDialogRef<DeleteCampaignParticipantModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDeleteCampaignParticipantDialogData,
        public campaignService: CampaignService,
        public adminService: AdminService
    ) {}

    closeDialog (): void {
        this.dialogRef.close(false);
    }

    deleteParticipant (): Promise<unknown> {
        this.data.campaignParticipant.id;
        return this.adminService.deleteCampaignParticipant(this.data.campaignParticipant.campaign_id, this.data.campaignParticipant.id).then((response) => {
            console.log("deleted participant", response);
            this.deleted = true;
            this.dialogRef.close(true);
            return <unknown>response;
        }).catch((err) => {
            console.error(err);
        });
    }

    ngOnInit (): void {
    }

}
