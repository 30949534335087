import { ICampaign } from '@amt/i-campaign';
import { IOrder } from '@amt/i-order';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IModifyPaymentMissionReturnData } from '@c/modify-payment-mission-dialog/modify-payment-mission-dialog.component';

@Component({
    selector: 'app-merchant-order-list-item',
    templateUrl: './merchant-order-list-item.component.html',
    styleUrls: ['./merchant-order-list-item.component.scss']
})
export class MerchantOrderListItemComponent implements OnInit {
    @Input() campaigns: Array<ICampaign> = [];
    @Output() modifiedPaymentMission = new EventEmitter<IModifyPaymentMissionReturnData>();
    @Input() open: boolean = false;
    @Input() order: IOrder;

    constructor () { }

    ngOnInit (): void {
    }

}
