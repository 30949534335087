import { lastValueFrom, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';

import {
    RestService
} from 'bkng';

import { IConfig } from '@amt/i-config';
import { IConfigDefaultOrganization } from '@amt/i-config-default-organization';
import { IOrganization } from '@amt/i-organization';
import { ICountry } from '@amt/i-country';
import { IEmailTemplate } from '@amt/i-email-template';
import { IConfigEmailTemplate } from '@amt/i-config-email-template';
import { IConfigCurrency } from '@amt/i-config-currency';
import { ICurrency } from '@amt/i-currency';
import { IConfigCountry } from '@amt/i-config-country';
import { IConfigPaymentProcessorAccount } from '@amt/i-config-payment-processor-account';
import { IPaymentProcessorAccount } from '@amt/i-payment-processor-account';
import { IConfigCampaign } from '@amt/i-config-campaign';
import { ICampaign } from '@amt/i-campaign';
import { IConfigPage } from '@amt/i-config-page';
import { IPage } from '@amt/i-page';
import { EnvService } from './env.service';
import { ConfigGetResult } from '@api-routes/config/types/config-get-result';
import { ConfigGetQuery } from '@api-routes/config/types/config-get-query';
import { ConfigGetOptions } from '@api-routes/config/types/config-get-options';
import { UploadBannerPhotoOptions } from '@api-routes/config/types/upload-banner-photo-options';
import { UploadBannerPhotoQuery } from '@api-routes/config/types/upload-banner-photo-query';
import { ConfigPutBody } from '@api-routes/config/types/config-put-body';
import { ConfigPutQuery } from '@api-routes/config/types/config-put-query';
import { ConfigPutResult } from '@api-routes/config/types/config-put-result';

@Injectable({
    providedIn: 'root'
})
export class ConfigService extends RestService {
    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        super(http, envService);
        this.namespace = `config`;
    }

    addCountryDefaultOrganization (
        configId: string,
        countryCode: string,
        organizationId: string
    ): Promise<{
        config: IConfig,
        configDefaultOrganization: IConfigDefaultOrganization;
        country: ICountry;
        organization: IOrganization;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/country/code/${countryCode}/default/organization/id/${organizationId}/add`
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configDefaultOrganization: IConfigDefaultOrganization;
                country: ICountry;
                organization: IOrganization;
            }>result;
        });
    }

    addDefaultOrganization (configId: string, organizationId: string): Promise<{
        config: IConfig,
        configDefaultOrganization: IConfigDefaultOrganization;
        organization: IOrganization;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/default/organization/id/${organizationId}/add`
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configDefaultOrganization: IConfigDefaultOrganization;
                organization: IOrganization;
            }>result;
        });
    }

    addEmailTemplate (configId: string, emailTemplateId: string): Promise<{
        config: IConfig,
        configEmailTemplate: IConfigEmailTemplate;
        emailTemplate: IEmailTemplate;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/email_template/id/${emailTemplateId}/add`
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configEmailTemplate: IConfigEmailTemplate;
                emailTemplate: IEmailTemplate;
            }>result;
        });
    }

    create (
        options: unknown
    ): Promise<{config: IConfig}> {  // : Observable<ArrayBuffer>
        return lastValueFrom(
            this.$post(
                `create`,
                options,
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
            }>result;
        });
    }

    createNewPage (configId: string): Promise<{
        config: IConfig,
        configPage: IConfigPage;
        page: IPage;
    }> {
        return lastValueFrom(
            this.$post(`id/${configId}/page/create`, {})
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configPage: IConfigPage;
                page: IPage;
            }>result;
        });
    }

    getById (
        options: ConfigGetOptions
    ): Promise<ConfigGetResult> {
        const params: ConfigGetQuery = {};

        if (options.id) {
            params.id = options.id;
        }

        if (options.domainId) {
            params.domain_id = options.domainId;
        }

        return lastValueFrom(
            this.$get(
                ``,
                {
                    params
                }
            )
        ).then((result: unknown) => {
            return <ConfigGetResult>result;
        });
    }

    async save (
        id: string,
        data: ConfigPutBody,
    ): Promise<ConfigPutResult> {
        const params: ConfigPutQuery = {
            id: id,
        };

        const result = await lastValueFrom(
            this.$put(
                ``,
                data,
                {
                    params: params,
                }
            )
        );

        return <ConfigPutResult><unknown>result;
    }

    setCampaign (
        configId: string,
        campaignId: string
    ): Promise<{
        campaign: ICampaign;
        config: IConfig,
        configCampaign: IConfigCampaign;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/campaign/id/${campaignId}/set`,
            )
        ).then((result: unknown) => {
            return <{
                campaign: ICampaign;
                config: IConfig,
                configCampaign: IConfigCampaign;
            }>result;
        });
    }

    setCountry (configId: string, countryCode: string): Promise<{
        config: IConfig,
        configCountry: IConfigCountry;
        country: ICountry;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/country/code/${countryCode}/set`,
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configCountry: IConfigCountry;
                country: ICountry;
            }>result;
        });
    }

    setCurrency (
        configId: string,
        currencyCode: string,
    ): Promise<{
        config: IConfig,
        configCurrency: IConfigCurrency;
        currency: ICurrency;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/currency/code/${currencyCode}/set`,
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configCurrency: IConfigCurrency;
                currency: ICurrency;
            }>result;
        });
    }

    setPaymentProcessorAccount (
        configId: string,
        paymentProcessorAccountId: string
    ): Promise<{
        config: IConfig,
        configPaymentProcessorAccount: IConfigPaymentProcessorAccount;
        paymentProcessorAccount: IPaymentProcessorAccount;
    }> {
        return lastValueFrom(
            this.$post(
                `id/${configId}/payment_processor_account/id/${paymentProcessorAccountId}/set`,
            )
        ).then((result: unknown) => {
            return <{
                config: IConfig,
                configPaymentProcessorAccount: IConfigPaymentProcessorAccount;
                paymentProcessorAccount: IPaymentProcessorAccount;
            }>result;
        });
    }

    uploadBannerPhoto (
        options: UploadBannerPhotoOptions & {
            fileToUpload: File;
        },
    ): Observable<unknown> {
        const formData: FormData = new FormData();
        formData.append('fileKey', options.fileToUpload, options.fileToUpload.name);

        const queryParams: UploadBannerPhotoQuery = {
            config_id: options.configId,
        };

        return this.$post(
            `/banner-photo`,
            formData,
            {
                // responseType: `blob`,
                reportProgress: true,
                observe: `events`,
                params: queryParams,
            }
        );
    }
}
