<div class="donate showing" *ngIf="clearedToLoad">
    <app-crowdfund-header
        [showAccountButtons]="false"
        [showCampaignAdminNav]="env.merchantUserRoles?.admin"
    ></app-crowdfund-header>

    <div class="crowdfund-admin-common-padding crowdfund-admin-nav-breadcrumbs">
        <app-nav-breadcrumbs></app-nav-breadcrumbs>
    </div>

    <div class="crowdfund-admin-common-padding">
        <div class="crowdfund-admin-section-title-holder">
            <div class="crowdfund-admin-section-title">
                <span>Administration</span>
            </div>
        </div>

        <div>
            <span>Use the navigation at top-right to select an admin section.</span>
        </div>
    </div>
</div>
