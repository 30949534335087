<div class="donate showing" *ngIf="clearedToLoad">
    <app-header-holder></app-header-holder>

    <div class="checkout-holder">
        <div class="checkout-content">
            <div class="checkout-section checkout-section-donor-info active">
                <div class="checkout-section-title-holder">
                    <div class="checkout-section-title">
                        <!-- <span class="num">{{number}}.</span> -->
                        <span>Success!</span>
                    </div>
                </div>
                <div class="checkout-section-subtitle-holder">
                    <div class="checkout-section-subtitle">
                        <span>Account created.</span>
                    </div>
                </div>
                
                <div>
                    <span>Please check your e-mail to confirm your account.</span>
                </div>

                <div class="" style="display: flex; padding-top: 25px;">
                    <a class="button callout primary" href="/account">OK</a>
                </div>
            </div>
        </div>
    </div>
</div>
