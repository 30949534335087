import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EnvService } from '@s/env.service';

import { IEnv } from '@alt/i-env';
import { IMerchant } from '@amt/i-merchant';

import { MerchantService } from '@s/merchant.service';
import { IMerchantHubspotIndex } from '@arhat/i-merchant-hubspot-index';
import { IHubspotProperty } from '@bk/lib/hubspot/types/i-hubspot-property';
import { IHubspotIntegration } from '@amt/i-hubspot-integration';
import { IConfigCampaignIntegrationHubspot } from '@amt/i-config-campaign-common';

@Component({
    selector: 'app-campaign-edit-config-hubspot',
    templateUrl: './campaign-edit-config-hubspot.component.html',
    styleUrls: ['./campaign-edit-config-hubspot.component.scss']
})
export class CampaignEditConfigHubspotComponent implements OnInit {
    busy: boolean = false;
    @Input() config: IConfigCampaignIntegrationHubspot;
    @Input() integration: IHubspotIntegration;
    merchantHubspotIndex: IMerchantHubspotIndex;
    env: IEnv;
    @Input() merchant: IMerchant;
    propertiesContacts: Array<IHubspotProperty>;
    propertiesContactsFiltered: Array<IHubspotProperty>;
    propertiesDeals: Array<IHubspotProperty>;
    propertiesDealsFiltered: Array<IHubspotProperty>;
    @Output() updated = new EventEmitter<unknown>();

    constructor (
        public envService: EnvService,
        public merchantsService: MerchantService,
    ) {
        this.env = envService.data;
    }

    changedDonationPipeline (): void {
        this.setDonationPipeline();
        this.updated.emit();
    }

    changedExistenceHubspotProperty (): void {
        this.setExistenceHubspotProperty();
        this.updated.emit();
    }

    changedMissionSupporterHubspotProperty (): void {
        this.setMissionSupporterHubspotProperty();
        this.updated.emit();
    }

    changedMtMissionHubspotProperty (): void {
        this.setMtMissionHubspotProperty();
        this.updated.emit();
    }

    changedParticipationPipeline (): void {
        this.setParticipationPipeline();
        this.updated.emit();
    }

    clearMissionSyncSettings (): void {
        this.config.sync.mission.pipeline = {};

        delete this.config.sync.mission.productId;

        this.updated.emit();
    }

    getHubspotIndex (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.getHubspotIndex(this.merchant.id, this.integration.id).then((result) => {
            console.log("Merchant's Hubspot:", result);
            this.merchantHubspotIndex = result;

            this.propertiesContacts = result.properties.contacts.results || [];
            this.propertiesContactsFiltered = this.propertiesContacts.filter((item) => {
                return !item.hubspotDefined;
            });

            this.propertiesDeals = result.properties.deals.results;
            this.propertiesDealsFiltered = this.propertiesDeals.filter((item) => {
                return !item.hubspotDefined;
            });

            this.setMtMissionHubspotProperty();
            this.setDonationPipeline();
            this.setParticipationPipeline();
            this.setExistenceHubspotProperty();

            console.log("Hubspot Properties:", this.propertiesContactsFiltered);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        this.config.sync = this.config.sync || {};

        this.config.sync.donation = this.config.sync.donation || {};
        this.config.sync.donation.lost = this.config.sync.donation.lost || {};
        this.config.sync.donation.received = this.config.sync.donation.received || {};
        this.config.sync.donation.productIds = this.config.sync.donation.productIds || {};
        this.config.sync.existence = this.config.sync.existence || {};

        this.config.sync.mission = this.config.sync.mission || {};

        this.config.sync.mission.status = this.config.sync.mission.status || {};
        this.config.sync.mission.status.options = this.config.sync.mission.status.options || {};

        this.config.sync.mission.pipeline = this.config.sync.mission.pipeline || {};
        this.config.sync.mission.pipeline.initialized = this.config.sync.mission.pipeline.initialized || {};
        this.config.sync.mission.pipeline.preInitialization = this.config.sync.mission.pipeline.preInitialization || {};
        this.config.sync.mission.pipeline.raised = this.config.sync.mission.pipeline.raised || {};

        this.config.sync.donation.association = this.config.sync.donation.association || {};
        this.config.sync.donation.association.dealsToContacts = this.config.sync.donation.association.dealsToContacts || {};

        this.config.sync.mission.association = this.config.sync.mission.association || {};
        this.config.sync.mission.association.dealsToContacts = this.config.sync.mission.association.dealsToContacts || {};

        this.config.sync.mission.url = this.config.sync.mission.url || {};

        this.config.sync.missionSupporter = this.config.sync.missionSupporter || {};

        this.getHubspotIndex();
    }

    setContactPropertySettingUsingPropertyName (
        settingObject: {
            property?: IHubspotProperty;
            propertyName?: string;
        },
        propertyName: string,
    ): void {
        settingObject.propertyName = propertyName;
        if (propertyName) {
            settingObject.property = this.propertiesContactsFiltered.filter((item) => {
                return item.name === propertyName;
            })[0];
        }
    }

    setDonationPipeline (): void {
        if (this.config.sync.donation.pipelineId) {
            this.config.sync.donation.pipeline = this.merchantHubspotIndex.pipelines.deals.filter((item) => {
                return item.id === this.config.sync.donation.pipelineId;
            })[0];
        }
    }

    setExistenceHubspotProperty (): void {
        this.setContactPropertySettingUsingPropertyName(this.config.sync.existence, this.config.sync.existence.propertyName);
    }

    setMissionSupporterHubspotProperty (): void {
        this.setContactPropertySettingUsingPropertyName(this.config.sync.missionSupporter, this.config.sync.missionSupporter.propertyName);
    }

    setMtMissionHubspotProperty (): void {
        if (this.config.sync.mission.status.propertyName) {
            this.config.sync.mission.status.property = this.propertiesContactsFiltered.filter((item) => {
                return item.name === this.config.sync.mission.status.propertyName;
            })[0];
        }

        if (this.config.sync.mission.url.propertyName) {
            this.config.sync.mission.url.property = this.propertiesDealsFiltered.filter((item) => {
                return item.name === this.config.sync.mission.url.propertyName;
            })[0];
        }
    }

    setParticipationPipeline (): void {
        if (this.config.sync.mission.pipeline.pipelineId) {
            this.config.sync.mission.pipeline.pipeline = this.merchantHubspotIndex.pipelines.deals.filter((item) => {
                return item.id === this.config.sync.mission.pipeline.pipelineId;
            })[0];
        }
    }

    syncContactsWithHubspot (): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.merchantsService.syncContactsWithHubspot(this.merchant.id, this.integration.id).then((result) => {
            console.log("Kicked-off sync:", result);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.busy = false;
        });
    }
}
