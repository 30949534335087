import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';

/*import {
    Subject
} from 'rxjs';*/

@Directive({
    selector: '[bkClick]'
})
export class ClickDirective {
    @Input() bkBusy: boolean = false;

    // @Input() bkClick: () => Observable<any> | Promise<any> = async() => void 0;  // Interesting.

    @Output() bkClick = new EventEmitter<unknown>();

    constructor () {
    }

    @HostListener('click', ['$event'])
    clicked (event: unknown): void {
        if (this.bkBusy) {
            return;
        }
        this.bkClick.emit(event);
    }
}
