import { BkRoutes } from 'bkng';

import { outletPlatform } from '@ngr/outlet-platform';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { DomainEmailViewComponent } from '@c/domain-email-view/domain-email-view.component';

export const id: BkRoutes = [
    {
        path: ":emailTemplateId",
        label: "Domain Email",
        ngRoute: routeStartingPoint([
            outletPlatform({component: DomainEmailViewComponent}),
        ]),
        children: [

        ],
    },
];
