import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SignInDialogData, SignInDialogService } from '@c/sign-in-dialog/sign-in-dialog.component';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';
import { ICampaignParticipant } from '@amt/i-campaign-participant';

@Component({
    selector: 'app-crowdfund-header',
    templateUrl: './crowdfund-header.component.html',
    styleUrls: ['./crowdfund-header.component.scss']
})
export class CrowdfundHeaderComponent implements OnInit {
    env: IEnv;
    @Input() showAccountButtons: boolean = true;
    @Input() showCampaignAdminNav: boolean = false;

    constructor (
        public envService: EnvService,
        public signInDialogService: SignInDialogService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }

    openSignInDialog (options: SignInDialogData = {}): void {
        const dialogRef = this.signInDialogService.open(options);
        dialogRef.afterClosed().subscribe((result: {
            campaignParticipant?: ICampaignParticipant;
        }) => {
            if (!options?.redirect && result?.campaignParticipant?.url) {
                this.router.navigate([`/${result.campaignParticipant.url}`], {
                    // relativeTo: this.route,
                    queryParams: {},
                }).catch((err) => {
                    console.error(err);
                });
            }
        });
    }
}
