import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ILoginData } from '@alt/i-login-data';

import {
    Blocker,
    LoadBlockerService,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-account-email-confirm',
    templateUrl: './account-email-confirm.component.html',
    styleUrls: ['./account-email-confirm.component.scss']
})
export class AccountEmailConfirmComponent implements OnInit {
    busy: boolean = false;
    clearedToLoad: boolean = true;
    env: IEnv;
    loadBlocker: Blocker;
    options: ILoginData = {};
    serverErrorMessages: Array<string>;

    constructor (
        public envService: EnvService,
        public userService: UserService,
        public recaptchaService: RecaptchaService,
        public route: ActivatedRoute,
        public lbs: LoadBlockerService,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    confirm (): void {
        if (this.busy) {
            return;
        }

        if (this.route.snapshot.queryParams.token) {
            this.options.token = <string>(this.route.snapshot.queryParams.token);
        }

        if (this.route.snapshot.queryParams.user_id) {
            this.options.userId = <string>(this.route.snapshot.queryParams.user_id);
        }

        this.busy = true;

        Promise.resolve().then(() => {
            return this.userService.confirmEmail(this.options);
        }).then((result) => {
            console.log("Email confirmed!", result);
        }).catch((err: {
            error?: {
                messages?: string[];
            };
            message?: string;
        }) => {
            console.error(err);
            this.serverErrorMessages = err?.error?.messages ? err.error.messages : [err.message];
        }).finally(() => {
            this.busy = false;
        });
    }

    ngOnInit (): void {
        setTimeout(() => {
            this.loadBlocker.unblock();
            this.clearedToLoad = true;
            this.confirm();
        });
    }
}
