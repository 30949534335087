<div class="platform-view-component" *ngIf="data?.campaign?.id">
    <div>Campaign: {{data.campaign.label}}: Participants ({{data.participants?.length || 0}})</div>

    <div style="padding-top: 25px;">
        <app-add-campaign-participant
            [data]="{route: route}"
            (created)="addedCampaignParticipant($event)"
        >
            <span>Add Campaign Participant</span>
        </app-add-campaign-participant>
    </div>

    <div style="padding-top: 15px;" *ngIf="data.participants?.length">
        <div style="padding-top: 25px;">Participants:</div>
        <div *ngFor="let p of data.participants" style="padding: 8px 0px; font-size: 13px; border-bottom: 1px solid lightgrey;">
            <div>
                <a [routerLink]="['id', p.user.id]">{{p.user.name || p.user.email?.[0]?.value || p.id}} | {{p.user.email?.[0]?.value || '[NO EMAIL?]'}}</a>
            </div>
            <div style="color: #444; font-size: 12px; padding-top: 3px;" *ngIf="p.missions">
                <div>Missions: {{p.missions.length}} | Payments: {{p.numMissionPayments}}</div>
            </div>
            <div style="color: grey; font-size: 12px; padding-top: 3px;">
                <div>User ID: {{p.user.id}}</div>
                <div>Person ID: {{p.id}}</div>
            </div>
        </div>
    </div>
</div>
