<div *ngIf="!success">
    <div class="title-holder" *ngIf="!showWelcomeBack">
        <div class="title">Sign in</div>
    </div>
    <div class="title-holder" *ngIf="showWelcomeBack">
        <div class="title">Welcome back!</div>
        <div class="subtext">Please sign-in to continue</div>
    </div>
</div>

<div *ngIf="success">
    <div class="title-holder">
        <div class="title">Success!</div>
        <div class="subtext">You have successfully signed-in.</div>
    </div>
</div>

<div *ngIf="!success">
    <app-login-form
        (success)="onSuccess($event)"
        (forgotPassword)="forgotPass()"
    ></app-login-form>

    <div>
        <div class="something-else">
            <!-- <a class="" href="/auth/user/google" target="_self">Sign-in with Google</a>
            <div class="separator"></div> -->
            <div class="link" (bkButton)="close()">Close</div>
        </div>
    </div>
</div>

<p *ngIf="success" style="text-align: center;">Please wait a moment...</p>
