
<div class="checkout-section checkout-section-donor-info" [ngClass]="{active: active && enabled, completed: completed}">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title"><span class="num">{{number}}.</span><span>Your Information</span></div>
        <div class="checkout-section-edit-button" *ngIf="isEditable()"><span (bkClick)="edit()">Edit</span></div>
    </div>

    <div class="checkout-section-top-message" *ngIf="completed">
        <span>Your mailing address &amp; contact information.</span>
    </div>

    <form name="donorInfoForm" bkForm #donorInfoForm="bkForm" [ngClass]="{'bk-hidden': !active}" [appCheckoutSectionValidator]="sectionValidateForm(donorInfoForm)" [checkoutSection]="this">
        <app-form-inputs-person-contact-info
            [personData]="donor"
            [addressData]="donor"
            [phoneData]="donor.phone"
            [showInputLabels]="false"
            [disableAllInputs]="validating"
            (onPressedEnter)="next()"
        ></app-form-inputs-person-contact-info>

        <div class="input-holder checkbox-input-holder" bkFormControlHolder style="padding-top: 15px;" *ngIf="donation && hasAnonymousOption">
            <input type="checkbox" name="IsAnonymous" [(ngModel)]="donation.anonymous">
            <div class="checkbox-input-label muted">
                <span>Make this gift anonymous.</span>
            </div>
        </div>

        <div class="button-holder">
            <app-button (action)="next()" [busy]="validating">{{completed ? 'Done' : 'Continue'}}</app-button>
        </div>
    </form>

    <div class="checkout-section-detail-view checkout-section-detail-view-donor-info" *ngIf="!active && (activated || completed)">
        <div class="details-box">
            <div class="title">{{donor.firstName}} {{donor.lastName}}</div>
            <div class="more-details">
                <div class="phone" *ngIf="donor.phone.value">{{donor.phone.value}}</div>
                <div class="address-1">{{donor.address1}}</div>
                <div class="address-2" *ngIf="donor.address2">{{donor.address2}}</div>
                <div class="city-state"><span class="city">{{donor.city}}, </span><span class="state">{{donor.state}}</span></div>
                <div class="postal-code">{{donor.postal_code}}</div>
                <div class="country">{{donor.country_code | countryCodeToName:env.countries}}</div>
            </div>
            <div class="muted" style="padding-top: 8px;" *ngIf="hasAnonymousOption && donation?.anonymous">
                <span>Your gift will be anonymous.</span>
            </div>
            <div class="muted" style="padding-top: 8px;" *ngIf="hasAnonymousOption && !donation?.anonymous">
                <span>Your name will be printed with the gift amount.</span>
            </div>
        </div>
    </div>
</div>
