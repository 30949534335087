import { Route } from '@angular/router';

import { HomeComponent } from '@c/home/home.component';

export const outletPlatform = (options: Route = {}): Route => {
    return Object.assign({
        component: HomeComponent,
        outlet: "platform",
    }, options);
};
