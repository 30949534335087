import { Route } from '@angular/router';

import { DonateComponent } from '@c/donate/donate.component';

export const outletMerchant = (options: Route = {}): Route => {
    return Object.assign({
        component: DonateComponent,
        outlet: "merchant",
    }, options);
};
