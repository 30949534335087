import {
    Injectable,
} from '@angular/core';

import { BkErrorHandler, RestService } from 'bkng';
import { MatDialog } from '@angular/material/dialog';
import { ConsoleErrorDialogComponent } from '@c/console-error-dialog/console-error-dialog.component';
import { EnvService } from '@s/env.service';

let popOpen: boolean = false;

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor extends BkErrorHandler {
    constructor (
        public envService: EnvService,
        public restService: RestService,
        public dialog: MatDialog,
    ) {
        super(
            envService,
            restService
        );
    }

    pop (
        error: {
            message?: string;
        },
    ): void {
        const env = this.envService.data;

        if (
            [
                "development",
                "staging"
            ].includes(env?.mode)
            &&
            !popOpen
        ) {
            popOpen = true;

            const dialogRef = this.dialog.open(ConsoleErrorDialogComponent, {
                width: '500px',
                data: {
                    error: error,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                popOpen = false;
            });
        }
    }
}
