<div class="platform-view-component" *ngIf="merchant?.id">
    <div>Merchant: {{merchant.label}}</div>
    <div>
        <div>
            <a [routerLink]="['campaigns'] | linkFix">Campaigns ({{campaigns ? campaigns.length : 0}})</a>
        </div>
        <div>
            <a [routerLink]="['domains'] | linkFix">Domains ({{domains ? domains.length : 0}})</a>
        </div>
        <!-- <div>
            <a [routerLink]="['configs']">Configurations ({{configs ? configs.length : 0}})</a>
        </div>
        <div *ngIf="configs?.length">
            <a [routerLink]="['emails']">E-mails ({{emailTemplates ? emailTemplates.length : 0}})</a>
        </div>
        <div *ngIf="!configs?.length">
            <span>Add a config to see e-mails.</span>
        </div> -->
        <div>
            <a [routerLink]="['webhooks', 'incoming'] | linkFix">Incoming Webhooks</a>
        </div>
        <div>
            <a [routerLink]="['orders'] | linkFix">Orders</a>
        </div>
    </div>

    <form
        #frm="ngForm"
        class="edit-merchant-form"
        style="padding-top: 65px; width: 50%;"
    >
        <div class="input-field">
            <mat-form-field style="width: 100%;">
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'MerchantLabel'" [(ngModel)]="merchant.label" />
            </mat-form-field>
        </div>

        <div class="photo" style="padding: 25px 0px;">
            <div style="padding-bottom: 15px;">Logo:</div>
            <div class="image">
                <div
                    class="img"
                    style="width: 250px; height: 100px; background-repeat: no-repeat; background-position: center center; background-size: cover;"
                    [ngStyle]="{
                        'background-image': 'url(' + (merchant | merchantLogoUrl) + ')'
                    }"
                ></div>
            </div>

            <div class="upload-photo">
                <bk-file-upload-button
                    [accept]="'.jpg,.jpeg,.png'"
                    (selected)="logoFileSelected($event)"
                    #uploadLogoButton
                >
                    <app-button
                        [busy]="busy"
                        (action)="uploadLogoButton.clickFileInput()"
                    >
                        <span class="background-camera" (click)="uploadLogoButton.clickFileInput()">
                            <i class="material-icons camera">photo_camera</i>
                        </span>
                        <span>Upload logo</span>
                    </app-button>
                </bk-file-upload-button>

                <span
                    *ngIf="logoFileToUpload"
                >Will upload {{logoFileToUpload.name}} ...</span>
            </div>
        </div>

        <div class="input-field">
            <mat-form-field style="width: 100%;">
                <mat-label>Website URL</mat-label>
                <input matInput type="text" [name]="'MerchantWebsiteURL'" [(ngModel)]="merchant.data.websiteUrl" />
            </mat-form-field>
        </div>
    </form>

    <div style="padding-top: 50px;">
        <button
            mat-raised-button
            color="primary"
            (click)="save()"
            [disabled]="busy"
        >
            <span>Save</span>
        </button>
    </div>

    <div style="padding-top: 80px; display: flex;">
        <div>
            <app-button (action)="downloadCadDonationCsv()" [busy]="busy">Download Canadian-Dollar Donations CSV</app-button>
        </div>
    </div>

    <div style="display: flex;">
        <div>
            <app-button (action)="downloadDonationCsv()" [busy]="busy">Download Donations CSV</app-button>
        </div>
    </div>
</div>
