<div class="add-something-dialog">
    <div class="add-something-dialog-available-things">
        <div class="title">Available organizations:</div>
        <div *ngIf="availableCountries?.length">
            <mat-form-field appearance="fill">  
                <mat-label>Country</mat-label>
                <mat-select [name]="'SelectedCountry'" [(ngModel)]="countryCode">
                    <mat-option *ngFor="let c of availableCountries" [value]="c.code">
                        <span>{{c.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="availableOrganizations?.length">
            <div class="add-thing-selector" *ngFor="let c of availableOrganizations">
                <!-- <span class="link" (click)="selectExisting(c)">{{c.label}}</span> -->
                <span>{{c.label}}</span>
                <button mat-raised-button color="primary" (click)="selectExisting(c)" [disabled]="(availableCountries?.length && !countryCode) ? true : false">Add</button>
            </div>
        </div>
        <div *ngIf="!availableOrganizations?.length">
            <span>No available organizations.</span>
        </div>
    </div>
    <div style="padding-top: 35px; display: none;">
        <div>
            <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput type="text" [name]="'OrgLabel'" [(ngModel)]="organization.label" />
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="primary" [disabled]="!organization.label" (click)="createNew(organization)">Create</button>
        </div>
    </div>
</div>
