/*
    This should be re-written
    hence eslint rule-silencing
*/

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */

import {
    Component,
    ElementRef,
    forwardRef,
    Renderer2,
} from '@angular/core';

import {
    ControlContainer,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import { ContentService } from '../content.service';

import {
    SelectComponent,
    ISelectListItem
} from '../select/select.component';

@Component({
    selector: 'bk-select-dialling-codes',
    templateUrl: './select-dialling-codes.component.html',
    styleUrls: ['./select-dialling-codes.component.css'],
    exportAs: 'SelectComponent',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectDiallingCodesComponent),
            multi: true
        }
    ],
})
export class SelectDiallingCodesComponent extends SelectComponent {
    primaryKey = 'code';

    constructor (
        element: ElementRef,
        renderer: Renderer2,
        CS: ContentService,
        controlContainer: ControlContainer,
    ) {
        super(
            element,
            renderer,
            CS,
            controlContainer,
        );
    }

    display (
        item: ISelectListItem<{
            calling_code?: string;
        }>
    ): string {
        return `+${item?.data?.calling_code}`;
    }

    getItemValue (
        item: ISelectListItem<{
            code?: string;
        }>
    ): string {
        return item.data.code;
    }

    onCountryAutofill (): void {
        const inputEvent = (<InputEvent>event);
        const val = (<any>inputEvent.srcElement).value;
        this.selectItemByKey(val);
    }
}
