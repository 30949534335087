export function updateObject <
    TARGET,
    SOURCE,
    SENTIMENTAL = boolean,
    RESULT = SENTIMENTAL extends true ? (TARGET & SOURCE) : SOURCE
> (
    objectToUpdate: TARGET,
    objectToUpdateFrom: SOURCE,
    sentimental: SENTIMENTAL = <SENTIMENTAL>false
): RESULT {
    const _updateObject = (
        _obj: object,
        _uObj: object,
        _lineage?: unknown[],
    ) => {
        _lineage = [].concat(_lineage || []);

        if (_lineage.indexOf(_uObj) > -1) {
            // Don't dive into a circular reference.
            return;
        }

        _lineage.push(_uObj);

        if (!sentimental) {
            if (
                Object.prototype.toString.call(_uObj) === '[object Object]'
            ) {
                const uObjKeys = Object.getOwnPropertyNames(_uObj); //was Object.keys ...
                for (const key in _obj) {
                    if (uObjKeys.indexOf(key) < 0) {
                        delete _obj[key];
                    }
                }
            }
        }

        for (const uKey of Object.getOwnPropertyNames(_uObj)) {
            if (Object.prototype.toString.call(_uObj[uKey]) === '[object Object]') {
                if (Object.prototype.toString.call(_obj[uKey]) !== '[object Object]') {
                    _obj[uKey] = {};
                }
                if (_lineage.indexOf(_uObj[uKey]) > -1) {
                    console.error(`[updateObject] (Depth: ${_lineage.length}) Key '${uKey}' is circular.`);
                    return;
                }
                _updateObject(
                    <object>(_obj[uKey]),
                    <object>(_uObj[uKey]),
                    _lineage
                );
            }
            else if (Object.prototype.toString.call(_uObj[uKey]) === '[object Array]') {
                if (Object.prototype.toString.call(_obj[uKey]) !== '[object Array]') {
                    _obj[uKey] = [];
                }
                _updateObject(
                    <object>(_obj[uKey]),
                    <object>(_uObj[uKey]),
                    _lineage
                );
            }
            else {
                _obj[uKey] = <object>(_uObj[uKey]);
            }
        }

        return _obj;
    };

    return <RESULT>_updateObject(
        <object>objectToUpdate,
        <object>objectToUpdateFrom
    );
}
