import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() denominator: number;
    @Input() numerator: number;

    constructor () { }

    getProgressBarColor (): string {
        // base: rgb(33, 96, 147)
        const redBase = 33;
        const greenBase = 96;
        const blueBase = 147;

        const redTarget = 0;
        const greenTarget = 128;
        const blueTarget = 0;

        const redDifference = redTarget - redBase;
        const greenDifference = greenTarget - greenBase;
        const blueDifference = blueTarget - blueBase;

        let fraction = this.getProgressFraction();
        if (fraction < 0) {
            fraction = 0;
        }
        else if (fraction > 1) {
            fraction = 1;
        }

        // green: rgb(0,128,0)
        const red = redBase + (redDifference * fraction);
        const green = greenBase + (greenDifference * fraction);
        const blue = blueBase + (blueDifference * fraction);

        return `rgba(${red}, ${green}, ${blue}, 1)`;
    }

    getProgressBarWidth (): string {
        let fraction = Math.abs(this.getProgressFraction());
        if (fraction > 1) {
            fraction = 1;
        }
        else if (fraction < 0) {
            fraction = 0;
        }
        const percent = fraction * 100;
        return `${percent.toFixed(0)}%`;
    }

    getProgressFraction (): number {
        if (
            this.numerator === undefined
            ||
            this.denominator === undefined
        ) {
            return 0;
        }
        const num = parseFloat(`${this.numerator}`);
        const den = parseFloat(`${this.denominator}`);
        const fraction = num / den;
        return fraction;
    }

    getProgressPercentage (): string {
        const fraction = Math.abs(this.getProgressFraction());
        const percent = fraction * 100;
        return `${percent.toFixed(0)}%`;
    }

    ngOnInit (): void {
    }
}
