import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';

import { IEmailTemplate } from '@amt/i-email-template';

import { AddEmailTemplateDialogComponent, IAddEmailTemplateDialogData } from '../add-email-template-dialog/add-email-template-dialog.component';

@Component({
    selector: 'app-add-email-template',
    templateUrl: './add-email-template.component.html',
    styleUrls: ['./add-email-template.component.scss']
})
export class AddEmailTemplateComponent implements OnInit {
    @Input() data: IAddEmailTemplateDialogData;
    @Output() selected = new EventEmitter<IEmailTemplate>();

    constructor (
        public dialog: MatDialog,
    ) { }

    ngOnInit (): void {
    }

    openDialog (): MatDialogRef<AddEmailTemplateDialogComponent> {
        const dialogRef = this.dialog.open(AddEmailTemplateDialogComponent, {
            width: '556px',
            data: this.data
        });

        dialogRef.afterClosed().subscribe((result: IEmailTemplate) => {
            if (result) {
                this.selected.emit(result);
            }
        });

        return dialogRef;
    }
}
