import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IConfig } from '@amt/i-config';
import { IDomain } from '@amt/i-domain';
import { IEmailTemplate } from '@amt/i-email-template';
import { IOrganization } from '@amt/i-organization';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { OrganizationService } from '@s/organization.service';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-organization-view',
    templateUrl: './organization-view.component.html',
    styleUrls: ['./organization-view.component.scss']
})
export class OrganizationViewComponent implements OnInit {
    blocker: Blocker;
    configs: Array<IConfig>;
    domains: Array<IDomain>;
    emailTemplates: Array<IEmailTemplate>;
    organization: IOrganization;
    saveSubject = new Subject();

    constructor (
        public env: EnvService,
        public lbs: LoadBlockerService,
        public orgService: OrganizationService,
        public route: ActivatedRoute,
    ) {
        this.blocker = lbs.register({});

        this.fetch().catch((err) => {
            console.error(err);
        }).finally(() => {
            this.blocker.unblock();
        });

        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save().catch((err) => {
                console.error(err);
            });
        });
    }

    doSave (): void {
        this.saveSubject.next(null);
    }

    fetch (): Promise<void> {
        return this.orgService.getById({
            organizationId: <string>(this.route.snapshot.params.organizationId),
        }).then((res: unknown) => {
            console.log("OrganizationViewComponent", res);
            Object.assign(this, res);
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }

    save (): Promise<void> {
        return this.orgService.save(this.organization.id, this.organization).then((res) => {
            return Promise.resolve();
        });
    }
}
