import {
    Directive,
    ElementRef,
    forwardRef,
    Input
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import {
    TextInputFilterDirective,
    TifFilterInput,
    TifFilterOutput,
} from './text-input-filter.directive';

export interface TifCurrencyObject {
    symbol: string;
}

export interface CifFiltered {
    accepted?: string;
    addedCurrencySymbol: boolean;
    input: TifFilterInput;
    output: TifFilterOutput;
    rejected?: string;
    returnCaretTo?: number;
}

@Directive({
    selector: '[bkCurrencyInputFilter]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyInputFilterDirective),
            multi: true
        }
    ],
})
export class CurrencyInputFilterDirective extends TextInputFilterDirective {
    @Input() currency: TifCurrencyObject = {
        symbol: `$`
    };
    tempMinDecimals: number;
    @Input() tifOptions: {
        integers: {
            min: number;
            max: number;
            required: boolean;
        };
        decimals: {
            min: number;
            max: number;
            required: boolean;
        };
        options?: {
            integers: {
                min: number;
                max: number;
                required: boolean;
            };
        };
    } = {
        integers: {
            min: 1,
            max: 15,
            required: true
        },
        decimals: {
            min: 2,
            max: 2,
            required: false
        }
    };

    constructor (
        public elementRef: ElementRef
    ) {
        super(elementRef);
    }

    filter (
        inputValue: string
    ): CifFiltered {
        const data: CifFiltered = {
            addedCurrencySymbol: false,
            input: {value: inputValue},
            output: {value: inputValue, viewValue: inputValue}
        };

        if (!inputValue.length) return data;

        // const wantReg = new RegExp(`(^[${this.currency.symbol}0-9.]{0,1})|([0-9.])`, `g`);
        const wantReg = /[0-9.]+/g;

        const options = this.tifOptions;

        if (options.integers.min > options.integers.max) options.integers.min = options.options.integers.max;
        if (options.decimals.min > options.decimals.max) options.decimals.min = options.decimals.max;

        this.tempMinDecimals = (typeof(this.tempMinDecimals) === 'number') ? this.tempMinDecimals : options.decimals.min;
        if (this.tempMinDecimals > options.decimals.min) this.tempMinDecimals = options.decimals.min;

        data.rejected = data.input.value.replace(wantReg, '');

        const acceptMatch = data.input.value.match(wantReg);
        data.accepted = acceptMatch ? acceptMatch.join('') : '';

        if (event?.type === `input`) { // event just resolves, apparently...
            if ((<{data?: string;}>event)?.data === `.`) {
                // obv needs to be smarter, aware of what was before.
                const splitP = (inputValue.indexOf(this.currency.symbol) > -1) ? (this.element.selectionStart - 2) : (this.element.selectionStart - 1);
                const newIntegers = data.accepted.substring(0, splitP).split('.').join('');
                const newDecimals = data.accepted.substring(splitP + 1, data.accepted.length).split('.').join('');
                data.returnCaretTo = newIntegers.length + 2;
                data.accepted = `${newIntegers}.${newDecimals}`;
            }
        }

        const numberParts = data.accepted.split('.');
        let integers = numberParts.shift();
        let decimals = numberParts.join('').substring(0, options.decimals.max);

        if (
            !event
            || ([`input`].indexOf(event?.type) < 0)
        ) {
            decimals = decimals.replace(/0+$/, '');
            if (options.integers.min) {
                if (integers.length < options.integers.min) {
                    integers = `${integers}${'0'.repeat(options.integers.min - integers.length)}`;
                }
            }
            if (options.decimals.min) {
                if (decimals.length < options.decimals.min) {
                    decimals = `${decimals}${'0'.repeat(options.decimals.min - decimals.length)}`;
                }
            }
        }
        else if ([`input`].indexOf(event?.type) > -1) {
            this.tempMinDecimals = (typeof(decimals.length) === 'number') ? decimals.length : 0;
        }

        let outputViewValue = `${integers}`;
        let outputModelValue = outputViewValue;

        if (decimals?.length) {
            outputModelValue = outputViewValue = `${outputViewValue}.${decimals}`;
        }
        else if ((<{data?: string;}>event)?.data === `.`) {
            outputViewValue = `${outputViewValue}.`;
        }

        outputViewValue = `${this.currency.symbol}${outputViewValue}`;

        if (
            this.currency?.symbol
            && (integers?.length || decimals?.length)
            && (inputValue.indexOf(this.currency.symbol) < 0)
        ) {
            data.addedCurrencySymbol = true;
        }

        data.output.value = outputModelValue;
        data.output.viewValue = outputViewValue;

        if ([`input`].indexOf(event?.type) > -1) {
            data.returnCaretTo = data.returnCaretTo || this.element.selectionStart;
            if (data.addedCurrencySymbol) {
                data.returnCaretTo++;
            }
        }

        return data;
    }
}
