import { BkRoutes } from 'bkng';

import { id } from './id/id';

export const template: BkRoutes = [
    {
        path: "id",
        label: "Organization Emails Template ID",
        children: id
    },
];
