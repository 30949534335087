<div>
    <b>Modify payment mission</b>
</div>
<div class="payment-data">
    <div><b>Customer:</b> {{data.payment.customer.person?.name || data.payment.customer.id}} ({{data.payment.customer.email}})</div>
    <div><b>Payment amount:</b> {{data.payment.amount | currency}} {{data.payment.currency_code}}</div>
    <div><b>Payment date:</b> {{data.payment.created_at | friendlyDateTime}}</div>
</div>
<form
    name="dialogForm"
    bkForm
    #dialogForm="bkForm"
    (bkSubmit)="modify()"
>
    <div class="selector">
        <div class="label">Campaign:</div>
        <select
            bkFormControl
            required
            [name]="'CampaignSelector'"
            [(ngModel)]="selected.campaignId"
            (ngModelChange)="setCampaign(selected.campaignId)"
        >
            <option *ngFor="let item of data.campaigns" [value]="item.id">
                <span>{{item.label || item.name || item.id}}</span>
            </option>
        </select>
    </div>

    <div class="selector">
        <div class="label">Participant:</div>
        <select
            bkFormControl
            required
            [name]="'CampaignParticipantSelector'"
            [(ngModel)]="selected.campaignParticipantId"
            (ngModelChange)="setCampaignParticipant(selected.campaignParticipantId)"
        >
            <option *ngFor="let item of (selected.campaign?.participants || [])" [value]="item.id">
                <span>{{item.user?.name || item.id}} ({{item.user?.email?.[0]?.value || 'no email?'}})</span>
            </option>
        </select>
    </div>

    <div class="selector">
        <div class="label">Mission:</div>
        <select
            bkFormControl
            required
            [name]="'CampaignParticipantMissionSelector'"
            [(ngModel)]="selected.campaignParticipantMissionId"
            (ngModelChange)="setCampaignParticipantMission(selected.campaignParticipantMissionId)"
        >
            <option *ngFor="let item of (selected.campaignParticipant?.missions || [])" [value]="item.id">
                <span>{{item.url ? ('/' + item.url) : item.id}} ({{item.mission?.data.eventType || 'eventType?'}})</span>
            </option>
        </select>
    </div>

    <div>
        <button
            type="button"
            mat-raised-button
            color="primary"
            [disabled]="busy || !dialogForm.ngForm.valid"
            (click)="dialogForm.submit()"
        >Update</button>
    </div>
</form>
