<div class="merchant-order-list-item">
    <div>
        <div class="merchant-order-list-item-general-info">
            <div class="merchant-order-list-item-order-date">
                <span>{{order.created_at | friendlyDateTime}}</span>
            </div>
            <div class="merchant-order-list-item-order-id">
                <span>{{order.id}}</span>
            </div>
        </div>
    
        <div class="merchant-order-list-item-campaign">
            <span>Campaign: <b>{{order.campaign.label}}</b></span>
        </div>
    
        <div class="merchant-order-list-item-customers-section">
            <div class="merchant-order-list-item-customers-section-title">
                <span>Customers:</span>
            </div>
            <div class="merchant-order-list-item-customers-list">
                <div class="merchant-order-list-item-customers-list-item" *ngFor="let cust of order.customers">
                    <div>{{cust.person?.name || cust.id}} ({{cust.email}})</div>
                </div>
            </div>
        </div>
    
        <div class="merchant-order-list-item-payments-section">
            <div class="merchant-order-list-item-payments-section-title">
                <span>Payments:</span>
            </div>
            <div class="merchant-order-list-item-payments-list">
                <div class="merchant-order-list-item-payments-list-item" *ngFor="let pmt of order.payments">
                    <app-merchant-order-list-item-payment (modifiedPaymentMission)="modifiedPaymentMission.emit($event)" [campaign]="order.campaign" [campaigns]="campaigns" [payment]="pmt"></app-merchant-order-list-item-payment>
                </div>
            </div>
        </div>

        <div class="merchant-order-list-item-payments-section">
            <div class="merchant-order-list-item-payments-section-title">
                <span>Receipts:</span>
            </div>
            <div class="merchant-order-list-item-payments-list">
                <div class="merchant-order-list-item-payments-list-item" *ngFor="let rcpt of order.receipts">
                    <div>
                        <span>{{rcpt.created_at | friendlyDateTime}} - (Org) - #{{rcpt.number}}</span>
                    </div>
                    <div *ngIf="rcpt.send?.length">
                        <div>
                            <span>Sent {{rcpt.send.length}} time(s):</span>
                        </div>
                        <div *ngFor="let snd of rcpt.send">
                            <div>Sent: {{snd.created_at}}</div>
                        </div>
                    </div>
                    <div *ngIf="!rcpt.send?.length">
                        <div>Never sent.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div>
            <span class="link" (click)="open = !open">{{open ? 'V' : '>'}} &nbsp;</span>
            <span>See more/less</span>
        </div>
        <div *ngIf="open" style="padding: 5px; border: 1px solid grey;">
            <pre
                style="white-space: pre-wrap; overflow: hidden;"
                [innerHtml]="order | stirfry"
                style="padding: 8px 0px; font-size: 12px;"
            ></pre>
        </div>
    </div>
</div>
