import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICampaign } from '@amt/i-campaign';
import { IDomain } from '@amt/i-domain';

import { IEmailTemplate } from '@amt/i-email-template';
import { IMerchant } from '@amt/i-merchant';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { EnvService } from '@s/env.service';
import { IAddCampaignDialogData, IAddCampaignDialogReturnData } from '../add-campaign-dialog/add-campaign-dialog.component';

import { MerchantService } from '@s/merchant.service';
import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-merchant-campaigns-view',
    templateUrl: './merchant-campaigns-view.component.html',
    styleUrls: ['./merchant-campaigns-view.component.scss']
})
export class MerchantCampaignsViewComponent implements OnInit {
    blocker: Blocker;
    campaigns: Array<ICampaign>;
    domains: Array<IDomain>;
    emailTemplates: Array<IEmailTemplate> = [];
    env: IEnv;
    merchant: IMerchant;
    data: IAddCampaignDialogData;

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public merchantService: MerchantService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.blocker = lbs.register({});

        this.fetch().catch(console.error).finally(() => {
            this.blocker.unblock();
        });
    }

    async createdCampaign (data: IAddCampaignDialogReturnData): Promise<void> {
        await this.fetch();
    }

    fetch (): Promise<void> {
        return this.merchantService.getById(
            <string>(this.route.snapshot.params.merchantId)
        ).then((res) => {
            console.log("MerchantCampaignsViewComponent", res);
            Object.assign(this, res);
            this.data = res;
            return Promise.resolve();
        });
    }

    ngOnInit (): void {
    }
}
