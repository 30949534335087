
<div class="checkout-section checkout-section-donor-info active">
    <div class="checkout-section-title-holder">
        <div class="checkout-section-title">
            <!-- <span class="num">{{number}}.</span> -->
            <span>Sign up</span>
        </div>
    </div>
    <div class="checkout-section-subtitle-holder">
        <div class="checkout-section-subtitle">
            <span>Use the form below to sign up for 1907 Foundation's "{{env.campaign.label || env.campaign.name || env.campaign.id}}" campaign. Challenge yourself and raise money for a good cause!</span>
        </div>
    </div>

    <form
        name="SignUpForm"
        bkForm
        #signUpForm="bkForm"
        (bkSubmit)="submit(signUpForm)"
    >
        <div class="inputs-sidebyeaches two-sidebyeaches">
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label" *ngIf="!options.firstName">First name</div>
                <input
                    name="FirstName"
                    type="text"
                    [(ngModel)]="options.firstName"
                    required
                    (keyup.enter)="signUpForm.submit()"
                    autocomplete="given-name"
                    bkFormControl
                >
                <bk-validation-display></bk-validation-display>
            </div>
            <div class="input-holder" bkFormControlHolder>
                <div class="input-label" *ngIf="!options.lastName">Last name</div>
                <input
                    name="LastName"
                    type="text"
                    [(ngModel)]="options.lastName"
                    required
                    (keyup.enter)="signUpForm.submit()"
                    autocomplete="family-name"
                    bkFormControl
                >
                <bk-validation-display></bk-validation-display>
            </div>
        </div>

        <div class="input-holder" bkFormControlHolder #fch="bkFormControlHolder">
            <div class="input-label" *ngIf="!options.email">Email</div>
            <input
                type="email"
                name="Email"
                [(ngModel)]="options.email"
                required
                (keyup.enter)="signUpForm.submit()"
                bkFormControl
                autocomplete="email"
            >
            <bk-validation-display></bk-validation-display>
            <!-- <div class="input-customerinfo" *ngIf="!(signUpForm.submissionAttempted && fch.control.errors)">
                <span>You'll receive receipts and notifications at this email address.</span>
            </div> -->
        </div>

        <!-- <div class="input-holder">
            <bk-phone-number-input
                [countries]="env.countries"
                [phone]="options.phone"
                (pressedEnter)="signUpForm.submit()"
                [name]="'MobilePhoneNumber'"
                [label]="'Mobile number'"
                required
            ></bk-phone-number-input>
        </div> -->
        
        <div class="input-holder" bkFormControlHolder>
            <div class="input-label" *ngIf="!options.password">Password</div>
            <input
                bkFormControl
                #passControl="bkFormControl"
                type="password"
                name="password"
                [(ngModel)]="options.password"
                required
                (keyup.enter)="signUpForm.submit()"
                autocomplete="new-password"
                [validatorName]="'new-password'"
            >
            <bk-validation-display-hints
                [ngClass]="{
                    'bk-hidden': (!passControl.control?.dirty && !passControl.control?.touched && !signUpForm.submissionAttempted)
                }"
            ></bk-validation-display-hints>
        </div>

        <bk-form-server-errors></bk-form-server-errors>

        <div class="button-holder">
            <app-button (action)="signUpForm.submit()" [busy]="busy">Submit</app-button>
        </div>
    </form>

    <div style="padding-top: 25px; text-align: center;">
        <div class="link" (bkClick)="openSignInDialog({redirect: '/account'})">Already have an account? Sign in!</div>
    </div>
</div>
