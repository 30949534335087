<div class="platform-view-component" *ngIf="data?.campaign?.id">
    <div>Campaign: {{data.campaign.label}}</div>

    <div style="padding-top: 15px;">
        <app-checkbox
            [checked]="data.campaign.status === 'open'"
            (clicked)="toggleStatus()"
            name="ToggleCampaignStatus"
            [color]="'warn'"
            [disabled]="busy"
        >
            <span>Open</span>
        </app-checkbox>
    </div>

    <div style="padding-top: 25px;">
        <a [routerLink]="['participants']">Participants ({{data.participants?.length || 0}})</a>
    </div>

    <div style="margin-top: 25px; padding: 15px; border: 1px solid lightgrey;">
        <!-- <div style="flex: 1 0 auto;">
            <mat-form-field style="width: 100%;">
                <mat-label>Campaign Label</mat-label>
                <input matInput type="text" [name]="'ConfigLabel'" [(ngModel)]="campaign.label" (ngModelChange)="doSave()" />
            </mat-form-field>
        </div> -->
        <app-campaign-edit
            [campaign]="data.campaign"
            [data]="data"
        ></app-campaign-edit>
    </div>

    <div style="padding-top: 25px;">
        <app-add-campaign-participant
            [data]="{route: route}"
            (created)="addedCampaignParticipant($event)"
        >
            <span>Add Campaign Participant</span>
        </app-add-campaign-participant>
    </div>

    <div style="padding-top: 15px;" *ngIf="data.participants?.length">
        <div style="padding-top: 25px;">Participants:</div>
        <div *ngFor="let p of data.participants" style="padding: 10px 0px;">
            <a [routerLink]="['participant', 'id', p.user.id]">{{p.user.name || p.user.email?.[0]?.value || p.id}} ({{p.user.email?.[0]?.value}})</a>
        </div>
    </div>
</div>
