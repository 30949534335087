import { BkRouteInfo, BkRoutes } from 'bkng';

import { outletLoggedIn } from '@ngr/outlet-logged-in';
import { routeStartingPoint } from '@ngr/route-starting-point';
import { outletTeamDonationCampaign } from '@ngr/outlet-team-donation-campaign';

import { AdminCampaignParticipantViewComponent } from '@c/admin-campaign-participant-view/admin-campaign-participant-view.component';
import { GetCampaignResult } from '@api-routes/admin/types/get-campaign-result';

export const id: BkRoutes = [
    {
        path: ":campaignParticipantId",
        label: "Admin: Campaign Participant",
        setInfo: (data: GetCampaignResult): BkRouteInfo => {
            return {
                crumb: `${data?.campaignParticipants?.[0]?.id ? data.campaignParticipants[0].user.email[0]?.value : `[No email?]`}`,
            };
        },
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletLoggedIn({component: AdminCampaignParticipantViewComponent})
            ])
        ]),
        children: [
        ],
    },
];
