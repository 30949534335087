import { ICampaign } from '@amt/i-campaign';
import { ICampaignParticipant } from '@amt/i-campaign-participant';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    DeleteCampaignParticipantModalComponent,
    IDeleteCampaignParticipantDialogData
} from '../delete-campaign-participant-modal/delete-campaign-participant-modal.component';

@Component({
    selector: 'app-delete-campaign-participant-button',
    templateUrl: './delete-campaign-participant-button.component.html',
    styleUrls: ['./delete-campaign-participant-button.component.scss'],
    exportAs: 'deleteCampaignParticipant'
})
export class DeleteCampaignParticipantButtonComponent implements OnInit {
    @Output() deleted = new EventEmitter<boolean>();
    @Input() campaign: ICampaign;
    @Input() campaignParticipant: ICampaignParticipant;

    constructor (
        public dialog: MatDialog,
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit (): void {
    }

    openDialog (): MatDialogRef<DeleteCampaignParticipantModalComponent> {
        const data: IDeleteCampaignParticipantDialogData = {
            campaign: this.campaign,
            campaignParticipant: this.campaignParticipant,

        };

        const dialogRef = this.dialog.open(DeleteCampaignParticipantModalComponent, {
            width: '556px',
            data: data
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            this.deleted.emit(result);
        });

        return dialogRef;
    }
}
