<div class="domain-list-item">
    <div class="delete-domain-button">
        <button mat-mini-fab color="basic" [disabled]="busy || deleted" (click)="delete()">
            <mat-icon [color]="willDelete ? 'warn' : 'basic'" *ngIf="!deleted">delete</mat-icon>
            <mat-icon [color]="'basic'" *ngIf="deleted">remove_done</mat-icon>
        </button>
    </div>
    <div>
        <a [routerLink]="['id', domain.id] | linkFix" *ngIf="!deleted">{{domain.full || domain.id}}</a>
        <span *ngIf="deleted">Deleted: {{domain.full || domain.id}}</span>
    </div>
</div>
