<div *ngIf="merchantHubspotIndex">
    <div>
        <b *ngIf="integration.options.prod">[PROD] </b>
        <b>HubSpot is Connected</b>
    </div>

    <div>
        <span>Number of Hubspot Contacts: </span>
        <b>{{merchantHubspotIndex?.contacts?.total || "None?"}}</b>
    </div>

    <!-- <div style="padding-top: 35px;" *ngIf="propertiesContactsFiltered?.length">
        <div *ngFor="let item of propertiesContactsFiltered" style="padding: 3px 0px;">
            <div>{{item.name}}</div>
        </div>
    </div> -->

    <div *ngIf="!integration.options.prod && canCopyProdSettings" style="padding: 15px 0px;">
        <app-button
            (action)="copyProdSettingsToDev()"
            [busy]="busy"
        >
            <span>Copy Prod Settings to Dev Account</span>
        </app-button>
    </div>

    <div *ngIf="!integration.options.prod"  style="padding: 15px 0px;">
        <app-button
            (action)="syncContactsWithHubspot()"
            [busy]="busy"
        >
            <span>Pyzon -> Hubspot</span>
        </app-button>
    </div>
</div>
