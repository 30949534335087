import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

export interface TifFilterInput {
    value: string;
}

export interface TifFilterOutput {
    value: string;
    viewValue: string;
}

export interface TifFiltered {
    accepted?: string;
    input: TifFilterInput;
    output: TifFilterOutput;
    rejected?: string;
    returnCaretTo?: number;
}

@Directive({
    selector: '[bkTextInputFilter]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputFilterDirective),
            multi: true
        }
    ],
})
export class TextInputFilterDirective implements ControlValueAccessor {
    element: HTMLTextAreaElement;
    onChange: (val: unknown) => unknown;
    onTouched: () => unknown;
    @Input() tifOptions: unknown = {};

    constructor (
        public elementRef: ElementRef
    ) {
        this.element = <HTMLTextAreaElement>(elementRef.nativeElement);
    }

    checkAndSetCaretPos (filtered: TifFiltered, inputEvent: InputEvent): void {
        if (typeof(filtered.returnCaretTo) === `number`) {
            this.element.selectionStart = this.element.selectionEnd = filtered.returnCaretTo;
        }
    }

    filter (inputValue: string): TifFiltered {
        const data: TifFiltered = {
            input: {value: inputValue},
            output: {value: inputValue, viewValue: inputValue}
        };
        return data;
    }

    @HostListener('blur', ['$event'])
    onBlurHandler (e: FocusEvent): void {
        this.update();
    }

    @HostListener('input', ['$event'])
    onInputHandler (e: InputEvent): void {
        const filtered = this.update();
        this.checkAndSetCaretPos(filtered, e);
    }

    registerOnChange (
        fn: (val: unknown) => unknown,
    ): void {
        // ng ControlValueAccessor
        this.onChange = fn;
    }

    registerOnTouched (
        fn: () => unknown,
    ): void {
        // ng ControlValueAccessor
        this.onTouched = fn;
    }

    setValues (filtered: TifFiltered): void {
        this.element.value = filtered.output.viewValue;
        if (this.onChange) this.onChange(
            filtered.output.value
        );
    }

    update (): TifFiltered  {
        const filtered = this.filter(this.element.value);
        this.setValues(filtered);
        return filtered;
    }

    writeValue (
        val: string
    ): void {
        // ng ControlValueAccessor
        this.element.value = val ? val : '';
        this.update();
    }
}
