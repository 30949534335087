import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    Blocker,
    LoadBlockerService,
    RecaptchaService,
} from 'bkng';

import { UserService } from '@s/user.service';
import { IEnv } from '@alt/i-env';
import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-account-password-reset',
    templateUrl: './account-password-reset.component.html',
    styleUrls: ['./account-password-reset.component.scss']
})
export class AccountPasswordResetComponent implements OnInit {
    busy: boolean = false;
    clearedToLoad: boolean = true;
    env: IEnv;
    loadBlocker: Blocker;
    options: {
        password?: string;
        recaptchaToken?: string;
        token?: string;
        userId?: string;
    } = {};
    serverErrorMessages: Array<string>;

    constructor (
        public envService: EnvService,
        public userService: UserService,
        public recaptchaService: RecaptchaService,
        public route: ActivatedRoute,
        public lbs: LoadBlockerService,
        public router: Router,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    ngOnInit (): void {
        setTimeout(() => {
            this.loadBlocker.unblock();
            this.clearedToLoad = true;
        });
    }
}
