import { IEnv } from '@alt/i-env';
import { Component, OnInit } from '@angular/core';

import { EnvService } from '@s/env.service';

@Component({
    selector: 'app-campaign-admin-navigation',
    templateUrl: './campaign-admin-navigation.component.html',
    styleUrls: ['./campaign-admin-navigation.component.scss']
})
export class CampaignAdminNavigationComponent implements OnInit {
    env: IEnv;

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }
}
