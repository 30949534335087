import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    // HttpErrorResponse,
    HttpEventType,
    HttpResponse
} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { CommonEnvService } from '../env.service';

/*

    ** NOTICE **


    DEPRECATED IN FAVOUR OF bk-file-upload-button


    ** NOTICE **

    */

@Component({
    selector: 'bk-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    @Input() accept: string;
    @Output() complete = new EventEmitter<unknown>();
    @Input() disabled: boolean = false;
    env: unknown = {};
    fileToUpload: File = null;
    loadingFileForUpload: boolean = false;
    @Input() showUploadButton: boolean = true;
    selecting: boolean = false;
    @Output() selected = new EventEmitter<UploadFileComponent>();
    @Output() status = new EventEmitter<UploadFileComponent>();
    uploadFileProgressPercent: unknown;
    uploadedFileSuccessfully: boolean = false;
    uploadFileError: unknown;
    uploadingFile: boolean = false;
    @Input() uploadMethod: (options: {
        fileToUpload: File;
    }) => Observable<unknown>;

    constructor (
        public envService: CommonEnvService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
    }

    clearFile (): void {
        this.fileToUpload = null;
        this.status.emit(this);
    }

    clickFileInput (
        fileInput: {
            click: () => void;
        }
    ): void {
        this.status.emit(this);
        fileInput.click();
        this.selecting = true;
    }

    fileChanged (
        event: unknown
    ): void {
        this.selecting = false;
        const files: FileList = (<{target: {files: FileList;}}>event).target.files;
        this.fileToUpload = files.item(0);
        this.status.emit(this);
        this.selected.emit(this);
    }

    ngOnInit (): void {
    }

    uploadFile (): void {
        if (this.uploadingFile) {
            return;
        }

        this.loadingFileForUpload = true;
        this.uploadingFile = true;
        delete this.uploadFileError;

        this.status.emit(this);

        const uploadObservable = this.uploadMethod({
            fileToUpload: this.fileToUpload,
        });

        uploadObservable.subscribe({
            next: (event: unknown) => {
                if ((<{type: unknown}>event).type === HttpEventType.UploadProgress) {
                    this.uploadFileProgressPercent = Math.round(100 * (<{loaded: number}>event).loaded / (<{total: number}>event).total);
                }
                else if (event instanceof HttpResponse) {
                    this.uploadedFileSuccessfully = true;
                    // event.body.attachment? event.body.file?
                }
            },
            error: (err) => {
                this.uploadFileError = err;
                //if (err instanceof HttpErrorResponse) {
                //}
            },
            complete: () => {
                this.uploadingFile = false;
                this.loadingFileForUpload = false;
                this.status.emit(this);
                this.complete.emit(this);
            },
        });
    }
}
