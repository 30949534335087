/*
    This pipe was created in response to an issue
    that arose when upgrading to Angular 16, in which
    hrefs produced from [routerLink] directives appeared
    to be malformed: they included parantheses and such.
*/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkFix',
    // pure: false,
})
export class LinkFixPipe implements PipeTransform {

    transform (
        value: (string | number)[] | (string | number),
        // ...args: unknown[]
    ): string | string[] | number[] | (string | number)[] | null | undefined {
        let startWith = window.location.pathname;

        if (
            Array.isArray(value)
        ) {
            value = value.map((v) => {
                return `${v}`;
            }).filter((v) => {
                return ![
                    "",
                    "."
                ].includes(v);
            }).join("/").split("/");

            if (
                value[0] === ""
            ) {
                // had been a slash at the beginning.
                value[0] = "/";
            }

            if (
                !/^\//.test(<string>value[0])
            ) {
                value.unshift(
                    startWith
                );
            }
        }
        else {
            if (
                typeof(value) === "string"
            ) {
                if (
                    !/^\//.test(value)
                    &&
                    !/\/$/.test(startWith)
                ) {
                    startWith = `${startWith}/`;
                }
            }
            value = `${startWith}${value}`;
        }

        return value;
    }
}
