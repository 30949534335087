import { Component, OnInit } from '@angular/core';

import {
    Blocker,
    LoadBlockerService,
} from 'bkng';

import { AccountService } from '@s/account.service';
import { IEnv } from '@alt/i-env';
import { ActivatedRoute } from '@angular/router';
import { EnvService } from '@s/env.service';
import { lastValueFrom } from 'rxjs';
import { ISubscription } from '@amt/i-subscription';

@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
    busy: boolean = false;
    env: IEnv;
    loadBlocker: Blocker;
    subscriptions: Array<ISubscription> = [];

    constructor (
        public envService: EnvService,
        public lbs: LoadBlockerService,
        public accountService: AccountService,
        public route: ActivatedRoute,
    ) {
        this.env = envService.data;
        this.loadBlocker = lbs.register({});
    }

    cancelSubscription (
        subscription: ISubscription
    ): Promise<void> {
        this.busy = true;
        return this.accountService.cancelSubscription(
            subscription
        ).toPromise().then((result) => {
            console.log("Cancelled subscription", result);
            Object.assign(subscription, result);
        }).catch((err) => {
            console.error("Error cancelling subscription");
        }).finally(() => {
            this.busy = false;
        });
    }

    fetch (): Promise<unknown> {
        if (!this.env.user?.id) {
            this.loadBlocker.unblock();
            return;
        }
        Promise.resolve().then(() => {
            return lastValueFrom(
                this.accountService.$get()
            ).then((result: unknown) => {
                return <{
                    subscriptions: ISubscription[];
                }>result;
            });
        }).then((result) => {
            console.log("userAccountComponent:", result);
            this.subscriptions = result.subscriptions;
        }).catch((err) => {
            console.error('accountService error', err);
        }).finally(() => {
            this.loadBlocker.unblock();
        });
    }

    loggedIn (): void {
        this.fetch().catch(console.error);
    }

    ngOnInit (): void {
        this.route.queryParams.subscribe((params) => {
            this.fetch().catch(console.error);
        });
    }
}
