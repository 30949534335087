import { BkRoutes } from 'bkng';

import { outletLoggedIn } from '@ngr/outlet-logged-in';
import { outletNotLoggedIn } from '@ngr/outlet-not-logged-in';
import { outletTeamDonationCampaign } from '@ngr/outlet-team-donation-campaign';
import { routeStartingPoint } from '@ngr/route-starting-point';

import { CreateAccountSuccessComponent } from '@c/create-account-success/create-account-success.component';

export const signup: BkRoutes = [
    {
        label: "Signup: Success",
        path: "success",
        ngRoute: routeStartingPoint([
            outletTeamDonationCampaign([
                outletNotLoggedIn({component: CreateAccountSuccessComponent}),
                outletLoggedIn({component: CreateAccountSuccessComponent}),
            ]),
        ]),
    }
];
